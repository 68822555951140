import React from 'react'
import { Typography } from '@material-ui/core'
import { useRouter } from 'common/hooks/use-router';

const ErrorPage2 = () => {
  const { back, push } = useRouter();
  
  return (
    <div className="sub-wrap">
      <div className="list-empty pt100">
        <div className="list-empty-caution">
          <div className="title-section">
            <Typography className="title pb20">
              죄송합니다. 페이지를 찾을 수 없습니다.
            </Typography>
          </div>
          <ul className="defalut-section">
            <li>주소가 잘못 입력되었거나,</li>
            <li>네트워크 에러가 발생하여 요청하신 페이지를 찾을 수 없습니다.</li>
            <li>입력하신 주소가 정확한지 다시 한번 확인해 주세요.</li>
          </ul>
          <button type="button" className="btnGoBack_w" onClick={back}>
            이전으로 가기
          </button>
          <button type="button" className="btnGoBack goToMain" onClick={() => push("/")}>
            메인으로 가기
          </button>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage2
