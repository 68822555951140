import moment from 'moment';

export function toCurrency(value) {
  if (typeof value !== 'number') {
    value = parseInt(value);
  }

  const formatter = new Intl.NumberFormat('en-KR', {
    minimumFractionDigits: 0,
  });

  return formatter.format(value);
}

export function toPhone(value) {
  if (!value) return '';
  value = value.replace(/[^0-9]/g, '');

  let result = '';
  if (value.length < 4) {
    result = value;
  } else if (value.length < 7) {
    result = value.replace(/(\d{3})(\d{3})/, '$1-$2');
  } else if (value.length === 8) {
    result = value.replace(/(\d{4})(\d{4})/, '$1-$2');
  } else if (value.length < 10) {
    if (value.substr(0, 2) === '02') {
      //02-123-5678
      result = value.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3');
    } else {
      result = value.replace(/(\d{3})(\d{3})(\d{3})/, '$1-$2-$3');
    }
  } else if (value.length < 11) {
    if (value.substr(0, 2) === '02') {
      //02-1234-5678
      result = value.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
    } else {
      //010-123-4567
      result = value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
  } else if (value.length > 11) {
    result = value.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3');
  } else {
    //010-1234-5678
    result = value.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  }
  return result;
}

export function toTime(value, splitter = ':') {
  if (!value) return '';

  if (typeof value === 'object') {
    return `${value.getHours()}${splitter}${value.getMinutes()}${splitter}${value.getSeconds()}`;
  }

  let result = '';
  if (value.length === 4) {
    result = value.replace(/(\d{2})(\d{2})/, '$1:$2');
    return result;
  } else if (value.length === 6) {
    result = value.replace(/(\d{2})(\d{2})(\d{2})/, '$1:$2:$3');
    return result;
  }
  return value;
}

export function toCardNumber(value) {
  if (!value) return '';

  let result = '';
  if (value.length === 16) {
    result = value.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, '$1 $2 $3 $4');
    return result;
  }
  return value;
}

export function getHiddenNameText(name, startIndex = 1) {
  if (!name || name.length === 0) {
    return '';
  }

  let hiddenName = '';
  for (let i = startIndex; i < name.length; i++) {
    hiddenName += '*';
  }
  return name.substring(0, startIndex) + hiddenName;
}

export function getDiscountRate(price, discountPrice) {
  return Math.round((1 - discountPrice / price) * 100.0);
}

export function getAge(birth) {
  if (!birth) {
    return 0;
  }

  const today = moment();
  const birthDate = moment(birth);
  const age =
    parseInt(today.format('YYYY')) - parseInt(birthDate.format('YYYY')) + 1;
  return age;
}

export function getRealAge(birth) {
  // 만 나이
  if (!birth) {
    return 0;
  }

  const today = new Date();
  const dateText = moment(birth).format('YYYY.MM.DD');
  const birthDate = new Date(dateText);
  const age = today.getFullYear() - birthDate.getFullYear();
  const mon = today.getMonth() - birthDate.getMonth();
  if (mon < 0 || (mon === 0 && today.getDate() < birthDate.getDate())) {
    return age - 1;
  }
  return age;
}

export function getStarText(star) {
  if (!star) {
    return 0.0;
  }
  return parseFloat(star).toFixed(1);
}
