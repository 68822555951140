import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { RECEPTION_STATUS } from 'common/constants';
import 'assets/css/class-item.css';

// ClassThumnail.js

function ClassThumnail({ image }) {
  return (
    <Card sx={{ width: 376 }} className="card-item-root">
      <CardActionArea>
        <CardMedia component="img" src={image.file_url} />
      </CardActionArea>
    </Card>
  );
}

export default React.memo(ClassThumnail);
