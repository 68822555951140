import React, { useCallback, useEffect } from 'react';
import { useAppSettings } from 'common/hooks';
import { useLocation } from 'react-router-dom';
import { SHARE_DOMAIN, KAKAO_CLIENT_ID } from 'common/constants';
import usePopAlert from 'components/popAlert/hooks/usePopAlert';

function useShare(shareData) {
  const { pathname } = useLocation();
  const { screenTypes } = useAppSettings();
  const { showAlert } = usePopAlert();

  useEffect(() => {
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init(KAKAO_CLIENT_ID);
    }
  }, []);

  const handleShareUrl = useCallback(
    (popup = false) => {
      // navigator.clipboard.writeText(DOMAIN + pathname);
      const $text = document.createElement('textarea');
      document.body.appendChild($text);
      $text.value = `${SHARE_DOMAIN}${pathname}`;
      $text.select();
      document.execCommand('copy');
      document.body.removeChild($text);

      if (popup) {
        showAlert('알림', 'URL이 복사되었습니다.');
      }
    },
    [pathname],
  );

  const handleShareKakao = useCallback(() => {
    window.Kakao.Link.sendDefault({
      objectType: 'feed', // 메시지 형식 : 피드 타입
      content: {
        title: shareData.title,
        description: shareData.description,
        imageUrl: shareData.imageUrl, // 메인으로 보여질 이미지 주소
        link: {
          webUrl: `${SHARE_DOMAIN}${pathname}`,
          mobileWebUrl: `${SHARE_DOMAIN}${pathname}`,
        },
      },
      buttons: [
        {
          title: '자세히 보기',
          link: {
            webUrl: `${SHARE_DOMAIN}${pathname}`,
            mobileWebUrl: `${SHARE_DOMAIN}${pathname}`,
          },
        },
      ],
    });
  }, [pathname]);

  const handleShareKakaoDirect = useCallback((shareData) => {
    console.log('handleShareKakaoDirect', shareData, {
      objectType: 'feed', // 메시지 형식 : 피드 타입
      content: {
        title: shareData.title,
        description: shareData.description,
        imageUrl: shareData.imageUrl, // 메인으로 보여질 이미지 주소
        link: {
          webUrl: shareData.url,
          mobileWebUrl: shareData.mUrl?shareData.mUrl:shareData.url,
        },
      },
      buttons: [
        {
          title: '자세히 보기',
          link: {
            webUrl: shareData.url,
            mobileWebUrl: shareData.mUrl?shareData.mUrl:shareData.url,
          },
        },
      ],
    });
    window.Kakao.Link.sendDefault({
      objectType: 'feed', // 메시지 형식 : 피드 타입
      content: {
        title: shareData.title,
        description: shareData.description,
        imageUrl: shareData.imageUrl, // 메인으로 보여질 이미지 주소
        link: {
          webUrl: shareData.url,
          mobileWebUrl: shareData.mUrl?shareData.mUrl:shareData.url,
        },
      },
      buttons: [
        {
          title: shareData.buttonTitle || '자세히 보기',
          link: {
            webUrl: shareData.url,
            mobileWebUrl: shareData.mUrl?shareData.mUrl:shareData.url,
          },
        },
      ],
    });
  }, [shareData]);

  const handleShareLine = useCallback(() => {}, [pathname]);

  useEffect(() => {
    if (screenTypes.isDesktop) {
      handleShareUrl();
    }
  }, [screenTypes.isDesktop, handleShareUrl]);

  return { handleShareUrl, handleShareKakao, handleShareLine, handleShareKakaoDirect };
}

export default useShare;
