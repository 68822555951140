import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import 'assets/css/components/modal.scss';

function ModalType2(props) {
  const {
    children,
    title,
    buttonCancel,
    buttonApply,
    handleClose,
    handleApply,
  } = props;
  return (
    <>
      <div>
        <Typography variant="h4" component="h4" className="popTitle">
          {title || ''}
        </Typography>
        <div className="popContents fullContent">
          {React.cloneElement(children, { ...props })}
           
        </div>
       
      </div>
    </>
  );
}

export default React.memo(ModalType2);
