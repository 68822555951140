import React, { useMemo, useState, useCallback, useEffect, useReducer } from 'react';
import produce from 'immer';
import { 
    useCommonQuery
} from 'common/hooks';
import 'moment/locale/ko';
import { useApiContext, useLoginContext } from 'common/context';
import usePopAlert from 'components/popAlert/hooks/usePopAlert';

function reducer(state, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case 'SET_SCHEDULE_INFO':
                return action.value;
            default:
                return state;
        }
    });
}

function useTogetherSchedule({ courseCode, gradeCode, applyDaySelect }) {
    const { queries } = useApiContext();
    const { showAlert, showConfirm } = usePopAlert();
    const [ scheduleList , dispatch ] = useReducer(reducer, undefined);

    const { loginState } = useLoginContext();
    const [loginData, setLoginData] =  useState('');

    useEffect(() => {
        if ( !loginState.contract ) {
            return;
        }
        setLoginData(loginState);
    }, [loginState])

    const { request: requestTogetherSchedule } = useCommonQuery({
        query: queries.togetherSchedules,
        params: {
            course_code: courseCode,
            grade_code: gradeCode,
            school_day_code: applyDaySelect
        },
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    // console.log("requestTogetherSchedule :: ", data.result_data);
                    dispatch({
                        type: 'SET_SCHEDULE_INFO',
                        value: data.result_data,
                    });
                } else {
                    // console.log(data.result_message);
                    showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                // console.log(error);
                showAlert('알림', '스케줄 조회에 실패했습니다.');
            },
        },
    });

    return { scheduleList };
}

export default useTogetherSchedule;
