import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useCommonMutation, useCommonQuery } from 'common/hooks';
import { useApiContext } from 'common/context';
import usePopAlert from 'components/popAlert/hooks/usePopAlert';
import { useLoginContext } from 'common/context/MemberContext';
import { useRadioGroup, useDatePicker, useComboBox } from 'common/hooks';
import { useNavigate } from 'react-router';
import useBasicPagination from 'components/utils/hooks/useBasicPagination';
import moment from 'moment';

function useClassReview({ classSeq, teacherSeq }) {
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const [reviews, setReviews] = useState([]);
  const [photoReviews, setPhotoReviews] = useState([]);
  const reviewType = useComboBox(0);
  const paginationInput = useBasicPagination(reviews.length, 10);

  const { request: requestClassReviews } = useCommonQuery({
    query: queries.classReviews,
    params: {
      class_seq: classSeq,
      page_per_count: 10,
      current_page: paginationInput.page,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          paginationInput.updateTotalCount(data.result_data.total_count);
          setReviews(data.result_data.data.reviews);
          setPhotoReviews(data.result_data.data.photoReviews);
        } else {
          console.log(data.result_message);
          //   showAlert('알림', data.result_message);
          setReviews([]);
          setPhotoReviews([]);
        }
      },
      onError: (error) => {
        // console.log(error);
        //   showAlert('알림', '스케줄 조회에 실패했습니다.');
        setReviews([]);
        setPhotoReviews([]);
      },
    },
    initEnabled: false,
  });

  const { request: requestClassReviewsByTeacher } = useCommonQuery({
    query: queries.teacherReviews,
    params: {
      teacher_seq: teacherSeq,
      page_per_count: 10,
      current_page: paginationInput.page,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          // console.log(data.result_data);
          paginationInput.updateTotalCount(data.result_data.total_count);
          setReviews(data.result_data.data.reviews);
          setPhotoReviews(data.result_data.data.photoReviews);
        } else {
          console.log(data.result_message);
          //   showAlert('알림', data.result_message);
          setReviews([]);
          setPhotoReviews([]);
        }
      },
      onError: (error) => {
        // console.log(error);
        //   showAlert('알림', '스케줄 조회에 실패했습니다.');
        setReviews([]);
        setPhotoReviews([]);
      },
    },
    initEnabled: false,
  });

  useEffect(() => {
    if (classSeq) {
      requestClassReviews();
    } else if (teacherSeq) {
      requestClassReviewsByTeacher();
    }
  }, [paginationInput.page, reviewType.state, classSeq, teacherSeq]);

  useEffect(() => {
    paginationInput.reset();
  }, [reviewType.state]);

  return {
    reviews,
    photoReviews,
    inputs: {
      reviewType,
      paginationInput,
    },
  };
}

export default useClassReview;
