import React, { useState, useCallback, useEffect } from 'react';

function useBasicPagination(temp, countPerPage) {
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        if (totalCount > 0) {
            let count = parseInt(totalCount / countPerPage);
            count += parseInt(totalCount % countPerPage) > 0 ? 1 : 0;
            setPageCount(count);
            
            if (page > count) {
                setPage(count);
            }
        }
    }, [countPerPage, totalCount]);

    const onPageChange = useCallback((event, value) => {
        // console.log(event, value);
        if (page === value) {
            return;
        }
        setPage(value);
    }, [page]);

    const updateTotalCount = useCallback((value) => {
        setTotalCount(value);
        setReady(true);
    }, []);

    const reset = useCallback(() => {
        setPage(1);
    }, []);

    return {
        ready,
        page,
        pageCount,
        totalCount,
        updateTotalCount,
        onPageChange,
        reset,
    };
}

export default useBasicPagination;
