import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import { Heart } from 'components/items';
import { heart_off, heart_on } from 'assets/images/icon';
import useItemsHandler from 'components/items/hooks/useItemsHandler';
import { RECEPTION_STATUS } from 'common/constants';
import 'assets/css/class-item.css';

import detailData from 'assets/data/class-detail.json'; //상세 전체 데이터
import {
  ClassCardThumnail,
  TagList,
  TeacherInfoItem,
  PriceInfoItem,
  ClassTitle,
} from 'components/items';

function MobileClassItem({ data, index, classStar, showRank = false }) {
  const { onClassClick, onTeacherClick } = useItemsHandler({
    classData: data,
    teacher: data.teacher_info,
  });

  const receptionClassName = useMemo(() => {
    if (
      data?.reception_status === undefined ||
      data?.reception_status === null
    ) {
      return '';
    }

    if (data.reception_status === RECEPTION_STATUS.awaited.value) {
      return 'sold-out';
    } else if (data.reception_status === RECEPTION_STATUS.closed.value) {
      return 'sold-out-closed';
    }
    return '';
  }, [data?.reception_status]);

  return (
    <Card
      sx={{ width: 376 }}
      className={`card-item-root ${receptionClassName}`}
      onClick={onClassClick}
    >
      <CardActionArea>
        <ClassCardThumnail data={data} index={index} showRank={showRank} />
         {/* 2022-12-28 접수 대기 상태 일때 보이는 가상콘텐츠 추가 됨*/}
         <div className="commingSoon-txt">오픈 예정</div>
      </CardActionArea>      
      <CardContent className="card-content-wrap">
        <div className="content-top">
          <TagList data={data} />
          <Heart data={data} />
          <ClassTitle data={data} />
          {/* // 클래스 타이틀 컴포넌트 */}
        </div>
        <div className="content-bottom">
          <TeacherInfoItem
            data={data}
            onTeacherClick={onTeacherClick}
            classStar={classStar}
          />
          <PriceInfoItem data={data} />
        </div>
      </CardContent>
    </Card>
  );
}

export default React.memo(MobileClassItem);
