import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import Button from '@mui/material/Button';
import { DOMAIN } from 'common/constants';
import Star from 'components/star/Star';
import useItemsHandler from './hooks/useItemsHandler';
import { getStarText } from 'common/utils';
import { Desktop, TabletAndMobile } from 'components/utils/MediaQuery';
// SeeMoreTeacherInfo.js

function SeeMoreTeacherInfo({ data }) {
  const { onTeacherClick } = useItemsHandler({ teacher: data });

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className="teacher-recap"
        onClick={onTeacherClick}
      >
        <div className="info-teacher">
          <span className="teacher-img">
            <img src={data.profile_image_url} alt="별모양 이미지" />
          </span>
          <div className="teacher_name">
            <p>{data.teacher_nickname} 선생님</p>
            <span className="line-info-1">{data.introduction}</span>
            {/* 2022-08-02 선생님 한 줄 소개 클래스 추가 */}
            <div className="teacher-rate">
              <div>
                <Star star={data.star} />
              </div>
              <span>{getStarText(data.star)}</span>
              <span>{data.review_count || 0}개의 후기</span>
            </div>
          </div>
          <Desktop>
            {/* 2022-08-03 더보기 버튼 모바일 피시 분리 */}
            <Button className="see-more-btn" onClick={onTeacherClick}>
              더보기<span className="arrow-icon"></span>
            </Button>
          </Desktop>
        </div>
        <TabletAndMobile>
          {/* 2022-08-03 더보기 버튼 모바일 피시 분리 */}
          <Button className="see-more-btn" onClick={onTeacherClick}>
            더보기<span className="arrow-icon"></span>
          </Button>
        </TabletAndMobile>
      </Grid>
    </>
  );
}

export default React.memo(SeeMoreTeacherInfo);
