import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

function useItemsHandler({ classData, teacher, keyword }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onClassClick = useCallback(
    (e, classSeq) => {
      // if (classData?.reception_status !== RECEPTION_STATUS.opened.value) {
      //   return;
      // }
      const parentRoute =
        pathname.indexOf('readingBook') >= 0 ? 'readingBook' : 'class';
        const navigationPath = `/${parentRoute}/${classSeq ? classSeq : classData.class_seq}${classData.test_mode_yn ? '/hidden' : ''}`;

      navigate(navigationPath);
    },
    [classData, navigate, pathname],
  );

  const onTeacherClick = useCallback(
    (e, teacherSeq) => {
      // console.log(teacher);
      navigate(`/teacher/${teacherSeq ? teacherSeq : teacher.teacher_seq}`);
      e.stopPropagation();
    },
    [teacher, navigate],
  );

  const onKeywordClick = useCallback(() => {
    // console.log(keyword);
  }, [keyword]);

  const onTogetherClassClick = useCallback((e, classSeq) => {
      // if (classData?.reception_status !== RECEPTION_STATUS.opened.value) {
      //   return;
      // }
      navigate(`/togetherClass/${classSeq ? classSeq : classData.class_seq}${classData.test_mode_yn === 1 ? "/hidden" : ""}`);
    },
    [classData, navigate, pathname],
  );


  return {
    onClassClick,
    onTeacherClick,
    onKeywordClick,
    onTogetherClassClick,
  };
}

export default useItemsHandler;
