import React, { useCallback, useEffect, useReducer, useState } from 'react';

import { GoogleAnalysticsProperties } from 'common/constants';
import ReactGA from 'react-ga4'
import produce from 'immer';
import { useApiContext } from 'common/context';
import { useCommonMutation } from 'common/hooks';
import { useCommonQuery } from 'common/hooks';
import { useLoginContext } from 'common/context/MemberContext';
import { useNavigate } from 'react-router';
import usePopAlert from 'components/popAlert/hooks/usePopAlert';
import { useLocation } from 'react-router-dom';

function reducer(state, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case 'SET_CLASS_INFO':
                return action.value;
            case 'REGIST_INQUIRY':
                draft.class_inquiry.push(action.value);
                break;
            case 'UPDATE_INQUIRY':
                const updateItem = draft.class_inquiry.find((item) => item.inquiry.inquiry_seq === action.inquirySeq);
                if (updateItem) {
                    // console.log(state);
                    updateItem.inquiry.subject = action.variables.subject;
                    updateItem.question.content = action.variables.content;
                    break;
                }
                return state;
            case 'DELETED_INQUIRY':
                const index = draft.class_inquiry.findIndex((item) => item.inquiry.inquiry_seq === action.inquirySeq);
                if (index >= 0) {
                    draft.class_inquiry.splice(index, 1);
                    break;
                }
                return state;
            default:
                return state;
        }
    });
}

function useTogetherSecretClassDetail(id) {
    const { queries } = useApiContext();
    const { showAlert } = usePopAlert();
    const navigate = useNavigate();
    const [classInfo, dispatch] = useReducer(reducer, undefined);
    const { loginState } = useLoginContext();
    const [loginData, setLoginData] =  useState('');

    const location = useLocation();
    const isDirect = location.key =='default' ? '/' : -1

    useEffect(() => {
        if ( !loginState.contract ) {
            return;
        }
        setLoginData(loginState);
    }, [loginState])

    const { request: requestTogetherSecretClassInfo } = useCommonQuery({
        query: queries.secretClassInfo,
        params: { class_seq: id },
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    // console.log(data.result_data);
                    dispatch({
                        type: 'SET_CLASS_INFO',
                        value: data.result_data,
                    });
                    // setClassInfo(data.result_data);
                } else {
                    showAlert('알림', data.result_message, {
                        confirmHandler: () => navigate(isDirect),
                        cancelHandler: () => navigate(isDirect),
                    });
                }
            },
            onError: (error) => {
                    // console.log(error);
                    showAlert('알림', '클래스 정보 조회에 실패했습니다.', {
                    confirmHandler: () => navigate(-1),
                    cancelHandler: () => navigate(-1),
                });
            },
        },
    });

    const { requestAsync: requestApplyTogetherClass } = useCommonMutation({
        query: queries.togetherApplyClass,
        callbacks: {
            onSuccess: (data, variables) => {
                if(data.result_code === '9002'){
                    showAlert('알림', data.result_message, {
                        confirmHandler: () => navigate('/login'),
                        // cancelHandler: () => navigate(-1),
                    });
                }
                if(data.result_code === '9001'){
                    showAlert('알림', data.result_message, {
                        confirmHandler: () => navigate(isDirect),
                        cancelHandler: () => navigate(isDirect),
                    });
                }
                if (data.result_code === '0000') {
                    // console.log("requesApplyTogetherClass ::", data.result_data);
                    navigate(`/togetherClass/${data.result_data.class_seq}/order`, {
                        state: {
                            orderInfo: data.result_data,
                            clubPoint: data.result_data.clubPoint,
                            contracts: data.result_data.contracts,
                        },
                    });
                } else {
                    showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', '클래스 신청에 실패했습니다.');
            },
        },
    });

    const handleApplyClass = useCallback((selectedScheduleDate, selectedScheduleTime, scheduleSeq, togetherSchedule) => {
        /* if (!selectedSchedule) {
            showAlert('알림', '클래스 일정을 선택해주세요.');
            return;
        } */

        // console.log(id, selectedScheduleDate, selectedScheduleTime, scheduleSeq, togetherSchedule)

        requestApplyTogetherClass({
            class_seq: id,
            school_day_code: selectedScheduleDate,
            school_hour_code: selectedScheduleTime,
            schedule_seq: scheduleSeq,
            progress_code: togetherSchedule.progressCode,
            classroom_seq: togetherSchedule.classroomSeq,
            class_time: togetherSchedule.note.split(" ")[0]
        });

        ReactGA.event(GoogleAnalysticsProperties.PurchaseStart)

    }, [requestApplyTogetherClass, id, showAlert]);

    return {
        classInfo,
        handleApplyClass,
    };
}

export default useTogetherSecretClassDetail;
