import React, { useMemo } from 'react';
import Comment from './Comment';
import CommentDefatul from './CommentDefatul';
import { getAge } from 'common/utils';

// CommentList.js

function CommentReply({
  user,
  comment,
  parentId,
  handleRegist,
  handleReply,
  handleUpdate,
  handleDelete,
}) {
  // console.log(comment);
  const isTeacher =
    comment.member_seq != comment.constructor_seq && !comment.isTemp;

  // const isTeacher = useMemo(() => {
  //   return (
  //     comment.classmate_seq === undefined || comment.classmate_seq === null
  //   );
  // }, [comment.classmate_seq]);

  const className = useMemo(() => {
    let value = isTeacher ? 'teacher' : 'default';
    value += parentId === undefined ? ' edit' : '';
    return value;
  }, [isTeacher, parentId]);

  const getTeacherNickName = (data) => {
    let teacherNickName = data.teacher_nickname;
    let constructorName = data.constructor_name;

    if(!teacherNickName) {
      return constructorName;
    } else {
      return teacherNickName;
    }
  };

  return (
    <>
      <div className={`${className} new list`}>
        <div className="top">
          {/* <div className="avatar"></div> */}
          <img
            src={
              comment.isTemp
                ? user.profile_image_url
                : comment.profile_image_url
            }
            className="avatar"
          />
          <div className="name">
            <h4>
              {isTeacher
                ? `${getTeacherNickName(comment)} 선생님`
                : `${comment.constructor_name}(${getAge(comment.birth)}세)`}
            </h4>
          </div>
        </div>
        <Comment
          user={user}
          comment={comment}
          handleRegist={handleRegist}
          parentId={parentId}
        />
      </div>

      {comment &&
        comment.childs &&
        comment.childs.map((data, index) =>
          data.isReply ? (
            <CommentReply
              comment={data}
              key={index}
              parentId={comment.feedback_seq}
              handleRegist={handleRegist}
            />
          ) : (
            <CommentDefatul
              comment={data}
              key={index}
              parentId={comment.feedback_seq}
              handleRegist={handleRegist}
              handleReply={handleReply}
              handleUpdate={handleUpdate}
              handleDelete={handleDelete}
            />
          ),
        )}
    </>
  );
}

export default React.memo(CommentReply);
