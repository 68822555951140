import React, { useState, useCallback } from 'react';

function useDropButton() {
    const [expandedItem, setExpandedItem] = useState(undefined);
    const handleItemExpand = useCallback((itemSeq) => {
        if (expandedItem === itemSeq) {
            setExpandedItem(undefined);
        } else {
            setExpandedItem(itemSeq);
        }
    }, [expandedItem] );

    return { expandedItem, handleItemExpand };
}

export default useDropButton;
