import React, { useState, useCallback } from "react";
import { useCommonMutation, useLoginPopup } from "common/hooks";
import { useApiContext } from "common/context";
import usePopAlert from "components/popAlert/hooks/usePopAlert";
import { useLoginContext } from "common/context";
import { useNavigate } from "react-router";

import ReactGA from "react-ga4";
import { GoogleAnalysticsProperties } from "common/constants";
import useToast from "components/toast/hooks/useToast";
import { useMediaQuery } from "react-responsive";

function useClassFavorite(seq, count, checked) {
  const navigate = useNavigate();
  const { isLogin } = useLoginContext();
  const [classSeq, setClassSeq] = useState(seq);
  const [favoriteCount, setFavoriteCount] = useState(count);
  const [favoriteYn, setFavoriteYn] = useState(checked);
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const { checkLogin } = useLoginPopup();
  const { showToast } = useToast();
  const isAllMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const updateClassInfo = useCallback((seq, count, check) => {
    setClassSeq(seq);
    setFavoriteCount(count);
    setFavoriteYn(check);
  }, []);

  const { requestAsync: requestAddFavorite } = useCommonMutation({
    query: queries.addFavoriteClass,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          // console.log(data);
          setFavoriteCount((prevCount) => prevCount + 1);
          setFavoriteYn((prevYn) => !prevYn);
          showToast("찜한 클래스에 추가되었습니다!",{},{position: { vertical: isAllMobile? "bottom" : "top", horizontal: "center" }})
        } else {
          console.log(data.result_message);
        }
      },
      onError: (error) => {
        // console.log(error);
        showAlert("알림", "관심 클래스 등록에 실패했습니다.");
      },
    },
  });

  const { requestAsync: requestDeleteFavorite } = useCommonMutation({
    query: queries.deleteFavoriteClass,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          // console.log(data.result_data);
          setFavoriteCount((prevCount) =>
            prevCount === 0 ? 0 : prevCount - 1
          );
          setFavoriteYn((prevYn) => !prevYn);
        } else {
          console.log(data.result_message);
        }
      },
      onError: (error) => {
        // console.log(error);
        showAlert("알림", "관심 클래스 삭제에 실패했습니다.");
      },
    },
  });

  const onAddFavorite = useCallback(
    (e) => {
      e.stopPropagation();

      if (checkLogin()) {
        return;
      }

      // TODO: 관심 클래스 등록 api 호출
      // console.log(`favoriteYn: ${favoriteYn} => ${!favoriteYn}`);
      if (favoriteYn) {
        requestDeleteFavorite({ class_seq: seq });

        // 2022-09-21 : Scott ->Google Analytics : favorite_off
        ReactGA.event(GoogleAnalysticsProperties.FavoriteOff);
      } else {
        requestAddFavorite({ class_seq: seq });

        // 2022-09-21 : Scott ->Google Analytics : favorite_on
        ReactGA.event(GoogleAnalysticsProperties.FavoriteOn);
      }
    },
    [checkLogin, favoriteYn, requestAddFavorite, requestDeleteFavorite, seq]
  );

  return {
    favoriteCount,
    favoriteYn,
    onAddFavorite,
    updateClassInfo,
  };
}

export default useClassFavorite;
