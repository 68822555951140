import React, { useState } from 'react';
import { toCurrency, getDiscountRate } from 'common/utils';

function CurrentPrice(props) {
  return (
    <span className="price">
      {toCurrency(props.data.discount_price)}
      <span>원</span>
    </span>
  );
}

export default React.memo(CurrentPrice);
