import {
  createContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { getUseContext } from 'common/utils';

const initialData = {
  header: {
    show: true,
  },
  gnb: {
    show: true,
  },
  screen: {
    isDesktop: true,
    isTablet: false,
    isMobile1: false,
    isMobile2: false,
    isDesktopAndTablet: false,
    isTabletAndMobile: false,
    isAllMobile: false
  },
};

const AppContext = createContext();

function AppProvider({ children }) {
  const [headerState, setHeaderState] = useState(initialData.header);
  const [gnbState, setGnbState] = useState(initialData.gnb);
  const [screenState, setScreenState] = useState(initialData.screen);

  const setHeaderVisible = useCallback((visible) => {
    setHeaderState({ show: visible });
  }, []);

  const setGnbVisible = useCallback((visible) => {
    setGnbState({ show: visible });
  }, []);

  const setScreenType = useCallback(
    ({
      isDesktop = false,
      isTablet = false,
      isMobile1 = false,
      isMobile2 = false,
      isDesktopAndTablet= false,
      isTabletAndMobile = false,
      isAllMobile = false
    }) => {
      setScreenState({
        isDesktop,
        isTablet,
        isMobile1,
        isMobile2,
        isDesktopAndTablet,
        isTabletAndMobile,
        isAllMobile
      });
    },
    [],
  );

  const appValue = useMemo(() => {
    return {
      headerState,
      gnbState,
      screenState,
      action: { setHeaderVisible, setGnbVisible, setScreenType },
    };
  }, [
    headerState,
    screenState,
    setHeaderVisible,
    setGnbVisible,
    setScreenType,
  ]);

  return <AppContext.Provider value={appValue}>{children}</AppContext.Provider>;
}

export const useAppContext = () => getUseContext(AppContext);
export default AppProvider;
