import React, { useCallback, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';

// BigCalendar.js

function BigCalendar({ events, handleDateSetChange, handleDateClick }) {
  const onDateSet = useCallback(
    (dateInfo) => {
      handleDateSetChange(
        moment(dateInfo.start).format('YYYYMMDD'),
        moment(dateInfo.end).format('YYYYMMDD'),
      );
    },
    [handleDateSetChange],
  );

  useEffect(() => {
    // console.log(events);
    const elems = document.querySelectorAll('.fc-daygrid-day.fc-day');
    elems.forEach((elem) => {
      if (elem.classList.contains('event-calender-item')) {
        elem.classList.remove('event-calender-item');
      }
    });
  }, [events]);

  return (
    <>
      <div className="cal-state-wrap">
        <p className="cal-state-classDate">
          <span></span>수업일
        </p>
      </div>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: '', //dayGridWeek,dayGridMonth
          center: 'prev,title,next',
          right: '',
        }}
        showNonCurrentDates={true}
        observeChanges={true}
        locale="ko"
        initialView={'dayGridMonth'}
        dateClick={handleDateClick}
        datesSet={onDateSet}
        events={events}
        height="parent"
        allDaySlot={true}
        // eventContent={function (event) {
        //     return <div className="event-contents" ref={eventContents}></div>;
        // }}
        eventDidMount={function (event) {
          event.el.parentNode.parentNode.parentNode.parentNode.classList.add(
            'event-calender-item',
          );
        }}
        // dayCellClassNames={(args) => {
        //   let el = 'aa';
        //   const eventContetns = document.querySelectorAll('.event-contents');
        //   eventContetns.forEach((element, key, parent) => {
        //     element.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.classList.add(
        //       'event-calender-item',
        //     );
        //   });
        //   return el;
        // }}
        dayCellContent={(args) => {
          return moment(args.date).format('D');
        }}
      />
    </>
  );
}

export default BigCalendar;
