import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router';

import {
  Desktop,
  AllMobile,
  Tablet,
  DesktopAndTablet,
  TabletAndMobile,
} from 'components/utils/MediaQuery';

import useBest from './hooks/useBest';
import { useComboBox } from 'common/hooks';
import { NAVER_AD_ID } from 'common/constants';
import { Tab } from 'components/utils';
import { Typography } from '@mui/material';
import { FormControl } from '@material-ui/core';
import SelectBox from 'components/items/SelectBox';
import LoadingWrapper from 'components/idle/LoadingWrapper';

import ClassCardMap from 'components/classCardItem/classCardMap';

import 'assets/css/pages/best.scss';

// Best.js

const timeOption = [
    { value: 'ALL', name: '전체 시간'},
    { value: 1, name: '오전'},
    { value: 2, name: '오후'},
    { value: 3, name: '저녁'},
]


const selectedClassTypeList = [
    { value: 0, name: '전체 유형' },
    { value: 1, name: '원데이' },
    { value: 2, name: '패키지' }
];
  
const sampleOption2 = [
    { value: 'Z', name: '전체 연령' },
    { value: 'A', name: '유아' },
    { value: 'B', name: '초등 저학년' },
    { value: 'C', name: '초등 고학년' },
    { value: 'D', name: '학부모' }
];

function Best({ type }) {
    let { id } = type === "event" ? "ALL" : useParams();

  const sort1Value = useComboBox(0);
  const sort2Value = useComboBox('Z');
  const sortTimeValue = useComboBox('ALL');

    const {
        classes,
        categories,
        categoriesStatus,
        classesStatus,
        selectedCategory,
        handleCategorySelect,
        selectedClassType,
        selectedRecommendAge,
        handleClassTypeSelect,
        handleRecommendAgeSelect,
        handleTimeSelect,
    } = useBest(id, sort1Value.state, sort2Value.state , sortTimeValue.state);

    useEffect(() => {
        document.title='클래스|라이브올';
        const el = document.querySelector("meta[name='description']");
        if(el) el.setAttribute('content','라이브올의 다양한 수업을 통해 배움의 즐거움을 경험해보세요.');

        // For Naver AD
        let scriptElement = document.createElement('script');
        scriptElement.type = `text/javascript`;
        scriptElement.innerHTML = `if(!wcs_add){var wcs_add={};}wcs_add["wa"]="${NAVER_AD_ID}";if(!_nasa){var _nasa={};}if(window.wcs){wcs.inflow();wcs_do(_nasa);}`;
        document.body.appendChild(scriptElement);
        return () => {
            document.body.removeChild(scriptElement);
        }; 
    }, []);

    useEffect(() => {
        handleCategorySelect({ category_seq : id });
        handleClassTypeSelect(sort1Value.state);
        handleRecommendAgeSelect(sort2Value.state);
        handleTimeSelect(sortTimeValue.state);
    }, [id]);

    useEffect(() => {
        handleClassTypeSelect(sort1Value.state);
        handleRecommendAgeSelect(sort2Value.state);
        handleTimeSelect(sortTimeValue.state);
    }, [sort1Value.state, sort2Value.state, sortTimeValue.state]);

    return (
        <div className="sub-wrap best">
            <div className="breadcrumb">
                <span className="depth">홈</span>
                <span className="depth">라이브올 클래스</span>
            </div>
            <LoadingWrapper status={[categoriesStatus, classesStatus]}>
                <Tab
                    list={categories}
                    selectedValue={selectedCategory}
                    handleSelect={handleCategorySelect}
                />
                <FormControl className="list-sort-slt-area">
                    <div className="each">
                        <SelectBox
                            options={selectedClassTypeList}
                            value={sort1Value.state}
                            handleChange={(e) => sort1Value.handleChange(e)}
                        ></SelectBox>
                    </div>
                    <div className="each">
                        <SelectBox
                            options={sampleOption2}
                            value={sort2Value.state}
                            handleChange={(e) => sort2Value.handleChange(e)}
                        ></SelectBox>
                    </div>
                    <div className="each">
                        <SelectBox
                        options={timeOption}
                        value={sortTimeValue.state}
                        handleChange={(e) => sortTimeValue.handleChange(e)}
                        ></SelectBox>
                    </div>
                </FormControl>

                {classes.length > 0 ? (
                    <ClassCardMap classList={classes} type={"class"} /> 
                ) : (
                    <div className="table-empty pt30 pb50">
                        <div className="table-empty-caution">
                            <div className="title-section">
                                <Typography className="title ">클래스가 없습니다.</Typography>
                            </div>
                        </div>
                    </div>
                )}
            </LoadingWrapper>
        </div>
    );
}

export default Best;
