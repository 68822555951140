import React from 'react';
import { Typography } from '@material-ui/core';
import 'assets/css/components/listEmpty.scss';

ListEmpty.defaultProps = {
    tit: '검색 결과가 없습니다.',
    desc: '단어의 철자가 정확한지 확인해 주세요. 검색어의 단어 수를 줄이거나, 클래스명을 정확하게 검색해 주세요.',
};

function ListEmpty({ isSearchEmpty = false, tit, desc}) {
    return (
        <div className={`sub-wrap ${isSearchEmpty && 'search-empty'}`}>
            <div className="list-empty pt100">
                <div className="list-empty-caution">
                    <div className="title-section">
                        <Typography className="title pb20">{tit}</Typography>
                    </div>
                    <ul className="defalut-section">
                        <li>{desc}</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default ListEmpty;
