import React, { useState } from "react";

import TeacherInfoPic from "components/items/TeacherInfoPic";
import TeacherInfoName from "components/items/TeacherInfoName";
import TeacherInfoRate from "components/items/TeacherInfoRate";

function TeacherInfoItem({
  data,
  onTeacherClick,
  classStar,
  showImage = true,
  showScore = true,
}) {
  return (
    <>
      {data && (
        <div className="info-teacher" onClick={onTeacherClick}>
          {showImage && <TeacherInfoPic data={data} />}
          <div className="teacher_name">
            <TeacherInfoName data={data} />
            {showScore && <TeacherInfoRate data={data} classStar={classStar} />}
          </div>
        </div>
      )}
    </>
  );
}

export default React.memo(TeacherInfoItem);
