import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import {
  prev_active,
  next_active,
  profile_check,
  profile_all,
} from 'assets/images/calender';
import { profileDefault } from 'assets/images/calender';
import { Typography, Grid, FormControl } from '@material-ui/core';

function Profile({ users, currentUser, handleClick }) {
  const [swiper, setSwiper] = useState(null);

  SwiperCore.use([Navigation]);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const swiperParams = {
    navigation: {
      prevEl: navigationPrevRef.current,
      nextEl: navigationNextRef.current,
    },
    // onBeforeInit: (swiper) => {
    //   swiper.params.navigation.prevEl = navigationPrevRef.current;
    //   swiper.params.navigation.nextEl = navigationNextRef.current;
    //   swiper.activeIndex = 0;
    //   swiper.navigation.update();
    // },
    onSwiper: setSwiper,
    // onSlideChange: (e) => setMainImageIndex(e.activeIndex),
    spaceBetween: 25,
    slidesPerView: 'auto',
    breakpoints: {
      1023: {
        spaceBetween: 20,
        slidesPerView: 5,
      },
    },
  };

  return (
    <div className="profile-wrap">
      <div className="profile">
        <button
          ref={navigationPrevRef}
          className="cal-slide-button cal-slide-prev"
        >
          <img src={prev_active} alt="이전" />
        </button>
        <button
          ref={navigationNextRef}
          className="cal-slide-button cal-slide-next"
        >
          <img src={next_active} alt="다음" />
        </button>
        {/* 
            //2022-08-05 프로필 개수 5개 초과 일때 스타일 변경 필요  
            5개 초과 시 flexStart 추가
            5개 미만 일 시 flexEnd 추가 됩니다.
        */}
        <Swiper
          {...swiperParams}
          ref={setSwiper}
          className={
            users?.length >= 6 || users?.length == 5 ? 'flexStart' : 'flexEnd'
          }
        >
          <SwiperSlide>
            <div
              className={`profile-item ${
                currentUser && 0 === currentUser.user_seq ? 'active' : ''
              }`}
              onClick={() => {
                handleClick();
              }}
            >
              <div className="img">
                <img src={profile_all} alt="전체 프로필 아이콘" />
                <img src={profile_check} alt="선택" className="chk" />
              </div>

              <Typography variant="body2" component="p" className="profile-p">
                ALL
              </Typography>
            </div>
          </SwiperSlide>

          {users &&
            users.map((item, index) => {
              return (
                <>
                  <SwiperSlide key={item.user_seq}>
                    <div
                      className={`profile-item ${
                        currentUser && item.user_seq === currentUser.user_seq
                          ? 'active'
                          : ''
                      }`}
                      onClick={() => {
                        handleClick(item);
                      }}
                    >
                      <div className="img">
                        <img
                          src={
                            item.profile_image_url
                              ? item.profile_image_url
                              : profileDefault
                          }
                          alt={item.user_name}
                        />
                        <img src={profile_check} alt="선택" className="chk" />
                      </div>

                      <Typography
                        variant="body2"
                        component="p"
                        className="profile-p"
                      >
                        {item.user_name}
                      </Typography>
                    </div>
                  </SwiperSlide>
                </>
              );
            })}
        </Swiper>
      </div>
    </div>
  );
}

export default React.memo(Profile);
