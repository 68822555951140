import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';

// BigCalenderTabWrapper.js

function BigCalendarTabWrapper({ calendarType, handleCalendarChange }) {
  return (
    <div className="bigcalender-tab">
      <Button
        className={'tab-button ' + (calendarType === 0 ? 'active' : '')}
        onClick={() => {
          handleCalendarChange(0);
        }}
      >
        주간 스케줄
      </Button>
      <Button
        className={'tab-button ' + (calendarType === 1 ? 'active' : '')}
        onClick={() => {
          handleCalendarChange(1);
        }}
      >
        월간 스케줄
      </Button>
    </div>
  );
}

export default React.memo(BigCalendarTabWrapper);
