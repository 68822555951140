import React, { useRef } from 'react';
import ClassThumnail from 'components/items/ClassThumnail'; // 슬라이드 내 top 매거진 컴포넌트
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/virtual';
import 'assets/css/category.css';

function ClassThumnailSlider({ images }) {
  SwiperCore.use([Navigation, Autoplay]); // swiper slide
  const prevRef = useRef();
  const nextRef = useRef();

  return (
    <>
      <Swiper
        slidesPerView={1}
        // spaceBetween={30}
        // navigation={{
        //   prevEl: prevRef?.current,
        //   nextEl: nextRef?.current,
        // }}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Navigation]}
        // autoplay={{ delay: 2000 }}
        className="slide-card-items-list-type swiper-pagination-type-2 "
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
      >
        {images &&
          images.map((image, index) => (
            <SwiperSlide key={index}>
              <ClassThumnail image={image} />
            </SwiperSlide>
          ))}
         <div className="swiper-button-prev" ref={prevRef}>
          Prev
        </div>
        <div className="swiper-button-next" ref={nextRef}>
          Next
        </div>
      </Swiper>
    </>
  );
}

export default React.memo(ClassThumnailSlider);
