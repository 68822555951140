import { useMemo, useEffect, useCallback } from "react";
import { ClassItemList, MobileClassItemList } from "components/indexContent";
import { KeywordTab } from "components/utils";
import useReadingBook from "./hooks/useReadingBook";

import { Desktop, TabletAndMobile } from "components/utils/MediaQuery";

import { Button, Typography } from "@mui/material";
import LoadingWrapper from "components/idle/LoadingWrapper";

// import 'assets/css/detail/detailClassInfo.css';
// import 'assets/css/pages/classDDetail.scss';

import {
  PC_ONLY_01,
  PC_ONLY_02,
  PC_ONLY_03,
  PC_ONLY_04,
  MO_ONLY_01,
  MO_ONLY_02,
  MO_ONLY_03,
  MO_ONLY_04,
} from "../../assets/images/event/onlyLiveall";
import usePopAlert from "components/popAlert/hooks/usePopAlert";

import { NAVER_AD_ID } from "common/constants";
import { useApiContext } from "common/context";
import { useLoginContext } from "common/context";
import { useCommonMutation, useLoginPopup, useInput } from "common/hooks";
import { useLocation } from "react-router";

// ReadingBook.js

function ReadingBook() {
  const {
    keywordyName,
    subKeywords,
    classesByKeyword,
    keywordsStatus,
    classesStatus,
    selectedSubKeyword,
    handleSubCategorySelect,
  } = useReadingBook();

  const { queries } = useApiContext();
  const { isLogin } = useLoginContext();
  const { checkLogin } = useLoginPopup();
  const { showAlert } = usePopAlert();
  const location = useLocation();

  useEffect(() => {
    document.title = "취향독서클럽|라이브올";
    const el = document.querySelector("meta[name='description']");
    if (el)
      el.setAttribute("content", "흥미있는 주제의 책들을 통한 독서토론 클래스");

    // For Naver AD
    let scriptElement = document.createElement("script");
    scriptElement.type = `text/javascript`;
    scriptElement.innerHTML = `if(!wcs_add){var wcs_add={};}wcs_add["wa"]="${NAVER_AD_ID}";if(!_nasa){var _nasa={};}if(window.wcs){wcs.inflow();wcs_do(_nasa);}`;
    document.body.appendChild(scriptElement);

    return () => {
      document.body.removeChild(scriptElement);
    };
  }, [location]);

  const { request: requestDownloadCoupon } = useCommonMutation({
    query: queries.downloadCoupon,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          let couponsInfo = "";
          data.result_data.forEach(
            (item) => (couponsInfo += `\n${item.coupon_name}`)
          );
          showAlert("알림", `쿠폰이 다운로드 완료되었습니다.\n${couponsInfo}`);
        } else {
          console.log(data.result_message);
          showAlert("알림", "이미 다운로드된 쿠폰입니다.");
        }
      },
      onError: (error) => {
        // console.log(error);
        showAlert("알림", "쿠폰 다운로드에 실패했습니다.");
      },
    },
    initEnabled: false,
  });

  const onDownloadCouponPolicyClick = useCallback(
    (couponPolicySeq) => {
      if (couponPolicySeq) {
        requestDownloadCoupon(couponPolicySeq);
      }
    },
    [requestDownloadCoupon]
  );

  const clickCouponDown = useCallback(
    (e) => {
      if (!isLogin) {
        checkLogin();
      } else {
        onDownloadCouponPolicyClick(e.target.value);
      }
    },
    [isLogin, onDownloadCouponPolicyClick]
  );

  return (
    <>
      <div className="sub-wrap event readingBook onlyLiveAll top">
        <div
          className="classInfo"
          style={{
            paddingBottom: location.pathname === "/readingBook" ? "0" : "50px",
          }}
        >
          <div className="event-detail-img">
            <Desktop>
              <div className="LinkInWrap p_r">
                <img src={PC_ONLY_01} alt="이벤트 피시 상세" />
              </div>
              <div className="LinkInWrap p_r">
                <img src={PC_ONLY_02} alt="이벤트 피시 상세" />
                <div className="coupon-btn-box">
                  <Button
                    className="coupon-btn per20"
                    value={83}
                    onClick={clickCouponDown}
                  >
                    쿠폰 다운로드
                  </Button>
                  <Button
                    className="coupon-btn per20"
                    value={82}
                    onClick={clickCouponDown}
                  >
                    쿠폰 다운로드
                  </Button>
                </div>
              </div>
              <div className="LinkInWrap p_r">
                <img src={PC_ONLY_03} alt="이벤트 피시 상세" />
              </div>
              <div className="LinkInWrap p_r">
                <img src={PC_ONLY_04} alt="이벤트 피시 상세" />
              </div>
            </Desktop>
            <TabletAndMobile>
              <div className="LinkInWrap p_r">
                <img src={MO_ONLY_01} alt="이벤트 모바일 상세" />
              </div>
              <div className="LinkInWrap p_r">
                <img src={MO_ONLY_02} alt="이벤트 모바일 상세" />
                <div className="coupon-btn-box">
                  <Button
                    className="coupon-btn per20"
                    value={83}
                    onClick={clickCouponDown}
                  >
                    쿠폰 다운로드
                  </Button>
                  <Button
                    className="coupon-btn per20"
                    value={82}
                    onClick={clickCouponDown}
                  >
                    쿠폰 다운로드
                  </Button>
                </div>
              </div>
              <div className="LinkInWrap p_r">
                <img src={MO_ONLY_03} alt="이벤트 모바일 상세" />
              </div>
              <div className="LinkInWrap p_r">
                <img src={MO_ONLY_04} alt="이벤트 모바일 상세" />
              </div>
            </TabletAndMobile>
          </div>
        </div>
      </div>

      <div className="sub-wrap readingbook">
        <div className="breadcrumb">
          <span className="depth">홈</span>
          <span className="depth">Only 라이브올 클래스</span>
          <span className="depth">
            {selectedSubKeyword ? selectedSubKeyword : "ALL"}
          </span>
        </div>

        <LoadingWrapper status={[keywordsStatus, classesStatus]}>
          <>
            <KeywordTab
              list={subKeywords}
              selectedValue={selectedSubKeyword}
              handleSelect={handleSubCategorySelect}
            />
            {classesByKeyword.length > 0 ? (
              <>
                <Desktop>
                  <ClassItemList dataList={classesByKeyword} />
                </Desktop>
                <TabletAndMobile>
                  <MobileClassItemList dataList={classesByKeyword} />
                </TabletAndMobile>
              </>
            ) : (
              <div className="table-empty pt30 pb50">
                <div className="table-empty-caution">
                  <div className="title-section">
                    <Typography className="title">
                      클래스가 없습니다.
                    </Typography>
                  </div>
                </div>
              </div>
            )}
          </>
        </LoadingWrapper>
      </div>
    </>
  );
}

export default ReadingBook;
