export const DOMAIN = process.env.REACT_APP_DOMAIN;
export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
export const XAPI_DOMAIN = process.env.REACT_APP_XAPI_DOMAIN;
export const SHARE_DOMAIN = process.env.REACT_APP_SHARE_DOMAIN;
export const ADMIN_DOMAIN = process.env.REACT_APP_ADMIN_DOMAIN;
export const PM_URL = process.env.REACT_APP_PM_URL;

// api
export const API_BASE_URL = `${API_DOMAIN}/v1/api`;
// kakao
export const KAKAO_CLIENT_ID = process.env.REACT_APP_KAKAO_CLIENT_ID;
export const KAKAO_REDIRECT_URI = `${DOMAIN}/auth/kakao/callback`;

// google
export const GOOGLE_API_KEY = 'AIzaSyCi3o7EwAqRlWpShw8_0Q5354r8axPAOio';

// iamport
export const IMP_IDENTITY_CODE = 'imp01041153';
export const IMP_REST_API_KEY = '1371141577357800';
export const IMP_REST_API_SECRET =
  '8Pl4Pu7GyuhhjkGnOvb10zJAPHUF5ZZJhjDsCVv1GmA8kyVSjBJkK5DI9PCNUTvts1UI5mSwEspW0cOk';
export const IMP_REDIRECT_URL = `${DOMAIN}/impay/callback`;

// admin
export const ADMIN_URL = `${ADMIN_DOMAIN}`;

//google analytics id
export const GOOGLE_ANALYTICS_TRACKING_ID = 'G-MBLXMT5ZRT';

// Naver 광고 ID
export const NAVER_AD_ID = 's_585f43ca8429';

// class
export const RECEPTION_STATUS = {
  awaited: {
    value: 0,
    text: '클래스 오픈 예정',
  },
  opened: {
    value: 1,
    text: '클래스 신청하기',
  },
  closed: {
    value: 2,
    text: '클래스 마감',
  },
};

// order
export const PAY_METHOD = {
  card: {
    value: 'card',
    text: '신용카드',
  },
  trans: {
    value: 'trans',
    text: '실시간 계좌이체',
  },
  vbank: {
    value: 'vbank',
    text: '가상계좌',
  },
  phone: {
    value: 'phone',
    text: '휴대폰 소액결제',
  },
  samsung: {
    value: 'samsung',
    text: '삼성페이',
  },
  kpay: {
    value: 'kpay',
    text: '케이페이',
  },
  kakaopay: {
    value: 'kakaopay',
    text: '카카오페이',
  },
  payco: {
    value: 'payco',
    text: '페이코',
  },
  naverpay: {
    value: 'naverpay',
    text: '네이버페이',
  },
  liveallPoint: {
    value: 'liveallPoint',
    text: '올머니',
  },
  clubPoint: {
    value: 'clubPoint',
    text: '클럽 포인트',
  },
  clubMileage: {
    value: 'clubMileage',
    text: '클럽 마일리지',
  },
};

export const ORDER_STATUS = {
  paid: 10,
  canceled: 20,
};

// common
export const CELLPHONE_OPTIONS = [
  {value: '010', name: '010'},
  {value: '011', name: '011'},
  {value: '016', name: '016'},
  {value: '017', name: '017'},
];

export const TELEPHONE_OPTIONS = [
  {value: '02', name: '02'},
  {value: '031', name: '031'},
  {value: '032', name: '032'},
  {value: '041', name: '041'},
  {value: '010', name: '010'},
];

export const GoogleAnalysticsProperties = {
  SignUpStart: {
    action: 'signup_start',
    category: 'Button',
    label: '회원가입시작',
  },
  SignUpComplete: {
    action: 'signup_complete',
    category: '',
    label: '회원가입완료',
  },
  Search: {action: 'search', category: 'Button', label: '검색'},
  PurchaseStart: {
    action: 'purchase_start',
    category: 'Button',
    label: '구매시작',
  },
  PurchaseComplete: {
    action: 'purchase_complete',
    category: '',
    label: '구매완료',
  },
  PurchaseCancel: {
    action: 'purchase_complete',
    category: 'Button',
    label: '구매취소',
  },

  LoginStart: {
    action: 'login_start',
    category: 'Button',
    label: '로그인시작',
  },
  LogoutComplete: {
    action: 'logout_complete',
    category: 'Button',
    label: '로그아웃완료',
  },

  FavoriteOn: {
    action: 'favorite_on',
    category: 'Button',
    label: '찜버튼 활성',
  },
  FavoriteOff: {
    action: 'favorite_off',
    category: 'Button',
    label: '찜버튼 비활성',
  },
};
