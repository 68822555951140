import React, { useCallback } from 'react';
import usePopAlert from 'components/popAlert/hooks/usePopAlert';
import { useNavigate } from 'react-router';
import { useLoginContext } from 'common/context';
import { useLocation } from 'react-router-dom';

function useLoginPopup() {
  const { pathname } = useLocation();
  const { isLogin } = useLoginContext();
  const { showConfirm, showAlert } = usePopAlert();
  const navigate = useNavigate();

  const checkLogin = useCallback(() => {
    if (!isLogin) {
      showConfirm(
        '로그인이 필요한 서비스 입니다.',
        '로그인 후 이용 가능합니다.',
        {
          confirmHandler: () => {
            sessionStorage.setItem('login_callback', pathname);
            navigate('/login');
          },
        },
        {
          confirmText: '로그인',
        },
      );
      return true;
    }
    return false;
  }, [isLogin, navigate, pathname, showConfirm]);

/*`23.07.18 로그인 체크 후 페이지 바로이동 함수 추가 */
  const checkLoginToPage = useCallback(() => {
    //로그인이 되어있을경우 해당 로직 종료
    if (localStorage.accessToken) {
      return false;
    };

    if(!isLogin) {
      showAlert('알림', '로그인이 필요한 서비스 입니다.',
        {
          confirmHandler: () => {
            sessionStorage.setItem('login_callback', pathname);
            navigate('/login');
          },
        },
      );
      return true;
    }
    return false;
  }, [isLogin, navigate, pathname, showAlert]);

  return { checkLogin, checkLoginToPage };
}

export default useLoginPopup;
