import { METHOD } from 'common/hooks';

export const teacherApi = (requestFunc) => {
  return {
    // 선생님
    teacherInfo: {
      key: 'teacherInfo', // 선생님 상세
      queryFn: (params) =>
        requestFunc(METHOD.GET, `/teachers/${params.teacher_seq}`),
    },
    teacherReviews: {
      key: 'teacherReviews', // 수업 후기 목록
      queryFn: (params) =>
        requestFunc(
          METHOD.GET,
          `/teachers/${params.teacher_seq}/reviews`,
          params,
        ),
    },
    teacherInquires: {
      key: 'teacherInquires', // 수업 문의 목록
      queryFn: (params) =>
        requestFunc(
          METHOD.GET,
          `/teachers/${params.teacher_seq}/inquires`,
          params,
        ),
    },
    applyTeacher: {
      key: 'applyTeacher', // 선생님 신청
      queryFn: (params) =>
        requestFunc(METHOD.POST, `/teachers-applications`, params),
    },
  };
};
