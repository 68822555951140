import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "swiper/css/virtual";
import "assets/css/subVisual.css";
import "assets/css/layouts/header.scss";
import "assets/css/components/modal.scss";

import {
  AllMobile,
  Desktop,
  DesktopAndTablet,
  TabletAndMobile,
} from "components/utils/MediaQuery";
import { Link, NavLink, useLocation } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { calendar, logoNavy2, mypage, search } from "assets/images/common";
import { useAppContext, useLoginContext } from "common/context";
import { useAppSettings, useCommonQuery, useLogout } from "common/hooks";

import { Box } from "@material-ui/core";
import Gnb from "./Gnb";
import SearchBar from "./SearchBar";
import { alarm } from "assets/images/calender";
import iconBannerClose from "assets/images/common/icon-banner-close.png";
import moment from "moment";
import { useApiContext } from "common/context";
import useModalWrapper from "components/modal/hooks/useModalWrapper";

// header.js
function Header() {
  const { headerState, gnbState } = useAppContext();
  const { isLogin, loginState } = useLoginContext();

  const [showMenu, setShowMenu] = useState(false);
  const { screenTypes } = useAppSettings();
  const { queries } = useApiContext();
  const [categories, setCategories] = useState([]);
  const [mainMenu, setMainMenu] = useState([]);
  const [topMenu, setTopMenu] = useState([]);
  const [topBanner, setTopBanner] = useState([]);
  const todayScheduleModal = useModalWrapper();
  const { logout } = useLogout();
  const location = useLocation();
  const [topBannerNotViewToday, setTopBannerNotViewToday] = useState(
    localStorage.getItem("topBannerNotViewToday") ===
      new Date().toLocaleDateString()
  );
  const accessToken = localStorage.getItem("accessToken");

  const { request: requestLeftMenu } = useCommonQuery({
    query: queries.mainLeftMenu,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          setCategories(data.result_data.categories);
          setMainMenu(data.result_data.menus);
        } else console.log(data.result_message);
      },
    },
  });
  const { request: requestTopMenu } = useCommonQuery({
    query: queries.mainTopMenu,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") setTopMenu(data.result_data);
        else console.log(data.result_message);
      },
    },
  });
  const { request: requestTopBanner } = useCommonQuery({
    query: queries.mainTopBanner,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          console.log(data.result_data);
          setTopBanner(data.result_data);
        } else console.log(data.result_message);
      },
    },
  });

  useEffect(() => {
    if (
      !accessToken ||
      !accessToken.length > 0 ||
      !loginState.accessToken.length === 0
    ) {
      requestLeftMenu();
      requestTopMenu();
      requestTopBanner();
    }
  }, [loginState.accessToken, accessToken, isLogin]);

  const onMenuShowClick = useCallback(() => {
    setShowMenu((prev) => !prev);
  }, []);

  const onTodayScheduleClick = useCallback(
    (e) => {
      sessionStorage.setItem("todayScheduleOff", moment().format("YYYYMMDD"));
      todayScheduleModal.handleClose(e);
    },
    [todayScheduleModal]
  );

  useEffect(() => {
    const styleValue =
      showMenu && screenTypes.isTabletAndMobile ? "hidden" : "visible";
    document.body.style.overflow = styleValue;
  }, [showMenu, screenTypes.isTabletAndMobile]);

  useEffect(() => {
    const todayScheduleValue = sessionStorage.getItem("todayScheduleOff");
    if (
      !todayScheduleValue ||
      todayScheduleValue !== moment().format("YYYYMMDD")
    )
      todayScheduleModal.handleOpen();
  }, []);

  // 오늘 하루 보지않기 버튼 클릭 함수
  const handleNotView = useCallback((e) => {
    setTopBannerNotViewToday(true); // 이후 팝업창을 오픈여부 결정
    localStorage.setItem(
      "topBannerNotViewToday",
      new Date().toLocaleDateString()
    ); //로컬스토리지 값 오늘날짜로 저장
  }, []);

  return (
    <>
      <div
        className={`header${screenTypes.isDesktop ? "" : " mo-header"}${
          !topBannerNotViewToday && topBanner?.length > 0 ? " top-banner" : ""
        }`}
        id="header"
      >
        {!topBannerNotViewToday && topBanner?.length > 0 && (
          <div className="topBanner-area">
            <button className="btn-close" onClick={handleNotView}>
              <img src={iconBannerClose} alt="닫기버튼" />
            </button>
            <Swiper className="slide-topBanner">
              {topBanner?.map((banner) => {
                return (
                  <SwiperSlide key={banner.banner_link_url}>
                    <Link to={banner.banner_link_url}>
                      <div
                        className="slideBg"
                        style={{ backgroundColor: banner.background_color }}
                      >
                        <DesktopAndTablet>
                          <img src={banner.image_url} />
                        </DesktopAndTablet>
                        <AllMobile>
                          <img src={banner.mobile_image_url} />
                        </AllMobile>
                      </div>
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        )}

        <Desktop>
          <div className="util-area">
            <div className="container">
              <ul className="head-menu">
                {isLogin ? (
                  <>
                    <li>
                      <Link onClick={logout}>로그아웃</Link>
                    </li>
                    <li>
                      <Link to="/mypage">마이페이지</Link>
                    </li>
                  </>
                ) : (
                  <li>
                    <Link to="/login">로그인</Link>
                  </li>
                )}
                <li>
                  <Link
                    to={isLogin ? "/calendar" : "login"}
                    className="btn-calendar"
                  >
                    나의 스케줄
                    {loginState.today_count > 0 && (
                      <span className="calendar-num">
                        {loginState.today_count}
                      </span>
                    )}
                  </Link>
                  {loginState.today_count > 0 &&
                    todayScheduleModal.visibleModal && (
                      <div
                        className="alarm-wrap"
                        onClick={onTodayScheduleClick}
                      >
                        <img src={alarm} alt="캘린더 알림 말풍선 이미지" />
                        <p>오늘 수업이 {loginState.today_count}개 있어요!</p>
                      </div>
                    )}
                </li>
              </ul>
            </div>
          </div>
        </Desktop>

        <div className="logo-area">
          <div className="container">
            <div className="logo">
              <Link to="/">
                <img src={logoNavy2} alt="라이브 올 로고 이미지" />
              </Link>
            </div>
            <Desktop>
              <SearchBar />
              <ul className="sub-menu">
                <li>
                  <NavLink to="/event">이벤트/기획전</NavLink>
                </li>
                <li>
                  <NavLink to="/magazine">매거진</NavLink>
                </li>
                <li>
                  <NavLink to="/intro">라이브올이란?</NavLink>
                </li>
              </ul>
            </Desktop>

            <TabletAndMobile>
              {!showMenu && (
                <>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/search">
                        <img src={search} alt="검색 아이콘" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={isLogin ? "/calendar" : "login"}
                        className="btn-calendar"
                      >
                        <img src={calendar} alt="캘린더 아이콘" />
                        {loginState.today_count > 0 ? (
                          <span className="calendar-num">
                            {loginState.today_count}
                          </span>
                        ) : (
                          <></>
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link to={isLogin ? "/mypage" : "login"}>
                        <img src={mypage} alt="마이페이지 아이콘" />
                      </Link>
                    </li>
                  </ul>
                </>
              )}
            </TabletAndMobile>
          </div>

          {showMenu && (
            <>
              <Desktop>
                <Box
                  className={showMenu ? "all-nav show" : "all-nav hide"}
                  onClick={onMenuShowClick}
                >
                  <ul className="category-list">
                    {categories.map((data, index) => (
                      <li key={data.category_seq}>
                        <Link to="/category" state={data}>
                          {data.category_name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Box>
              </Desktop>

              <TabletAndMobile>
                <Box
                  className={showMenu ? "all-nav-mo show" : "all-nav-mo hide"}
                  onClick={onMenuShowClick}
                >
                  <div className="category-list">
                    <div className={`category-wrap category-wrap2`}>
                      <div className="category-mo-title">
                        <div className="category-title-img"></div>전체메뉴
                      </div>
                      <ul>
                        <li>
                          <Link to="/intro">라이브올이란?</Link>
                        </li>
                        <li>
                          <Link to="/class">클래스</Link>
                        </li>
                        <li>
                          <Link to="/readingBook">취향독서클럽</Link>
                        </li>
                        <li>
                          <Link to="/magazine">매거진</Link>
                        </li>
                        <li>
                          <Link to="/event">이벤트</Link>
                        </li>
                        <li>
                          <Link to="/apply">선생님 지원하기</Link>
                        </li>
                        <li>
                          <Link to="/inquiry/cooperation">제휴/협력 문의</Link>
                        </li>
                        <li>
                          <Link to="/inquiry/group">단체 교육 문의</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Box>
              </TabletAndMobile>
            </>
          )}
        </div>
        {gnbState.show && (
          <Gnb cate={categories} menu={mainMenu} top={topMenu} />
        )}
      </div>
    </>
  );
}
export default React.memo(Header);
