import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: 1,
      cacheTime: 1,
    },
  },
  queryCache: new QueryCache({
    onSuccess: (data, query) => {
      // console.log('onSuccess: ', data, query);
    },
    onError: (error, query) => {
      console.warn('onError: ', error, query);
    },
  }),
  mutationCache: new MutationCache({
    onMutate: (variables, mutation) => {
      // console.log('onMutate: ', variables, mutation);
    },
    onSuccess: (data, variables, context, mutation) => {
      // console.log('onSuccess: ', data, variables);
    },
    onError: (error, variables, context, mutation) => {
      console.warn('onError: ', error, variables);
    },
  }),
});

function QueryProvider({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}

export default QueryProvider;
