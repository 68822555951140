import React, { useCallback } from 'react';
import {
  Desktop,
  Mobile,
  Tablet,
  TabletAndMobile,
} from 'components/utils/MediaQuery';
import { detail_lock } from 'assets/images/icon';
import { DropButton } from 'components/utils';
import { useLoginContext } from 'common/context';
import useModalWrapper from 'components/modal/hooks/useModalWrapper';
import { getHiddenNameText } from 'common/utils';
import { useComboBox, useInput, useLoginPopup } from 'common/hooks';
import { ModalWrap, ModalType1 } from '../modal';
import { ClassInquiryCreate } from 'components/items';
import usePopAlert from 'components/popAlert/hooks/usePopAlert';
import moment from 'moment';
import 'moment/locale/ko';
import useClassInquiry from './hooks/useClassInquiry';

function ClassInquiryItem({
  inquiry,
  index,
  classData,
  dropButtonHandler,
  requestUpdateInquiry,
  onDeleteClick,
}) {
  const { orderUser } = useClassInquiry({ classSeq: classData.class_seq });
  const { loginState, isLogin } = useLoginContext();
  const updateModal = useModalWrapper();
  const inquiryType = useComboBox(-1);
  const inquiryContract = useComboBox(-1);
  const modalInquiryName = useInput('', 35);
  const modalInquiryContent = useInput('', 2000);
  const { showAlert } = usePopAlert();

  const handleUpdate = useCallback(
    async (e) => {

      if(classData.class_type === 9 && inquiryType.state === -1) {
        showAlert('알림', '문의 유형을 선택해주세요.');
        return;
      }

      if(classData.class_type === 9 && inquiryType.state === 3 && inquiryContract.state === -1) {
        showAlert('알림', '결제 정보를 입력해주세요.');
        return;
      }

      if (modalInquiryName.state.length === 0) {
        showAlert('알림', '제목을 입력해주세요.');
        return;
      }
      if (modalInquiryContent.state.length === 0) {
        showAlert('알림', '내용을 입력해주세요.');
        return;
      }

      const { inquiry } = updateModal.params;
      const result = await requestUpdateInquiry({
        inquiry_seq: inquiry.inquiry_seq,
        category_seq: inquiryType.state,
        order_seq: inquiryContract.state,
        subject: modalInquiryName.state,
        content: modalInquiryContent.state,
      });
      //   console.log(result);
      updateModal.handleClose(e);
      window.location.reload();
    },
    [
      modalInquiryName.state,
      modalInquiryContent.state,
      updateModal,
      requestUpdateInquiry,
      showAlert,
    ],
  );

  const onUpdateClick = useCallback((inquiry) => {
        console.log("inquiry :: ", inquiry);
      inquiryType.select(inquiry.inquiry.category_seq);
      inquiryContract.select(inquiry.inquiry.order_seq);
      modalInquiryName.update(inquiry.inquiry.subject);
      modalInquiryContent.update(inquiry.question.content);
      updateModal.handleOpen(inquiry);
  }, [modalInquiryName, modalInquiryContent, updateModal]);

  //   console.log(updateModal.visibleModal);

  return (
    <>
      <ModalWrap
        className="-inquiry-edit"
        modalinfo="other-button"
        visible={updateModal.visibleModal}
        handleClose={updateModal.handleClose}
      >
        <ModalType1
          title="클래스 문의"
          buttonCancel="취소"
          buttonApply="수정"
          handleApply={handleUpdate}
          inquiryType={inquiryType}
          inquiryContract={inquiryContract}
          inquiryName={modalInquiryName}
          inquiryContent={modalInquiryContent}
        >
          <ClassInquiryCreate classData={classData} orderUser={orderUser} />
        </ModalType1>
      </ModalWrap>

      <Desktop>
        <li className="classInquiry_list" key={inquiry.inquiry.inquiry_seq}>
          {inquiry.inquiry && (
            <>
              <div className="classInquiry_question">
                <div className="num">{index + 1}</div>
                <div
                  className={
                    inquiry.inquiry.answer_yn
                      ? 'answerState'
                      : 'answerState gray'
                  }
                >
                  {inquiry.inquiry.answer_yn ? '답변완료' : '답변대기'}
                </div>
                <div className="title">
                  {inquiry.inquiry.secret_yn ? null : (
                    <img src={detail_lock} alt="자물쇠 이미지" />
                  )}
                  {inquiry.inquiry.owner_yn
                    ? inquiry.inquiry.subject
                    : '비밀글입니다.'}
                </div>
                <div className="name">
                  {getHiddenNameText(inquiry.inquiry.inquirer_name)}
                </div>
                <div className="date">
                  {moment(inquiry.inquiry.inquirer_dt).format('YYYY-MM-DD')}
                </div>
                <DropButton
                  expand={
                    dropButtonHandler.expandedItem ===
                    inquiry.inquiry.inquiry_seq
                  }
                  handleItemExpand={dropButtonHandler.handleItemExpand}
                  index={inquiry.inquiry.inquiry_seq}
                />
              </div>

              <div
                className={
                  dropButtonHandler.expandedItem === inquiry.inquiry.inquiry_seq
                    ? 'classInquiry_answer isOn'
                    : 'classInquiry_answer'
                }
              >
                {/* 2022-08-02 classInquiry_answer_top 에 추가로 상태 별 필요한 css 달라 클래스명 추가 함 */}
                <div
                  className={
                    (!inquiry.inquiry.owner_yn && !inquiry.inquiry.answer_yn) ||
                    inquiry.inquiry.answer_yn
                      ? 'classInquiry_answer_top flexStart'
                      : 'classInquiry_answer_top'
                  }
                >
                  <div className="answerState">
                    <span className="square">질문</span>
                  </div>
                  <div className="title">
                    {inquiry.inquiry.owner_yn
                      ? inquiry.question.content
                      : '비밀글입니다.'}
                  </div>
                  {isLogin &&
                    !inquiry.inquiry.answer_yn &&
                    inquiry.inquiry.owner_yn && (
                      <div className="date">
                        <span
                          className="circle"
                          onClick={() => onUpdateClick(inquiry)}
                        >
                          수정
                        </span>
                        <span
                          className="circle"
                          onClick={() =>
                            onDeleteClick(inquiry.inquiry.inquiry_seq)
                          }
                        >
                          삭제
                        </span>
                      </div>
                    )}
                </div>
                {inquiry.answer && (
                  <div className="classInquiry_answer_bottom">
                    <div className="answerState">
                      <span className="square">답변</span>
                    </div>
                    <div className="title">
                      {inquiry.inquiry.owner_yn
                        ? inquiry.answer.answer
                        : '비밀글입니다.'}
                    </div>
                    <Desktop>
                      <div className="date">
                        <span>답변일</span>
                        <span>
                          {moment(inquiry.answer.response_dt).format(
                            'YYYY-MM-DD',
                          )}
                        </span>
                      </div>
                    </Desktop>
                  </div>
                )}
              </div>
            </>
          )}
        </li>
      </Desktop>

      <TabletAndMobile>
        <li className="classInquiry_list" key={inquiry.inquiry.inquiry_seq}>
          {inquiry.inquiry && (
            <>
              <div className="classInquiry_question">
                <div className="inquiry-question-top">
                  <div
                    className={
                      inquiry.inquiry.answer_yn
                        ? 'answerState'
                        : 'answerState gray'
                    }
                  >
                    {inquiry.inquiry.answer_yn ? '답변완료' : '답변대기'}
                  </div>
                  <div className="dp-f">
                    <div className="name">
                      {getHiddenNameText(inquiry.inquiry.inquirer_name)}
                    </div>
                    <div className="date"> 
                      {moment(inquiry.inquiry.inquirer_dt).format('YYYY-MM-DD')}
                    </div>
                  </div>
                </div>
                <div className="inquiry-question-bottom">
                  <div className="title">
                    {inquiry.inquiry.secret_yn ? null : (
                      <img src={detail_lock} alt="자물쇠 이미지" />
                    )}
                    {inquiry.inquiry.owner_yn
                      ? inquiry.inquiry.subject
                      : '비밀글입니다.'}
                  </div>
                  <DropButton
                    expand={
                      dropButtonHandler.expandedItem ===
                      inquiry.inquiry.inquiry_seq
                    }
                    handleItemExpand={dropButtonHandler.handleItemExpand}
                    index={inquiry.inquiry.inquiry_seq}
                  />
                </div>
              </div>

              <div
                className={
                  dropButtonHandler.expandedItem === inquiry.inquiry.inquiry_seq
                    ? 'classInquiry_answer isOn'
                    : 'classInquiry_answer'
                }
              >
                {/* 2022-08-02 classInquiry_answer_top 에 추가로 상태 별 필요한 css 달라 클래스명 추가 함 */}
                <div
                  className={
                    !inquiry.inquiry.owner_yn || inquiry.inquiry.answer_yn
                      ? 'classInquiry_answer_top flexStart'
                      : 'classInquiry_answer_top'
                  }
                >
                  <div className="answerState">
                    <span className="square">질문</span>
                  </div>
                  {isLogin &&
                    !inquiry.inquiry.answer_yn &&
                    inquiry.inquiry.owner_yn && (
                      <div className="date">
                        <span
                          className="circle"
                          onClick={() => onUpdateClick(inquiry)}
                        >
                          수정
                        </span>
                        <span
                          className="circle"
                          onClick={() =>
                            onDeleteClick(inquiry.inquiry.inquiry_seq)
                          }
                        >
                          삭제
                        </span>
                      </div>
                    )}
                  <div className="title">
                    {inquiry.inquiry.owner_yn
                      ? inquiry.question.content
                      : '비밀글입니다.'}
                  </div>
                </div>
                {inquiry.answer && (
                  <div className="classInquiry_answer_bottom">
                    <div className="answerState">
                      <span className="square">답변</span>
                    </div>
                    {/* 2022-08-02 "답변일" 보이는 위치 모바일/피시 달라 수정완료 */}
                    <div className="date">
                      <span>답변일</span>
                      <span>
                        {moment(inquiry.answer.response_dt).format(
                          'YYYY-MM-DD',
                        )}
                      </span>
                    </div>
                    <div className="title">
                      {inquiry.inquiry.owner_yn
                        ? inquiry.answer.answer
                        : '비밀글입니다.'}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </li>
      </TabletAndMobile>
    </>
  );
}

export default React.memo(ClassInquiryItem);
