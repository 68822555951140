import { METHOD } from 'common/hooks';

export const couponPolicyApi = (requestFunc) => {
  return {
    // 화상방
    downloadCoupon: {
      key: 'downloadCoupon',
      queryFn: (couponPolicySeq) =>
        requestFunc(
          METHOD.POST,
          `/coupon-policy/${couponPolicySeq}/download`,
        ),
    },
  };
};
