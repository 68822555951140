import React, { useState, useCallback, useReducer, useEffect } from 'react';
import produce from 'immer';

function reducer(state, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'UPDATE':
        return action.value;
      case 'RESET':
        return action.initialData;
      default:
        return state;
    }
  });
}

function useRadioGroup(initialData) {
  const [state, dispatch] = useReducer(reducer, initialData);

  const update = useCallback((value) => {
    dispatch({
      type: 'UPDATE',
      value,
    });
  }, []);

  const handleChange = useCallback(
    (e) => {
      // console.log('handle change...', e.target.value);
      update(e.target.value);
    },
    [update],
  );

  const reset = useCallback(() => {
    dispatch({
      type: 'RESET',
      initialData,
    });
  }, [initialData]);

  // return {
  //   [`state${name}`]: state,
  //   [`handle${name}`]: handle,
  //   [`select${name}`]: select,
  //   [`reset${name}`]: reset,
  // };

  return {
    state,
    handleChange,
    update,
    reset,
  };
}

export default useRadioGroup;
