import { METHOD } from 'common/hooks';

export const applicationApi = (requestFunc) => {
  return {
    // 수강 신청
    addApplication: {
      key: 'addApplication', // 수강 신청
      queryFn: (params) => requestFunc(METHOD.POST, `/applications`, params),
    },
    updateApplicationSchedule: {
      key: 'updateApplicationSchedule', // 수강 신청 - 일정 변경
      queryFn: (params) =>
        requestFunc(
          METHOD.PATCH,
          `/applications/${params.application_seq}`,
          params,
        ),
    },
    applicationSchedules: {
      key: 'applicationSchedules', // 수강 신청 - 일정 조회
      queryFn: (params) =>
        requestFunc(
          METHOD.GET,
          `/applications/${params.application_seq}/schedules`,
        ),
    },
    orderApplication: {
      key: 'orderApplication', // 수강 신청 주문
      queryFn: (params) =>
        requestFunc(
          METHOD.POST,
          `/applications/${params.application_seq}/orders`,
          params,
        ),
    },
    cancelApplication: {
      key: 'cancelApplication', // 수강 신청 취소
      queryFn: (params) =>
        requestFunc(
          METHOD.DELETE,
          `/applications/${params.application_seq}/orders/${params.order_no}`,
        ),
    },
    paymentApplication: {
      key: 'paymentApplication', // 수강 신청 결제
      queryFn: (params) =>
        requestFunc(
          METHOD.POST,
          `/applications/${params.application_seq}/orders/${params.order_no}/payment`,
          params,
        ),
    },
  };
};
