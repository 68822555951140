import styled from 'styled-components';

export const DarkBackground = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1500;
`;

export const DialogBlock = styled.div`
  width: ${(props) => (props.width ? props.width : '320px;')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '1;')};
  min-height: 174px;
  height: ${(props) => (props.height ? props.height : 'auto')};
  border: 2px solid #2f99f4;
  border-radius: 8px;
  box-shadow: 4px 6px 14px rgb(0 0 0 / 16%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props.padding ? props.padding : '40px 20px;')};
  margin: ${(props) => (props.margin ? props.margin : '')};
  background: #fff;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  h3 {
    white-space: pre-wrap;
    margin: 0;
    font-size: 16px;
    color: #1c1c1c;
    font-family: 'NotoSansBold';
  }
  p {
    font-size: ${(props) => (props.fontSize ? props.fontSize : '14px;')};
  }
  p span {
    color: #1c1c1c;
  }
  p span.couponItem {
    color: #1c1c1c;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p.copyText {
    font-size: 12px;
    position: absolute;
    bottom: 15px;
    right: 50%;
    transform: translateX(50%);
  }
`;

export const OverScroll = styled.div`
  max-height: 80vh;
  overflow-y: auto; 
`;

export const ButtonGroup = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`;

// 2022-07-27 오른쪽 버튼 (로그인, 확인 등등)
export const ApplyButton = styled.button`
  font-size: 14px;
  width: 114px;
  height: 40px;
  border-radius: 20px;
  background: #2f99f4;
  color: #fff;
  font-family: 'NotoSansMedium';
  :hover {
    background-color: #2f99f4;
  }
`;

// 2022-07-27 왼쪽 버튼 (취소)
export const CancelButton = styled.button`
  font-size: 14px;
  width: 114px;
  height: 40px;
  border-radius: 20px;
  border: 2px solid #2f99f4;
  background: #fff;
  color: #1c1c1c;
  font-size: 14px;
  font-family: 'NotoSansMedium';
  margin-right: 10px;
`;

export const Contents = styled.div`
  margin-top: 10px;
  text-align: center;
`;

export const ButtonSet = styled.div`
  margin-left: auto;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '30px')};
`