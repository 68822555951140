import React, { useState } from 'react';
import { Typography, Grid } from '@material-ui/core';

// ClassNotice.js

function TogetherClassNotice({ data }) {
  // console.log(data);
  return (
    <Grid className="classNotice">
      <Typography variant="h3">클래스 정보</Typography>
      <ul className="class-info-list">
        <li className="classinfo-item">
          <span className="classinfo-icon"></span>
          <p>커리큘럼 주 1회 / {data.together_class_month ? data.together_class_month : 0}개월</p>
        </li>
        <li className="classinfo-item">
          <span className="duration-icon"></span>
          <p>1회 수업 당 {data.class_time}분</p>
        </li>
        {data.worksheet_yn && (
          <li className="classinfo-item">
            <span className="package-icon"></span>
            <p>교재포함</p>
          </li>
        )}
        <li className="classinfo-item">
          <span className="usercount-icon"></span>
          {data.all_age_yn ? (
              data.all_age_yn == 2 ?
                <p>학부모 추천</p> :
                <p>전연령 추천</p>
          ) : data.min_target_age === data.max_target_age ? 
              (<p>{data.max_target_age}세 추천</p>) : (
            <p>{data.min_target_age}~{data.max_target_age}세 추천</p>
          )}
        </li>
        <li className="classinfo-item">
          <span className="targetage-icon"></span>
          {data.min_user_count === data.max_user_count ? (
            <p>{data.max_user_count}명 정원</p>
          ) : (
            <p>{data.min_user_count}~{data.max_user_count}명 정원</p>
          )}
          
        </li>
        <li className="classinfo-item">
          <span className="bookpad-icon"></span>
          <p>북패드 수업</p>
        </li>
      </ul>
    </Grid>
  );
}

export default React.memo(TogetherClassNotice);
