import React from 'react';
import 'assets/css/subVisual.css';

// SubVisual.js

function SubVisual({ children }) {
  return <div className="subVisual">{children}</div>;
}

export default React.memo(SubVisual);
