import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useCommonMutation, useCommonQuery } from 'common/hooks';
import { useApiContext } from 'common/context';
import { useInput } from 'common/hooks';
import { useNavigate } from 'react-router';
import {useLocation} from "react-router-dom";

function useSearchBar(initKeyword = '') {
  const navigate = useNavigate();
  const { queries } = useApiContext();
  const searchKeyword = useInput(initKeyword);
  const [popularKeywords, setPopularKeywords] = useState([]);
  const [optionVisible, setOptionVisible] = useState(false);
  const searchRef = useRef();
  const location = useLocation();

  // kakaopixel 검색
  useEffect(() => {
    const kakaoPixelScript = document.createElement('script');
    kakaoPixelScript.type = 'text/javascript';
    kakaoPixelScript.charset = 'UTF-8';
    kakaoPixelScript.src = '//t1.daumcdn.net/kas/static/kp.js';
    document.head.appendChild(kakaoPixelScript);

    const urlSearch = (location.search).split("=");
    const encodeData = urlSearch[1];
    const decodedData = decodeURIComponent(encodeData);
    // console.log("decodedData", decodedData);

      const kakaoPixel = () => {
        if (window.kakaoPixel) {
          window.kakaoPixel('1737087911283410504').pageView();
          window.kakaoPixel('1737087911283410504').search({
            keyword: decodedData,
            tag: 'search_keyword',
          });
        } else {
          setTimeout(kakaoPixel, 500);
        }
      };
      kakaoPixel();
  }, [location]);

  const { request: requestSearchInline } = useCommonQuery({
    query: queries.mainSearchInline,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          // console.log(data.result_data);
        } else {
          console.log(data.result_message);
        }
      },
      onError: (error) => {
        // console.log(error);
      },
    },
    initEnabled: false,
  });

  const { request: requestSearchPopular } = useCommonQuery({
    query: queries.mainSearchPopular,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          setPopularKeywords(data.result_data);
        } else {
          console.log(data.result_message);
        }
      },
      onError: (error) => {
        // console.log(error);
      },
    },
  });

  const handleSearchOptionShow = useCallback((e) => {
    setOptionVisible(true);
  }, []);

  const handleSearchOptionHide = useCallback((e) => {
    setOptionVisible(false);
  }, []);

  const handleSearchClick = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      setOptionVisible(false);

      if (searchKeyword.state.length > 0) {
        navigate(`/search/result?keyword=${searchKeyword.state}`);
      }
    },
    [navigate, searchKeyword.state],
  );

  const handlePopularClick = useCallback(
    (e, keyword) => {
      e.stopPropagation();
      e.preventDefault();
      document.getElementById('standard-search').blur();
      setOptionVisible(false);
      searchKeyword.update(keyword);

      if (keyword.length > 0) {
        navigate(`/search/result?keyword=${keyword}`);
      }
    },
    [navigate, searchKeyword],
  );

  const handleSearchKeyDown = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        document.getElementById('standard-search').blur();
        setOptionVisible(false);

        if (searchKeyword.state.length > 0) {
          navigate(`/search/result?keyword=${searchKeyword.state}`);
        }
      }
    },
    [navigate, searchKeyword.state],
  );

  return {
    searchRef,
    searchKeyword,
    popularKeywords,
    optionVisible,
    handleSearchClick,
    handleSearchKeyDown,
    handlePopularClick,
    handleSearchOptionShow,
    handleSearchOptionHide,
  };
}

export default useSearchBar;
