import React, { useState } from 'react';
import { DOMAIN } from 'common/constants';

function TeacherInfoPic({ data }) {
  const { teacher_info } = data;
  if (!teacher_info) {
    return null;
  }
  return (
    <span className="teacher-img">
      <img src={data.teacher_info.profile_image_url} alt="선생님 사진" />
    </span>
  );
}

export default React.memo(TeacherInfoPic);
