import "./App.css";
import PopAlert from "components/popAlert/PopAlert";
import MainRouter from "common/routers";
import Spinner from "components/spinner/Spinner";
import Header from "components/layout/Header";
import Footer from "components/layout/Footer";
import PopAlertProvider from "components/popAlert/context/PopAlertContext";
import SpinnerProvider from "components/spinner/context/SpinnerContext";
import MemberProvider from "common/context/MemberContext";
import ApiProvider from "common/context/ApiContext";
import AppProvider from "common/context/AppContext";
import Reset from "components/globalStyle/Reset";
import AutoLogin from "components/login/AutoLogin";
import Scroller from "components/scroll/Scroller";
import { BrowserView } from "react-device-detect";
import "assets/css/style.scss";
import { ScrollTopButton } from "components/utils";
import useDelayRender from "components/layout/hooks/useDelayRender";
import Toast from "components/toast/Toast";
import ToastProvider from "components/toast/context/ToastContext";

function App() {
  const isDelayRender = useDelayRender(500); // footer

  return (
    <div className="App">
      <ToastProvider>
        <PopAlertProvider>
          <SpinnerProvider>
            <MemberProvider>
              <ApiProvider>
                <AppProvider>
                  {/* <BrowserView> */}
                  <AutoLogin />
                  <Scroller />
                  <Reset />
                  <Header />
                  <MainRouter />
                  {isDelayRender && <Footer />}
                  <Toast />
                  <PopAlert />
                  <Spinner />
                  <ScrollTopButton />
                  {/* </BrowserView> */}
                </AppProvider>
              </ApiProvider>
            </MemberProvider>
          </SpinnerProvider>
        </PopAlertProvider>
      </ToastProvider>
    </div>
  );
}

export default App;
