import React, { useMemo } from 'react';
import { toCurrency, getDiscountRate } from 'common/utils';
import BasePrice from './BasePrice';
import DiscountPercent from 'components/items/DiscountPercent';
import CurrentPrice from 'components/items/CurrentPrice';
import {Desktop, TabletAndMobile} from "../utils/MediaQuery";

function PriceInfoItem({ data }) {
  const discountRate = useMemo(() => {
    return getDiscountRate(data.price, data.discount_price);
  }, [data.discount_price, data.price]);

  return (
    <>
      <Desktop>
      <div className="info-price desk-info-price">
        <BasePrice data={data} discountRate={discountRate} />
        {/* // 할인 전 가격 */}
        <DiscountPercent discountRate={discountRate} />
        {/* // 할인율 */}
        <CurrentPrice data={data} />
        {/* // 할인 된 가격 */}
      </div>
      </Desktop>
      <TabletAndMobile>
        <div className="info-price mo-info-price ">
          <BasePrice data={data} discountRate={discountRate} />
          {/* // 할인 전 가격 */}
          <DiscountPercent discountRate={discountRate} />
          {/* // 할인율 */}
          <CurrentPrice data={data} />
          {/* // 할인 된 가격 */}
        </div>
      </TabletAndMobile>
    </>
  );
}

export default React.memo(PriceInfoItem);
