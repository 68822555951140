import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import 'assets/css/detail/classInquiry.css';

// ClassSupplies.js

function ClassSupplies({ data }) {
  // console.log(data);
  return (
    <div className="detailTabBox isOn">
      <h1 className="classReview_tit">준비물 & 유의사항</h1>
      <div className="classSupplies-list-wrap">
        {data?.included &&
          data.included.trim().length > 0 &&
          data?.materials &&
          data.materials.trim().length > 0 && (
            <div className="list-title">
              <span>이렇게 준비해 주세요.</span>
            </div>
          )}
        {/* 2022-08-03 
        list-content 에 추가되는 dp-f 부분은 이 부분 밖에 없습니다.
            두번째 con-box에 mr0 추가
        */}
        <div className="list-content dp-f">
          {data?.included && data.included.trim().length > 0 && (
            <div className="con-box">
              <p>
                <span>수업에 포함되어 있어요!</span>
                {/* 2022-08-01 고정 텍스트 수정 */}
              </p>
              <p>{data.included}</p>
            </div>
          )}
          {data?.materials && data.materials.trim().length > 0 && (
            <div className="con-box mr0">
              <p>
                <span>따로 챙겨주세요!</span>
              </p>
              <p>{data.materials}</p>
            </div>
          )}
        </div>
        {/* //2022-08-03 끝 */}

        <div className="list-content innerHtml_style">
          <Typography
            dangerouslySetInnerHTML={{ __html: data.curriculum_html }}
          />
        </div>

        <div className="list-title">
          <span>수업 전 꼭 확인해 주세요.</span>
        </div>
        <div className="list-content innerHtml_style">
          <Typography dangerouslySetInnerHTML={{ __html: data.notice_html }} />
        </div>

        {data.isOneDay && (
          <>
            <div className="list-title">
              <span>원데이 수업 신청 시, 확인해 주세요.</span>
            </div>
            <div className="list-content">
              <ul>
                <li>
                  <span></span>수업 확정일 이전에는 언제든지 취소 및 환불이
                  가능하며, 확정일 이후에는 취소 및 환불이 불가합니다.
                </li>
                <li>
                  <span></span>수업 확정일 전까지 최소 인원이 모집되지 않은
                  스케줄은 폐강되며, 결제하신 수단으로 환불됩니다.
                </li>
                <li>
                  <span></span>수업 확정일 이전에 수업 자료를 다운로드한 경우
                  취소 및 환불이 불가합니다.
                </li>
                <li>
                  <span></span>준비물 배송이 없는 수업의 경우, 수업 1일 전에
                  수업이 확정되며 수업 2일 전까지 취소 및 환불이 가능합니다.
                </li>
                <li>
                  <span></span>준비물 배송이 있는 수업의 경우, 수업 6일 전에
                  수업이 확정되며 수업 7일 전까지 취소 및 환불이 가능합니다.
                </li>
                <li>
                  <span></span>단, 일부 배송 상품의 경우 배송 상황(택배사 파업,
                  물량 증가 등)에 따라 차질 없는 수업 진행을 위해 수업 6일 전이
                  아닌 8~9일 이전에 확정될 수 있으며, 수업 확정 이후에는 취소 및
                  환불이 불가합니다.
                </li>
                <li>
                  <span></span>자세한 내용은 환불 규정을 참고해 주세요.
                </li>
              </ul>
            </div>
          </>
        )}

        {data.isPackage && (
          <>
            <div className="list-title">
              <span>패키지 수업 신청 시, 확인해 주세요.</span>
            </div>
            <div className="list-content">
              <ul>
                <li>
                  <span></span>패키지(다회차)수업의 1회차 수업 확정일 전에는
                  언제든지 취소 및 환불이 가능합니다.
                </li>
                <li>
                  <span></span>수업 확정일 전까지 최소 인원이 모집되지 않은
                  스케줄은 폐강되며, 결제하신 수단으로 환불됩니다.
                </li>
                <li>
                  <span></span>수업 확정일 이전에 수업 자료를 다운로드한 경우
                  취소 및 환불이 불가합니다.
                </li>
                <li>
                  <span></span>준비물 배송이 없는 수업의 경우, 1회차 수업 1일
                  전에 수업이 확정되며 1회차 수업 2일 전까지 취소 및 환불이
                  가능합니다.
                </li>
                <li>
                  <span></span>준비물 배송이 있는 수업의 경우, 1회차 수업 6일
                  전에 수업이 확정되며 1회차 수업 7일 전까지 취소 및 환불이
                  가능합니다.
                </li>
                <li>
                  <span></span>단, 일부 배송 상품의 경우 배송 상황(택배사 파업,
                  물량 증가 등)에 따라 차질 없는 수업 진행을 위해 수업 6일 전이
                  아닌 8~9일 이전에 확정될 수 있으며, 수업 확정 이후에는 취소 및
                  환불이 불가합니다.
                </li>
                <li>
                  <span></span>부득이한 경우에 한해 전체 수업 횟수의 50% 미만을
                  수강 및 수업 확정 된 경우 부분 환불 가능하나, 포인트로 결제한
                  금액은 제외하고 나머지 금액만 환불됩니다.
                </li>
                <li>
                  <span></span>자세한 내용은 환불 규정을 참고해 주세요.
                </li>
              </ul>
            </div>
          </>
        )}

        {/* <div className="list-title">
          <span>취소/환불</span>
        </div>
        <div className="list-content">
          <ul>
            <li>
              <span></span>수업 확정일 이전까지는 취소가 가능하지만, 수업 확정
              이후에는 취소가 불가능합니다.
            </li>
          </ul>
        </div> */}

        {/* <div className="list-title">
          <span>반품/교환</span>
        </div>
        <div className="list-content">
          <ul>
            <li>
              <span></span>맞춤형 제작 상품으로 인해, 교재 발송 이후 반품은
              불가합니다.
            </li>
            <li>
              <span></span>상품의 문제가 있는 경우 수령 후 7일 이내 라이브올
              고객센터로 사유와 함께 문의해 주시기 바랍니다.
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  );
}

export default React.memo(ClassSupplies);
