import React, { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import { Button } from "react-scroll";
import useToast from "./hooks/useToast";

const Toast = () => {
  const { state, hideToast, clickToast } = useToast();

  return (
    <>
      <Snackbar
        anchorOrigin={state.position} 
        open={state.visible}
        autoHideDuration={state.duration}
        onClose={hideToast}
        onClick={clickToast}
        message={state.text}
        key={"top"}
      />
    </>
  );
};

export default React.memo(Toast);
