import React, { useMemo } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router';
import moment from 'moment';
import 'assets/css/index/sub-list.css'; // 매거진 & 이벤트 페이지 컨텐츠 리스트 css

// EventItem.js

function EventItem({ data }) {
    const navigate = useNavigate();

    const onClick = () => {
        // console.log(data);
        navigate(`/event/${data?.event_seq}`);
    };

    const startDateText = useMemo(() => {
        return moment(data?.period_start_dt).format('YYYY-MM-DD');
    }, [data?.period_start_dt]);

    const endDateText = useMemo(() => {
        return moment(data?.period_end_dt).format('YYYY-MM-DD');
    }, [data?.period_end_dt]);

    return (
        <>
            <Card sx={{ width: '47.4%' }} onClick={onClick} className="sub-item-root">
                <CardActionArea>
                    <CardMedia component="img" height="194" src={data?.event_image_url} alt={data?.event_title} />
                </CardActionArea>
                <CardContent className="sub-item-text">
                    <div className="class-name">
                        <h1>{data?.event_title}</h1>
                    </div>
                    <div className="event-period">
                        {data?.period_start_dt && data?.period_end_dt ? `기간 ${startDateText} ~ ${endDateText}` : ''}
                    </div>
                </CardContent>
            </Card>
        </>
    );
}

export default React.memo(EventItem);
