import React, { useCallback, useEffect, useState } from 'react';

import { Button, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { Typography, Grid, FormControl } from '@material-ui/core';

import 'assets/css/together/togetherSchedule.scss';
import { useRadioGroup } from 'common/hooks';
import { useCommonQuery } from 'common/hooks';
import { useApiContext, useLoginContext } from 'common/context';
import usePopAlert from 'components/popAlert/hooks/usePopAlert';
import moment from 'moment';

function TogetherScheduleTable({ orderInfo, together, setLectureSeq, setAsisStudyRoomSeq, setTobeStudyRoomSeq, changeScheduleType, scheduleStatus=null }) {
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const dayScheduleSelect = useRadioGroup(together.hourCode ? together.hourCode : '');
  const [applyDaySelect, setDaySelect] = useState(together.dayCode ? together.dayCode : '');
  const [changeScheduleList, setChangeScheduleList] = useState([]);

  // const isPassedSchedule = moment(together.scheduleDate).isAfter(moment());
  // const isWeekSchedule = moment(together.nextLessonDate, 'YYYYMMDD').add(1, 'weeks').isAfter(together.scheduleDate);
  const isTodaySchdule = together.scheduleDate? together.isTodayStudyYn :moment(together.scheduleDate).isSame(moment(), 'day')

  // 2024-06-13 스케줄 데이터 당일 수업 1시간전까지 당일 변경가능추가
  const { request: requestChangeableTogetherSchedule } = useCommonQuery({
    query: queries.togetherChangeSchedules,
    params: {
      lep_order_no: orderInfo.lep_order_no,
      // study_day: isPassedSchedule && isWeekSchedule ? together.scheduleDate : null, // 당일 수업이 아닌 경우 & 지난 수업이 아닌 경우 & 해당 주차 수업인 경우
      study_day: isTodaySchdule && scheduleStatus == 1 ? moment().format('YYYYMMDD') : together.nextLessonDate, // 당일 수업 1시간 전
      school_day_code: applyDaySelect,
      member_code: orderInfo.bookclub_child_member_code,
      change_type: changeScheduleType,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          if (data.result_data.resultCode === '200') {
            setChangeScheduleList(data.result_data.data.scheduleChangeAbleList.dataList);
            setLectureSeq(data.result_data.data.scheduleChangeAbleList.lectureSeq);
            setAsisStudyRoomSeq(data.result_data.data.scheduleChangeAbleList.asisStudyRoomSeq);
          } else {
            return;
          }
        } else {
          console.log('togetherChangeSchedules Error ::', data.result_message);
          // showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        console.log('togetherChangeSchedules Error ::', error);
        // showAlert('알림', '스케줄 조회에 실패했습니다.');
      },
    },
  });

  const changeApplyDaySelect = useCallback(
    (day) => {
      if (!day || applyDaySelect == day) {
        return;
      }
      setDaySelect(day);
      dayScheduleSelect.update(-1);
    },
    [applyDaySelect]
  );

  const changeApplyTimeSelect = useCallback(
    (e) => {
      dayScheduleSelect.handleChange(e);
      dayScheduleSelect.update(e.target.value);
      setTobeStudyRoomSeq(e.target.value);
    },
    [dayScheduleSelect.state, applyDaySelect, setTobeStudyRoomSeq]
  );

  return (
    <div className="schedule-wrap">
      <Typography className="wrap-title">수업 주차/일정을 선택해 주세요.</Typography>
      <Typography className="notice">
        <p>※ 실제 수업일을 꼭 확인해 주세요.</p>
        <p>
          ※ 선택한 수업 주차에 <span>공휴일</span>이 있을 시, 실제 수업 요일에 차이가 발생될 수 있습니다.
        </p>
      </Typography>

      <Stack direction="row" spacing={1} className="day-btn-area">
        <Button
          className={applyDaySelect == 'DAY2' ? 'active' : ''}
          variant="outlined"
          onClick={() => {
            changeApplyDaySelect('DAY2');
          }}
        >
          월요일
        </Button>
        <Button
          className={applyDaySelect == 'DAY3' ? 'active' : ''}
          variant="outlined"
          onClick={() => {
            changeApplyDaySelect('DAY3');
          }}
        >
          화요일
        </Button>
        <Button
          className={applyDaySelect == 'DAY4' ? 'active' : ''}
          variant="outlined"
          onClick={() => {
            changeApplyDaySelect('DAY4');
          }}
        >
          수요일
        </Button>
        <Button
          className={applyDaySelect == 'DAY5' ? 'active' : ''}
          variant="outlined"
          onClick={() => {
            changeApplyDaySelect('DAY5');
          }}
        >
          목요일
        </Button>
        <Button
          className={applyDaySelect == 'DAY6' ? 'active' : ''}
          variant="outlined"
          onClick={() => {
            changeApplyDaySelect('DAY6');
          }}
        >
          금요일
        </Button>
      </Stack>

      {applyDaySelect !== '' && (
        <Grid className="slt-area">
          <Stack direction="row" className="thead">
            <div className="cell">선택</div>
            <div className="cell">실제 수업일</div>
            <div className="cell">시간표</div>
            <div className="cell">신청</div>
          </Stack>
          <Stack direction="column" className="tbody">
            <FormControl>
              <RadioGroup name="scheduleTime" value={dayScheduleSelect.state} onChange={(e) => changeApplyTimeSelect(e)}>
                {changeScheduleList && changeScheduleList.length > 0 ? (
                  changeScheduleList?.map((item, idx) => (
                    <FormControlLabel
                      key={idx}
                      className={`${dayScheduleSelect.state != -1 && item.STUDY_ROOM_SEQ == dayScheduleSelect.state ? 'isChecked' : ''}`}
                      value={item.STUDY_ROOM_SEQ}
                      control={<Radio />}
                      checked={dayScheduleSelect.state != -1 && item.STUDY_ROOM_SEQ == dayScheduleSelect.state}
                      label={
                        <>
                          <div className="cell">{moment(item.BASE_DATE).format('MM/DD (dd)')}</div>
                          <div className="cell">{item.STUDY_ROOM_INFO}</div>
                          <div className="cell">{'가능'}</div>
                        </>
                      }
                    />
                  ))
                ) : (
                  <div style={{ textAlign: 'center', margin: '50px 0', fontWeight: 'bold', color: '#1c1c1c' }}>
                    변경 가능한 일정이 없습니다.
                  </div>
                )}
              </RadioGroup>
            </FormControl>
          </Stack>
        </Grid>
      )}
    </div>
  );
}

export default React.memo(TogetherScheduleTable);
