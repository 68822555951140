import { Desktop } from "components/utils/MediaQuery";
import Button from "@mui/material/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import { NavLink, useLocation, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { CATEGORY, SUB_CATEGORY } from "./common";

const CategorySubed = ({
  categorySubed,
  subCategoryView,
  subCategoryClick,
  nowSub,
}) => {
  return categorySubed?.map((item, idx) => {
    return (
      <li key={`category${idx}`}>
        <button
          className="btn arr"
          onClick={() => {
            subCategoryClick(item.category_seq);
          }}
        >
          {item.category_name}
        </button>
        {subCategoryView && nowSub === item.category_seq ? (
          <SubCategory item={item} />
        ) : (
          <></>
        )}
      </li>
    );
  });
};

const SubCategory = ({ item }) => {
  const nowLink_1 = CATEGORY.get(item.category_seq);
  return (
    <div className="category-dep2-area">
      <ul className="category-dep2-menu">
        <li>
          <NavLink to={nowLink_1} className="btn">
            ALL
          </NavLink>
        </li>
        {item.childs.map((subCate, index) => {
          const nowLink_2 = `${SUB_CATEGORY.get(item.category_seq)}${subCate.category_seq}`;
          return (
            <li key={`subCate_${index}`}>
              <NavLink to={nowLink_2} className="btn">
                {subCate.category_name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const Category = ({ category }) => {
  return category?.map((item) => (
    <li key={item.main_menu_seq}>
      <NavLink to={item.link_url} className="btn">
        {item.menu_title}
      </NavLink>
    </li>
  ));
};

export default function DesktopCategory(props) {
  const {
    cate: categorySubed,
    categoryView,
    subCategoryView,
    categorySwitch,
    subCategorySwith,
    swiperParams,
    setSwiper,
    top: mainMenu,
    menu: category,
  } = props;

  const [nowSub, setNowSub] = useState("");
  const handleMouseLeave = (e) => {
    setNowSub("");
    if (categoryView) categorySwitch();
  };

  const subCategoryClick = (seq) => {
    setNowSub(seq);
    subCategorySwith();
  };

  const Categories = () => {
    return (
      <ul className={`category-menu on ${subCategoryView ? "extend" : ""}`}>
        {categorySubed && (
          <CategorySubed
            categorySubed={categorySubed}
            subCategoryView={subCategoryView}
            subCategoryClick={subCategoryClick}
            nowSub={nowSub}
          />
        )}
        {category && <Category category={category} />}
      </ul>
    );
  };

  return (
    <Desktop>
      <div className="gnb-area">
        <div className="container">
          <div className="category-box" onMouseLeave={handleMouseLeave}>
            <Button className="category-btn" onClick={categorySwitch}>
              카테고리
            </Button>
            {categoryView && <Categories />}
          </div>

          <Swiper
            {...swiperParams}
            ref={setSwiper}
            className="gnb"
            style={{
              width: "auto !important",
              height: "63px",
            }}
          >
            {mainMenu &&
              mainMenu?.map((item, idx) =>
                item.exposure_yn ? (
                  <SwiperSlide key={`swifer${idx}`}>
                    <NavLink to={item.link_url}>{item.menu_title}</NavLink>
                  </SwiperSlide>
                ) : (
                  <></>
                )
              )}
          </Swiper>
        </div>
      </div>
    </Desktop>
  );
}
