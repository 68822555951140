import React, { useState, useCallback, useEffect, useReducer } from 'react';
import { useCommonQuery } from 'common/hooks';
import { useApiContext } from 'common/context';
import { useCommonMutation } from 'common/hooks';
import usePopAlert from 'components/popAlert/hooks/usePopAlert';
import { useNavigate } from 'react-router';
import produce from 'immer';

import ReactGA from 'react-ga4'
import { GoogleAnalysticsProperties } from 'common/constants';
import { useLoginContext } from 'common/context/MemberContext';

function reducer(state, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case 'SET_CLASS_INFO':
                return action.value;
            case 'REGIST_INQUIRY':
                draft.class_inquiry.push(action.value);
                break;
            case 'UPDATE_INQUIRY':
                const updateItem = draft.class_inquiry.find((item) => item.inquiry.inquiry_seq === action.inquirySeq);
                if (updateItem) {
                    updateItem.inquiry.subject = action.variables.subject;
                    updateItem.question.content = action.variables.content;
                    break;
                }
                return state;
            case 'DELETED_INQUIRY':
                const index = draft.class_inquiry.findIndex((item) => item.inquiry.inquiry_seq === action.inquirySeq);
                if (index >= 0) {
                    draft.class_inquiry.splice(index, 1);
                    break;
                }
                return state;
            default:
                return state;
        }
    });
}

function useSecretClassDetail(id) {
    const { queries } = useApiContext();
    const { showAlert } = usePopAlert();
    const navigate = useNavigate();
    const [classInfo, dispatch] = useReducer(reducer, undefined);
    const [selectedSchedule, setSelectedSchedule] = useState('');
    const [clubPoint, setClubPoint] = useState('');
    const { loginState } = useLoginContext();

    const [loginData, setLoginData] =  useState('');

    useEffect(() => {
        if ( !loginState.contract ) {
            return;
        }
        setLoginData(loginState);
    }, [loginState])

    const { request: requestClassInfo } = useCommonQuery({
        query: queries.secretClassInfo,
        params: { class_seq: id },
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    dispatch({
                        type: 'SET_CLASS_INFO',
                        value: data.result_data,
                    });
                } else {
                    showAlert('알림', data.result_message, {
                        confirmHandler: () => navigate(-1),
                        cancelHandler: () => navigate(-1),
                    });
                }
            },
            onError: (error) => {
                    showAlert('알림', '클래스 정보 조회에 실패했습니다.', {
                    confirmHandler: () => navigate(-1),
                    cancelHandler: () => navigate(-1),
                });
            },
        },
    });

    const { requestAsync: requesApplyClass } = useCommonMutation({
        query: queries.applyClass,
        callbacks: {
            onSuccess: (data, variables) => {
                if (data.result_code === '0000') {
                    // console.log("requesApplyClass ::", data.result_data);
                    navigate(`/class/${data.result_data.class_seq}/order`, {
                        replace: true,
                        state: {
                            schedule: selectedSchedule,
                            orderInfo: data.result_data,
                            clubPoint: data.result_data.clubPoint,
                            contracts: data.result_data.contracts
                        },
                    });
                } else {
                    showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                showAlert('알림', '클래스 신청에 실패했습니다.');
            },
        },
    });

    const handleApplyClass = useCallback(() => {
        if (!selectedSchedule) {
            showAlert('알림', '클래스 일정을 선택해주세요.');
            return;
        }

        requesApplyClass({
            class_seq: id,
            schedule_seq: '',
        });

        ReactGA.event(GoogleAnalysticsProperties.PurchaseStart)

    }, [selectedSchedule, requesApplyClass, id, showAlert]);

    return {
        classInfo,
        setSelectedSchedule,
        handleApplyClass,
    };
}

export default useSecretClassDetail;
