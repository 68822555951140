import React, { useCallback, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';

// BigCalenderWeek.js

function BigCalendarWeek({ events, handleDateSetChange, handleDateClick }) {
  const onDateSet = useCallback(
    (dateInfo) => {
      handleDateSetChange(
        moment(dateInfo.start).format('YYYYMMDD'),
        moment(dateInfo.end).format('YYYYMMDD'),
      );
    },
    [handleDateSetChange],
  );

  useEffect(() => {
    // console.log(events);
    const elems = document.querySelectorAll('.fc-daygrid-day.fc-day');
    elems.forEach((elem) => {
      if (elem.classList.contains('event-calender-item')) {
        elem.classList.remove('event-calender-item');
      }
    });
  }, [events]);

  return (
    <>
      <div className="cal-state-wrap">
        <p className="cal-state-classDate">
          <span></span>수업일
        </p>
      </div>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: '', //dayGridWeek,dayGridMonth
          center: 'prev,title,next',
          right: '',
        }}
        locale="ko"
        initialView={'dayGridWeek'}
        dateClick={handleDateClick}
        datesSet={onDateSet}
        events={events}
        height="parent"
        allDaySlot={true}
        eventDidMount={function (event) {
          event.el.parentNode.parentNode.parentNode.parentNode.classList.add(
            'event-calender-item',
          );
        }}
        titleFormat={function (date) {
          //주차 계산
          const getWeekNumber = (dateFrom = new Date()) => {
            const currentDate = dateFrom.getDate();
            const startOfMonth = new Date(dateFrom.setDate(0));
            const weekDay = startOfMonth.getDay();
            return parseInt((weekDay - 1 + currentDate) / 7) + 1;
          };

          let start = date.start.marker;
          let end = date.end.marker;
          let year = '' + start.getFullYear() + '년';
          let month = '' + (start.getMonth() + 1) + '월';
          if (month.length < 2) {
            month = '0' + month;
          }
          return (
            year + ' ' + month + ' ' + getWeekNumber(new Date(start)) + '주차'
          );
        }}
        // columnFormat={{
        //   week: "dddd"
        // }}
        dayHeaderFormat={{
          weekday: 'short',
        }}
        dayCellContent={(args) => {
          return moment(args.date).format('D');
        }}
      />
    </>
  );
}

export default BigCalendarWeek;
