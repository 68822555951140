import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import modal_x_white from 'assets/images/etc/modal/modal_x_white.png';
// import qaBtn from 'assets/images/common/qa-btn.png';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '3px solid #2f99f4',
  boxShadow: 24,
  p: 4,
};

function ModalWrap(props) {
  const {
    children,
    className,
    buttonName,
    modalinfo,
    visible,
    handleOpen,
    handleClose,
  } = props;

  const buttons = useMemo(() => {
    if (modalinfo === 'inqiory-create') {
      return (
        <Button onClick={handleOpen} className="qa-btn">
          클래스 문의하기
        </Button>
      );
    }
    if (modalinfo === 'other-button') {
      return null;
    }
    return (
      <Button onClick={handleOpen} className={className} variant="contained">
        {buttonName}
      </Button>
    );
  }, [buttonName, className, handleOpen, modalinfo]);

  return (
    <>
      {buttons}

      <Modal
        open={visible}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={'modal-pop-wrap ' + 'pop-wrap' + className}
      >
        <Box sx={style} className="modal-pop-contents">
          <Button onClick={handleClose} className="pop-close-x-button">
            <img src={modal_x_white} alt="CLOSE" />
          </Button>
          {React.cloneElement(children, { props, handleClose })}
        </Box>
      </Modal>
    </>
  );
}

export default React.memo(ModalWrap);
