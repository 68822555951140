import React, { useState, useCallback, useReducer, useMemo } from 'react';
import produce from 'immer';

function reducer(state, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'UPDATE':
        if (!action.value || action.maxLength === 0) {
          return action.value;
        }
        return `${action.value}`.slice(0, action.maxLength);
      case 'RESET':
        return action.initialData;
      default:
        return state;
    }
  });
}

function useInput(
  initialData,
  maxLength = 0,
  type = undefined,
  maxValue = undefined,
) {
  const [state, dispatch] = useReducer(reducer, initialData);

  const update = useCallback(
    (value) => {
      // if (state === value) {
      //   return;
      // }

      dispatch({
        type: 'UPDATE',
        value,
        maxLength,
      });
    },
    [maxLength, state],
  );

  const handleChange = useCallback(
    (e) => {
      // console.log('handle change...', e.target.value);
      let value = e.target.value;
      if (type === 'phone') {
        value = value.replace(/\D/g, '');
      } else if (type === 'name') {
        value = value.replace(
          /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim,
          '',
        );
      } else if (type === 'code') {
        value = value
          .replace(/[^a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣]/gi, '')
          .toUpperCase();
      } else if (type === 'number') {
        value = value.replace(/[^0-9]/gi, '');
        value = maxValue && parseInt(value) > maxValue ? maxValue : value;
      }
      update(`${value}`);
    },
    [update, type],
  );

  const reset = useCallback(() => {
    dispatch({
      type: 'RESET',
      initialData,
    });
  }, [initialData]);

  return {
    state,
    handleChange,
    update,
    reset,
  };
}

export default useInput;
