import React, { useCallback, useMemo } from 'react';
import {
  Container,
  FormControl,
  Typography,
  Grid,
  Box,
} from '@material-ui/core';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import moment from 'moment';
// import 'assets/css/pages/classDDetail.scss';

function TimeSelect({ isAm, times, value, handleChange }) {
  const sortedTimes = useMemo(() => {
    if (!times) {
      return [];
    }
    const dummy = times.slice(0);
    dummy.sort((a, b) => {
      if (parseInt(a.time) > parseInt(b.time)) {
        return 1;
      } else if (parseInt(a.time) < parseInt(b.time)) {
        return -1;
      }
      return 0;
    });
    return dummy;
  }, [times]);

  const onChange = useCallback(
    (e) => {
      const time = times.find((data) => data.time === e.target.value);
      handleChange(e, time);
    },
    [handleChange, times],
  );

  if (!times || times.length === 0) {
    return null;
  }

  return (
    <>
      <Grid container direction="row" className="TimeRadioWrap">
        <Grid item xs={12} md={1}>
          <Typography className="dayTime">{isAm ? '오전' : '오후'}</Typography>
        </Grid>
        <Grid item xs={12} md={11} className="radioGrid">
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="09"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={onChange}
            >
              {sortedTimes &&
                sortedTimes.length > 0 &&
                sortedTimes.map((data) => (
                  <FormControlLabel
                    key={data.time}
                    value={data.time}
                    disabled={!data.schedule.apply_yn}
                    control={<Radio />}
                    label={
                      <Typography>
                        {moment('20220101 ' + data.time).format('hh:mm')}
                      </Typography>
                    }
                    checked={value === data.time}
                    className="radio_item"
                  />
                ))}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default TimeSelect;
