import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useSpinnerContext } from 'components/spinner/context/SpinnerContext';

// GET
export function useCommonQuery({
  query,
  params = {}, //타입 에러로 undefined에서 {}로 변경
  callbacks = {},
  initEnabled = true,
  showSpinner = true,
  showError = false,
}) {
  const [enabled, setEnabled] = useState(initEnabled);
  const { action } = useSpinnerContext();
  const { showLoading, hideLoading } = action;
  const result = useQuery([query.key, params], () => query.queryFn(params), {
    enabled,
    ...callbacks,
  });

  const status = useMemo(() => {
    return {
      isLoading: result.isLoading,
      isSuccess: result.isSuccess,
      isFetching: result.isFetching,
      isRefetching: result.isRefetching,
      isError: result.isError,
      isLoadingError: result.isLoadingError,
    };
  }, [
    result.isLoading,
    result.isSuccess,
    result.isFetching,
    result.isRefetching,
    result.isError,
    result.isLoadingError,
  ]);

  const { isLoading, isFetching, isRefetching, isError } = result;

  if(isError && showError == true) {
    throw result.error;
  }

  useEffect(() => {
    if ((isLoading || isFetching || isRefetching) && showSpinner) showLoading();
    else hideLoading();
  }, [
    isLoading,
    isFetching,
    isRefetching,
    showSpinner,
    showLoading,
    hideLoading,
  ]);

  // const queryClient = useQueryClient();
  // const request = useCallback(() => {
  //   if (enabled) {
  //     queryClient.invalidateQueries([query.key, params]);
  //   } else {
  //     setEnabled(true);
  //   }
  // }, [enabled, params, query.key, queryClient]);

  const request = result.refetch;

  return { result, status, request };
}

export default useCommonQuery;
