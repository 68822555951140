import React, { useMemo } from 'react';
import usePopAlert from './hooks/usePopAlert';
import {
    DarkBackground,
    DialogBlock,
    ButtonGroup,
    ApplyButton,
    CancelButton,
    Contents,
} from 'components/styled/StyledModal';
import { Desktop, TabletAndMobile } from 'components/utils/MediaQuery';

function PopAlert() {
    const { state, onConfirm, onCancel } = usePopAlert();
    const { title, description, confirmText, cancelText, visible, visibleCancel } = state;

    const descList = useMemo(() => {
        if (!description) {
            return [];
        }
        return description.split('\n');
    }, [description]);

    if (!visible) return null;

    return (
      <>
      <Desktop>
        <DarkBackground onClick={onCancel}>
            <DialogBlock width={state.width} lineHeight={state.lineHeight}>
                {title && title.length > 0 && <h3>{title}</h3>}
                <Contents>
                    <p>
                        {descList &&
                            descList.map((item, index) => {
                                const values = item.split("####class####");
                                const className = values.length > 1 ? values[0] : "";
                                const desc = values.length > 1 ? values[1] : item;
                                return index > 0 ? (
                                    <span key={index} className={className}>
                                        <br />
                                        {desc}
                                    </span>
                                ) : (
                                    <span key={index} className={className}>{desc}</span>
                                );
                            })}
                    </p>
                </Contents>
                {/* 2022-07-28 버튼 스타일 클래스명 추가 */}
                <ButtonGroup>
                    {visibleCancel && (
                        <button onClick={onCancel} className="CancelButton">
                            {cancelText}
                        </button>
                    )}
                    <button onClick={onConfirm} className="ApplyButton">
                        {confirmText}
                    </button>
                </ButtonGroup>
                {/* /// */}
            </DialogBlock>
        </DarkBackground>
      </Desktop>
      <TabletAndMobile>
          <DarkBackground onClick={onCancel}>
              <DialogBlock lineHeight={state.lineHeight} fontSize={state.fontSize}>
                  {title && title.length > 0 && <h3>{title}</h3>}
                  <Contents>
                      <p>
                          {descList &&
                            descList.map((item, index) => {
                                const values = item.split("####class####");
                                const className = values.length > 1 ? values[0] : "";
                                const desc = values.length > 1 ? values[1] : item;
                                return index > 0 ? (
                                  <span key={index} className={className}>
                                        <br />
                                      {desc}
                                    </span>
                                ) : (
                                  <span key={index} className={className}>{desc}</span>
                                );
                            })}
                      </p>
                  </Contents>
                  {/* 2022-07-28 버튼 스타일 클래스명 추가 */}
                  <ButtonGroup>
                      {visibleCancel && (
                        <button onClick={onCancel} className="CancelButton">
                            {cancelText}
                        </button>
                      )}
                      <button onClick={onConfirm} className="ApplyButton">
                          {confirmText}
                      </button>
                  </ButtonGroup>
                  {/* /// */}
              </DialogBlock>
          </DarkBackground>
      </TabletAndMobile>
      </>
    );
}

export default React.memo(PopAlert);
