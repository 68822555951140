import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useCommonMutation, useCommonQuery } from 'common/hooks';
import { useApiContext } from 'common/context';
import { useNavigate } from 'react-router';
import { useComboBox } from 'common/hooks';
import useBasicPagination from 'components/utils/hooks/useBasicPagination';

function useSearchResult(keyword) {
  const navigate = useNavigate();
  const { queries } = useApiContext();
  const [classes, setClasses] = useState([]);
  const sortType = useComboBox(0);
  const paginationInput = useBasicPagination(classes.length, 30);

  useEffect(() => {
    requestSearch();
  }, [paginationInput.page]);

  const { status: classesStatus, request: requestSearch } = useCommonQuery({
    query: queries.mainSearch,
    params: {
      keyword,
      page_per_count: 30,
      current_page: paginationInput.page,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          paginationInput.updateTotalCount(data.result_data.total_count);
          setClasses([data.result_data.data, data.banner]);
        //   window.scrollTo(0, 0);
        } else {
          console.log(data.result_message);
        }
      },
      onError: (error) => {
        // console.log(error);
      },
    },
  });

  return { classes, classesStatus, paginationInput, sortType };
}

export default useSearchResult;
