import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import {
  Rating,
  Box,
  Grid,
} from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { getAge } from 'common/utils';
import {
  ClassTableThumnail,
  TagList,
  TeacherInfoItem,
  PriceInfoItem,
  ClassTitle,
} from '.';
import moment from 'moment';
import 'moment/locale/ko';
// import 'assets/css/pages/classDDetail.scss';
import StarIcon from '@mui/icons-material/Star';

import { test1, test2 } from 'assets/images/detail/test';

import { closeWhite } from 'assets/images/common';
import usePopAlert from 'components/popAlert/hooks/usePopAlert';
import { useFileAttachment } from 'common/hooks';

const labels = {
  // 0.5: '0.5',
  1: '1.0',
  // 1.5: '1.5',
  2: '2.0',
  // 2.5: '2.5',
  3: '3.0',
  // 3.5: '3.5',
  4: '4.0',
  // 4.5: '4.5',
  5: '5.0',
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

const maxKByteSize = 3000;

function ClassReviewCreate({
  classInfo,
  contentInput,
  starInput,
  reviewImages,
  handleUpdateImage,
}) {
  // const ComponentData = detailData.result_data.application_info.class_info;
  // const [value, setValue] = useState(5);
  const { showAlert } = usePopAlert();
  const fileSelectInput = useRef(null);
  const fileIdRef = useRef(0);
  const [hover, setHover] = useState(-1);
  const [swiper, setSwiper] = useState(null);
  const fileAttachment = useFileAttachment(3072);
  const swiperParams = {
    onSwiper: setSwiper,
    spaceBetween: 10,
    slidesPerView: 'auto',
  };

  const onAddPhotoClick = useCallback(
    (e) => {
      if (reviewImages.length >= 5) {
        showAlert('알림', '최대 5개까지 등록 가능합니다.');
        return;
      }
      fileAttachment.handleChange(e);
    },
    [fileAttachment, reviewImages],
  );

  useEffect(() => {
    if (fileAttachment.state.name.length === 0) {
      return;
    }

    handleUpdateImage({
      ...fileAttachment.state,
      id: fileIdRef.current++,
    });
  }, [fileAttachment.state, handleUpdateImage]);

  const onDeleteImageClick = useCallback(
    (id) => {
      const target = reviewImages.find((item) => item.id === id);
      if (target) {
        handleUpdateImage(target);
      }
    },
    [reviewImages, handleUpdateImage],
  );

  if (!classInfo) {
    return null;
  }

  return (
    <>
      <div className="classReview-create-wrap">
        {/* <div className="classReview-info-wrap"></div> */}
        <div className="classReview-info-text-wrap">
          <Grid container className="pop-table">
            {/* 2022-08-16 리스트 순서 변경 : 클래스정보-학습자정보-클래스만족도-클래스후기 */}
            <Grid container className="tr2 line2 " alignItems="center">
              <Grid item xs={2} className="columnLeft">
                클래스 정보
              </Grid>
              <Grid item xs={10} className="columnRight classReview-info-wrap">
                <div className="classReview-info-img">
                  <ClassTableThumnail data={classInfo} />
                </div>
                <CardContent className="card-content-wrap">
                  <div className="content-top">
                    <TagList data={classInfo} />
                  </div>
                  <ClassTitle
                    data={classInfo}
                    title={
                      '[희곡] 장르와 친해지고 실감나게 즐기며 읽는 법을 배우는 클래스'
                    }
                  />
                  <div className="content-bottom">
                    <TeacherInfoItem
                      data={classInfo}
                      showImage={false}
                      showScore={false}
                    />
                  </div>
                </CardContent>
              </Grid>
            </Grid>
            {/* 2022-08-16 학습자 정보 노출 추가 - 개발필요 */}
            <Grid container className="tr2 line1 " alignItems="center">
              <Grid item xs={2} className="columnLeft">
                학습자 정보
              </Grid>
              <Grid item xs={10} className="columnRight">
                <div className="student-info">
                  {`${classInfo.user_name} | ${getAge(classInfo.birth)}세 | ${
                    classInfo.gender_type_name
                  } | ${moment(classInfo.start_dt).format('M월D일(dd)')}`}
                </div>
              </Grid>
            </Grid>
            {/* 2022-08-16 끝 */}
            <Grid container className="tr2 line2 rateLine" alignItems="center">
              <Grid item xs={3} className="columnLeft">
                클래스 만족도
              </Grid>
              <Grid item xs={9} className="columnRight">
                <Rating
                  name="hover-feedback"
                  value={starInput.state}
                  precision={1}
                  getLabelText={getLabelText}
                  // size="large"
                  onChange={(event, newValue) =>
                    starInput.handleChange(newValue)
                  }
                  onChangeActive={(event, newHover) => setHover(newHover)}
                  emptyIcon={
                    <StarIcon style={{ opacity: 1 }} fontSize="inherit" />
                  }
                />
                <Box className="score-text" sx={{ ml: 2 }}>
                  {labels[hover !== -1 ? hover : starInput.state]} /{' '}
                  <span>5</span>
                </Box>
                {/* 2022-08-18 별점 항상 노출 되도록 / 기존 코드 미노출
                {starInput.state !== null && (
                  <Box className="score-text" sx={{ ml: 2 }}>
                    {labels[hover !== -1 ? hover : starInput.state]} /{' '}
                    <span>5</span>
                  </Box>
                )} */}
              </Grid>
            </Grid>
            <Grid container className="tr2 line1" alignItems="center">
              <Grid item xs={2} className="columnLeft">
                클래스 후기<span className="important">*</span>
              </Grid>
              <Grid item xs={10} className="columnRight">
                <textarea
                  aria-label="empty textarea"
                  placeholder="* 최소 10자 이상 작성해주세요. &#13;&#10;* 클래스와 무관한 내용이거나 불건전한 내용 및 광고성 게시물은 사전 통보 없이&#13;&#10; &nbsp;&nbsp;&nbsp;&nbsp; 삭제 처리될 수 있습니다.
                                    "
                  className="textarea-type1"
                  value={contentInput.state}
                  onChange={contentInput.handleChange}
                />
              </Grid>
            </Grid>
            <Grid container className="tr2 line2" alignItems="center">
              <Grid item xs={3} className="columnLeft">
                사진 첨부
              </Grid>
              <Grid item xs={9} className="columnRight">
                <div>
                  <Button
                    className="select-btn btn-color-type3 btn-bdr-type1 font-kr-regular"
                    variant="outlined"
                    onClick={() => fileSelectInput.current.click()}
                  >
                    사진 선택
                    <input
                      hidden
                      ref={fileSelectInput}
                      type="file"
                      accept="image/jpg,image/png"
                      onChange={onAddPhotoClick}
                    />
                  </Button>
                  <span className="inform-txt ">
                    ※ 3MB 미만의 파일 5개까지 첨부 가능 (파일확장자 : JPG, PNG)
                  </span>
                </div>
                {reviewImages.length > 0 && (
                  <div className="reviewImgListWrap">
                    <Swiper {...swiperParams} ref={setSwiper}>
                      {reviewImages.map((file) => (
                        <SwiperSlide key={file.id} className="reviewImgItem">
                          <img src={file.file} alt="img01" />
                          <img
                            src={closeWhite}
                            alt="close"
                            className="close_W"
                            onClick={() => onDeleteImageClick(file.id)}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default React.memo(ClassReviewCreate);
