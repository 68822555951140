import { useState, useCallback } from 'react';

function useModalWrapper() {
  const [visibleModal, setVisibleModal] = useState(false);
  const [params, setParams] = useState(undefined);

  const handleOpen = useCallback((params) => {
    setVisibleModal(true);
    if (params) {
      setParams(params);
    }
  }, []);

  const handleClose = useCallback((e) => {
    if (e) e.stopPropagation();
    setVisibleModal(false);
    setParams(undefined);
  }, []);

  return {
    visibleModal,
    handleOpen,
    handleClose,
    params,
  };
}

export default useModalWrapper;
