import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import ShareIcon from '@mui/icons-material/Share';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { useLoginContext } from 'common/context';
import { NavLink, Route, Link, useLocation } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SHARE_DOMAIN } from 'common/constants';
import SwiperCore, { Scrollbar, Navigation, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'assets/css/detail/detailClassInfo.css';
import 'assets/css/detail/eventDetail.css';
import ModalShare from '../modal/ModalShare';
import usePopAlert from 'components/popAlert/hooks/usePopAlert';
import useModalWrapper from 'components/modal/hooks/useModalWrapper';
import { DesktopAndTablet, AllMobile } from 'components/utils/MediaQuery';
import SnsUrlRegist from './SnsUrlRegist';
import InnerHTML from 'dangerously-set-html-content';

import {
    eventMo,
    eventPc,
    eventMo2,
    eventPc2,
    eventMo3,
    eventPc3,
} from 'assets/images/icon';

import {
    slide1,
    slide2,
    slide3,
    slide4,
    slide5,
    slide6,
    slide7,
    slide8,
    eventPc4,
    eventPc4_1,
    eventMo4,
    eventMo4_1,
} from 'assets/images/event';

import {
    Mo1,
    Mo3,
    Mo4,
    Mo5,
    Pc1,
    Pc3,
    Pc4,
    Pc5,
} from 'assets/images/event/livealUse';

import {
    POINT01,
    POINT02,
    POINT03,
    POINT04,
    POINT05,
    POINT06,
    MOPOINT01,
    MOPOINT02,
    MOPOINT03,
    MOPOINT04,
    MOPOINT05,
    MOPOINT06
} from 'assets/images/event/liveallPoint';

import {
    USE01,
    USE02,
    USE03,
    USETAB01,
    USETAB02,
    USETAB03,
    MO_USE01,
    MO_USE02,
    MO_USE03,
    MO_USETAB01,
    MO_USETAB02,
    MO_USETAB03
} from 'assets/images/event/liveallUse2';

import {
    ALLMONEY01,
    ALLMONEY02,
    ALLMONEY03,
    ALLMONEYTAB01,
    ALLMONEYTAB02,
    ALLMONEYTAB03,
    MO_ALLMONEY01,
    MO_ALLMONEY02,
    MO_ALLMONEY03,
    MO_ALLMONEYTAB01,
    MO_ALLMONEYTAB02,
    MO_ALLMONEYTAB03
} from 'assets/images/event/allMoney';

import {
    CELEB01,
    CELEB02,
    MO_CELEB01,
    MO_CELEB02
} from 'assets/images/event/celeb';

import {
    ALLIANCE01,
    ALLIANCE02,
    ALLIANCE03,
    ALLIANCE04,
    MO_ALLIANCE01,
    MO_ALLIANCE02,
    MO_ALLIANCE03,
    MO_ALLIANCE04
} from 'assets/images/event/alliance';

import { useNavigate } from 'react-router';
import moment from 'moment';

import {
    MO_HOBBY01,
    MO_HOBBY02,
    MO_HOBBY03,
    PC_HOBBY01,
    PC_HOBBY02,
    PC_HOBBY03
} from 'assets/images/event/hobbyClass';

import {
    PC_EXHIBIT_01,
    PC_EXHIBIT_02,
    PC_EXHIBIT_03,
    PC_EXHIBIT_04,
    PC_EXHIBIT_05,
    MO_EXHIBIT_01,
    MO_EXHIBIT_02,
    MO_EXHIBIT_03,
    MO_EXHIBIT_04,
    MO_EXHIBIT_05,
    EXHIBIT3_01,
    EXHIBIT3_02,
    EXHIBIT3_03,
    EXHIBIT3_04,
} from 'assets/images/event/exhibit/230410';

import {
    PC_WELCOME_00,
    PC_WELCOME_01,
    PC_WELCOME_02,
    PC_WELCOME_03,
    PC_WELCOME_04,
    PC_WELCOME_05,
    PC_WELCOME_06,
    PC_WELCOME_07,
    PC_WELCOME_08,
    MO_WELCOME_00,
    MO_WELCOME_01,
    MO_WELCOME_02,
    MO_WELCOME_03,
    MO_WELCOME_04,
    MO_WELCOME_05,
    MO_WELCOME_06,
    MO_WELCOME_07,
    MO_WELCOME_08
} from 'assets/images/event/welcome'

import {
    PC_FAMILY01,
    PC_FAMILY02,
    PC_FAMILY03,
    PC_FAMILY04,
    PC_FAMILY_TABCON1,
    MO_FAMILY01,
    MO_FAMILY02,
    MO_FAMILY03,
    MO_FAMILY04,
    MO_FAMILY_TABCON1,
} from 'assets/images/event/familyMonth';

import {
    PC_SCAMP01,
    PC_SCAMP01_obj1,
    PC_SCAMP01_obj2,
    PC_SCAMP01_obj3,
    PC_SCAMP01_obj4,
    PC_SCAMP01_obj5,
    PC_SCAMP01_obj6,
    PC_SCAMP01_lecturer,
    PC_SCAMP02_tit,
    PC_SCAMP02_box_tit,
    PC_SCAMP02_stamp_open,
    PC_SCAMP02_btn,
    PC_SCAMP03,
    PC_SCAMP04,
    PC_SCAMP04_1,
    PC_SCAMP04_3,
    PC_SCAMP04_4,
    PC_SCAMP04_obj1,
    PC_SCAMP04_obj2,
    PC_SCAMP04_obj3,
    PC_SCAMP04_obj4,
    PC_SCAMP04_obj5,
    PC_SCAMP05,
    SCAMP05_slide1_1,
    SCAMP05_slide1_2,
    SCAMP05_slide1_3,
    SCAMP05_slide1_4,
    SCAMP05_slide1_5,
    SCAMP05_slide2_1,
    SCAMP05_slide2_2,
    SCAMP05_slide2_3,
    SCAMP05_slide2_4,
    SCAMP05_slide2_5,
    SCAMP05_slide2_6,
    SCAMP05_slide2_7,
    SCAMP05_slide2_8,
    SCAMP05_slide3_1,
    SCAMP05_slide3_2,
    SCAMP05_slide3_3,
    SCAMP05_slide3_4,
    SCAMP05_slide3_5,
    SCAMP05_slide3_6,
    SCAMP05_slide3_7,
    PC_SCAMP06,
    PC_SCAMP06_title,
    PC_SCAMP06_lecturer,
    PC_SCAMP07,
    PC_SCAMP07_tabcon,
    MO_SCAMP01,
    MO_SCAMP01_lecturer,
    MO_SCAMP02_tit,
    MO_SCAMP03,
    MO_SCAMP04,
    MO_SCAMP04_1,
    MO_SCAMP04_3,
    MO_SCAMP04_4,
    MO_SCAMP05,
    MO_SCAMP06,
    MO_SCAMP07,
    MO_SCAMP07_tabcon,
} from 'assets/images/event/summerCamp';

import {
    PC_top_logo,
    PC_class01,
    PC_class02,
    PC_class03,
    PC_class04,
    PC_tabcon,
    MO_top_logo,
    MO_class01,
    MO_class02,
    MO_class03,
    MO_class04,
    MO_tabcon
} from 'assets/images/event/backToTheSchool';

import {
    PC_festival_01,
    PC_festival_02,
    PC_festival_03,
    PC_festival_04_00,
    PC_festival_04_01,
    PC_festival_05,
    PC_slide,
    MO_festival_01,
    MO_festival_02,
    MO_festival_03,
    MO_festival_04_00,
    MO_festival_04_01,
    MO_festival_05,
    MO_slide
} from 'assets/images/event/festival';

import {
    PC_JOIN_RENEWAL_01,
    PC_JOIN_RERENEWAL_01,
    PC_JOIN_RENEWAL_02,
    PC_JOIN_RERENEWAL_02,
    PC_JOIN_RENEWAL_03,
    PC_JOIN_RENEWAL_03_1,
    PC_JOIN_RENEWAL_03_2,
    PC_JOIN_RENEWAL_03_3,
    PC_JOIN_RENEWAL_04,
    PC_JOIN_RENEWAL_05,
    PC_JOIN_RENEWAL_05_1,
    PC_JOIN_RENEWAL_05_2,
    PC_JOIN_RENEWAL_05_3,
    MO_JOIN_RENEWAL_01,
    MO_JOIN_RERENEWAL_01,
    MO_JOIN_RENEWAL_02,
    MO_JOIN_RERENEWAL_02,
    MO_JOIN_RENEWAL_03,
    MO_JOIN_RENEWAL_03_1,
    MO_JOIN_RENEWAL_03_2,
    MO_JOIN_RENEWAL_03_3,
    MO_JOIN_RENEWAL_04,
    MO_JOIN_RENEWAL_05,
    MO_JOIN_RENEWAL_05_1,
    MO_JOIN_RENEWAL_05_2,
    MO_JOIN_RENEWAL_05_3,
} from 'assets/images/event/join_renewal';

import {
    PC_RECS_RENEWAL_01,
    PC_RECS_RENEWAL_02,
    PC_RECS_RENEWAL_03,
    PC_RECS_RENEWAL_03_00,
    PC_RECS_RENEWAL_03_01,
    MO_RECS_RENEWAL_01,
    MO_RECS_RENEWAL_02,
    MO_RECS_RENEWAL_03,
    MO_RECS_RENEWAL_03_00,
    MO_RECS_RENEWAL_03_01,
} from 'assets/images/event/recs_renewal';

import {
    PC_WINTERCAMP_00,
    PC_WINTERCAMP_01_00,
    PC_WINTERCAMP_01_01,
    PC_WINTERCAMP_02,
    PC_WINTERCAMP_02_00,
    PC_WINTERCAMP_02_01,
    PC_WINTERCAMP_03,
    PC_WINTERCAMP_03_00,
    PC_WINTERCAMP_03_01,
    PC_WINTERCAMP_04,
    PC_SLIDECARD_01_01,
    PC_SLIDECARD_01_02,
    PC_SLIDECARD_01_03,
    PC_SLIDECARD_01_04,
    PC_SLIDECARD_02_01,
    PC_SLIDECARD_02_02,
    PC_SLIDECARD_02_03,
    PC_SLIDECARD_03_01,
    PC_SLIDECARD_03_02,
    PC_SLIDECARD_03_03,
    PC_SLIDECARD_03_04,
    PC_SLIDECARD_03_05,
    PC_SLIDECARD_03_06,
    PC_SLIDECARD_04_01,
    PC_SLIDECARD_04_02,
    PC_SLIDECARD_04_03,
    PC_SLIDECARD_04_04,
    PC_WINTERCAMP_05,
    PC_WINTERCAMP_05_02,
    PC_WINTERCAMP_05_03,
    PC_WINTERCAMP_05_04,
    MO_WINTERCAMP_00,
    MO_WINTERCAMP_01_00,
    MO_WINTERCAMP_01_01,
    MO_WINTERCAMP_02,
    MO_WINTERCAMP_02_00,
    MO_WINTERCAMP_02_01,
    MO_WINTERCAMP_03,
    MO_WINTERCAMP_03_00,
    MO_WINTERCAMP_03_01,
    MO_WINTERCAMP_04,
    MO_SLIDECARD_01_01,
    MO_SLIDECARD_01_02,
    MO_SLIDECARD_01_03,
    MO_SLIDECARD_01_04,
    MO_SLIDECARD_02_01,
    MO_SLIDECARD_02_02,
    MO_SLIDECARD_02_03,
    MO_SLIDECARD_03_01,
    MO_SLIDECARD_03_02,
    MO_SLIDECARD_03_03,
    MO_SLIDECARD_03_04,
    MO_SLIDECARD_03_05,
    MO_SLIDECARD_03_06,
    MO_SLIDECARD_04_01,
    MO_SLIDECARD_04_02,
    MO_SLIDECARD_04_03,
    MO_SLIDECARD_04_04,
    MO_WINTERCAMP_05,
    MO_WINTERCAMP_05_02,
    MO_WINTERCAMP_05_03,
    MO_WINTERCAMP_05_04,
    PC_WINTER_01,
    PC_WINTER_02,
    MO_WINTER_01,
    MO_WINTER_02,
} from 'assets/images/event/winterEvent';


import {
    PC_DISCOUNT_01,
    PC_DISCOUNT_02,
    PC_DISCOUNT_03,
    PC_DISCOUNT_04,
    PC_DISCOUNT_05,
    PC_DISCOUNT_06,
    MO_DISCOUNT_01,
    MO_DISCOUNT_02,
    MO_DISCOUNT_03,
    MO_DISCOUNT_04,
    MO_DISCOUNT_05,
    MO_DISCOUNT_06
} from 'assets/images/event/discountClass';


import {ModalType1, ModalWrap} from "../modal";

// EventDetailContent.js
import { useApiContext } from 'common/context';
import { useCommonMutation, useLoginPopup, useInput, useCommonQuery } from 'common/hooks';

import ReadingBook from 'pages/index/ReadingBook';
import Banner from "../banner/Banner";
import useSearchResult from 'pages/index/hooks/useSearchResult';
import LoadingWrapper from 'components/idle/LoadingWrapper';
import Best from 'pages/index/Best';
import ClassCardMap from 'components/classCardItem/classCardMap';
import { random_coupon_event104 } from 'assets/images/coupon';
import useMainCoupon from 'pages/main/hooks/useMainCoupon';

function EventDetailContent({ data }) {
    if (!data) {
        return null;
    }
    const navigate = useNavigate();
    const { onDownloadCoupons } = useMainCoupon();
    const { showAlert, showConfirm } = usePopAlert();
    const { isLogin } = useLoginContext();
    const { event_title, event_seq } = data;
    const shareModalHandler = useModalWrapper();
    const { queries } = useApiContext();
    const { checkLogin } = useLoginPopup();
    const modalContentInput = useInput('',3000);
    const eventModalHandler = useModalWrapper();
    const [onStamp, setOnStamp] = useState(new Array(30).fill(false));
    const [onStamp2, setOnStamp2] = useState(new Array(14).fill(false)); // 백투더스쿨 스탬프
    const [getStamp, setGetStamp] = useState();
    const [ keyword, setKeyword ] = useState(""); 
    const [ selectKeyword, setSelectKeyword ] = useState(0);
    let keywordByClass;
    if (window.location.href.includes("/event/77") && keyword.length <= 0) {
         keywordByClass = useSearchResult("겨울방학");
    } else if (window.location.href.includes("/event/85") && keyword.length <= 0) {
        keywordByClass = useSearchResult("씽크빅클래스");
    } else if (window.location.href.includes("/event/97") && keyword.length <= 0) {
        keywordByClass = useSearchResult("3월 브랜드 기획전");
    } else if (window.location.href.includes("/event/96") && keyword.length <= 0) {
        keywordByClass = useSearchResult("공부발전소");
    } else if (window.location.href.includes("/event/95") && keyword.length <= 0) {
        keywordByClass = useSearchResult("주니어생글생글");
    } else if (window.location.href.includes("/event/94") && keyword.length <= 0) {
        keywordByClass = useSearchResult("매쓰몽플러스");
    } else {
        keywordByClass = useSearchResult(keyword);
    }
    const { classes , classesStatus } = keywordByClass;
    const classList = classes[0];
    const [stepData, setStepData] = useState([]);
    const [festivalApply, setFestivalApply] = useState(false);
    const [ allClass, setAllClass ] = useState();
    
    SwiperCore.use([Scrollbar, Navigation, Autoplay]); // swiper slide
    const [swiper, setSwiper] = React.useState();
    const prevRef = React.useRef();
    const nextRef = React.useRef();
    React.useEffect(() => {
        if (swiper) {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
        }
    }, [swiper]);


    const shareData = useMemo(() => {
        return {
            title: data?.event_title || '',
            description: '',
            imageUrl: data?.event_image_url || '',
        };
    }, [data]);

    const startDateText = useMemo(() => {
        return moment(data.period_start_dt).format('YYYY-MM-DD');
    }, [data.period_start_dt]);

    const endDateText = useMemo(() => {
        return moment(data.period_end_dt).format('YYYY-MM-DD');
    }, [data.period_end_dt]);

    const onRecommandClick = useCallback(() => {
        if(!isLogin) {
            checkLogin();
        } else {
            navigate('/mypage/privacy');
            return;
        }

        /* if(isLogin) {
            navigate('/mypage/privacy');
            return;
        } else {
            showConfirm('','추천인 코드는\n로그인 후 확인할 수 있습니다.',
                { confirmHandler: () => { navigate('/login'); },},
                { confirmText: '로그인하기'},
            );
            return true;
        } */
    },[isLogin, showConfirm]);

    const onJoinClick = useCallback(() => {
        if(!isLogin) {
            checkLogin();
        } else {
            showAlert('알림', '이미 가입된 회원입니다.');
            return;
        }
        /* if (isLogin) {
            showAlert('알림', '이미 가입된 회원입니다.');
            return;
        }
        navigate('/login'); */
    }, [isLogin]);

    const onLink3Click = useCallback(() => {
        window.open('https://forms.gle/9h8RNcf96Qixxqmp7', '_blank');
    }, []);

    // 2022-11-11 라이브올 사용법 토글버튼 추가 시작
    const [notiExpanded, setNotiExpanded] = useState(false);
    const [noti2Expanded, setNoti2Expanded] = useState(false);
    const [noti3Expanded, setNoti3Expanded] = useState(false);

    const onNotiExpandClick = useCallback(() => {
        setNotiExpanded((prevValue) => !prevValue);
    }, []);

    const onNoti2ExpandedClick = useCallback(() => {
        setNoti2Expanded((prevValue) => !prevValue);
    }, []);

    const onNoti3ExpandedClick = useCallback(() => {
        setNoti3Expanded((prevValue) => !prevValue);
    }, []);
    // 2022-11-11 라이브올 사용법 토글버튼 추가 끝

    const onPointClick = useCallback(() => {
        if (isLogin) {
            navigate('/mypage/point');
        } else {
            showAlert('알림', '라이브올 회원이 아니신가요?\n회원가입 및 로그인 후 참여해주세요.',
                {confirmHandler: () => navigate('/login'), cancelHandler: () => navigate('/login')},
            );
        }
    }, [isLogin]);

    const onReviewClick = useCallback(() => {
        if (isLogin) {
            navigate('/mypage/review');
        } else {
            showAlert('알림', '라이브올 회원이 아니신가요?\n회원가입 및 로그인 후 참여해주세요.',
                {confirmHandler: () => navigate('/login'), cancelHandler: () => navigate('/login')},
            );
        }
    }, [isLogin]);

    // 이벤트 참여 api
    const { request: requestEventSubmit } = useCommonMutation({
        query: queries.eventSubmit,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    showAlert('알림', '제출이 완료되었습니다.');
                }
                // else {
                //     console.log(data.result_message);
                //     showAlert('알림', "이미 참여했습니다.");
                // }
            },
            onError: (error) => {
                // console.log(error);
                showAlert('알림', '결과가 없습니다.');
            },
        },
        initEnabled: false,
    });

    /**
    * 쿠폰 복사 이벤트
    *
    * <Button className="" onClick={onAddCouponClick}>쿠폰복사</Button>
    *
    * button.link-btn.MuiButton-root 으로 기본 css 지정되어있습니다.
    * 추가로 width, height, top, left 지정 후 사용할 수 있습니다.
    *
    */
    const onAddCouponClick = useCallback(() => {
        showAlert('알림', '쿠폰이 복사되었습니다.');
    }, []);
    const scienceClickRef = useRef();
    const bookClickRef = useRef();

    const { request: requestDownloadClassCoupons } = useCommonMutation({
        query: queries.downloadCoupons,
        callbacks: {
          onSuccess: (data) => {
            if (data.result_code === '0000') {
              let couponsInfo = '';
              data.result_data.forEach(
                (item) =>
                  (couponsInfo += `\n${item.coupon_name}`),
              );
              showAlert('알림', `쿠폰이 다운로드 완료되었습니다.\n${couponsInfo}`);
            } else {
              console.log(data.result_message);
              showAlert('알림', "이미 다운로드된 쿠폰입니다.");
            }
          },
          onError: (error) => {
            // console.log(error);
            showAlert('알림', '쿠폰 다운로드에 실패했습니다.');
          },
        },
        initEnabled: false,
      });

    const { request: requestDownloadCoupon } = useCommonMutation({
        query: queries.downloadCoupon,
        callbacks: {
            onSuccess: (data) => {
            if (data.result_code === '0000') {
                let couponsInfo = '';
                data.result_data.forEach((item) => (couponsInfo += `\n${item.coupon_name}`));
                showAlert('알림', `쿠폰이 다운로드 완료되었습니다.\n${couponsInfo}`);
            } else {
                console.log(data.result_message);
                showAlert('알림', "이미 다운로드된 쿠폰입니다.");
            }
            },
            onError: (error) => {
            // console.log(error);
            showAlert('알림', '쿠폰 다운로드에 실패했습니다.');
            },
        },
        initEnabled: false,
    });

    /**
     * 특정 클래스 시퀀스로 다운로드 가능한 모든 쿠폰 다운받기
     */
    const onDownloadClassCouponClick = useCallback((classSeq) =>{

        if(classSeq){
            requestDownloadClassCoupons({
                class_seq: classSeq,
              })
        }

    },[requestDownloadClassCoupons])

    /**
     * 쿠폰 발행 정책 시퀀스로 하나의 쿠폰 다운로드 받기
     */
    const onDownloadCouponPolicyClick = useCallback((couponPolicySeq) =>{
        if(couponPolicySeq){
            requestDownloadCoupon(couponPolicySeq)
        }
    },[requestDownloadCoupon])
    
    const handleButtonClick = () => {
        if(!isLogin) {
            checkLogin()
        } else{
            modalContentInput.reset();
            eventModalHandler.handleOpen(); // 모달 열기 함수 호출
        }
    };

    const handleRegist = useCallback(async (e) => {

        if (modalContentInput.state.length === 0) {
            showAlert('알림', '내용을 입력해주세요.');
            return;
        }
        eventModalHandler.handleClose(e);

        const res = await requestEventSubmit({
            event_seq: event_seq,
            content: modalContentInput.state
        });
        // if (res.result_code === '0000') {
        //     console.log(res.result_message)
        // }

    }, [eventModalHandler,modalContentInput.state,requestEventSubmit ]);

    const leftCouponDown = useCallback(() => {
        if(!isLogin) {
            checkLogin();
        } else {
            onDownloadCouponPolicyClick(17);
        }
    },[isLogin,onDownloadCouponPolicyClick]);

    const rightCouponDown = useCallback(() => {
        if(!isLogin) {
            checkLogin();
        } else {
            onDownloadCouponPolicyClick(18);
        }
    },[isLogin,onDownloadCouponPolicyClick]);

    const clickCouponDown = useCallback((e) => {
        if(!isLogin) {
            checkLogin();
        } else {
            onDownloadCouponPolicyClick(e.target.value);
        }
    },[isLogin,onDownloadCouponPolicyClick]);

    /* 2023-06-30 출석체크 이벤트 */
    const { request: requestAttendance } = useCommonQuery({
        query: queries.getAttendance,
        params: { event_seq: data.event_seq },
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                   /* setOnStamp(onStamp.fill(true, 0, data.result_data.length));
                    setGetStamp(data.result_data.length);*/

                    setOnStamp2(onStamp.fill(true, 0, data.result_data.length));
                } else {
                    showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                console.log("error :: ", error);
            },
        },
    });
    const { request: requestCheckAttendance } = useCommonMutation({
        query: queries.attendance,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    showAlert('알림', `${data.result_message}`);
                    requestAttendance();
                }
                else {
                    console.log(data.result_message);
                    showAlert('알림', `${data.result_message}`);
                }
            },
            onError: (error) => {
                console.log(error);
                showAlert('알림', `${error.result_message}`);
            },
        },
        initEnabled: false,
    });

    const stampOpen = (e) => {
        const stampBox = document.querySelector('.stamp-box');
        stampBox.classList.add("open");
        e.target.style.display = "none";
    }

    const clickStamp = useCallback((e) => {
        if(!isLogin) {
            checkLogin();
        } else {
            requestCheckAttendance({ event_seq: e.target.value });
        }
    },[isLogin, requestCheckAttendance]);

    useEffect(() => {
        var resizeDelay = 300;
        var resizeTimer = null;

        fadeObserver();

        window.addEventListener('resize', function(){
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function(){
                fadeObserver();
            }, resizeDelay);
        });

        function fadeObserver(){
            const targets = document.querySelectorAll(".fade-element");
            const options = { root: null, threshold: 0.1, rootMargin: "-0px" };
            const observer = new IntersectionObserver(function (entries, observer) {
                entries.forEach((entry) => {
                    const container = entry.target;
                    if (entry.isIntersecting) {
                        container.classList.add("fade-in");
                    } else {
                        container.classList.remove("fade-in");
                    }
                });
            }, options);

            targets.forEach((target) => {
                observer.observe(target);
            });
        }
    }, []);

    const onStrawberryBeans = useCallback(() => {
        window.open('https://abr.ge/pbq24w', '_blank');
    }, []);


    const handleKeyword = useCallback((e, value) => {
        setKeyword(value);
        setSelectKeyword(e.target.value);
    },[keyword, selectKeyword]);

   
    /* 2023-09-07 가을이벤트 */
    const { request: requestFestivalData } = useCommonQuery({
        query: queries.Festival.festivalInfo,
        params: { event_seq: data.event_seq },
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    setStepData(data.result_data.step_result);
                    setFestivalApply(data.result_data.apply_yn);
                } else {
                    showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                console.log("error :: ", error);
            },
        },
    });

    const { request: requestCheckFavoriteClass } = useCommonMutation({ // 인기투표
        query: queries.Festival.favoriteClass,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    showAlert('알림', `${data.status}`);
                    requestFestivalData();
                }
                else {
                    showAlert('알림', `${data.result_message}`);
                }
            },
            onError: (error) => {
                console.log(error);
                showAlert('알림', `${error.result_message}`);
            },
        },
        initEnabled: false,
    });

    const { request: requestCheckFestivalClass } = useCommonMutation({ // 가을클래스 구매여부 
        query: queries.Festival.festivalClass,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    showAlert('알림', `${data.result_message}`);
                    requestFestivalData();
                }
                else {
                    console.log(data.result_message);
                    showAlert('알림', `${data.result_message}`);
                }
            },
            onError: (error) => {
                console.log(error);
                showAlert('알림', `${error.result_message}`);
            },
        },
        initEnabled: false,
    });

    const { request: requestJoinFestival } = useCommonMutation({ // 응모하기
        query: queries.Festival.festivalJoin,
        callbacks: {
            onSuccess: (data) => {
                if (data.result_code === '0000') {
                    showAlert('알림', `${data.result_message}`);
                    requestFestivalData();
                }
                else {
                    console.log(data.result_message);
                    showAlert('알림', `${data.result_message}`);
                }
            },
            onError: (error) => {
                console.log(error);
                showAlert('알림', `${error.result_message}`);
            },
        },
        initEnabled: false,
    });

    const clickToParticipate = useCallback((e) => {
        const clickType = e.target.value;
        if(clickType === "F1") {
            requestCheckFavoriteClass({ event_seq: data.event_seq });
        } else if(clickType === "C1" || clickType === "C2") {
            requestCheckFestivalClass({ event_seq: data.event_seq });
        } else {
            requestJoinFestival({ event_seq: data.event_seq });
        }
    },[isLogin]);

    const { request: requestEventJoin } = useCommonMutation({
        query: queries.renewal.entryEvent,
        callbacks: {
            onSuccess: (data) => {
                console.log("DATA :: ", data)
                if (data.result_code === '0000') {
                    showAlert('알림', data.result_message);
                } else {
                    showAlert('알림', data.result_message);
                }
            },
            onError: (error) => {
                console.log("error :: ", error);
            },
        },
    });

    const onJoinEvent = useCallback(() => {
        if(!isLogin) {
            checkLogin();
        } else {
            requestEventJoin({ event_seq: data.event_seq });
        }
    },[isLogin])

    const scrollRef = useRef();

    const [existBottomTab, setExistBottomTab] = useState(true)
    
    const getElementHeight = () => {
        const bottomTabHeight = document.querySelector('.tabBox')
        if(!bottomTabHeight) return

        if(bottomTabHeight.offsetTop <= window.scrollY + 900 ){
            setExistBottomTab(false)
        }else{
            setExistBottomTab(true)
        }
      };

    useEffect(()=>{
        if(scrollRef.current == undefined || !scrollRef) return
        
          window.addEventListener('scroll', getElementHeight);
      
          return () => {
            window.removeEventListener('scroll', getElementHeight);
          };
    },[window.scrollY])

    return (
        <div className="classInfo top" > {/* style={{ paddingTop: data.banner_info ? "1.5%" : "4.5%"}} 매거진 쪽과 동일하게 수정 하기 위해 주석 처리*/}
            {data.banner_info?.has_banner && <Banner banner={data.banner_info} />}
            <div className="classInfo_title" > {/* style={{ margin: data.banner_info ? "1.5% 0" : "0", paddingBottom: data.banner_info ? "0" : "30px"}} 매거진 쪽과 동일하게 수정 하기 위해 주석 처리 */}
                <div>
                    <h2>{data.event_title}</h2>
                    <h3>{data.period_start_dt && data.period_end_dt ? `기간 ${startDateText} ~ ${endDateText}` : ''}</h3> {/* <h3>기간 2022-09-13 ~ 2022-10-16</h3> */}
                </div>
                <div className="classInfo_share_icon">
                    <ShareIcon onClick={shareModalHandler.handleOpen} />
                </div>
                {shareModalHandler.visibleModal && (
                    <ModalShare
                        data={shareData}
                        handleClose={shareModalHandler.handleClose}
                    />
                )}
            </div>

            {/*이벤트 참여하기 팝업 틀 */}
            <ModalWrap
              className=" sns-url-regist"
              modalinfo="other-button"
              visible={eventModalHandler.visibleModal}
              handleOpen={eventModalHandler.handleOpen}
              handleClose={eventModalHandler.handleClose}
            >
                <ModalType1
                  title=""
                  buttonApply="제출하기"
                  handleApply={handleRegist}
                  contentInput={modalContentInput}
                >
                    <SnsUrlRegist modalContent={modalContentInput.state}/>
                </ModalType1>
            </ModalWrap>

            {data.content_type === 1 ? (
                <>
                    {event_seq === 1 && (
                        <div className="event-detail-img">
                            <AllMobile>
                                <img src={eventMo} alt="이벤트 모바일 상세" />
                            </AllMobile>
                            <DesktopAndTablet>
                                <img src={eventPc} alt="이벤트 피시 상세" />
                            </DesktopAndTablet>
                        {/* 2022-08-02 이벤트 상세 이미지 피시,모바일 분리 */}
                            <Button className="event-link link1" onClick={onJoinClick}>지금 바로 가입하러 가기</Button>
                            <Link to="/class" className="event-link link2 eventImg1">라이브올 수업 둘러보러 가기</Link>
                        </div>
                    )}
                    {/* 2022-08-05 라이브올 런칭 기념 회원가입 이벤트 */}
                    {event_seq === 2 && (
                        <div className="event-detail-img">
                            <AllMobile>
                                <img src={eventMo2} alt="이벤트 모바일 상세" />
                            </AllMobile>
                            <DesktopAndTablet>
                                <img src={eventPc2} alt="이벤트 피시 상세" />
                            </DesktopAndTablet>
                            <Button className="event-link link1 eventImg2" onClick={onJoinClick}>지금 바로 가입하러 가기</Button>
                            <Link to="/class" className="event-link link2">라이브올 수업 둘러보러 가기</Link>
                        </div>
                    )}
                    {/* 2022-09-29 라이브올 클래스를 부탁해 이벤트 */}
                    {event_seq === 6 && (
                        <div className="event-detail-img">
                            <p className="closed-noti-txt">* 본 이벤트의 추첨 당첨 대상자에게는 10/20(목) 개별 안내되었으며, 10/25(화) 참여한 회원 모두에게 1,000P (~11/23까지 사용가능) 일괄 지급되었습니다.{' '}</p>
                            <AllMobile>
                                <img src={eventMo3} alt="이벤트 모바일 상세" />
                            </AllMobile>
                            <DesktopAndTablet>
                                <img src={eventPc3} alt="이벤트 피시 상세" />
                            </DesktopAndTablet>
                            <Button className="event-link link1 eventImg3" onClick={onJoinClick}>지금 바로 가입하러 가기</Button>
                            <Link to="/class" className="event-link link2 eventImg3">라이브올 수업 둘러보러 가기</Link>
                            {/* 이벤트 참여하기 버튼 추가 되었습니다 */}
                            <Button className="event-link link3 eventImg3" onClick={onLink3Click}>이벤트 참여하기</Button>
                        </div>
                    )}
                    {/* 2022-10-14 초등생활 필수 강의 특별할인 이벤트 */}
                    {event_seq === 24 && (
                        <div className="event-detail-img">
                            <AllMobile>
                                <img src={eventMo4} alt="이벤트 모바일 상세" />
                            </AllMobile>
                            <DesktopAndTablet>
                                <img src={eventPc4} alt="이벤트 피씨 상세" />
                            </DesktopAndTablet>
                            <div className="event-slider">
                                <div className="event-slider-box">
                                    <div>
                                        <img src={slide1} />
                                    </div>
                                    <div>
                                        <img src={slide2} />
                                    </div>
                                    <div>
                                        <img src={slide3} />
                                    </div>
                                    <div>
                                        <img src={slide4} />
                                    </div>
                                    <div>
                                        <img src={slide5} />
                                    </div>
                                    <div>
                                        <img src={slide6} />
                                    </div>
                                    <div>
                                        <img src={slide7} />
                                    </div>
                                    <div>
                                        <img src={slide8} />
                                    </div>
                                    <div>
                                        <img src={slide1} />
                                    </div>
                                    <div>
                                        <img src={slide2} />
                                    </div>
                                    <div>
                                        <img src={slide3} />
                                    </div>
                                    <div>
                                        <img src={slide4} />
                                    </div>
                                    <div>
                                        <img src={slide5} />
                                    </div>
                                    <div>
                                        <img src={slide6} />
                                    </div>
                                    <div>
                                        <img src={slide7} />
                                    </div>
                                    <div>
                                        <img src={slide8} />
                                    </div>
                                </div>
                            </div>
                            <AllMobile>
                                <img src={eventMo4_1} alt="이벤트 모바일 상세" />
                            </AllMobile>
                            <DesktopAndTablet>
                                <img src={eventPc4_1} alt="이벤트 피시 상세" />
                            </DesktopAndTablet>
                            <Button className="event-link link1 eventImg4" onClick={onJoinClick}>지금 바로 가입하러 가기</Button>
                            <Link to="/class" className="event-link link2 eventImg4">원데이 클래스 수업가기</Link>
                        </div>
                    )}
                    {/* 2022-11-11 라이브올 사용법 이벤트 */}
                    {event_seq === 17 && (
                        <div className="event-detail-img pb-20">
                            <AllMobile>
                                <div className="LinkInWrap p_r">
                                    <img src={Mo1} alt="이벤트 모바일 상세" />
                                    <Button className="link-btn eventJoinBtn" onClick={onJoinClick}>회원가입</Button>
                                    {/*
                                            [웰컴 포인트 확인하기 버튼]
                                            회원일 경우 - 마이페이지 > 포인트페이지 이동
                                            비회원 일 경우 - 로그인 후 참여해주세요 알럿 노출
                                        */}
                                    <Button className="link-btn eventPointBtn" onClick={onPointClick}>웰컴 포인트 확인하기</Button>
                                </div>
                                <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={Mo3} alt="이벤트 모바일 상세"/>
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={Mo4} alt="이벤트 모바일 상세" />
                                    {/*
                                            [후기 남기러가기 버튼]
                                            회원일 경우 - 마이페이지 > 리뷰페이지 이동
                                            비회원 일 경우 - 로그인 후 참여해주세요 알럿 노출
                                        */}
                                    <Button className="link-btn eventReviewBtn" onClick={onReviewClick}>후기 남기러가기</Button>
                                </div>
                                <div className={noti2Expanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNoti2ExpandedClick} dataid={0}></div>
                                <div className={noti2Expanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={Mo5} alt="이벤트 모바일 상세"/>
                                </div>
                            </AllMobile>
                            <DesktopAndTablet>
                                <div className="LinkInWrap p_r">
                                    <img src={Pc1} alt="이벤트 피시 상세" />
                                    <Button className="link-btn eventJoinBtn" onClick={onJoinClick}>회원가입</Button>
                                    <Button className="link-btn eventPointBtn" onClick={onPointClick}>웰컴 포인트 확인하기</Button>
                                </div>
                                <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={Pc3} alt="이벤트 피시 상세"/>
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={Pc4} alt="이벤트 피시 상세" />
                                    <Button className="link-btn eventReviewBtn" onClick={onReviewClick}>후기 남기러가기</Button>
                                </div>
                                <div className={noti2Expanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNoti2ExpandedClick} dataid={0}></div>
                                <div className={noti2Expanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={Pc5} alt="이벤트 피시 상세"/>
                                </div>
                            </DesktopAndTablet>
                        </div>
                    )}
                    {/* 2023-01-05 겨울방학 이벤트 */}
                    {event_seq === 21 && (
                        <div className="event-detail-img liveallPoint">
                            <AllMobile>
                                <div className="LinkInWrap p_r">
                                    <img src={MOPOINT01} alt="이벤트 모바일 상세" />
                                    <Button className="link-btn eventJoinBtn" onClick={onJoinClick}>회원가입</Button>
                                </div>
                                <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={MOPOINT02} alt="이벤트 모바일 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={MOPOINT03} alt="이벤트 모바일 상세" />
                                    <Link to="/mypage/privacy" className="eventPointBtn">내 추천인 코드 확인하기</Link>
                                </div>
                                <div className={noti2Expanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNoti2ExpandedClick} dataid={0}></div>
                                <div className={noti2Expanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={MOPOINT04} alt="이벤트 모바일 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={MOPOINT05} alt="이벤트 모바일 상세" />
                                    <Link to="/class" className="link-btn eventApplyBtn">클래스 신청하기</Link>
                                </div>
                                <div className={noti3Expanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNoti3ExpandedClick} dataid={0}></div>
                                <div className={noti3Expanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={MOPOINT06} alt="이벤트 모바일 상세" />
                                </div>
                            </AllMobile>
                            <DesktopAndTablet>
                                <div className="LinkInWrap p_r">
                                    <img src={POINT01} alt="이벤트 피시 상세" />
                                    <Button className="link-btn eventJoinBtn" onClick={onJoinClick}>회원가입</Button>
                                </div>
                                <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={POINT02} alt="이벤트 피시 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={POINT03} alt="이벤트 피시 상세" />
                                    <Link to="/mypage/privacy" className="eventPointBtn">내 추천인 코드 확인하기</Link>
                                </div>
                                <div className={noti2Expanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNoti2ExpandedClick} dataid={0}></div>
                                <div className={noti2Expanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={POINT04} alt="이벤트 피시 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={POINT05} alt="이벤트 피시 상세" />
                                    <Link to="/class" className="link-btn eventApplyBtn">클래스 신청하기</Link>
                                </div>
                                <div className={noti3Expanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNoti3ExpandedClick} dataid={0}></div>
                                <div className={noti3Expanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={POINT06} alt="이벤트 피시 상세" />
                                </div>
                            </DesktopAndTablet>
                        </div>
                    )}
                    {/* 2023-01-25 라이브올 포인트 적립백서 이벤트 */}
                    {/* 2023-03-07 라이브올 포인트 -> 라이브올 올머니로 변경 */}
                    {event_seq === 22 && (
                        <div className="event-detail-img liveallUse2">
                            <AllMobile>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_USE01} alt="이벤트 모바일 상세" />
                                    <Button className="link-btn join" onClick={onJoinClick}>라이브올 가입하기</Button>
                                </div>
                                <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={MO_USETAB01} alt="이벤트 모바일 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_USE02} alt="이벤트 모바일 상세" />
                                </div>
                                <div className={noti2Expanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNoti2ExpandedClick} dataid={0}></div>
                                <div className={noti2Expanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={MO_USETAB02} alt="이벤트 모바일 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_USE03} alt="이벤트 모바일 상세" />
                                    <Button className="recommend-btn" onClick={onRecommandClick}>내 추천인 코드 확인하기</Button>
                                </div>
                                <div className={noti3Expanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNoti3ExpandedClick} dataid={0}></div>
                                <div className={noti3Expanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={MO_USETAB03} alt="이벤트 모바일 상세" />
                                </div>
                            </AllMobile>
                            <DesktopAndTablet>
                                <div className="LinkInWrap p_r">
                                    <img src={USE01} alt="이벤트 피시 상세" />
                                    <Button className="link-btn join" onClick={onJoinClick}>라이브올 가입하기</Button>
                                </div>
                                <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={USETAB01} alt="이벤트 피시 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={USE02} alt="이벤트 피시 상세" />
                                </div>
                                <div className={noti2Expanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNoti2ExpandedClick} dataid={0}></div>
                                <div className={noti2Expanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={USETAB02} alt="이벤트 피시 상세"  />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={USE03} alt="이벤트 피시 상세" />
                                    <Button className="recommend-btn" onClick={onRecommandClick}>내 추천인 코드 확인하기</Button>
                                {/* <Link to="/mypage/privacy" className="recommend-btn">
                                    내 추천인 코드 확인하기
                                </Link> */}
                                </div>
                                <div className={noti3Expanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNoti3ExpandedClick} dataid={0}></div>
                                <div className={noti3Expanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={USETAB03} alt="이벤트 피시 상세" />
                                </div>
                            </DesktopAndTablet>
                        </div>
                    )}
                    {/* 2023-02-08 셀럽 이벤트 상세 추가 */}
                    {event_seq === 23 && (
                        <div className="event-detail-img celeb">
                            <AllMobile>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_CELEB01} alt="이벤트 피시 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_CELEB02} alt="이벤트 피시 상세" />
                                    <Button href="/intro" className="link-btn intro">라이브올이란?</Button>
                                    <Button href="https://docs.google.com/forms/d/e/1FAIpQLSeoMij_QIN29YjJqOCAUg9RPsquSwLAriZS4Ln8UObH4lPS2A/viewform"  className="link-btn regist">이벤트 신청하기</Button>
                                </div>
                            </AllMobile>
                            <DesktopAndTablet>
                                <div className="LinkInWrap p_r">
                                    <img src={CELEB01} alt="이벤트 피시 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={CELEB02} alt="이벤트 피시 상세" />
                                    <Button href="/intro" className="link-btn intro">라이브올이란?</Button>
                                    <Button href="https://docs.google.com/forms/d/e/1FAIpQLSeoMij_QIN29YjJqOCAUg9RPsquSwLAriZS4Ln8UObH4lPS2A/viewform"  className="link-btn regist">이벤트 신청하기</Button>
                                </div>
                            </DesktopAndTablet>
                        </div>
                    )}
                    {/* 2023-03-08 라이브올x웅진씽크빅 특별 제휴 프로모션 */}
                    {event_seq === 26 && (
                        <div className="event-detail-img alliance">
                            <AllMobile>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_ALLIANCE01} alt="이벤트 모바일 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_ALLIANCE02} alt="이벤트 모바일 상세" />
                                    {/* 2023-03-09 URL 변경 */}
                                    <Button href="/search/result?keyword=%EC%B7%A8%ED%96%A5%EB%8F%85%EC%84%9C%ED%81%B4%EB%9F%BD" className="link-btn class1">취향독서클럽</Button>
                                    <Button href="/search/result?keyword=%EA%B5%90%EA%B3%BC%EB%8F%85%EC%84%9C%ED%81%B4%EB%9F%BD" className="link-btn class2">교과독서클럽</Button>
                                    <Button href="/search/result?keyword=%EC%8B%A0%EA%B7%9C" className="link-btn class3">신규 클래스</Button>
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_ALLIANCE03} alt="이벤트 모바일 상세" />
                                    <Button className="link-btn join" onClick={onJoinClick}>라이브올 회원가입</Button>
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_ALLIANCE04} alt="이벤트 모바일 상세" />
                                    <Button href="/class" className="link-btn view">라이브올 수업 둘러보기</Button>
                                </div>
                            </AllMobile>
                            <DesktopAndTablet>
                                <div className="LinkInWrap p_r">
                                    <img src={ALLIANCE01} alt="이벤트 피시 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={ALLIANCE02} alt="이벤트 피시 상세" />
                                    {/* 2023-03-09 URL 변경 */}
                                    <Button href="/search/result?keyword=%EC%B7%A8%ED%96%A5%EB%8F%85%EC%84%9C%ED%81%B4%EB%9F%BD" className="link-btn class1">취향독서클럽</Button>
                                    <Button href="/search/result?keyword=%EA%B5%90%EA%B3%BC%EB%8F%85%EC%84%9C%ED%81%B4%EB%9F%BD" className="link-btn class2">교과독서클럽</Button>
                                    <Button href="/search/result?keyword=%EC%8B%A0%EA%B7%9C" className="link-btn class3">신규 클래스</Button>
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={ALLIANCE03} alt="이벤트 피시 상세" />
                                    <Button className="link-btn join" onClick={onJoinClick}>라이브올 회원가입</Button>
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={ALLIANCE04} alt="이벤트 피시 상세" />
                                    <Button href="/class" className="link-btn view">라이브올 수업 둘러보기</Button>
                                </div>
                            </DesktopAndTablet>
                        </div>
                    )}
                    {/* 2023-03-07 라이브올 포인트 -> 라이브올 올머니로 변경 */}
                    {event_seq === 28 && (
                        <div className="event-detail-img liveallUse2">
                            <AllMobile>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_ALLMONEY01} alt="이벤트 모바일 상세" />
                                    <Button className="link-btn join" onClick={onJoinClick}>라이브올 가입하기</Button>
                                </div>
                                <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={MO_ALLMONEYTAB01} alt="이벤트 모바일 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_ALLMONEY02} alt="이벤트 모바일 상세" />
                                </div>
                                <div className={noti2Expanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNoti2ExpandedClick} dataid={0}></div>
                                <div className={noti2Expanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={MO_ALLMONEYTAB02} alt="이벤트 모바일 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_ALLMONEY03} alt="이벤트 모바일 상세" />
                                    <Button className="recommend-btn" onClick={onRecommandClick}>내 추천인 코드 확인하기</Button>
                                </div>
                                <div>
                                </div>
                                <div className={noti3Expanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNoti3ExpandedClick} dataid={0}></div>
                                <div className={noti3Expanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={MO_ALLMONEYTAB03} alt="이벤트 모바일 상세" />
                                </div>
                            </AllMobile>
                            <DesktopAndTablet>
                                <div className="LinkInWrap p_r">
                                    <img src={ALLMONEY01} alt="이벤트 피시 상세" />
                                    <Button className="link-btn join" onClick={onJoinClick}>라이브올 가입하기</Button>
                                </div>
                                <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={ALLMONEYTAB01} alt="이벤트 피시 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={ALLMONEY02} alt="이벤트 피시 상세" />
                                </div>
                                <div className={noti2Expanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNoti2ExpandedClick} dataid={0}></div>
                                <div className={noti2Expanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={ALLMONEYTAB02} alt="이벤트 피시 상세"  />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={ALLMONEY03} alt="이벤트 피시 상세" />
                                    <Button className="recommend-btn" onClick={onRecommandClick}>내 추천인 코드 확인하기</Button>


                                {/* <Link to="/mypage/privacy" className="recommend-btn">
                                    내 추천인 코드 확인하기
                                </Link> */}
                                </div>
                                <div className={noti3Expanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNoti3ExpandedClick} dataid={0}></div>
                                <div className={noti3Expanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={ALLMONEYTAB03} alt="이벤트 피시 상세" />
                                </div>
                            </DesktopAndTablet>
                        </div>
                    )}
                    {/* 2023-03-30 4월 취미클래스 기획전 */}
                    {event_seq === 29 && (
                        <div className="event-detail-img hobbyClass">
                            <AllMobile>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_HOBBY01} alt="이벤트 모바일 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_HOBBY02} alt="이벤트 모바일 상세" />
                                    <Button href="/class/665" className="link-btn card1"></Button>
                                    <Button href="/class/666" className="link-btn card2"></Button>
                                    <Button href="/class/638" className="link-btn card3"></Button>
                                    <Button href="/class/641" className="link-btn card4"></Button>
                                    <Button href="/class/708" className="link-btn card5"></Button>
                                    <Button href="/class/709" className="link-btn card6"></Button>
                                    <Button href="/class/470" className="link-btn card7"></Button>
                                    <Button href="/search/result?keyword=%EC%B7%A8%EB%AF%B8" className="link-btn toClass"></Button>
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_HOBBY03} alt="이벤트 모바일 상세" />
                                </div>
                            </AllMobile>
                            <DesktopAndTablet>
                                <div className="LinkInWrap p_r">
                                    <img src={PC_HOBBY01} alt="이벤트 피씨 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={PC_HOBBY02} alt="이벤트 피씨 상세" />
                                    <Button href="/class/665" className="link-btn card1"></Button>
                                    <Button href="/class/666" className="link-btn card2"></Button>
                                    <Button href="/class/638" className="link-btn card3"></Button>
                                    <Button href="/class/641" className="link-btn card4"></Button>
                                    <Button href="/class/708" className="link-btn card5"></Button>
                                    <Button href="/class/709" className="link-btn card6"></Button>
                                    <Button href="/class/470" className="link-btn card7"></Button>
                                    <Button href="/search/result?keyword=%EC%B7%A8%EB%AF%B8" className="link-btn toClass"></Button>
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={PC_HOBBY03} alt="이벤트 피씨 상세" />
                                </div>
                            </DesktopAndTablet>
                        </div>
                    )}
                    {/* 2023-04-10 기획전 과학의 날, 책의 날 */}
                    {event_seq === 31 && (
                        <div className="event-detail-img exhibitScience">
                            <AllMobile>
                            <div className="LinkInWrap p_r">
                                    <img src={MO_EXHIBIT_01} alt="이벤트 모바일 상세" />
                                    <Button className="goToScience" onClick={()=>{scienceClickRef.current.scrollIntoView({behavior: "smooth"})}} />
                                    <Button className="goToReading" onClick={()=>{bookClickRef.current.scrollIntoView({behavior: "smooth"})}} />
                                </div>
                                <div className="LinkInWrap p_r" ref={scienceClickRef}>
                                    <img src={MO_EXHIBIT_02} alt="이벤트 모바일 상세" />
                                    <Button href="/search/result?keyword=%EB%AA%AC%EC%8A%A4%ED%84%B0%EB%B0%95%EC%8A%A4" className="link-btn class0"></Button>
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_EXHIBIT_03} alt="이벤트 모바일 상세" />
                                    <Button href="/search/result?keyword=%EA%B3%BC%ED%95%99%EB%A7%88%EC%88%A0" className="link-btn"></Button>
                                    <Button href="/search/result?keyword=VR" className="link-btn"></Button>
                                </div>
                                <div className="LinkInWrap p_r" ref={bookClickRef}>
                                    <img src={MO_EXHIBIT_04} alt="이벤트 모바일 상세" />
                                    <Button href="/search/result?keyword=%EC%B7%A8%ED%96%A5%EB%8F%85%EC%84%9C%ED%81%B4%EB%9F%BD" className="link-btn class3"></Button>
                                    <Button href="/search/result?keyword=%EA%B5%90%EA%B3%BC%EB%8F%85%EC%84%9C%ED%81%B4%EB%9F%BD" className="link-btn class4"></Button>
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_EXHIBIT_05} alt="이벤트 모바일 상세" />
                                    <Button href="/class/495" className="link-btn class5"></Button>
                                    <Button href="/class/501" className="link-btn class6"></Button>
                                    <Button href="/search/result?keyword=4%EC%9B%94%EC%9D%B4%EB%B2%A4%ED%8A%B8" className="link-btn toClass"></Button>
                                </div>
                            </AllMobile>
                            <DesktopAndTablet>
                                <div className="LinkInWrap p_r">
                                    <img src={PC_EXHIBIT_01} alt="이벤트 피씨 상세" />
                                    <Button className="goToScience" onClick={()=>{scienceClickRef.current.scrollIntoView({behavior: "smooth"})}} />
                                    <Button className="goToReading" onClick={()=>{bookClickRef.current.scrollIntoView({behavior: "smooth"})}} />
                                </div>
                                <div className="LinkInWrap p_r" ref={scienceClickRef}>
                                    <img src={PC_EXHIBIT_02} alt="이벤트 피씨 상세" />
                                    <Button href="/search/result?keyword=%EB%AA%AC%EC%8A%A4%ED%84%B0%EB%B0%95%EC%8A%A4" className="link-btn class0"></Button>
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={PC_EXHIBIT_03} alt="이벤트 피씨 상세" />
                                    <Button href="/search/result?keyword=%EA%B3%BC%ED%95%99%EB%A7%88%EC%88%A0" className="link-btn class1"></Button>
                                    <Button href="/search/result?keyword=VR" className="link-btn class2"></Button>
                                </div>
                                <div className="LinkInWrap p_r" ref={bookClickRef}>
                                    <img src={PC_EXHIBIT_04} alt="이벤트 피씨 상세" />
                                    <Button href="/search/result?keyword=%EC%B7%A8%ED%96%A5%EB%8F%85%EC%84%9C%ED%81%B4%EB%9F%BD" className="link-btn class3"></Button>
                                    <Button href="/search/result?keyword=%EA%B5%90%EA%B3%BC%EB%8F%85%EC%84%9C%ED%81%B4%EB%9F%BD" className="link-btn class4"></Button>
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={PC_EXHIBIT_05} alt="이벤트 피씨 상세" />
                                    <Button href="/class/495" className="link-btn class5"></Button>
                                    <Button href="/class/501" className="link-btn class6"></Button>
                                    <Button href="/search/result?keyword=4%EC%9B%94%EC%9D%B4%EB%B2%A4%ED%8A%B8" className="link-btn toClass"></Button>
                                </div>
                            </DesktopAndTablet>
                        </div>
                    )}
                    {/* 2023-05-09 라이브올 신규회원 웰컴팩 */}
                    {event_seq === 34 && (
                        <div className="event-detail-img welcomeLiveAll">
                            <AllMobile>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_WELCOME_01} alt="이벤트 모바일 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_WELCOME_02} alt="이벤트 모바일 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_WELCOME_03} alt="이벤트 모바일 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_WELCOME_00} alt="이벤트 모바일 상세" />
                                    <Button className="recommend-btn btnCoupon" onClick={onStrawberryBeans}>쿠폰등록하기</Button>
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_WELCOME_04} alt="이벤트 모바일 상세" />
                                    <Button className="recommend-btn" onClick={onRecommandClick}>내 추천인 코드 확인하기</Button>
                                </div>
                                <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={MO_WELCOME_05} alt="이벤트 모바일 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_WELCOME_06} alt="이벤트 모바일 상세" />
                                    <Button className="link-btn join" onClick={onJoinClick}>라이브올 가입하기</Button>
                                </div>
                                <div className={noti2Expanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNoti2ExpandedClick} dataid={0}></div>
                                <div className={noti2Expanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={MO_WELCOME_07} alt="이벤트 모바일 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_WELCOME_08} alt="이벤트 모바일 상세" />
                                </div>
                            </AllMobile>
                            <DesktopAndTablet>
                                <div className="LinkInWrap p_r">
                                    <img src={PC_WELCOME_01} alt="이벤트 피시 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={PC_WELCOME_02} alt="이벤트 피시 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={PC_WELCOME_03} alt="이벤트 피시 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={PC_WELCOME_00} alt="이벤트 피시 상세" />
                                    <Button className="recommend-btn btnCoupon" onClick={onStrawberryBeans}>쿠폰등록하기</Button>
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={PC_WELCOME_04} alt="이벤트 피시 상세" />
                                    <Button className="recommend-btn" onClick={onRecommandClick}>내 추천인 코드 확인하기</Button>
                                </div>
                                <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={PC_WELCOME_05} alt="이벤트 피시 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={PC_WELCOME_06} alt="이벤트 피시 상세" />
                                    <Button className="link-btn join" onClick={onJoinClick}>라이브올 가입하기</Button>
                                </div>
                                <div className={noti2Expanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNoti2ExpandedClick} dataid={0}></div>
                                <div className={noti2Expanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={PC_WELCOME_07} alt="이벤트 피시 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={PC_WELCOME_08} alt="이벤트 피시 상세" />
                                </div>
                            </DesktopAndTablet>
                        </div>
                    )}
                    {/* 23-04-26 : 김갑석 - 쿠폰 다운로드 예제 */}
                    {/* 클래스 시퀀스로 다운로드 받을수 있는 모든 쿠폰 다운받기 */}
                    {/* <Button className="recommend-btn" onClick={()=>{onDownloadClassCouponClick(459)}}>클래스 쿠폰 다운로드</Button>

                                    {/* 클래스 정책 시퀀스로 쿠폰 다운받기 */}
                    {/* <Button className="recommend-btn" onClick={()=>{onDownloadCouponPolicyClick(71)}}>쿠폰 정책 쿠폰 다운로드</Button> */}

                    {/* 2023-05-11 가정의 달 */}
                    {event_seq === 32 && (
                        <div className="event-detail-img familyMonth">
                            <AllMobile>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_FAMILY01} alt="이벤트 모바일 상세" />
                                </div>
                                <div className="LinkInWrap p_r" >
                                    <img src={MO_FAMILY02} alt="이벤트 모바일 상세" />
                                    <Button className="event-tag-btn e1" href="#event1">EVENT 1</Button>
                                    <Button className="event-tag-btn e2" href="#event2">EVENT 2</Button>
                                </div>
                                <div className="LinkInWrap p_r">
                                    <div className="hashtag e1" id="event1"></div>
                                    <img src={MO_FAMILY03} alt="이벤트 모바일 상세" />
                                    <Button
                                      className="coupon-btn per30"
                                      onClick={leftCouponDown}
                                    >
                                        쿠폰 다운로드
                                    </Button>
                                    <Button
                                      className="coupon-btn per20"
                                      onClick={rightCouponDown}
                                    >
                                        쿠폰 다운로드
                                    </Button>
                                    <Button className="class-btn" href="/class">클래스 보러가기</Button>
                                </div>
                                <div className="LinkInWrap p_r">
                                    <div className="hashtag e2" id="event2"></div>
                                    <img src={MO_FAMILY04} alt="이벤트 모바일 상세" />

                                    <Button className="event-join-btn" onClick={handleButtonClick}>이벤트 참여하기</Button>
                                </div>
                                <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={MO_FAMILY_TABCON1} alt="이벤트 모바일 상세" />
                                </div>
                            </AllMobile>
                            <DesktopAndTablet>
                                <div className="LinkInWrap p_r">
                                    <img src={PC_FAMILY01} alt="이벤트 피시 상세" />
                                </div>
                                <div className="LinkInWrap p_r" >
                                    <img src={PC_FAMILY02} alt="이벤트 피시 상세" />
                                    <Button className="event-tag-btn e1" href="#event1">EVENT 1</Button>
                                    <Button className="event-tag-btn e2" href="#event2">EVENT 2</Button>
                                </div>
                                <div className="LinkInWrap p_r">
                                    <div className="hashtag e1" id="event1"></div>
                                    <img src={PC_FAMILY03} alt="이벤트 피시 상세" />
                                    <Button
                                      className="coupon-btn per30"
                                      onClick={leftCouponDown}
                                    >
                                        쿠폰 다운로드
                                    </Button>
                                    <Button
                                      className="coupon-btn per20"
                                      onClick={rightCouponDown}
                                    >
                                        쿠폰 다운로드
                                    </Button>
                                    <Button className="class-btn" href="/class">클래스 보러가기</Button>
                                </div>
                                <div className="LinkInWrap p_r">
                                    <div className="hashtag e2" id="event2"></div>
                                    <img src={PC_FAMILY04} alt="이벤트 피시 상세" />
                                    <Button className="event-join-btn" onClick={handleButtonClick}>이벤트 참여하기</Button>
                                </div>
                                <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={PC_FAMILY_TABCON1} alt="이벤트 피시 상세" />
                                </div>
                            </DesktopAndTablet>
                        </div>
                    )}
                    {event_seq === 39 && (
                        <ReadingBook />
                    )}
                    {event_seq === 42 && (
                      <div className="event-detail-img summerCamp">
                          <DesktopAndTablet>
                              <div className="LinkInWrap p_r scamp01">
                                  <img src={PC_SCAMP01} alt="이벤트 피시 상세" />
                                  <div className="bubble-box">
                                      <img src={PC_SCAMP01_obj1} className="bubble1" alt="" />
                                      <img src={PC_SCAMP01_obj2} className="bubble2" alt="" />
                                      <img src={PC_SCAMP01_obj3} className="bubble3" alt="" />
                                      <img src={PC_SCAMP01_obj4} className="bubble4" alt="" />
                                      <img src={PC_SCAMP01_obj5} className="bubble5" alt="" />
                                      <img src={PC_SCAMP01_obj6} className="bubble6" alt="" />
                                  </div>
                                  <img src={PC_SCAMP01_lecturer} className="lecturer" alt="강사 3인" />
                              </div>
                              <div className="LinkInWrap p_r scamp02">
                                  <img src={PC_SCAMP02_tit} className="tit" alt="summer camp event 01 - 30일 출석 이벤트" />
                                  <div className="balloon">
                                      <div className="inner">
                                          <img src={PC_SCAMP02_box_tit} className="box-tit" alt="출석하기를 누르면 매일매일 올머니 500올 증정!" />
                                          <div className={`stamp-box ${getStamp >= 12 ? "open" : ""}`}>
                                              {onStamp.map((item, idx) => (
                                                <span key={`stamp ${idx}`} className={`stamp ${item === true ? "on" : ""}`}></span>
                                              ))}
                                          </div>
                                          <Button className="stamp-open-btn" style={{ display: getStamp >= 12 ? "none" : "" }} onClick={stampOpen}>
                                              <img src={PC_SCAMP02_stamp_open} alt="펼쳐보기" />
                                          </Button>
                                      </div>
                                  </div>
                                  <Button className="btn" value={event_seq} onClick={clickStamp}>출석체크</Button>
                              </div>
                              <div className="LinkInWrap p_r scamp03">
                                  <img src={PC_SCAMP03} alt="이벤트 피시 상세" />
                                  <Button className="btn b1" href="#program1">프로그램 01 - 라이브올 여름특강 코스</Button>
                                  <Button className="btn b2" href="#program2">프로그램 02 - 무료! 금쪽같은 라이브</Button>
                              </div>
                              <div className="LinkInWrap p_r scamp04">
                                  <div className="hashtag" id="program1"></div>
                                  <img src={PC_SCAMP04_4} alt="이벤트 피시 상세" />
                                  <div className="bubble-box">
                                      <img src={PC_SCAMP04_obj1} className="bubble1" alt="" />
                                      <img src={PC_SCAMP04_obj2} className="bubble2" alt="" />
                                      <img src={PC_SCAMP04_obj3} className="bubble3" alt="" />
                                      <img src={PC_SCAMP04_obj4} className="bubble4" alt="" />
                                      <img src={PC_SCAMP04_obj5} className="bubble5" alt="" />
                                  </div>
                                  <Button className="btn b1" href="/event/44">여름특강 코스 보러가기</Button>
                                  <Button className="btn c1" value={118} onClick={(e) => clickCouponDown(e)} style={{ pointerEvents: "none" }}>1차 쿠폰 다운받기 - 1</Button>
                                  <Button className="btn c2" value={119} onClick={(e) => clickCouponDown(e)} style={{ pointerEvents: "none" }}>1차 쿠폰 다운받기 - 2</Button>
                                  <Button className="btn c3" value={128} onClick={(e) => clickCouponDown(e)} style={{ pointerEvents: "none" }}>2차 쿠폰 다운받기 - 1</Button>
                                  <Button className="btn c4" value={127} onClick={(e) => clickCouponDown(e)} style={{ pointerEvents: "none" }}>2차 쿠폰 다운받기 - 2</Button>
                              </div>
                              <div className="LinkInWrap p_r scamp05">
                                  <img src={PC_SCAMP05} alt="이벤트 피시 상세" />
                                  <a className="camp-slider slider1" href="/search/result?keyword=자격증">
                                      <div className="box">
                                          <img src={SCAMP05_slide1_1} alt="COS 3급 초저반"/>
                                          <img src={SCAMP05_slide1_2} alt="COS 3급 초고반"/>
                                          <img src={SCAMP05_slide1_3} alt="한자 8급 마스터 초저반"/>
                                          <img src={SCAMP05_slide1_4} alt="한자 8급 마스터 초고반"/>
                                          <img src={SCAMP05_slide1_5} alt="마인드맵으로 배우는 한국사"/>
                                          <img src={SCAMP05_slide1_1} alt="COS 3급 초저반"/>
                                          <img src={SCAMP05_slide1_2} alt="COS 3급 초고반"/>
                                          <img src={SCAMP05_slide1_3} alt="한자 8급 마스터 초저반"/>
                                          <img src={SCAMP05_slide1_4} alt="한자 8급 마스터 초고반"/>
                                          <img src={SCAMP05_slide1_5} alt="마인드맵으로 배우는 한국사"/>
                                      </div>
                                  </a>

                                  <a className="camp-slider slider2" href="/search/result?keyword=루틴">
                                      <div className="box">
                                          <img src={SCAMP05_slide2_1} alt="초글비 클래스"/>
                                          <img src={SCAMP05_slide2_2} alt="저녁 신문 읽기"/>
                                          <img src={SCAMP05_slide2_3} alt="아침 신문 읽기"/>
                                          <img src={SCAMP05_slide2_4} alt="애니멀 모션 키즈 필라테스"/>
                                          <img src={SCAMP05_slide2_5} alt="일기 쓰기 클래스"/>
                                          <img src={SCAMP05_slide2_6} alt="집콕! 곤충 탐구"/>
                                          <img src={SCAMP05_slide2_7} alt="영어 논술 클럽"/>
                                          <img src={SCAMP05_slide2_8} alt="디지털 마인드맵 씽크와이즈"/>
                                          <img src={SCAMP05_slide2_1} alt="초글비 클래스"/>
                                          <img src={SCAMP05_slide2_2} alt="저녁 신문 읽기"/>
                                          <img src={SCAMP05_slide2_3} alt="아침 신문 읽기"/>
                                          <img src={SCAMP05_slide2_4} alt="애니멀 모션 키즈 필라테스"/>
                                          <img src={SCAMP05_slide2_5} alt="일기 쓰기 클래스"/>
                                          <img src={SCAMP05_slide2_6} alt="집콕! 곤충 탐구"/>
                                          <img src={SCAMP05_slide2_7} alt="영어 논술 클럽"/>
                                          <img src={SCAMP05_slide2_8} alt="디지털 마인드맵 씽크와이즈"/>
                                      </div>
                                  </a>

                                  <a className="camp-slider slider3" href="/search/result?keyword=교과완성">
                                      <div className="box">
                                          <img src={SCAMP05_slide3_1} alt="마인드맵 교과 요점 정리"/>
                                          <img src={SCAMP05_slide3_2} alt="주산 스쿨"/>
                                          <img src={SCAMP05_slide3_3} alt="굿모닝! 독서 클럽"/>
                                          <img src={SCAMP05_slide3_4} alt="라이브올 문해력 교실"/>
                                          <img src={SCAMP05_slide3_5} alt="한글 완성 프로젝트"/>
                                          <img src={SCAMP05_slide3_6} alt="우리나라 독립운동가"/>
                                          <img src={SCAMP05_slide3_7} alt="모나르떼 세계사 클래스"/>
                                          <img src={SCAMP05_slide3_1} alt="마인드맵 교과 요점 정리"/>
                                          <img src={SCAMP05_slide3_2} alt="주산 스쿨"/>
                                          <img src={SCAMP05_slide3_3} alt="굿모닝! 독서 클럽"/>
                                          <img src={SCAMP05_slide3_4} alt="라이브올 문해력 교실"/>
                                          <img src={SCAMP05_slide3_5} alt="한글 완성 프로젝트"/>
                                          <img src={SCAMP05_slide3_6} alt="우리나라 독립운동가"/>
                                          <img src={SCAMP05_slide3_7} alt="모나르떼 세계사 클래스"/>
                                      </div>
                                  </a>
                              </div>
                              <div className="LinkInWrap p_r scamp06">
                                  <div className="hashtag" id="program2"></div>
                                  <img src={PC_SCAMP06} alt="이벤트 피시 상세" />
                                  <img src={PC_SCAMP06_title} className="tit" alt="라인업 폼 미쳤다! 놓치면 손해! 라이브올 금쪽같은 라이브" />
                                  <img src={PC_SCAMP06_lecturer} className="lecturer fade-element" alt="강사 3인" />

                                  <Button className="btn b1" onClick={onJoinClick}>회원가입</Button>

                                  <Button className="btn b2" href="/class/791">아들TV 최민준 사전 신청하기</Button>
                                  <Button className="btn b3" href="/class/792">홍성우 원장 사전 신청하기</Button>
                                  <Button className="btn b4" href="/class/793">마술사 제니 사전 신청하기</Button>

                                  <Button className="btn b5" href="/class/791">아들TV 최민준 사전 신청하기</Button>
                                  <Button className="btn b6" href="/class/792">홍성우 원장 사전 신청하기</Button>
                                  <Button className="btn b7" href="/class/793">마술사 제니 사전 신청하기</Button>
                              </div>
                              <div className="LinkInWrap p_r scamp07">
                                  <img src={PC_SCAMP07} alt="이벤트 피시 상세" />

                                  <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                  <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                      <img src={PC_SCAMP07_tabcon} alt="이벤트 피시 상세" />
                                  </div>
                              </div>
                          </DesktopAndTablet>
                          <AllMobile>
                              <div className="LinkInWrap p_r scamp01">
                                  <img src={MO_SCAMP01} alt="이벤트 모바일 상세" />
                                  <div className="bubble-box">
                                      <img src={PC_SCAMP01_obj1} className="bubble1" alt="" />
                                      <img src={PC_SCAMP01_obj2} className="bubble2" alt="" />
                                      <img src={PC_SCAMP01_obj3} className="bubble3" alt="" />
                                      <img src={PC_SCAMP01_obj4} className="bubble4" alt="" />
                                      <img src={PC_SCAMP01_obj5} className="bubble5" alt="" />
                                      <img src={PC_SCAMP01_obj6} className="bubble6" alt="" />
                                  </div>
                                  <img src={MO_SCAMP01_lecturer} className="lecturer" alt="강사 3인" />
                              </div>
                              <div className="LinkInWrap p_r scamp02">
                                  <img src={MO_SCAMP02_tit} className="tit" alt="summer camp event 01 - 30일 출석 이벤트" />
                                  <div className="balloon">
                                      <div className="inner">
                                          <img src={PC_SCAMP02_box_tit} className="box-tit" alt="출석하기를 누르면 매일매일 올머니 500올 증정!" />
                                          <div className={`stamp-box ${getStamp >= 12 ? "open" : ""}`}>
                                              {onStamp.map((item, idx) => (
                                                <span key={`stamp ${idx}`} className={`stamp ${item === true ? "on" : ""}`}></span>
                                              ))}
                                          </div>
                                          <Button className="stamp-open-btn" style={{ display: getStamp >= 12 ? "none" : "" }} onClick={stampOpen}>
                                              <img src={PC_SCAMP02_stamp_open} alt="펼쳐보기" />
                                          </Button>
                                      </div>
                                  </div>
                                  <Button className="btn" value={event_seq} onClick={clickStamp}>출석체크</Button>
                              </div>
                              <div className="LinkInWrap p_r scamp03">
                                  <img src={MO_SCAMP03} alt="이벤트 모바일 상세" />
                                  <Button className="btn b1" href="#program1">프로그램 01 - 라이브올 여름특강 코스</Button>
                                  <Button className="btn b2" href="#program2">프로그램 02 - 무료! 금쪽같은 라이브</Button>
                              </div>
                              <div className="LinkInWrap p_r scamp04">
                                  <div className="hashtag" id="program1"></div>
                                  <img src={MO_SCAMP04_4} alt="이벤트 모바일 상세" />
                                  <div className="bubble-box">
                                      <img src={PC_SCAMP04_obj1} className="bubble1" alt="" />
                                      <img src={PC_SCAMP04_obj2} className="bubble2" alt="" />
                                      <img src={PC_SCAMP04_obj3} className="bubble3" alt="" />
                                      <img src={PC_SCAMP04_obj4} className="bubble4" alt="" />
                                      <img src={PC_SCAMP04_obj5} className="bubble5" alt="" />
                                  </div>
                                  <Button className="btn b1" href="/event/44">여름특강 코스 보러가기</Button>
                                  <Button className="btn c1" value={118} onClick={(e) => clickCouponDown(e)} style={{ pointerEvents: "none" }}>1차 쿠폰 다운받기 - 1</Button>
                                  <Button className="btn c2" value={119} onClick={(e) => clickCouponDown(e)} style={{ pointerEvents: "none" }}>1차 쿠폰 다운받기 - 2</Button>
                                  <Button className="btn c3" value={128} onClick={(e) => clickCouponDown(e)} style={{ pointerEvents: "none" }}>2차 쿠폰 다운받기 - 1</Button>
                                  <Button className="btn c4" value={127} onClick={(e) => clickCouponDown(e)} style={{ pointerEvents: "none" }}>2차 쿠폰 다운받기 - 2</Button>
                              </div>
                              <div className="LinkInWrap p_r scamp05">
                                  <img src={MO_SCAMP05} alt="이벤트 모바일 상세" />

                                  <a className="camp-slider slider1" href="/search/result?keyword=자격증">
                                      <div className="box">
                                          <img src={SCAMP05_slide1_1} alt="COS 3급 초저반"/>
                                          <img src={SCAMP05_slide1_2} alt="COS 3급 초고반"/>
                                          <img src={SCAMP05_slide1_3} alt="한자 8급 마스터 초저반"/>
                                          <img src={SCAMP05_slide1_4} alt="한자 8급 마스터 초고반"/>
                                          <img src={SCAMP05_slide1_5} alt="마인드맵으로 배우는 한국사"/>
                                          <img src={SCAMP05_slide1_1} alt="COS 3급 초저반"/>
                                          <img src={SCAMP05_slide1_2} alt="COS 3급 초고반"/>
                                          <img src={SCAMP05_slide1_3} alt="한자 8급 마스터 초저반"/>
                                          <img src={SCAMP05_slide1_4} alt="한자 8급 마스터 초고반"/>
                                          <img src={SCAMP05_slide1_5} alt="마인드맵으로 배우는 한국사"/>
                                      </div>
                                  </a>

                                  <a className="camp-slider slider2" href="/search/result?keyword=루틴">
                                      <div className="box">
                                          <img src={SCAMP05_slide2_1} alt="초글비 클래스"/>
                                          <img src={SCAMP05_slide2_2} alt="저녁 신문 읽기"/>
                                          <img src={SCAMP05_slide2_3} alt="아침 신문 읽기"/>
                                          <img src={SCAMP05_slide2_4} alt="애니멀 모션 키즈 필라테스"/>
                                          <img src={SCAMP05_slide2_5} alt="일기 쓰기 클래스"/>
                                          <img src={SCAMP05_slide2_6} alt="집콕! 곤충 탐구"/>
                                          <img src={SCAMP05_slide2_7} alt="영어 논술 클럽"/>
                                          <img src={SCAMP05_slide2_8} alt="디지털 마인드맵 씽크와이즈"/>
                                          <img src={SCAMP05_slide2_1} alt="초글비 클래스"/>
                                          <img src={SCAMP05_slide2_2} alt="저녁 신문 읽기"/>
                                          <img src={SCAMP05_slide2_3} alt="아침 신문 읽기"/>
                                          <img src={SCAMP05_slide2_4} alt="애니멀 모션 키즈 필라테스"/>
                                          <img src={SCAMP05_slide2_5} alt="일기 쓰기 클래스"/>
                                          <img src={SCAMP05_slide2_6} alt="집콕! 곤충 탐구"/>
                                          <img src={SCAMP05_slide2_7} alt="영어 논술 클럽"/>
                                          <img src={SCAMP05_slide2_8} alt="디지털 마인드맵 씽크와이즈"/>
                                      </div>
                                  </a>

                                  <a className="camp-slider slider3" href="/search/result?keyword=교과완성">
                                      <div className="box">
                                          <img src={SCAMP05_slide3_1} alt="마인드맵 교과 요점 정리"/>
                                          <img src={SCAMP05_slide3_2} alt="주산 스쿨"/>
                                          <img src={SCAMP05_slide3_3} alt="굿모닝! 독서 클럽"/>
                                          <img src={SCAMP05_slide3_4} alt="라이브올 문해력 교실"/>
                                          <img src={SCAMP05_slide3_5} alt="한글 완성 프로젝트"/>
                                          <img src={SCAMP05_slide3_6} alt="우리나라 독립운동가"/>
                                          <img src={SCAMP05_slide3_7} alt="모나르떼 세계사 클래스"/>
                                          <img src={SCAMP05_slide3_1} alt="마인드맵 교과 요점 정리"/>
                                          <img src={SCAMP05_slide3_2} alt="주산 스쿨"/>
                                          <img src={SCAMP05_slide3_3} alt="굿모닝! 독서 클럽"/>
                                          <img src={SCAMP05_slide3_4} alt="라이브올 문해력 교실"/>
                                          <img src={SCAMP05_slide3_5} alt="한글 완성 프로젝트"/>
                                          <img src={SCAMP05_slide3_6} alt="우리나라 독립운동가"/>
                                          <img src={SCAMP05_slide3_7} alt="모나르떼 세계사 클래스"/>
                                      </div>
                                  </a>
                              </div>
                              <div className="LinkInWrap p_r scamp06">
                                  <div className="hashtag" id="program2"></div>
                                  <img src={MO_SCAMP06} alt="이벤트 모바일 상세" />
                                  <img src={PC_SCAMP06_lecturer} className="lecturer fade-element" alt="강사 3인" />

                                  <Button className="btn b1" onClick={onJoinClick}>회원가입</Button>

                                  <Button className="btn b2" href="/class/791">아들TV 최민준 사전 신청하기</Button>
                                  <Button className="btn b3" href="/class/792">홍성우 원장 사전 신청하기</Button>
                                  <Button className="btn b4" href="/class/793">마술사 제니 사전 신청하기</Button>

                                  <Button className="btn b5" href="/class/791">아들TV 최민준 사전 신청하기</Button>
                                  <Button className="btn b6" href="/class/792">홍성우 원장 사전 신청하기</Button>
                                  <Button className="btn b7" href="/class/793">마술사 제니 사전 신청하기</Button>
                              </div>
                              <div className="LinkInWrap p_r scamp07">
                                  <img src={MO_SCAMP07} alt="이벤트 모바일 상세" />

                                  <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                  <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                      <img src={MO_SCAMP07_tabcon} alt="이벤트 모바일 상세" />
                                  </div>
                              </div>
                          </AllMobile>
                      </div>
                    )}
                    {/* 작업 중 backSchool eventSeq 54*/}
                    {event_seq === 54 && (
                        <div className="event-detail-img backToTheSchool">
                            <DesktopAndTablet>
                                <div className="p_r btt-school0">
                                    <img src={PC_top_logo} alt="이벤트 피시 상세" />
                                </div>
                                {/* 1교시 - 출석이벤트*/}
                                <div className="p_r btt-school1">
                                    <img src={PC_class01} alt="1교시 출석체크 이벤트" />
                                    {/* pub: 체크박스 스탬프 영역 */}
                                    <div className="check-stamp-wrap">
                                        {onStamp2.map((item, idx) => (
                                          <span key={`stamp ${idx}`} className={`stamp ${item === true ? "on" : ""}`}></span>
                                        ))}
                                        <span key={`stamp 15`} className={"stamp all"}></span>
                                    </div>
                                    <Button className="btn check" value={event_seq} onClick={clickStamp}>출석체크 스탬프</Button>
                                </div>
                                {/* 2교시 - 할인쿠폰, 추천클래스*/}
                                <div className="p_r btt-school2">
                                    <img src={PC_class02} alt="2교시 할인쿠폰, 추천클래스" />
                                    <Button className="btn download-1" value={151} onClick={(e) => clickCouponDown(e)}>원데이 15% 다운로드</Button>
                                    <Button className="btn download-2" value={152} onClick={(e) => clickCouponDown(e)} >패키지 10% 다운로드</Button>

                                    <Button className="btn recommend-1" href="/search/result?keyword=%EC%96%B4%ED%9C%98">어휘마스터</Button>
                                    <Button className="btn recommend-2" href="/search/result?keyword=%EA%B3%BC%ED%95%99%EA%B5%90%EC%8B%A4">과학교실</Button>
                                    <Button className="btn recommend-3" href="/class/845">문화재</Button>
                                    <Button className="btn recommend-4" href="/class/850">영어</Button>
                                </div>
                                {/* 3교시 - 체험클래스*/}
                                <div className="p_r btt-school3">
                                    <img src={PC_class03} alt="3교시 체험클래스" />
                                    <Button className="btn experience" href="/search/result?keyword=%EC%B2%B4%ED%97%98%ED%81%B4%EB%9E%98%EC%8A%A4">체험클래스</Button>
                                </div>
                                {/* 4교시 - 새학기 특별 선물*/}
                                <div className="p_r btt-school4">
                                    <img src={PC_class04} alt="4교시 새학기 특별 선물" />
                                    <Button className="btn gift-1" href="/class/818">보물편</Button>
                                    <Button className="btn gift-2" href="/class/820">직업편</Button>
                                </div>
                                {/* 유의사항 */}
                                <div className="p_r btt-school-notice">
                                    <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                    <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                        <img src={PC_tabcon} alt="이벤트 피시 상세" />
                                    </div>
                                </div>
                            </DesktopAndTablet>
                            <AllMobile>
                                <div className="p_r btt-school0">
                                    <img src={MO_top_logo} alt="이벤트 피시 상세" />
                                </div>
                                {/* 1교시 - 출석이벤트*/}
                                <div className="p_r btt-school1">
                                    <img src={MO_class01} alt="1교시 출석체크 이벤트" />
                                    {/* pub: 체크박스 스탬프 영역 */}
                                    <div className="check-stamp-wrap">
                                        {onStamp2.map((item, idx) => (
                                          <span key={`stamp ${idx}`} className={`stamp ${item === true ? "on" : ""}`}></span>
                                        ))}
                                        <span key={`stamp 15`} className={"stamp all"}></span>
                                    </div>
                                    <Button className="btn check" value={event_seq} onClick={clickStamp}>출석체크 스탬프</Button>
                                </div>
                                {/* 2교시 - 할인쿠폰, 추천클래스*/}
                                <div className="p_r btt-school2">
                                    <img src={MO_class02} alt="2교시 할인쿠폰, 추천클래스" />
                                    <Button className="btn download-1" value={151} onClick={(e) => clickCouponDown(e)}>원데이 15% 다운로드</Button>
                                    <Button className="btn download-2" value={152} onClick={(e) => clickCouponDown(e)} >패키지 10% 다운로드</Button>

                                    <Button className="btn recommend-1" href="/search/result?keyword=%EC%96%B4%ED%9C%98">어휘마스터</Button>
                                    <Button className="btn recommend-2" href="/search/result?keyword=%EA%B3%BC%ED%95%99%EA%B5%90%EC%8B%A4">과학교실</Button>
                                    <Button className="btn recommend-3" href="/class/845">문화재</Button>
                                    <Button className="btn recommend-4" href="/class/850">영어</Button>
                                </div>
                                {/* 3교시 - 체험클래스*/}
                                <div className="p_r btt-school3">
                                    <img src={MO_class03} alt="3교시 체험클래스" />
                                    <Button className="btn experience" href="/search/result?keyword=%EC%B2%B4%ED%97%98%ED%81%B4%EB%9E%98%EC%8A%A4">체험클래스</Button>
                                </div>
                                {/* 4교시 - 새학기 특별 선물*/}
                                <div className="p_r btt-school4">
                                    <img src={MO_class04} alt="4교시 새학기 특별 선물" />
                                    <Button className="btn gift-1" href="/class/818">보물편</Button>
                                    <Button className="btn gift-2" href="/class/820">직업편</Button>
                                </div>
                                {/* 유의사항 */}
                                <div className="p_r btt-school-notice">
                                    <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                    <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                        <img src={MO_tabcon} alt="이벤트 피시 상세" />
                                    </div>
                                </div>
                            </AllMobile>
                        </div>
                    )}
                    {event_seq === 44 && (
                        <>
                            <div className="event-detail-img summerCamp">
                                <DesktopAndTablet>
                                    <div className="LinkInWrap p_r scamp04">
                                        <div className="hashtag" id="program1"></div>
                                        <img src={PC_SCAMP04_4} alt="이벤트 피시 상세" />
                                        <div className="bubble-box">
                                            <img src={PC_SCAMP04_obj1} className="bubble1" alt="" />
                                            <img src={PC_SCAMP04_obj2} className="bubble2" alt="" />
                                            <img src={PC_SCAMP04_obj3} className="bubble3" alt="" />
                                            <img src={PC_SCAMP04_obj4} className="bubble4" alt="" />
                                            <img src={PC_SCAMP04_obj5} className="bubble5" alt="" />
                                        </div>
                                        <Button className="btn c1" value={118} onClick={(e) => clickCouponDown(e)} style={{ pointerEvents: "none" }}>1차 쿠폰 다운받기 - 1</Button>
                                        <Button className="btn c2" value={119} onClick={(e) => clickCouponDown(e)} style={{ pointerEvents: "none" }}>1차 쿠폰 다운받기 - 2</Button>
                                        <Button className="btn c3" value={128} onClick={(e) => clickCouponDown(e)} style={{ pointerEvents: "none" }}>2차 쿠폰 다운받기 - 1</Button>
                                        <Button className="btn c4" value={127} onClick={(e) => clickCouponDown(e)} style={{ pointerEvents: "none" }}>2차 쿠폰 다운받기 - 2</Button>
                                    </div>
                                </DesktopAndTablet>
                                <AllMobile>
                                    <div className="LinkInWrap p_r scamp04">
                                        <div className="hashtag" id="program1"></div>
                                        <img src={MO_SCAMP04_4} alt="이벤트 모바일 상세" />
                                        <div className="bubble-box">
                                            <img src={PC_SCAMP04_obj1} className="bubble1" alt="" />
                                            <img src={PC_SCAMP04_obj2} className="bubble2" alt="" />
                                            <img src={PC_SCAMP04_obj3} className="bubble3" alt="" />
                                            <img src={PC_SCAMP04_obj4} className="bubble4" alt="" />
                                            <img src={PC_SCAMP04_obj5} className="bubble5" alt="" />
                                        </div>
                                        <Button className="btn c1" value={118} onClick={(e) => clickCouponDown(e)} style={{ pointerEvents: "none" }}>1차 쿠폰 다운받기 - 1</Button>
                                        <Button className="btn c2" value={119} onClick={(e) => clickCouponDown(e)} style={{ pointerEvents: "none" }}>1차 쿠폰 다운받기 - 2</Button>
                                        <Button className="btn c3" value={128} onClick={(e) => clickCouponDown(e)} style={{ pointerEvents: "none" }}>2차 쿠폰 다운받기 - 1</Button>
                                        <Button className="btn c4" value={127} onClick={(e) => clickCouponDown(e)} style={{ pointerEvents: "none" }}>2차 쿠폰 다운받기 - 2</Button>
                                    </div>
                                </AllMobile>
                            </div>
                            <div className="recommendClass summerCamp">
                                <div className="breadcrumb">
                                    <span className="depth">홈</span>
                                    <span className="depth">여름 캠프</span>
                                </div>
                                <div className="tabBox">
                                    <ul className="tabMenu">
                                        <li className={selectKeyword !== 0 ? "" : "on"} value={0} onClick={(e) => handleKeyword(e, "여름방학")}>ALL</li>
                                        <li className={selectKeyword !== 1 ? "" : "on"} value={1} onClick={(e) => handleKeyword(e, "방학습관")}>습관</li>
                                        <li className={selectKeyword !== 2 ? "" : "on"} value={2} onClick={(e) => handleKeyword(e, "방학숙제")}>방학숙제</li>
                                        <li className={selectKeyword !== 3 ? "" : "on"} value={3} onClick={(e) => handleKeyword(e, "단기완성")}>단기완성</li>
                                        <li className={selectKeyword !== 4 ? "" : "on"} value={4} onClick={(e) => handleKeyword(e, "교과연계")}>교과연계</li>
                                        <li className={selectKeyword !== 5 ? "" : "on"} value={5} onClick={(e) => handleKeyword(e, "자격증")}>자격증</li>
                                    </ul>
                                </div>
                                <LoadingWrapper status={[classesStatus]}>
                                    {classList?.length > 0 ? (
                                        <ClassItemList dataList={classList} />
                                    ) : (
                                        <div className="table-empty pt30 pb50">
                                            <div className="table-empty-caution">
                                                <div className="title-section">
                                                    <Typography className="title">클래스가 없습니다.</Typography>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </LoadingWrapper>
                            </div>
                        </>
                        
                    )}
                    {event_seq === 52 && ( // 52
                        <>
                            <div className="event-detail-img festival">
                                <DesktopAndTablet>
                                    <div className="LinkInWrap p_r">
                                        <img src={PC_festival_01} alt="이벤트 피시 상세" />
                                        <Button href="/search/result?keyword=가을페스티벌" className="link-btn"></Button>
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={PC_festival_02} alt="이벤트 피시 상세" />
                                        <a className="event-slider" href="/search/result?keyword=가을페스티벌">
                                            <div className="event-slider-box">
                                                <img src={PC_slide} alt="PC 슬라이드 이미지"/>
                                            </div>
                                        </a>
                                        <div className="btn_box" onClick={checkLogin}>
                                            {isLogin ? (
                                                <>
                                                    {stepData?.map((item) => (
                                                        <Button className={`btn_entry ${item.done_yn === true ? 'on' : ''}`} value={item.step} onClick={(e) => clickToParticipate(e)} />
                                                    ))}
                                                    <Button className={`btn_festival_join ${festivalApply === true ? 'on' : ''}`} onClick={(e) => clickToParticipate(e)} />
                                                </>
                                            ) : (
                                                <>
                                                    <Button className="btn_entry" />
                                                    <Button className="btn_entry" />
                                                    <Button className="btn_entry" />
                                                    <Button className="btn_festival_join" />
                                                </>
                                            )}
                                        </div>
                                    
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={PC_festival_03} alt="이벤트 피시 상세" />
                                        <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                        <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                            <img src={PC_festival_04_01} alt="이벤트 피시 상세" />
                                        </div>  
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={PC_festival_05} alt="이벤트 피시 상세" />
                                    </div>
                                </DesktopAndTablet>
                                <AllMobile>
                                    <div className="LinkInWrap p_r">
                                        <img src={MO_festival_01} alt="이벤트 모바일 상세" />
                                        <Button href="/search/result?keyword=가을페스티벌" className="link-btn"></Button>
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={MO_festival_02} alt="이벤트 모바일 상세" />
                                        <a className="event-slider" href="/search/result?keyword=가을페스티벌">
                                            <div className="event-slider-box">
                                                <img src={MO_slide} alt="MO 슬라이드 이미지"/>
                                            </div>
                                        </a>
                                        <div className="btn_box" onClick={checkLogin}>
                                            {isLogin ? (
                                                <>
                                                    {stepData?.map((item) => (
                                                        <Button className={`btn_entry ${item.done_yn === true ? 'on' : ''}`} value={item.step} onClick={(e) => clickToParticipate(e)} />
                                                    ))}
                                                    <Button className={`btn_festival_join ${festivalApply === true ? 'on' : ''}`} onClick={(e) => clickToParticipate(e)} />
                                                </>
                                            ) : (
                                                <>
                                                    <Button className="btn_entry" />
                                                    <Button className="btn_entry" />
                                                    <Button className="btn_entry" />
                                                    <Button className="btn_festival_join" />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={MO_festival_03} alt="이벤트 모바일 상세" />
                                        <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                        <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                            <img src={MO_festival_04_01} alt="이벤트 모바일 상세" />
                                        </div>  
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={MO_festival_05} alt="이벤트 모바일 상세" />
                                    </div>
                                </AllMobile>
                            </div>

                            <Best type="event" />
                        </>
                    )}
                    {event_seq === 58 && (
                        <>
                            <div className="event-detail-img joinRenewal">
                                <DesktopAndTablet>
                                    <div className="LinkInWrap p_r">
                                        <img src={PC_JOIN_RENEWAL_01} alt="이벤트 피시 상세" />
                                        <Button className="link-btn" onClick={onJoinClick}></Button>
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={PC_JOIN_RENEWAL_02} alt="이벤트 피시 상세" />
                                        <Button className="link-btn event" onClick={onJoinEvent}></Button>
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={PC_JOIN_RENEWAL_03} alt="이벤트 피시 상세" />
                                        <Button className="recommend-btn" onClick={onRecommandClick}></Button>
                                    </div>
                                    <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                    <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                        <img src={PC_JOIN_RENEWAL_03_2} alt="이벤트 피시 상세" />
                                    </div>  
                                    <img src={PC_JOIN_RENEWAL_03_3} alt="이벤트 피시 상세" />
                                    <div className="LinkInWrap p_r">
                                        <img src={PC_JOIN_RENEWAL_04} alt="이벤트 피시 상세" />
                                        <Button className="link-btn link" onClick={onStrawberryBeans}></Button>
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={PC_JOIN_RENEWAL_05} alt="이벤트 피시 상세" />
                                        <Button className="link-btn join" onClick={onJoinClick}></Button>
                                    </div>
                                    <div className={noti2Expanded ? 'noticeToggle2 isOn' : 'noticeToggle2'} onClick={onNoti2ExpandedClick} dataid={0}></div>
                                    <div className={noti2Expanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                        <img src={PC_JOIN_RENEWAL_05_2} alt="이벤트 피시 상세" />
                                    </div>  
                                    <img src={PC_JOIN_RENEWAL_05_3} alt="이벤트 피시 상세" />
                                </DesktopAndTablet>
                                <AllMobile>
                                    <div className="LinkInWrap p_r">
                                        <img src={MO_JOIN_RENEWAL_01} alt="이벤트 모바일 상세" />
                                        <Button className="link-btn" onClick={onJoinClick}></Button>
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={MO_JOIN_RENEWAL_02} alt="이벤트 모바일 상세" />
                                        <Button className="link-btn event" onClick={onJoinEvent}></Button>
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={MO_JOIN_RENEWAL_03} alt="이벤트 모바일 상세" />
                                        <Button className="recommend-btn" onClick={onRecommandClick}></Button>
                                    </div>
                                    <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                    <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                        <img src={MO_JOIN_RENEWAL_03_2} alt="이벤트 모바일 상세" />
                                    </div>  
                                    <img src={MO_JOIN_RENEWAL_03_3} alt="이벤트 피시 상세" />
                                    <div className="LinkInWrap p_r">
                                        <img src={MO_JOIN_RENEWAL_04} alt="이벤트 모바일 상세" />
                                        <Button className="link-btn link" onClick={onStrawberryBeans}></Button>
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={MO_JOIN_RENEWAL_05} alt="이벤트 모바일 상세" />
                                        <Button className="link-btn join" onClick={onJoinClick}></Button>
                                    </div>
                                    <div className={noti2Expanded ? 'noticeToggle2 isOn' : 'noticeToggle2'} onClick={onNoti2ExpandedClick} dataid={0}></div>
                                    <div className={noti2Expanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                        <img src={MO_JOIN_RENEWAL_05_2} alt="이벤트 모바일 상세" />
                                    </div>  
                                    <img src={MO_JOIN_RENEWAL_05_3} alt="이벤트 모바일 상세" />
                                </AllMobile>
                            </div>
                        </>
                    )}
                    {event_seq === 59 && (
                        <>
                            <div className="event-detail-img recsRenewal">
                                <DesktopAndTablet>
                                    <div className="LinkInWrap p_r">
                                        <img src={PC_RECS_RENEWAL_01} alt="이벤트 피시 상세" />
                                        <Button className="recommend-btn" onClick={onRecommandClick}></Button>
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={PC_RECS_RENEWAL_02} alt="이벤트 피시 상세" />
                                    </div>
                                    <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                    <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                        <img src={PC_RECS_RENEWAL_03} alt="이벤트 피시 상세" />
                                    </div>  
                                </DesktopAndTablet>
                                <AllMobile>
                                    <div className="LinkInWrap p_r">
                                        <img src={MO_RECS_RENEWAL_01} alt="이벤트 모바일 상세" />
                                        <Button className="recommend-btn" onClick={onRecommandClick}></Button>
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={MO_RECS_RENEWAL_02} alt="이벤트 모바일 상세" />
                                    </div>
                                    <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                    <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                        <img src={MO_RECS_RENEWAL_03} alt="이벤트 모바일 상세" />
                                    </div>  
                                </AllMobile>
                            </div>
                        </>
                    )}
                    {event_seq === 71 && (
                        <div className="event-detail-img joinRenewal">
                            <DesktopAndTablet>
                                <div className="LinkInWrap p_r">
                                    <img src={PC_JOIN_RERENEWAL_01} alt="이벤트 피시 상세" />
                                    <Button className="link-btn" onClick={onJoinClick}></Button>
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={PC_JOIN_RERENEWAL_02} alt="이벤트 피시 상세" />
                                    <Button className="recommend-btn" onClick={onRecommandClick}></Button>
                                </div>
                                <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={PC_JOIN_RENEWAL_03_2} alt="이벤트 피시 상세" />
                                </div>  
                                <img src={PC_JOIN_RENEWAL_03_3} alt="이벤트 피시 상세" />
                                <div className="LinkInWrap p_r">
                                    <img src={PC_JOIN_RENEWAL_04} alt="이벤트 피시 상세" />
                                    <Button className="link-btn link" onClick={onStrawberryBeans}></Button>
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={PC_JOIN_RENEWAL_05} alt="이벤트 피시 상세" />
                                    <Button className="link-btn join" onClick={onJoinClick}></Button>
                                </div>
                                <div className={noti2Expanded ? 'noticeToggle2 isOn' : 'noticeToggle2'} onClick={onNoti2ExpandedClick} dataid={0}></div>
                                <div className={noti2Expanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={PC_JOIN_RENEWAL_05_2} alt="이벤트 피시 상세" />
                                </div>  
                                <img src={PC_JOIN_RENEWAL_05_3} alt="이벤트 피시 상세" />
                            </DesktopAndTablet>
                            <AllMobile>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_JOIN_RERENEWAL_01} alt="이벤트 모바일 상세" />
                                    <Button className="link-btn" onClick={onJoinClick}></Button>
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_JOIN_RERENEWAL_02} alt="이벤트 모바일 상세" />
                                    <Button className="recommend-btn" onClick={onRecommandClick}></Button>
                                </div>
                                <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={MO_JOIN_RENEWAL_03_2} alt="이벤트 모바일 상세" />
                                </div>  
                                <img src={MO_JOIN_RENEWAL_03_3} alt="이벤트 피시 상세" />
                                <div className="LinkInWrap p_r">
                                    <img src={MO_JOIN_RENEWAL_04} alt="이벤트 모바일 상세" />
                                    <Button className="link-btn link" onClick={onStrawberryBeans}></Button>
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_JOIN_RENEWAL_05} alt="이벤트 모바일 상세" />
                                    <Button className="link-btn join" onClick={onJoinClick}></Button>
                                </div>
                                <div className={noti2Expanded ? 'noticeToggle2 isOn' : 'noticeToggle2'} onClick={onNoti2ExpandedClick} dataid={0}></div>
                                <div className={noti2Expanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={MO_JOIN_RENEWAL_05_2} alt="이벤트 모바일 상세" />
                                </div>  
                                <img src={MO_JOIN_RENEWAL_05_3} alt="이벤트 모바일 상세" />
                            </AllMobile>
                        </div>
                    )}
                    {event_seq === 79 && (
                        <>
                            <div className="event-detail-img recsRenewal">
                                <DesktopAndTablet>
                                    <div className="LinkInWrap p_r">
                                        <img src={PC_RECS_RENEWAL_01} alt="이벤트 피시 상세" />
                                        <Button className="recommend-btn" onClick={onRecommandClick}></Button>
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={PC_RECS_RENEWAL_02} alt="이벤트 피시 상세" />
                                    </div>
                                    <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                    <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                        <img src={PC_RECS_RENEWAL_03} alt="이벤트 피시 상세" />
                                    </div>  
                                </DesktopAndTablet>
                                <AllMobile>
                                    <div className="LinkInWrap p_r">
                                        <img src={MO_RECS_RENEWAL_01} alt="이벤트 모바일 상세" />
                                        <Button className="recommend-btn" onClick={onRecommandClick}></Button>
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={MO_RECS_RENEWAL_02} alt="이벤트 모바일 상세" />
                                    </div>
                                    <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                    <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                        <img src={MO_RECS_RENEWAL_03} alt="이벤트 모바일 상세" />
                                    </div>  
                                </AllMobile>
                            </div>
                        </>
                    )}
                    {event_seq === 76 && ( // 겨울캠프
                        <div className="event-detail-img winterCamp">
                            <DesktopAndTablet>
                                <div className="LinkInWrap p_r">
                                    <img src={PC_WINTERCAMP_01_00} className="txt_hot" alt="이벤트 피시 상세" />
                                    <img src={PC_WINTERCAMP_00} alt="이벤트 피시 상세" />
                                    <img src={PC_WINTERCAMP_01_01} alt="이벤트 피시 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={PC_WINTERCAMP_03_00} className="coffee1" alt="이벤트 피시 상세" />
                                    <img src={PC_WINTERCAMP_03_01} className="coffee2" alt="이벤트 피시 상세" />    
                                    <img src={PC_WINTERCAMP_03} alt="이벤트 피시 상세" />
                                    <div className="btn_box">
                                        <Button className="btn_coupon" value={293} onClick={(e) => clickCouponDown(e)}>쿠폰다운로드</Button>
                                        <Button className="btn_coupon" value={294} onClick={(e) => clickCouponDown(e)}>쿠폰다운로드</Button>
                                    </div>
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={PC_WINTERCAMP_04} alt="이벤트 피시 상세" />
                                    <Button className="btn_all1" href="/search/result?keyword=겨울특강%20새학년">새학년준비</Button>
                                    <Swiper 
                                        slidesPerView={3.4}
                                        className="slide-card-items-list-type newYear"
                                        onSwiper={setSwiper}
                                    >
                                        <SwiperSlide key={0}>
                                            <Button className="card" href="/search/result?keyword=마인드맵공부방">
                                                <img src={PC_SLIDECARD_01_01} alt="교과 마인드맵"/>
                                            </Button>
                                        </SwiperSlide>
                                        <SwiperSlide key={1}>
                                            <Button className="card" href="/class/956">
                                                <img src={PC_SLIDECARD_01_02} alt="교과 독서 클럽"/>
                                            </Button>
                                        </SwiperSlide>
                                        <SwiperSlide key={2}>
                                            <Button className="card" href="/search/result?keyword=학년별수학집중력치트키">
                                                <img src={PC_SLIDECARD_01_03} alt="수학 집중력"/>
                                            </Button>
                                        </SwiperSlide>
                                        <SwiperSlide key={3}>
                                            <Button className="card" href="/search/result?keyword=한글%20풀패키지">
                                                <img src={PC_SLIDECARD_01_04} alt="한글완성"/>
                                            </Button>
                                        </SwiperSlide>
                                    </Swiper>
                                    
                                    <Button className="btn_all2" href="/search/result?keyword=겨울특강%20영어">영어</Button>
                                    <Swiper 
                                        slidesPerView={3.4}
                                        className="slide-card-items-list-type english"
                                        onSwiper={setSwiper}
                                    >
                                        <SwiperSlide key={0}>
                                            <Button className="card" href="/search/result?keyword=art">
                                                <img src={PC_SLIDECARD_02_01} alt="ArtClass"/>
                                            </Button>
                                        </SwiperSlide>
                                        <SwiperSlide key={1}>
                                            <Button className="card" href="/search/result?keyword=3개월">
                                                <img src={PC_SLIDECARD_02_02} alt="네이티브 스피킹"/>
                                            </Button>
                                        </SwiperSlide>
                                        <SwiperSlide key={2}>
                                            <Button className="card" href="/search/result?keyword=영어원서">
                                                <img src={PC_SLIDECARD_02_03} alt="리드&디스커스"/>
                                            </Button>
                                        </SwiperSlide>
                                    </Swiper>

                                    <Button className="btn_all3" href="/search/result?keyword=겨울특강%20습관">바른루틴</Button>
                                    <Swiper 
                                        slidesPerView={3.4}
                                        className="slide-card-items-list-type routin"
                                        onSwiper={setSwiper}
                                    >
                                        <SwiperSlide key={0}>
                                            <Button className="card" href="/class/953">
                                                <img src={PC_SLIDECARD_03_01} alt="독서토론"/>
                                            </Button>
                                        </SwiperSlide>
                                        <SwiperSlide key={1}>
                                            <Button className="card" href="/class/811">
                                                <img src={PC_SLIDECARD_03_02} alt="문해력 일기쓰기"/>
                                            </Button>
                                        </SwiperSlide>
                                        <SwiperSlide key={2}>
                                            <Button className="card" href="/search/result?keyword=신문읽기챌린지">
                                                <img src={PC_SLIDECARD_03_03} alt="신문 읽기"/>
                                            </Button>
                                        </SwiperSlide>
                                        <SwiperSlide key={3}>
                                            <Button className="card" href="/class/954">
                                                <img src={PC_SLIDECARD_03_05} alt="독서 토론&논술"/>
                                            </Button>
                                        </SwiperSlide>
                                    </Swiper>

                                    <Button className="btn_all4" href="/search/result?keyword=겨울특강%20단기완성">단기완성</Button>
                                    <Swiper 
                                        slidesPerView={3.4}
                                        className="slide-card-items-list-type shortTerm"
                                        onSwiper={setSwiper}
                                    >
                                        <SwiperSlide key={0}>
                                            <Button className="card" href="/class/777">
                                                <img src={PC_SLIDECARD_04_02} alt="한국사"/>
                                            </Button>
                                        </SwiperSlide>
                                        <SwiperSlide key={1}>
                                            <Button className="card" href="/class/808">
                                                <img src={PC_SLIDECARD_04_03} alt="주산 연산법"/>
                                            </Button>
                                        </SwiperSlide>
                                        <SwiperSlide key={2}>
                                            <Button className="card" href="/search/result?keyword=한자자격증">
                                                <img src={PC_SLIDECARD_04_04} alt="한자 7,8급"/>
                                            </Button>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={PC_WINTERCAMP_05_03} className="txt_me" alt="이벤트 피시 상세" />
                                    <img src={PC_WINTERCAMP_05_04} className="txt_you" alt="이벤트 피시 상세" />
                                    <img src={PC_WINTERCAMP_05} alt="이벤트 피시 상세" />
                                </div>
                                <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={PC_WINTERCAMP_05_02} alt="이벤트 피시 상세" />
                                </div> 
                            </DesktopAndTablet>
                            <AllMobile>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_WINTERCAMP_01_00} className="txt_hot" alt="이벤트 모바일 상세" />
                                    <img src={MO_WINTERCAMP_00} alt="이벤트 모바일 상세" />
                                    <img src={MO_WINTERCAMP_01_01} alt="이벤트 모바일 상세" />
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_WINTERCAMP_03_00} className="coffee1" alt="이벤트 모바일 상세" />
                                    <img src={MO_WINTERCAMP_03_01} className="coffee2" alt="이벤트 모바일 상세" />   
                                    <img src={MO_WINTERCAMP_03} alt="이벤트 모바일 상세" />
                                    <div className="btn_box">
                                        <Button className="btn_coupon" value={293} onClick={(e) => clickCouponDown(e)}>쿠폰다운로드</Button>
                                        <Button className="btn_coupon" value={294} onClick={(e) => clickCouponDown(e)}>쿠폰다운로드</Button>
                                    </div>
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_WINTERCAMP_04} alt="이벤트 모바일 상세" />
                                    <Button className="btn_all1" href="/search/result?keyword=겨울특강%20새학년">새학년준비</Button>
                                    <Swiper 
                                        slidesPerView={2.6}
                                        className="slide-card-items-list-type newYear"
                                        onSwiper={setSwiper}
                                    >
                                        <SwiperSlide key={0}>
                                            <Button className="card" href="/search/result?keyword=마인드맵공부방">
                                                <img src={MO_SLIDECARD_01_01} alt="교과 마인드맵"/>
                                            </Button>
                                        </SwiperSlide>
                                        <SwiperSlide key={1}>
                                            <Button className="card" href="/class/956">
                                                <img src={MO_SLIDECARD_01_02} alt="교과 독서 클럽"/>
                                            </Button>
                                        </SwiperSlide>
                                        <SwiperSlide key={2}>
                                            <Button className="card" href="/search/result?keyword=학년별수학집중력치트키">
                                                <img src={MO_SLIDECARD_01_03} alt="수학 집중력"/>
                                            </Button>
                                        </SwiperSlide>
                                        <SwiperSlide key={3}>
                                            <Button className="card" href="/search/result?keyword=한글%20풀패키지">
                                                <img src={MO_SLIDECARD_01_04} alt="한글완성"/>
                                            </Button>
                                        </SwiperSlide>
                                    </Swiper>
                                    
                                    <Button className="btn_all2" href="/search/result?keyword=겨울특강%20영어">영어</Button>
                                    <Swiper 
                                        slidesPerView={2.6}
                                        className="slide-card-items-list-type english"
                                        onSwiper={setSwiper}
                                    >
                                        <SwiperSlide key={0}>
                                            <Button className="card" href="/search/result?keyword=art">
                                                <img src={MO_SLIDECARD_02_01} alt="ArtClass"/>
                                            </Button>
                                        </SwiperSlide>
                                        <SwiperSlide key={1}>
                                            <Button className="card" href="/search/result?keyword=3개월">
                                                <img src={MO_SLIDECARD_02_02} alt="네이티브 스피킹"/>
                                            </Button>
                                        </SwiperSlide>
                                        <SwiperSlide key={2}>
                                            <Button className="card" href="/search/result?keyword=영어원서">
                                                <img src={MO_SLIDECARD_02_03} alt="리드&디스커스"/>
                                            </Button>
                                        </SwiperSlide>
                                    </Swiper>

                                    <Button className="btn_all3" href="/search/result?keyword=겨울특강%20습관">바른루틴</Button>
                                    <Swiper 
                                        slidesPerView={2.6}
                                        className="slide-card-items-list-type routin"
                                        onSwiper={setSwiper}
                                    >
                                        <SwiperSlide key={0}>
                                            <Button className="card" href="/class/953">
                                                <img src={MO_SLIDECARD_03_01} alt="독서토론"/>
                                            </Button>
                                        </SwiperSlide>
                                        <SwiperSlide key={1}>
                                            <Button className="card" href="/class/811">
                                                <img src={MO_SLIDECARD_03_02} alt="문해력 일기쓰기"/>
                                            </Button>
                                        </SwiperSlide>
                                        <SwiperSlide key={2}>
                                            <Button className="card" href="/search/result?keyword=신문읽기챌린지">
                                                <img src={MO_SLIDECARD_03_03} alt="신문 읽기"/>
                                            </Button>
                                        </SwiperSlide>
                                        <SwiperSlide key={3}>
                                            <Button className="card" href="/class/954">
                                                <img src={MO_SLIDECARD_03_05} alt="독서 토론&논술"/>
                                            </Button>
                                        </SwiperSlide>
                                    </Swiper>

                                    <Button className="btn_all4" href="/search/result?keyword=겨울특강%20단기완성">단기완성</Button>
                                    <Swiper 
                                        slidesPerView={2.6}
                                        className="slide-card-items-list-type shortTerm"
                                        onSwiper={setSwiper}
                                    >
                                        <SwiperSlide key={0}>
                                            <Button className="card" href="/class/777">
                                                <img src={MO_SLIDECARD_04_02} alt="한국사"/>
                                            </Button>
                                        </SwiperSlide>
                                        <SwiperSlide key={1}>
                                            <Button className="card" href="/class/808">
                                                <img src={MO_SLIDECARD_04_03} alt="주산 연산법"/>
                                            </Button>
                                        </SwiperSlide>
                                        <SwiperSlide key={2}>
                                            <Button className="card" href="/search/result?keyword=한자자격증">
                                                <img src={MO_SLIDECARD_04_04} alt="한자 7,8급"/>
                                            </Button>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                                <div className="LinkInWrap p_r">
                                    <img src={MO_WINTERCAMP_05_03} className="txt_me" alt="이벤트 피시 상세" />
                                    <img src={MO_WINTERCAMP_05_04} className="txt_you" alt="이벤트 피시 상세" />
                                    <img src={MO_WINTERCAMP_05} alt="이벤트 모바일 상세" />
                                </div>
                                <div className={notiExpanded ? 'noticeToggle isOn' : 'noticeToggle'} onClick={onNotiExpandClick} dataid={0}></div>
                                <div className={notiExpanded ? 'noticeToggle_info isOn' : 'noticeToggle_info'}>
                                    <img src={MO_WINTERCAMP_05_02} alt="이벤트 모바일 상세" />
                                </div> 
                            </AllMobile>
                        </div>
                    )}
                    {event_seq === 77 && ( // 겨울특강
                        <>
                            <div className="event-detail-img winterEvent">
                                <DesktopAndTablet>
                                    <div className="LinkInWrap p_r">
                                        <img src={PC_WINTER_01} alt="이벤트 피시 상세" />
                                        <img src={PC_WINTERCAMP_01_01} className="fade-element" alt="이벤트 모바일 상세" />
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={PC_WINTERCAMP_03_00} className="coffee1" alt="이벤트 모바일 상세" />
                                        <img src={PC_WINTERCAMP_03_01} className="coffee2" alt="이벤트 모바일 상세" />   
                                        <img src={PC_WINTER_02} alt="이벤트 피시 상세" />
                                        <div className="btn_box">
                                            <Button className="btn_coupon" value={293} onClick={(e) => clickCouponDown(e)}>쿠폰다운로드</Button>
                                            <Button className="btn_coupon" value={294} onClick={(e) => clickCouponDown(e)}>쿠폰다운로드</Button>
                                        </div>
                                    </div>
                                </DesktopAndTablet>
                                <AllMobile>
                                <div className="LinkInWrap p_r">
                                        <img src={MO_WINTER_01} alt="이벤트 피시 상세" />
                                        <img src={MO_WINTERCAMP_01_01} className="fade-element" alt="이벤트 모바일 상세" />
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={MO_WINTERCAMP_03_00} className="coffee1" alt="이벤트 모바일 상세" />
                                        <img src={MO_WINTERCAMP_03_01} className="coffee2" alt="이벤트 모바일 상세" />   
                                        <img src={MO_WINTER_02} alt="이벤트 피시 상세" />
                                        <div className="btn_box">
                                            <Button className="btn_coupon" value={293} onClick={(e) => clickCouponDown(e)}>쿠폰다운로드</Button>
                                            <Button className="btn_coupon" value={294} onClick={(e) => clickCouponDown(e)}>쿠폰다운로드</Button>
                                        </div>
                                    </div>
                                </AllMobile>
                            </div>
                            <div className="recommendClass summerCamp">
                                <div className="breadcrumb">
                                    <span className="depth">홈</span>
                                    <span className="depth">겨울 캠프</span>
                                </div>
                                <div className="tabBox">
                                    <ul className="tabMenu">
                                        <li className={selectKeyword !== 0 ? "" : "on"} value={0} onClick={(e) => handleKeyword(e, "겨울방학")}>ALL</li>
                                        <li className={selectKeyword !== 1 ? "" : "on"} value={1} onClick={(e) => handleKeyword(e, "새학년준비")}>새학년 준비</li>
                                        <li className={selectKeyword !== 2 ? "" : "on"} value={2} onClick={(e) => handleKeyword(e, "영어특강")}>영어 특강</li>
                                        <li className={selectKeyword !== 3 ? "" : "on"} value={3} onClick={(e) => handleKeyword(e, "습관만들기")}>습관 만들기</li>
                                        <li className={selectKeyword !== 4 ? "" : "on"} value={4} onClick={(e) => handleKeyword(e, "방학단기완성")}>방학 단기완성</li>
                                    </ul>
                                </div>
                                <LoadingWrapper status={[classesStatus]}>
                                    {classList?.length > 0 ? (
                                        <ClassCardMap classList={classList} type={'class'} />
                                    ) : (
                                        <div className="table-empty pt30 pb50">
                                            <div className="table-empty-caution">
                                                <div className="title-section">
                                                    <Typography className="title">클래스가 없습니다.</Typography>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </LoadingWrapper>
                            </div>
                        </>
                    )}
                    {event_seq === 85 && (
                        <>
                            <div className="event-detail-img discountClass">
                                <DesktopAndTablet>
                                    <div className="LinkInWrap p_r">
                                        <img src={PC_DISCOUNT_01} alt="이벤트 피시 상세" />
                                        <div className="btnBox">
                                            <Button className="btn l1" href="/search/result?keyword=한글완성" />
                                            <Button className="btn l2" href="/search/result?keyword=취향독서" />
                                            <Button className="btn l3" href="/search/result?keyword=교과독서" />
                                            <Button className="btn l4" href="/search/result?keyword=원어민영어" />
                                            <Button className="btn l5" href="/search/result?keyword=매쓰피드" />
                                            <Button className="btn l6" href="/search/result?keyword=과학실험" />
                                        </div>
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={PC_DISCOUNT_02} alt="이벤트 피시 상세" />
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={PC_DISCOUNT_03} alt="이벤트 피시 상세" />
                                        <div className="vbtnBox">
                                            <Button className="box_btn1" href="/search/result?keyword=한글완성" />
                                            <Button className="box_btn1" href="/search/result?keyword=취향독서" />
                                            <Button className="box_btn1" href="/search/result?keyword=교과독서" />
                                        </div>
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={PC_DISCOUNT_04} alt="이벤트 피시 상세" />
                                        <Button className="box_btn2" href="/search/result?keyword=원어민영어" />
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={PC_DISCOUNT_05} alt="이벤트 피시 상세" />
                                        <Button className="box_btn2" href="/search/result?keyword=매쓰피드" />
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={PC_DISCOUNT_06} alt="이벤트 피시 상세" />
                                        <Button className="box_btn2" href="/search/result?keyword=과학실험" />
                                    </div>
                                </DesktopAndTablet>
                                <AllMobile>
                                    <div className="LinkInWrap p_r">
                                        <img src={MO_DISCOUNT_01} alt="이벤트 모바일 상세" />
                                        <div className="btnBox">
                                            <Button className="btn l1" href="/search/result?keyword=한글완성" />
                                            <Button className="btn l2" href="/search/result?keyword=취향독서" />
                                            <Button className="btn l3" href="/search/result?keyword=교과독서" />
                                            <Button className="btn l4" href="/search/result?keyword=원어민영어" />
                                            <Button className="btn l5" href="/search/result?keyword=매쓰피드" />
                                            <Button className="btn l6" href="/search/result?keyword=과학실험" />
                                        </div>
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={MO_DISCOUNT_02} alt="이벤트 모바일 상세" />
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={MO_DISCOUNT_03} alt="이벤트 모바일 상세" />
                                        <div className="vbtnBox">
                                            <Button className="box_btn1" href="/search/result?keyword=한글완성" />
                                            <Button className="box_btn1" href="/search/result?keyword=취향독서" />
                                            <Button className="box_btn1" href="/search/result?keyword=교과독서" />
                                        </div>
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={MO_DISCOUNT_04} alt="이벤트 모바일 상세" />
                                        <Button className="box_btn2" href="/search/result?keyword=원어민영어" />
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={MO_DISCOUNT_05} alt="이벤트 모바일 상세" />
                                        <Button className="box_btn2" href="/search/result?keyword=매쓰피드" />
                                    </div>
                                    <div className="LinkInWrap p_r">
                                        <img src={MO_DISCOUNT_06} alt="이벤트 모바일 상세" />
                                        <Button className="box_btn2" href="/search/result?keyword=과학실험" />
                                    </div>
                                </AllMobile>
                            </div>
                            <div className="recommendClass">
                                    <div className="breadcrumb">
                                        <span className="depth">홈</span>
                                        <span className="depth">씽크빅클래스</span>
                                    </div>
                                    <div className="tabBox">
                                        <ul className="tabMenu">
                                            <li className={selectKeyword !== 0 ? "" : "on"} value={0} onClick={(e) => handleKeyword(e, "씽크빅클래스")}>ALL</li>
                                            <li className={selectKeyword !== 1 ? "" : "on"} value={1} onClick={(e) => handleKeyword(e, "취향독서클럽")}>취향독서클럽</li>
                                            <li className={selectKeyword !== 2 ? "" : "on"} value={2} onClick={(e) => handleKeyword(e, "교과독서클럽")}>교과독서클럽</li>
                                            <li className={selectKeyword !== 3 ? "" : "on"} value={3} onClick={(e) => handleKeyword(e, "한글완성")}>한글완성</li>
                                            <li className={selectKeyword !== 4 ? "" : "on"} value={4} onClick={(e) => handleKeyword(e, "원어민영어")}>원어민영어</li>
                                            <li className={selectKeyword !== 5 ? "" : "on"} value={5} onClick={(e) => handleKeyword(e, "매쓰피드")}>매쓰피드</li>
                                            <li className={selectKeyword !== 6 ? "" : "on"} value={6} onClick={(e) => handleKeyword(e, "과학실험")}>과학실험</li>
                                        </ul>
                                    </div>
                                    <LoadingWrapper status={[classesStatus]}>
                                        {classList?.length > 0 ? (
                                            <ClassCardMap classList={classList} type={'class'} />
                                        ) : (
                                            <div className="table-empty pt30 pb50">
                                                <div className="table-empty-caution">
                                                    <div className="title-section">
                                                        <Typography className="title">클래스가 없습니다.</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </LoadingWrapper>
                            </div>

                            
                        </>
                    )}
                    {event_seq === 96 && (
                        <>
                            <div className="event-detail-img discountClass">
                                <DesktopAndTablet>
                                    <div className="LinkInWrap p_r">
                                        <img src={EXHIBIT3_02} alt="이벤트 피시 상세" />
                                    </div>
                                </DesktopAndTablet>
                                <AllMobile>
                                    <div className="LinkInWrap p_r">
                                        <img src={EXHIBIT3_02} alt="이벤트 모바일 상세" />
                                    </div>
                                </AllMobile>
                            </div>
                            
                            <div className="recommendClass" ref={scrollRef}>
                            <div className="breadcrumb">
                                        {/* <span className="depth">홈</span>
                                        <span className="depth">씽크빅클래스</span> */}
                                    </div>
                                    <div className='card-layout'>
                                        <h2 className='recommendClassTItle'>요약하고 상상하는 힘! 마인드맵 클래스</h2>
                                    </div>
                                    <div className="tabBox">
                                    </div>
                                    <LoadingWrapper status={[classesStatus]}>
                                        {classList?.length > 0 ? (
                                            <ClassCardMap classList={classList} type={'class'} />
                                        ) : (
                                            <div className="table-empty pt30 pb50">
                                                <div className="table-empty-caution">
                                                    <div className="title-section">
                                                        <Typography className="title">클래스가 없습니다.</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </LoadingWrapper>
                            </div>

                            {existBottomTab &&
                                <div className='bottomTabBox'>
                                    <div
                                        className={`bottomTab-title ${data.event_seq === 96 ? 'colorType96' : ''}`}
                                        onClick={() => { scrollRef?.current?.scrollIntoView({ behavior: 'smooth' }) }}
                                    >
                                        클래스 보러가기
                                    </div>
                                </div>
                            }
                        </>
                    )}

                    {event_seq === 95 && (
                        <>
                            <div className="event-detail-img discountClass">
                                <DesktopAndTablet>
                                    <div className="LinkInWrap p_r">
                                        <img src={EXHIBIT3_04} alt="이벤트 피시 상세" />
                                    </div>
                                </DesktopAndTablet>
                                <AllMobile>
                                    <div className="LinkInWrap p_r">
                                        <img src={EXHIBIT3_04} alt="이벤트 모바일 상세" />
                                    </div>
                                </AllMobile>
                            </div>

                            {existBottomTab &&
                                <div className='bottomTabBox'>
                                    <div
                                        className={`bottomTab-title ${data.event_seq === 95 ? 'colorType95' : ''}`}
                                        onClick={() => { scrollRef?.current?.scrollIntoView({ behavior: 'smooth' }) }}
                                    >
                                        클래스 보러가기
                                    </div>
                                </div>
                            }

                            <div className="recommendClass"  ref={scrollRef}>
                                    <div className="breadcrumb">
                                        {/* <span className="depth">홈</span>
                                        <span className="depth">씽크빅클래스</span> */}
                                    </div>
                                    <div className='card-layout'>
                                        <h2 className='recommendClassTItle'>문해력이 성장하는 신문 NIE 클래스</h2>
                                    </div>
                                    <div className="tabBox">
                                    </div>
                                    <LoadingWrapper status={[classesStatus]}>
                                        {classList?.length > 0 ? (
                                            <ClassCardMap classList={classList} type={'class'} />
                                        ) : (
                                            <div className="table-empty pt30 pb50">
                                                <div className="table-empty-caution">
                                                    <div className="title-section">
                                                        <Typography className="title">클래스가 없습니다.</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </LoadingWrapper>
                            </div>
                        </>
                    )}
                     {event_seq === 97 && (
                        <>
                            <div className="event-detail-img discountClass">
                                <DesktopAndTablet>
                                    <div className='imgBox'>
                                        <div>
                                            <button className='btn-type1' value={439} onClick={(e) => clickCouponDown(e)} />
                                        </div>
                                        <div className='flexBox'>
                                            <div>
                                                <button className='btn-type2' onClick={() => {window.location.href = "/event/96"}} /> 
                                            </div>
                                            <div>
                                                <button className='btn-type3' onClick={() => {window.location.href = "/event/95"}} />
                                            </div>
                                            <div>
                                                <button className='btn-type4' onClick={() => {window.location.href = "/event/94"}} />
                                            </div>
                                        </div>
                                        <div>
                                            <button className='btn-type5' onClick={() => {window.location.href = "/event/96"}} />
                                        </div>
                                        <div>
                                            <button className='btn-type6'onClick={() => {window.location.href = "/event/95"}} />
                                        </div>
                                        <div>
                                            <button className='btn-type7' onClick={() => {window.location.href = "/event/94"}} />
                                        </div>
                                            <img src={EXHIBIT3_01} alt="이벤트 피시 상세" />    
                                    </div>
                                </DesktopAndTablet> 
                                <AllMobile>
                                        <div className='imgBox'>
                                            <div>
                                                <button className='btn-type1' value={293} onClick={(e) => clickCouponDown(e)} />
                                            </div>
                                            <div className='flexBox'>
                                                <div>
                                                    <button className='btn-type2' onClick={() => {window.location.href = "/event/96"}} /> 
                                                </div>
                                                <div>
                                                    <button className='btn-type3' onClick={() => {window.location.href = "/event/95"}} />
                                                </div>
                                                <div>
                                                    <button className='btn-type4' onClick={() => {window.location.href = "/event/94"}} />
                                                </div>
                                            </div>
                                            <div>
                                                <button className='btn-type5' onClick={() => {window.location.href = "/event/96"}} />
                                            </div>
                                            <div>
                                                <button className='btn-type6'onClick={() => {window.location.href = "/event/95"}} />
                                            </div>
                                            <div>
                                                <button className='btn-type7' onClick={() => {window.location.href = "/event/94"}} />
                                            </div>
                                                <img src={EXHIBIT3_01} alt="이벤트 피시 상세" />    
                                        </div>
                                </AllMobile>
                            </div>
                            <div className="recommendClass">
                                    <div className="breadcrumb">
                                        {/* <span className="depth">홈</span>
                                        <span className="depth">씽크빅클래스</span> */}
                                    </div>
                                    <div className="tabBox">
                                        <ul className="tabMenu">
                                            <li className={selectKeyword !== 0 ? "" : "on"} value={0} onClick={(e) => handleKeyword(e, "3월 브랜드 기획전")}>ALL</li>
                                            <li className={selectKeyword !== 1 ? "" : "on"} value={1} onClick={(e) => handleKeyword(e, "공부발전소")}>공부발전소</li>
                                            <li className={selectKeyword !== 2 ? "" : "on"} value={2} onClick={(e) => handleKeyword(e, "주니어생글생글")}>주니어생글생글</li>
                                            <li className={selectKeyword !== 3 ? "" : "on"} value={3} onClick={(e) => handleKeyword(e, "매쓰몽 플러스")}>매쓰몽플러스</li>
                                        </ul>
                                    </div>
                                    <LoadingWrapper status={[classesStatus]}>
                                        {classList?.length > 0 ? (
                                            <ClassCardMap classList={classList} type={'class'} />
                                        ) : (
                                            <div className="table-empty pt30 pb50">
                                                <div className="table-empty-caution">
                                                    <div className="title-section">
                                                        <Typography className="title">클래스가 없습니다.</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </LoadingWrapper>
                            </div>
                        </>
                    )}
                    {/* TODO : 241삭제 예정 (test중) */}
                    {event_seq === 241 && ( 
                        <>
                            <div className="event-detail-img-relayive">
                                <img src={random_coupon_event104}/>
                                <div className='event-detail-abs-btn'>
                                    <button onClick={onDownloadCoupons}>test</button>
                                </div>
                            </div>
                        </>
                    )}
                     {event_seq === 104 && (
                        <>
                            <div className="event-detail-img-relayive">
                                <img src={random_coupon_event104}/>
                                <div className='event-detail-abs-btn'>
                                    <button onClick={onDownloadCoupons}>test</button>
                                </div>
                            </div>
                        </>
                    )}
                </>
            ) : (
                <>
                    {event_seq === 94 ? (
                        <>
                            <div className="event-detail-img discountClass">
                                <DesktopAndTablet>
                                    <div className="LinkInWrap p_r">
                                        <img src={EXHIBIT3_03} alt="이벤트 피시 상세" />
                                    </div>
                                </DesktopAndTablet>
                                <AllMobile>
                                    <div className="LinkInWrap p_r">
                                        <img src={EXHIBIT3_03} alt="이벤트 모바일 상세" />
                                    </div>
                                </AllMobile>
                            </div>

                            {existBottomTab &&
                                <div className='bottomTabBox'>
                                    <div
                                        className={`bottomTab-title ${data.event_seq === 94 ? 'colorType94' : ''}`}
                                        onClick={() => { scrollRef?.current?.scrollIntoView({ behavior: 'smooth' }) }}
                                    >
                                        클래스 보러가기
                                    </div>
                                </div>
                            }

                            <div className="recommendClass"  ref={scrollRef}>
                                    <div className="breadcrumb"></div>
                                    <div className='card-layout'>
                                        <h2 className='recommendClassTItle'>수학적 문해력 UP! 수학 동화 클래스</h2>
                                    </div>
                                    <div className="tabBox" />
                                    <LoadingWrapper status={[classesStatus]}>
                                        {classList?.length > 0 ? (
                                            <ClassCardMap classList={classList} type={'class'} />
                                        ) : (
                                            <div className="table-empty pt30 pb50">
                                                <div className="table-empty-caution">
                                                    <div className="title-section">
                                                        <Typography className="title">클래스가 없습니다.</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </LoadingWrapper>
                            </div>
                        </>
                    ) :
                    <>
                        <DesktopAndTablet>
                            <InnerHTML html={data ? data.event_content_html : ''} />
                        </DesktopAndTablet>
                        <AllMobile>
                            <InnerHTML html={data ? data.event_content_mo_html : ''} />
                        </AllMobile>
                        </>
                    }
                </>
            )}
        </div>
    );
}

export default React.memo(EventDetailContent);
