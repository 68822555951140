import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router';

function ClassTitle({ data }) {
  const navigate = useNavigate();
  return (
    <Typography
      variant="h3"
      component="h3"
      className="class-name"
      style={{ font: "20px/1.3 NotoSansBold", border: 0, padding: 0, margin: "10px 0 20px" }}
      onClick={() => data.class_type === 9 ? navigate(`/togetherClass/${data.class_seq}${data.test_mode_yn ? "/hidden" : ""}`) : navigate(`/class/${data.class_seq}${data.test_mode_yn ? "/hidden" : ""}`)}
    >
      {data.class_name}
    </Typography>
  );
}

export default React.memo(ClassTitle);
