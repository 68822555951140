import * as constants from 'common/constants';

import axios from 'axios';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useLoginContext } from 'common/context';
import useLogout from './useLogout';
import { useNavigate } from 'react-router';
import usePopAlert from 'components/popAlert/hooks/usePopAlert';

export const METHOD = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete',
    PATCH: 'patch',
};

const headers = {
    Accept: 'application/json',
    Authorization: '',
};

function useAxios() {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { showAlert } = usePopAlert();
    const { loginState, action } = useLoginContext();
    const { logout } = useLogout();

    const client = axios.create();

    client.interceptors.request.use((config) => {
        const accessToken = localStorage.getItem('accessToken')
        if(config.headers.Authorization == 'Bearer ') {
            config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
        return config
    })

    client.interceptors.response.use((response) => {
        if (response && response.data && response.data.newToken) {
            action.setAccessToken(response.data.newToken);
            localStorage.setItem('accessToken', response.data.newToken);
        }

        return response;
    }, (err) => {
        // console.log(err.response);
        if (err.response && err.response.status && (err.response.status == 401 || err.response.status == 419)) {
            logout();
            showAlert('알림', '더 이상 로그인 정보가 유효하지 않습니다.\n다시 로그인하세요', {
                confirmHandler: () => {
                    sessionStorage.setItem('login_callback', pathname);
                    navigate('/login');
                },
                cancelHandler: () => {
                    sessionStorage.setItem('login_callback', pathname);
                    navigate('/login');
                },
            });
        }
            return Promise.reject(err);
    });

    const request = useCallback((method, url, args, isBlob = false) => {
        let params = {};
        if (method === METHOD.GET) {
            params = { params: args };
        } else {
            params = { data: args };
        }
        if (!args) {
            params = {};
        }
        if (isBlob) {
            params = { ...params, responseType: 'blob' };
        }

        const axiosConfig = {
            headers: {...headers, Authorization: `Bearer ${loginState.accessToken}`},
            method,
            url,
            timeout: 30000,
            ...params,
        };

        // console.info('axios config:', axiosConfig);
        return client(axiosConfig);
    }, [loginState.accessToken]);

    const requestFunc = useCallback(async (method, url, args, isBlob = false) => {
        
        const response = await request(method, `${constants.API_BASE_URL}${url}`, args, isBlob);
        return response.data;
    }, [request]);

    return { requestFunc };
}

export default useAxios;
