import React, { useEffect,useState } from "react";
import { useLocation } from "react-router";

const useDelayRender = (delay = 1000) => {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handler = setTimeout(() => {
      setIsVisible(true);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [location]);

  return isVisible;
};

export default useDelayRender;
