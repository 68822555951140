import React, { useState } from 'react';
import CardMedia from '@mui/material/CardMedia';
import { Typography } from '@mui/material';
import { DOMAIN } from 'common/constants';

function ClassCardThumnail({ data, index, showRank = false }) {
  return (
    <>
      <CardMedia
        component="img"
        src={data.thumbnail_image_url}
        alt={data.class_name}
      />
      {/* {showRank && (
        <div className="raking-label">
          <Typography>{index + 1}</Typography>
        </div>
      )} */}
    </>
  );
}

export default React.memo(ClassCardThumnail);
