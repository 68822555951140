import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import 'assets/css/detail/dropButton.css';

// DropButton.js

function DropButton({ expand, handleItemExpand, index }) {
    return (
        <div className={expand ? 'arrowIcon isOn' : 'arrowIcon'} dataid={index} onClick={() => handleItemExpand(index)}>
            <ArrowForwardIosIcon />
        </div>
    );
}

export default React.memo(DropButton);
