import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import SubItem from './SubItem'; 
import EventItem from './EventItem';
import MagazineItem from './MagazineItem';

const OPTIONS = [
    { value: 0, name: '전체 이벤트', label: '전체 이벤트' },
    { value: 1, name: '진행중 이벤트', label: '진행중 이벤트' },
    { value: 2, name: '종료 이벤트', label: '종료 이벤트' },
];

// SubDetailList.js

function SubItemList({ dataList, type, eventType, handleChange }) {
    const getItemType = useCallback((data, index) => {
        if (type === 'event') {
            return <EventItem key={data.event_seq} data={data} />;
        } else if (type === 'magazine') {
            return <MagazineItem key={data.magazine_seq} data={data} />;
        } else {
            return <SubItem key={index} data={data} />;
        }
    }, [type]);

    return (
        <div className="event-Container sub-Container">
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {dataList.map((data, index) => getItemType(data, index))}
            </Box>
        </div>
    );
}

export default React.memo(SubItemList);
