import React, { useState } from 'react';
import { getStarText } from 'common/utils';
import { star } from 'assets/images/icon';

function TeacherInfoRate({ data, classStar }) {
    const { teacher_info } = data;
    if (!teacher_info && !classStar) {
        return null;
    }

    return (
        <div className="teacher-rate">
            <img src={star} alt="별모양 이미지" />
            <p>{teacher_info ? getStarText(teacher_info.star) : getStarText(classStar)}</p>
        </div>
    );
}

export default React.memo(TeacherInfoRate);
