import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function ClassTableThumnail({ data }) {
    let linkTo = '';
    if(data.class_type === 9) {
      linkTo = data.test_mode_yn ? `/togetherClass/${data.class_seq}/hidden` : `/togetherClass/${data.class_seq}`;  
    } else {
      linkTo = data.test_mode_yn ? `/class/${data.class_seq}/hidden` : `/class/${data.class_seq}`;
    }
      
  return (
    <Link to={linkTo} className="orderInfo-img myImg-m">
      <img src={data.thumbnail_image_url} alt="수업 이미지"></img>
    </Link>
  );
}

export default React.memo(ClassTableThumnail);
