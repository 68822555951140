import React, { useState } from 'react';
import 'assets/css/style.scss';

function TagList({ data }) {
    return (
        <ul className="tag-list">
            {data.isOneDay && <li className="tag-oneday">원데이</li>}
            {data.isPackage && <li className="tag-package">패키지</li>}
            {data.class_type === 9 && <li className="tag-together">투게더</li>}
            {data.isSubscribe && <li className="tag-subscribe">구독</li>}
            {data.isSupplementary && <li className="tag-makeup">보강</li>}
            {data.all_age_yn !== 0 && <li className="tag-age">{data.all_age_yn === 1 ? "전연령" : "학부모"}</li>}
            {data.all_age_yn === 0 && (data.min_target_age !== 0 && data.max_target_age !== 0) && (
                data.min_target_age === data.max_target_age ? (
                    <li className="tag-age">{data.max_target_age}세</li>
                ) : (
                    <li className="tag-age">{data.min_target_age}~{data.max_target_age}세</li>
                ))}
        </ul>
    );
}

export default React.memo(TagList);
