import { createContext, useState, useCallback } from 'react';
import { getUseContext } from 'common/utils';

const initialData = {
    state: {
        loading: false,
    },
    action: {
        showLoading: () => {},
        hideLoading: () => {},
    },
};

const SpinnerContext = createContext();

function SpinnerProvider({ children }) {
    const [state, setState] = useState(initialData.state);

    const showLoading = useCallback(() => {
        setTimeout(() => {
            setState(() => ({ loading: false }));
        }, 1000*13);
        setState(() => ({ loading: true }));
    }, []);

    const hideLoading = useCallback(() => {
        setState(() => ({ loading: false }));
    }, []);

    const value = {
        state,
        action: { showLoading, hideLoading },
    };

    return (
        <SpinnerContext.Provider value={value}>{children}</SpinnerContext.Provider>
    );
}

export const useSpinnerContext = () => getUseContext(SpinnerContext);
export default SpinnerProvider;
