import React, { useState } from 'react';
import {
  Typography,
  Grid,
} from '@mui/material';
import Star from 'components/star/Star';
import CardContent from '@mui/material/CardContent';
import detailData from 'assets/data/class-detail.json'; //상세 전체 데이터
import { getHiddenNameText, getStarText } from 'common/utils';
import { getAge } from 'common/utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Scrollbar,
  Navigation,
  Autoplay,
  Pagination,
} from 'swiper';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import {
  ClassCardThumnail,
  TagList,
  TeacherInfoItem,
  PriceInfoItem,
  ClassTitle,
} from 'components/items';

import { reviewBig1, review5 } from 'assets/images/detail/test';

import { Mobile } from '../utils/MediaQuery';
import ClassTableThumnail from 'components/items/ClassTableThumnail';
// import 'assets/css/pages/classDDetail.scss';
import { useInput } from 'common/hooks';
import moment from 'moment';
import 'moment/locale/ko';

function ClassReviewShow({ review }) {
  SwiperCore.use([Scrollbar, Navigation, Autoplay]); // swiper slide
  const [swiper, setSwiper] = React.useState();
  const prevRef = React.useRef();
  const nextRef = React.useRef();
  React.useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  return (
    <>
      <div className="classReview-show-wrap">
        <div className=" reviewShow_wrap">
          <Swiper
            slidesPerView={1}
            modules={[Pagination]}
            // autoplay={{ delay: 3000 }}
            className="slide-card-items-list-type swiper-pagination-type-2"
            onSwiper={setSwiper}
            pagination={{
              clickable: true,
            }}
            // autoplay={{ delay: 2000 }}
          >
            {review.photos.map((item) => (
              <SwiperSlide key={item.image_seq}>
                <img src={item.file_url} alt="img01" />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="seen-classInfo">
            <Grid item className="classReview-info-wrap">
              <div className="classReview-info-img">
                <ClassTableThumnail data={review.classInfo || review.class} />
              </div>
              <CardContent className="card-content-wrap">
                <div className="content-top">
                  <TagList data={review.classInfo || review.class} />
                </div>
                <ClassTitle data={review.classInfo || review.class} />
                <div className="content-bottom">
                  <TeacherInfoItem
                    data={review.classInfo || review.class}
                    showImage={false}
                    showScore={false}
                  />
                </div>
              </CardContent>
            </Grid>
            <div className="main-grid">
              <div>
                <Star star={review.star} />
                <span>{getStarText(review.star)}</span>
              </div>
              <div className="student-info">
                {getHiddenNameText(review.user_name)} |{' '}
                {`${getAge(review.birth)}`}세 | {review.gender_type_name} |{' '}
                {moment(review.created_dt).format('YYYY-MM-DD')}
              </div>
            </div>
            <p className="txt_gray fs14">
              수업일 :{' '}
              {review?.schedule_date
                ? moment(review.schedule_date).format('YYYY-MM-DD (dd) A hh:mm')
                : ''}
            </p>
            <Typography>{review?.review || ''}</Typography>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(ClassReviewShow);
