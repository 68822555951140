import React, { useEffect, useState } from 'react';

function LoadingWrapper({ status, children }) {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    
    useEffect(() => {
        return () => setIsLoading(false); 
    }, []);

    useEffect(() => {
        if (isFinished) {
            return;
        }

        let loading = false;
        status.forEach((item) => {
            loading = item.isFetching || item.isRefetching ? true : loading;
        });

        setIsLoading(loading);

        if (loading) {
            setIsLoaded(true);
        }
    }, [status, isFinished]);

    useEffect(() => {
        if (isLoaded && !isLoading) {
            setIsFinished(true);
        }
    }, [isLoading, isLoaded]);



    return isLoading ? null : children;
}

export default React.memo(LoadingWrapper);
