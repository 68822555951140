import React, { useState } from 'react';

function TeacherInfoPic({ data }) {
  const { teacher_info } = data;
  if (!teacher_info) {
    return null;
  }
  return <p>{data.teacher_info.teacher_nickname} 선생님</p>;
}

export default React.memo(TeacherInfoPic);
