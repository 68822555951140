import React, { useMemo } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router';
import moment from 'moment';
import 'assets/css/index/sub-list.css'; // 매거진 & 이벤트 페이지 컨텐츠 리스트 css

// MagazineItem.js

function MagazineItem({ data }) {
  const navigate = useNavigate();

  const onClick = () => {
    // console.log(data);
    navigate(`${data.magazine_seq}`);
  };

  const createdDateText = useMemo(() => {
    return moment(data.exposure_dt).format('YYYY-MM-DD');
  }, [data.exposure_dt]);

  return (
    <>
      <Card sx={{ width: '47.4%' }} className="sub-item-root" onClick={onClick}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="194"
            src={data.sub_image_url}
            alt={data.magazine_title}
          />
        </CardActionArea>
        <CardContent className="sub-item-text">
          <div className="class-name">
            <h1>{data.magazine_title}</h1>
          </div>
          <div className="event-period">
            작성일 {createdDateText} {/* {data.start_date}~{data.end_date} */}
          </div>
        </CardContent>
      </Card>
    </>
  );
}

export default React.memo(MagazineItem);
