import React, { useState, useEffect, useRef, useCallback } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import 'assets/css/calender/calender.scss';

// calender.js

function Calendar({ events, handleDateSelect, scheduleTimes, resetValue }) {
  const calendarRef = useRef();

  const handleDateClick = useCallback((args) => {
      const elems = document.querySelectorAll('.fc-daygrid-day.fc-day');
      if (!args.dayEl.classList.contains('fc-day-other') && args.dayEl.classList.contains('event-calender-item')) {
        elems.forEach((elem) => {
          if (elem.classList.contains('active')) {
            elem.classList.remove('active');
          }
          if (elem.classList.contains('active2')) {
            elem.classList.remove('active2');
          }
        });
        args.dayEl.classList.add('active');
        if (handleDateSelect) {
          handleDateSelect(args.dateStr);
        }
      }
  },[handleDateSelect]);

  const updateScheduleTimes = useCallback(() => {
    if (!scheduleTimes || scheduleTimes.length === 0) {
      return;
    }

    const elems = document.querySelectorAll('.fc-daygrid-day.fc-day');
    elems.forEach((elem) => {
      const findElem = elem.querySelector('.fc-daygrid-day-number');
      const attributes = findElem?.attributes;
      if (attributes && attributes['aria-label']) {
        let dateValue = attributes['aria-label'].value;
        dateValue = dateValue.replaceAll('년', '-');
        dateValue = dateValue.replaceAll('월', '-');
        dateValue = dateValue.replaceAll('일', '');
        dateValue = moment(dateValue).format('YYYYMMDD');

        const target = scheduleTimes.find(
          (data) => data.schedule_date === dateValue,
        );
        if (target) {
          elem.classList.add('active');
          if (scheduleTimes[0] === target) {
            elem.classList.add('active2');
          }
        } else {
          if (elem.classList.contains('active')) {
            elem.classList.remove('active');
          }
          if (elem.classList.contains('active2')) {
            elem.classList.remove('active2');
          }
        }
      }
    });
  }, [scheduleTimes]);

  useEffect(() => {
    if (!resetValue) {
      return;
    }

    const elems = document.querySelectorAll('.fc-daygrid-day.fc-day');
    elems.forEach((elem) => {
      elem.classList.remove('active');
    });
  }, [resetValue]);

  useEffect(() => {
    updateScheduleTimes();

    if (scheduleTimes?.length > 0) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(scheduleTimes[0].schedule_date);
    }
  }, [scheduleTimes]);

  const onPrevClick = useCallback(() => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
  }, [scheduleTimes]);

  const onNextClick = useCallback(() => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.next();
  }, [scheduleTimes]);

  const onDatesChange = useCallback(() => {
    updateScheduleTimes();
  }, [updateScheduleTimes]);

  useEffect(() => {
    // console.log(events);
    const elems = document.querySelectorAll('.fc-daygrid-day.fc-day');
    elems.forEach((elem) => {
      if (elem.classList.contains('event-calender-item')) {
        elem.classList.remove('event-calender-item');
      }
    });
  }, [events]);

  return (
    <>
      <FullCalendar
        ref={calendarRef}
        customButtons={{
          prevButton: {
            icon: 'chevron-left',
            click: onPrevClick,
          },
          nextButton: {
            icon: 'chevron-right',
            click: onNextClick,
          },
        }}
        plugins={[dayGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: 'prevButton',
          center: 'title',
          right: 'next',
        }}
        height={360}
        locale="ko"
        showNonCurrentDates={false}
        observeChanges={false}
        initialView="dayGridMonth"
        dateClick={handleDateClick}
        allDaySlot={true}
        events={events}
        datesSet={onDatesChange}
        // onEventAdded={(event) => onEventAdded(event)}
        // eventRender={(event, element) => {
        //     if (event.className == 'booked') {
        //         element.css({
        //             'background-color': '#333333',
        //             'border-color': '#333333',
        //         });
        //     }
        // }}
        eventContent={function (event) {
          return <div className="event-contents"></div>;
        }}
        eventWillUnmount={function (event) {}}
        eventDidMount={function (event) {
          // console.log(event);
          const eventContetns = document.querySelectorAll('.event-contents');
          eventContetns.forEach((element, key, parent) => {
            element.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.classList.add(
              'event-calender-item',
            );
          });
        }}
        dayCellContent={(args) => {
          return moment(args.date).format('D');
        }}
      />
    </>
  );
}

export default Calendar;
