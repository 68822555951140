import React from 'react';
import { nolist } from 'assets/images/calender';

function NOTLIST(props) {
  return (
    <div className="no-list">
      {/* <img src={nolist} /> */}
      <p>신청한 클래스가 없습니다.</p>
    </div>
  );
}

export default React.memo(NOTLIST);
