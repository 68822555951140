import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import 'assets/css/components/modal.scss';

function ModalType3(props) {
  const {
    children,
    title,
    buttonCancel,
    buttonnottoday,
    handleClose,
    handleNotToday,
  } = props;
  return (
    <>
      <div>
        <Typography variant="h4" component="h4" className="popTitle">
          {title || ''}
        </Typography>
        <div className="popContents">
          {React.cloneElement(children, { ...props })}
            <div className="main-pop-bottom">
              {buttonnottoday && (
                <Button
                  className="pop-button"
                  onClick={handleNotToday}
                >
                  {buttonnottoday}
                </Button>
              )}
              {buttonCancel && (
                <Button
                  className="pop-button"
                  onClick={handleClose}
                >
                  {buttonCancel}
                </Button>
              )}
            </div>
        </div>
       
      </div>
    </>
  );
}

export default React.memo(ModalType3);
