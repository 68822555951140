import React, { useState, useEffect, useRef, useMemo } from "react";
import { NavLink, useLocation, Link } from "react-router-dom";

import { useAppSettings } from "common/hooks";

import "swiper/css";
import "assets/css/layouts/gnb.scss";
import MobileCategory from "./MobileCategory";
import DesktopCategory from "./DesktopCategory";

// gnb.js

function Gnb({ cate, menu, top }) {
  const { pathname } = useLocation();
  const [currentLink, setCurrentLink] = useState("");
  const [swiper, setSwiper] = useState(null);

  const [categortView, setCategoryView] = useState(false);
  const [subCategoryView, setSubCategoryView] = useState(false);

  useEffect(() => {
    const url = document.location.href;
    const splitUrl = url.split("/");
    const location = splitUrl[splitUrl.length - 1];
    setCurrentLink(location);
    setCategoryView(false);
  }, [pathname]);

  useEffect(() => {
    if (currentLink !== "") {
      setTimeout(() => {
        window.ENP_VAR = {};
      }, 1000);
    }
  }, [currentLink]);
  /* 2022-08-08 끝 */

  const swiperParams = {
    onSwiper: setSwiper,
    slidesPerView: "auto",
  };

  const categorySwitch = () => {
    if (categortView) {
      setSubCategoryView(false);
      setCategoryView(false);
    } else {
      setCategoryView(true);
    }
  };

  const subCategorySwith = () => {
    setSubCategoryView(true);
  };

  return (
    <>
      <DesktopCategory
        cate={cate}
        categoryView={categortView}
        categorySwitch={categorySwitch}
        subCategoryView={subCategoryView}
        subCategorySwith={subCategorySwith}
        swiperParams={swiperParams}
        setSwiper={setSwiper}
        top={top}
        menu={menu}
      />
      <MobileCategory
        cate={cate}
        categoryView={categortView}
        categorySwitch={categorySwitch}
        subCategoryView={subCategoryView}
        subCategorySwith={subCategorySwith}
        swiperParams={swiperParams}
        setSwiper={setSwiper}
        top={top}
        menu={menu}
      />
    </>
  );
}
export default React.memo(Gnb);
