import { METHOD } from 'common/hooks';

export const couponApi = (requestFunc) => {
  return {
    usableCoupons: {
      key: 'usableCoupons',
      queryFn: (params) =>
        requestFunc(METHOD.GET, `/coupons?isUsable=true`, params),
    },
    usedCoupons: {
      key: 'usedCoupons',
      queryFn: (params) =>
        requestFunc(METHOD.GET, `/coupons?isUsable=false`, params),
    },
    registCoupon: {
      key: 'registCoupon',
      queryFn: (params) => requestFunc(METHOD.POST, `/coupons`, params),
    },
  };
};
