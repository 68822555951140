import React, { useCallback, useState, useRef, useMemo } from 'react';
import { BasicPagination } from 'components/utils';
import { CommentList, Comment } from 'components/items';
import { getAge } from 'common/utils';
import { useLoginContext } from 'common/context/MemberContext';
import usePopAlert from 'components/popAlert/hooks/usePopAlert';
import { Desktop } from 'components/utils/MediaQuery';
import ListEmpty from 'pages/listEmpty/ListEmpty';
import useComments from './hooks/useComments';

// CommentBox.js

function CommentBox({
  classInfo,
  isEditable,
  classroomSeq,
  classmateSeq,
  user,
}) {
  const {
    comments,
    paginationInput,
    handleRegist,
    handleReply,
    handleUpdate,
    handleDelete,
  } = useComments({ classInfo, classroomSeq, classmateSeq, user });

  if (!user) {
    return null;
  }

  return (
    <div className="commentBox-wrap">
      {isEditable && (
        <div className="classFeedback-inner">
          <div className="commentBox-top">
            <Desktop>
              <div className="comment-top">
                {/* <div className="avatar"></div> */}
                <img src={user.profile_image_url} className="avatar" />
                <h1 className="name">
                  {user.user_name}({getAge(user.birth)}세)
                </h1>
              </div>
            </Desktop>
            <Comment user={user} handleRegist={handleRegist} isNew />
          </div>
        </div>
      )}

      {comments && comments.length > 0 ? (
        <>
          <ul className="commentBox-list">
            {comments.map((comment, index) => (
              <CommentList
                key={index}
                user={user}
                comment={comment}
                // clickHandler={clickHandler}
                handleRegist={handleRegist}
                handleReply={handleReply}
                handleUpdate={handleUpdate}
                handleDelete={handleDelete}
              />
            ))}
          </ul>
          <BasicPagination
            page={paginationInput.page}
            count={paginationInput.pageCount}
            onChange={paginationInput.onPageChange}
          />
        </>
      ) : (
        <ListEmpty desc="작성한 피드백 내역이 없습니다." />
      )}
    </div>
  );
}

export default React.memo(CommentBox);
