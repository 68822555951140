import React, { useState, useCallback, useReducer, useMemo } from 'react';
import moment from 'moment';
import produce from 'immer';

function reducer(state, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SELECT':
        return action.value;
      case 'RESET':
        return action.initialData;
      default:
        return state;
    }
  });
}

function useDatePicker(initialData) {
  const [state, dispatch] = useReducer(reducer, initialData);

  const select = useCallback(
    (value) => {
      if (state === value) {
        return;
      }

      dispatch({
        type: 'SELECT',
        value,
      });
    },
    [state],
  );

  const selectByPeriod = useCallback(
    (period) => {
      let dateText = moment().format('YYYY-MM-DD');
      switch (period) {
        case 0: //1개울
          dateText = moment().subtract(1, 'month').format('YYYY-MM-DD');
          break;
        case 1: //3개월
          dateText = moment().subtract(3, 'month').format('YYYY-MM-DD');
          break;
        case 2: //6개월
          dateText = moment().subtract(6, 'month').format('YYYY-MM-DD');
          break;
        case 3: //1년
          dateText = moment().subtract(1, 'year').format('YYYY-MM-DD');
          break;
        default:
          break;
      }

      select(dateText);
    },
    [select],
  );

  const selectByPeriodAfter = useCallback(
    (period) => {
      let dateText = moment().format('YYYY-MM-DD');
      switch (period) {
        case 0: //1개울
          dateText = moment().add(1, 'month').format('YYYY-MM-DD');
          break;
        case 1: //3개월
          dateText = moment().add(3, 'month').format('YYYY-MM-DD');
          break;
        case 2: //6개월
          dateText = moment().add(6, 'month').format('YYYY-MM-DD');
          break;
        case 3: //1년
          dateText = moment().add(1, 'year').format('YYYY-MM-DD');
          break;
        default:
          break;
      }

      select(dateText);
    },
    [select],
  );

  const handleChange = useCallback(
    (value) => {
      // console.log('handle change...', value);

      const date = moment(value).format('YYYY-MM-DD');
      select(date);
    },
    [select],
  );

  const reset = useCallback(() => {
    dispatch({
      type: 'RESET',
      initialData,
    });
  }, [initialData]);

  return {
    state,
    handleChange,
    select,
    selectByPeriod,
    selectByPeriodAfter,
    reset,
  };
}

export default useDatePicker;
