import React from 'react';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import 'assets/css/index/sub-list.css'; // 매거진 & 이벤트 페이지 컨텐츠 리스트 css

// SubItem.js

function SubItem({ data }) {
  const { id, thumbnail_image_url, class_name, start_date, end_date } = data;

  return (
    <>
      <Card sx={{ width: '47.4%' }} className="sub-item-root">
        <Link to={`${id}`}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="194"
              src={thumbnail_image_url}
              alt={class_name}
            />
          </CardActionArea>
          <CardContent>
            <div className="class-name">
              <h1>{class_name}</h1>
            </div>
            <div className="event-period">
              작성일 {start_date}~{end_date}
            </div>
          </CardContent>
        </Link>
      </Card>
    </>
  );
}

export default React.memo(SubItem);
