import React from 'react';
import { Oval } from 'react-loader-spinner';
import { useSpinnerContext } from './context/SpinnerContext';

function Spinner({ layout }) {
    const { state } = useSpinnerContext();

    if (!state.loading) {
        return null;
    }

    const style = {
        left: '50%',
        top: '50%',
        position: 'fixed',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
    };

    return (
        <div style={layout ? layout : style}>
            <Oval
                ariaLabel="loading-indicator"
                height={100}
                width={100}
                strokeWidth={2}
                color="black"
                secondaryColor="gray"
            />
        </div>
    );
}

export default React.memo(Spinner);

// documentation
// https://mhnpd.github.io/react-loader-spinner/
