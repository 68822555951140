import React, { useState, useCallback, useEffect } from 'react';
import { Typography } from '@mui/material';
import { BasicPagination, DropButton } from 'components/utils';
import useDropButton from 'components/utils/hooks/useDropButton';
import usePopAlert from 'components/popAlert/hooks/usePopAlert';
import { useLoginContext } from 'common/context';
import useModalWrapper from 'components/modal/hooks/useModalWrapper';
import { ClassInquiryCreate } from 'components/items';
import { useComboBox, useInput, useLoginPopup } from 'common/hooks';
import { Desktop } from 'components/utils/MediaQuery';
import { ModalWrap, ModalType1 } from '../modal';
// import 'assets/css/detail/classInquiry.css';

import 'assets/css/pages/classInquiry.scss';
import useClassInquiry from './hooks/useClassInquiry';
import ClassInquiryItem from './ClassInquiryItem';

// ClassInquiry.js

function ClassInquiry({
  classData,
  teacherData,
  updateInquiryCount,
  enableRegist = true,
}) {
  const {
    inquires,
    orderUser,
    paginationInput,
    requestRegistInquiry,
    requestUpdateInquiry,
    requestDeleteInquiry
  } = useClassInquiry(
    classData
      ? { classSeq: classData.class_seq }
      : { teacherSeq: teacherData.teacher_seq },
  );
  const { checkLogin } = useLoginPopup();
  const { loginState, isLogin } = useLoginContext();
  const { showAlert, showConfirm } = usePopAlert();
  const dropButtonHandler = useDropButton();
  const inquiryType = useComboBox(-1);
  const inquiryContract = useComboBox(-1);
  const modalInquiryName = useInput('', 35);
  const modalInquiryContent = useInput('', 2000);
  const registModal = useModalWrapper();

  useEffect(() => {
    updateInquiryCount(paginationInput.totalCount);
  }, [paginationInput.totalCount]);

  const handleRegist = useCallback(
    async (e) => {
      if (!classData) {
        return;
      }

      if(classData.class_type === 9 && inquiryType.state === -1) {
        showAlert('알림', '문의 유형을 선택해주세요.');
        return;
      }

      if(classData.class_type === 9 && inquiryType.state === 3 && inquiryContract.state === -1) {
        showAlert('알림', '결제 정보를 입력해주세요.');
        return;
      }

      if (modalInquiryName.state.length === 0) {
        showAlert('알림', '제목을 입력해주세요.');
        return;
      }
      if (modalInquiryContent.state.length === 0) {
        showAlert('알림', '내용을 입력해주세요.');
        return;
      }

      const res = await requestRegistInquiry({
        class_seq: classData.class_seq,
        category_seq: inquiryType.state,
        order_seq: inquiryContract.state,
        subject: modalInquiryName.state,
        content: modalInquiryContent.state,
      });

      if (res.result_code === '0000') {
        showAlert('알림', '클래스 문의가 등록되었습니다.', {
          confirmHandler: () => registModal.handleClose(e),
          cancelHandler: () => registModal.handleClose(e),
        });
      }
    },
    [
      requestRegistInquiry,
      classData,
      inquiryType.state,
      inquiryContract.state,
      modalInquiryName.state,
      modalInquiryContent.state,
      registModal,
      showAlert,
    ],
  );

  const onRegistClick = useCallback(() => {
    if (checkLogin()) {
      return;
    }

    inquiryType.reset();
    inquiryContract.reset();
    modalInquiryName.reset();
    modalInquiryContent.reset();
    registModal.handleOpen();
  }, [modalInquiryName, modalInquiryContent, registModal]);

  const onDeleteClick = useCallback(
    (inquirySeq) => {
      showConfirm('알림', '클래스 문의를 삭제하시겠습니까?', {
        confirmHandler: async () => {
          const res = await requestDeleteInquiry({ inquiry_seq: inquirySeq });
          if (res.result_code === '0000') {
            showAlert('알림', '클래스 문의가 삭제되었습니다.');
          }
        },
      });
  }, [requestDeleteInquiry, showConfirm]);

  return (
    <div className="detailTabBox isOn">
      <Typography variant="h1" component="h2" className="classInquiry_title">
        클래스 문의
        {classData && enableRegist && (
          <ModalWrap
            className="-inquiry-create"
            modalinfo="inqiory-create"
            visible={registModal.visibleModal}
            handleOpen={onRegistClick}
            handleClose={registModal.handleClose}
          >
            <ModalType1
              title="클래스 문의"
              buttonCancel="취소"
              buttonApply="등록"
              handleApply={handleRegist}
              inquiryType={inquiryType}
              inquiryContract={inquiryContract}
              inquiryName={modalInquiryName}
              inquiryContent={modalInquiryContent}
            >
              {isLogin && <ClassInquiryCreate classData={classData} orderUser={orderUser ? orderUser : ''} />}
            </ModalType1>
          </ModalWrap>
        )}
      </Typography>

      {inquires && inquires.length > 0 ? (
        <>
          <ul className="classInquiry_list_wrap">
            <Desktop>
              <li className="classInquiry_list_title">
                <div className="num">번호</div>
                <div className="answerState">답변상태</div>
                <div className="title">제목</div>
                <div className="name">작성자</div>
                <div className="date">등록일</div>
              </li>
            </Desktop>

            {inquires.map((inquiry, index) => (
              <ClassInquiryItem
                key={inquiry.inquiry.inquiry_seq}
                inquiry={inquiry}
                index={inquiry.index}
                classData={classData ? classData : inquiry?.classInfo}
                dropButtonHandler={dropButtonHandler}
                requestUpdateInquiry={requestUpdateInquiry}
                onDeleteClick={onDeleteClick}
              />
            ))}
          </ul>

          <BasicPagination
            page={paginationInput.page}
            count={paginationInput.pageCount}
            onChange={paginationInput.onPageChange}
          />
        </>
      ) : (
        <ul className="classInquiry_list_wrap">
          <div className="table-empty pt30 pb50">
            <div className="table-empty-caution">
              <div className="title-section">
                <Typography>클래스에 대해 궁금한 내용을 남겨주세요.</Typography>
              </div>
            </div>
          </div>
        </ul>
      )}
    </div>
  );
}

export default React.memo(ClassInquiry);
