import React, { useCallback } from "react";

import { useApiContext } from "common/context";
import { useCommonMutation, useLoginPopup } from "common/hooks";
import usePopAlert from "components/popAlert/hooks/usePopAlert";
import useModalWrapper from "components/modal/hooks/useModalWrapper";
import { useNavigate } from "react-router";

const useMainCoupon = () => {
  const { queries } = useApiContext();
  const { showAlert } = usePopAlert();
  const { checkLogin } = useLoginPopup();
  const mainModal = useModalWrapper();
  const navigate = useNavigate();

  const { request: requestDownloadCoupons } = useCommonMutation({
    query: queries.renewal.mayEvent,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          showAlert(
            `${data.result_message} 당첨!`,
            "지금 마이페이지를 확인해보세요.",
            {
              confirmHandler: () => {
                navigate(`${data.link}`);
              },
            }
          );
          mainModal.handleClose();
        } else {
          showAlert(data.result_message); //중복 메세지(1일 1회) code:2000
          mainModal.handleClose();
        }
      },
      onError: (error) => {
        console.log(error);
        showAlert("알림", "등록이 실패했습니다.");
      },
    },
  });

  const onDownloadCoupons = useCallback(() => {
    if (checkLogin()) return;

    requestDownloadCoupons({ event_seq: 104 }); 
  }, [checkLogin]);

  return {
    onDownloadCoupons,
    mainModal,
  };
};

export default useMainCoupon;
