import React, { useCallback, useEffect, useMemo, useState } from 'react';

import ClassCard from './classCard';
import { Tab } from 'components/utils';
import { useScroll } from 'components/items';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

function classCardMap({ classList, type, selectKeyword, addClassName }) {
    // console.log("classList :: ", classList)
    const [swiper, setSwiper] = useState(null);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const scroller = useScroll("card-tab");
    const buttonScroller = useScroll("button-tab");

    useEffect(() => {
        const resizeListener = () => {
            setInnerWidth(window.innerWidth);
        };
        window.addEventListener("resize", resizeListener);
    });

    const groupClass = useMemo(() => {
        let list = [];
        if(type === "group" || type === "category" || type === "categoryKeyword") {
            classList.forEach((data, idx) => {
                list.push({
                    id: ++idx,
                    groupId: data.group_id,
                    title: data.title,
                    classList: data.class_list
                })
            })
            return list;
        }
    });

    const swiperParams = {
        onSwiper: setSwiper,
        slidesPerView: 'auto',
    };

    const handleKeyword = useCallback(() => {

    }, []);
    

    return (
        <>
            {type === "class" ? ( // PC 4개 PAD 3개 MO 2개
                <article>
                    <div className={`card-list ${addClassName ? addClassName : ""}`}>
                        <div className="card-row" direction="row">
                            {classList && classList?.map((item, idx) => (
                                <ClassCard key={item.class_seq} data={item} index={idx} />
                            ))}
                        </div>
                    </div>
                </article>
            ) : type === "group" ? ( // event Group형 content_type = 2
                <article>
                    <div key={groupClass.id} className="groupContainer">
                        <div className="card-list">
                            {groupClass && groupClass.map((data) => (
                                <div style={{ textAlign: "left" }}>
                                    <h1>{data.title}</h1>
                                    <div className="card-row" direction="row">
                                        {data.classList.length > 0 && data.classList.map((item, idx) => (
                                            <ClassCard key={item.class_seq} data={item} index={idx} />
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </article>
            ) : type === "keyword" ? (
                <article>
                    <Tab
                        list={keywordList}
                        selectedValue={selectKeyword}
                        handleSelect={handleKeyword}
                    />
                    <div className="card-list">
                        <div className="card-row" direction="row">
                            {classList && classList?.map((item, idx) => (
                                <ClassCard key={item.class_seq} data={item} index={idx} />
                            ))}
                        </div>
                    </div>
                </article>
            ) : type === "category" ? (
                <article>
                    <div key={groupClass.id} className="categoryContainer">
                        <ul className={scroller.ScrollActive ? 'card-tab fixed' : 'card-tab'}>
                            {groupClass && groupClass.map((item, idx) => (
                                <li dataid={idx} className={parseInt(scroller.activeBtn) === idx ? 'isOn' : ''} onClick={scroller.tabClickHandler} style={{ width: `calc(100%/${item.length})`}}>
                                    {item.groupId} 
                                </li>
                            ))}
                        </ul>
                        <div className="card-list" ref={scroller.ScrollContent}>
                            {groupClass && groupClass.map((item, idx) => (
                                <>
                                    <div className="card-row isOn">
                                    <h1>{item.title}</h1>
                                        {item.classList.map((data, i) => (
                                            <ClassCard key={data.class_seq} data={data} index={i} />
                                        ))}
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                </article>
            ) : type === "categoryKeyword" ? ( // event Category형 content_type = 3
                <article>
                    <div key={groupClass.id} className="categoryContainer">
                        <div className={buttonScroller.ScrollActive ? 'button-tab fixed' : 'button-tab'}>
                            {groupClass && groupClass.map((item, idx) => (
                                <button dataid={idx} className={parseInt(buttonScroller.activeBtn) === idx ? 'isOn' : ''} onClick={buttonScroller.tabClickHandler}>
                                    {item.groupId} 
                                </button>
                            ))}
                        </div>

                        <div className="card-list" ref={buttonScroller.ScrollContent}>
                            {groupClass && groupClass.map((item, idx) => (
                                <>
                                    <div className="card-row isOn">
                                    <h1>{item.title}</h1>
                                        {item.classList.map((data, i) => (
                                            <ClassCard key={data.class_seq} data={data} index={i} />
                                        ))}
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                </article>
            ) : type === "swipe" ? (
                    <article>
                        <div className="card-list">
                            <Swiper 
                                {...swiperParams}
                                ref={setSwiper}
                                className="card-row"
                            > 
                                {classList && classList?.map((item, idx) => (
                                    <SwiperSlide key={item.class_seq} style={{ width: "auto" }}>
                                        <ClassCard key={item.class_seq} data={item} index={idx} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </article>
            ) : (<></>)}
        </>
   
    );
}

export default React.memo(classCardMap);

