import React, { useCallback, useState } from 'react';
import { kakaoIcon, lineIcon, urlIcon, closeIcon } from 'assets/images/icon';
import { Desktop, TabletAndMobile } from 'components/utils/MediaQuery';
import { useShare } from 'common/hooks';

const ModalShare = ({ data, handleClose }) => {
  const { handleShareUrl, handleShareKakao, handleShareLine } = useShare(data);
  const [shareUrl, setShareUrl] = useState(false);

  const onShareKakaoClick = useCallback(
    (e) => {
      handleShareKakao();
      handleClose(e);
    },
    [handleClose, handleShareKakao],
  );

  const onShareLineClick = useCallback(
    (e) => {
      handleShareLine();
      handleClose(e);
    },
    [handleClose, handleShareLine],
  );

  const onShareUrlClick = useCallback(
    (e) => {
      handleShareUrl(true);
      handleClose(e);
    },
    [handleClose, handleShareUrl],
  );

  return (
    <div className="modal-share-wrap">
      <Desktop>
        <p>URL이 복사되었습니다.</p>
        <button className="close-btn-wrap confirm-btn" onClick={handleClose}>
          확인
        </button>
      </Desktop>

      <TabletAndMobile>
        <button className="close-btn-wrap" onClick={handleClose}>
          <img src={closeIcon} alt="닫기 버튼 이미지" />
        </button>
        <div className="share-btn-wrap">
          <li>
            <button onClick={onShareKakaoClick}>
              <img src={kakaoIcon} alt="카카오톡 공유 이미지" />
            </button>
          </li>
          {/* <li>
            <button onClick={onShareLineClick}>
              <img src={lineIcon} alt="라인 공유 이미지" />
            </button>
          </li> */}
          <li>
            <button onClick={onShareUrlClick}>
              <img src={urlIcon} alt="url 공유 이미지" />
            </button>
          </li>
        </div>
      </TabletAndMobile>
    </div>
  );
};

export default ModalShare;
