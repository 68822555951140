import { METHOD } from 'common/hooks';

export const mainApi = (requestFunc) => {
  return {
    main: {
      key: 'main', // 메인 화면
      queryFn: () => requestFunc(METHOD.GET, '/main'),
    },
    mainBest: {
      key: 'mainBest', // 메인 화면 - 베스트
      queryFn: (params) => requestFunc(METHOD.GET, '/main/best', params),
    },
    mainBestClasses: {
      key: 'mainBestClasses', // 메인 화면 - 베스트
      queryFn: (params) => requestFunc(METHOD.GET, `/main/best/${params.category_seq}/classes`, params),
    },
    mainMagazines: {
      key: 'mainMagazines', // 메인 화면 - 매거진
      queryFn: (params) => requestFunc(METHOD.GET, '/main/magazines', params),
    },
    mainEvents: {
      key: 'mainEvents', // 메인 화면 - 이벤트
      queryFn: (params) => requestFunc(METHOD.GET, '/main/events', params),
    },
    mainTeachers: {
      key: 'mainTeachers', // 메인 화면 - 선생님
      queryFn: () => requestFunc(METHOD.GET, '/main/teachers'),
    },
    mainCategories: {
      key: 'mainCategories', // 메인 화면 - 전체 카테고리
      queryFn: () => requestFunc(METHOD.GET, '/main/categories'),
    },
    mainLeftMenu: {
        key: 'mainLeftMenu', // Gnb Left 메뉴 (category + menu)
        queryFn: () => requestFunc(METHOD.GET, `/main/leftMenu`),
    },
    mainTopMenu: {
        key: 'mainTopMenu', // Gnb Top 메뉴
        queryFn: () => requestFunc(METHOD.GET, `/main/topMenu`),
    },
    mainSubCategories: {
      key: 'mainSubCategories', // 메인 화면 - 개별 카테고리
      queryFn: (params) =>
        requestFunc(METHOD.GET, `/main/categories/${params.category_seq}`),
    },
    mainClassesByCategory: {
      key: 'mainClassesByCategory', // 카테고리 클래스 조회
      queryFn: (params) =>
        requestFunc(
          METHOD.GET,
          `/main/categories/${params.category_seq}/classes`,
        ),
    },
    mainSearch: {
      key: 'mainSearch', // 메인 화면 - 수업 검색
      queryFn: (params) => requestFunc(METHOD.GET, '/main/search', params),
    },
    mainSearchPopular: {
      key: 'mainSearchPopular', // 인기 검색어 목록
      queryFn: () => requestFunc(METHOD.GET, '/main/search/popular'),
    },
    mainSearchInline: {
      key: 'mainSearchInline', // 검색어 자동완성
      queryFn: (params) => requestFunc(METHOD.GET, '/search/inline', params),
    },
    mainKeywords: {
      key: 'mainKeywords', // 메인 화면 - 키워드 검색
      queryFn: (params) => requestFunc(METHOD.GET, '/main/keywords', params),
    },
    eventSubmit: {
      key: 'eventSubmit',
      queryFn: (params) =>
        requestFunc(
          METHOD.POST,
          `/main/events/${params.event_seq}`,
          params,
        ),
    },
    mainTopBanner: {
        key: 'mainTopBanner', // Gnb Top 메뉴
        queryFn: () => requestFunc(METHOD.GET, `/main/topBanner`),
    },
    mainFloatingBanner: {
      key: 'mainFloatingBanner', // Gnb Top 메뉴
      queryFn: () => requestFunc(METHOD.GET, `/main/floatingBanner`),
  },
  };
};
