import { useCallback } from 'react';
import axios from 'axios';
import qs from 'qs';
import { useKakaoContext } from 'common/context';
import * as constants from 'common/constants';

function useKakao() {
  const { action } = useKakaoContext();
  const { setKakaoData } = action;
  const kakaoAuthUrl = `https://kauth.kakao.com/oauth/authorize?client_id=${constants.KAKAO_CLIENT_ID}&redirect_uri=${constants.KAKAO_REDIRECT_URI}&response_type=code`;

  const requestKakaoToken = useCallback(
    async (authCode) => {
      const kakaoTokenAxiosOptions = {
        method: 'POST',
        headers: {
          'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
        data: qs.stringify({
          grant_type: 'authorization_code',
          client_id: constants.KAKAO_CLIENT_ID,
          redirect_uri: constants.KAKAO_REDIRECT_URI,
          code: authCode,
        }),
        url: 'https://kauth.kakao.com/oauth/token',
      };
      const res = await axios(kakaoTokenAxiosOptions);
      setKakaoData({
        access_token: res.data.access_token,
        id_token: res.data.id_token,
        refresh_token: res.data.refresh_token,
      });
      return res.data;
    },
    [setKakaoData],
  );

  const requestKakaoUser = useCallback(async (kakaoAccessToken) => {
    const kakaoUserAxiosOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${kakaoAccessToken}`,
        'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
      data: qs.stringify({
        property_keys: [
          [
            'kakao_account.email',
            'kakao_account.phone_number',
            'kakao_account.profile',
          ],
        ],
      }),
      url: 'https://kapi.kakao.com/v2/user/me',
    };
    const res = await axios(kakaoUserAxiosOptions);
    return res.data;
  }, []);

  const requestKakaoLogout = useCallback(async (kakaoAccessToken) => {
    const kakaoLogoutAxiosOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${kakaoAccessToken}`,
        'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
      url: 'https://kapi.kakao.com/v1/user/logout',
    };
    const res = await axios(kakaoLogoutAxiosOptions);
    return res.data;
  }, []);

  const requestKakaoUnlink = useCallback(async (kakaoAccessToken) => {
    const kakaoUnlinkAxiosOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${kakaoAccessToken}`,
        'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
      url: 'https://kapi.kakao.com/v1/user/unlink',
    };
    const res = await axios(kakaoUnlinkAxiosOptions);
    // console.log(res)
    return res.data;
  }, []);

  return {
    kakaoAuthUrl,
    requestKakaoToken,
    requestKakaoUser,
    requestKakaoLogout,
    requestKakaoUnlink,
  };
}

export default useKakao;
