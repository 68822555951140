import React, {Suspense, lazy, useCallback, useEffect, useState} from 'react';
import {Routes, Route, useLocation} from 'react-router';
import ReactGA from 'react-ga4';
import SecretClassDetail from 'pages/detail/SecretClassDetail';
import TogetherSecretClassDetail from 'pages/detail/TogetherSecretClassDetail';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage2 from 'pages/listEmpty/ErrorPage2';

const Main = lazy(() => import('pages/main/Main'));
const NotFound = lazy(() => import('pages/main/NotFound'));
const Login = lazy(() => import('pages/auth/Login'));
const Register = lazy(() => import('pages/auth/Register'));
const LiveAll = lazy(() => import('pages/index/LiveAll'));
const Best = lazy(() => import('pages/index/Best'));
const TogetherClass = lazy(() => import('pages/index/TogetherClass'));
const ReadingBook = lazy(() => import('pages/index/ReadingBook'));
const Magazine = lazy(() => import('pages/index/Magazine'));
const Event = lazy(() => import('pages/index/Event'));
const Teacher = lazy(() => import('pages/index/Teacher'));
const TeacherDetail = lazy(() => import('pages/detail/TeacherDetail'));
const MagazineDetail = lazy(() => import('pages/detail/MagazineDetail'));
const EventDetail = lazy(() => import('pages/detail/EventDetail'));
const ClassDetail = lazy(() => import('pages/detail/ClassDetail'));
const TogetherClassDetail = lazy(() => import('pages/detail/TogetherClassDetail'));
const Auth = lazy(() => import('pages/auth/Auth'));
const ClassFeedback = lazy(() => import('pages/feedback/ClassFeedback'));
const Order = lazy(() => import('pages/order/Order'));
const TogetherOrder = lazy(() => import('pages/order/TogetherOrder'));
const TogetherOrderComplete = lazy(() => import('pages/order/TogetherOrderComplete'));
const OrderComplete = lazy(() => import('pages/order/OrderComplete'));
const Search = lazy(() => import('pages/index/Search'));
const SearchResult = lazy(() => import('pages/index/SearchResult'));
const MyPage = lazy(() => import('pages/mypage/MyPage'));
const BigCalendarWrap = lazy(() => import('pages/calendar/BigCalendarWrap'));
const ApplyTeacherGate = lazy(() => import('pages/footer/ApplyTeacherGate'));
const ApplyTeacher = lazy(() => import('pages/footer/ApplyTeacher'));
const InquiryCooperation = lazy(() => import('pages/footer/InquiryCooperation'));
const InquiryGroup = lazy(() => import('pages/footer/InquiryGroup'));
const Terms = lazy(() => import('pages/footer/Terms'));
const Personal = lazy(() => import('pages/footer/Personal'));
const PersonalOrigin1 = lazy(() => import('pages/footer/PersonalOrigin1'));
const PersonalOrigin2 = lazy(() => import('pages/footer/PersonalOrigin2'));
const PersonalOrigin3 = lazy(() => import('pages/footer/PersonalOrigin3'));
const PersonalOrigin4 = lazy(() => import('pages/footer/PersonalOrigin4'));
const NoticeBoard = lazy(() => import('pages/footer/NoticeBoard')); // 2023-06-05 공지사항 리스트 페이지
const BoardDetail = lazy(() => import('pages/footer/BoardDetail')); // 2023-06-05 공지사항 상세 페이지

const RefundPolicy = lazy(() => import('pages/footer/RefundPolicy'));
const CategoryMenu = lazy(() => import('pages/category/CategoryMenu'));
const ImpayCallback = lazy(() => import('pages/order/ImpayCallback'));

const GatePage = lazy(() => import('pages/index/GatePage'));
const TogetherIntro = lazy(() => import('pages/index/TogetherIntro'));

function MainRouter() {

  const location = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
      title: location.pathname,
    });
  }, []);

  return (
    <ErrorBoundary
    fallback={<ErrorPage2 />}
    onReset={() => {
      window.location.reload();
    }}
    >
      <Suspense fallback={<div className='sub-fallback-margin'></div>}>
        <Routes>
          <Route path="/" element={<Main/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/register" element={<Register/>}/>
          {/* <Route path="/intro" element={<LiveAll/>}/> */}
          <Route path="/intro" element={<GatePage/>}/>
          <Route path="/class/" element={<Best/>}/>
          <Route path="/class/best/:id" element={<Best/>}/>
          <Route path="/readingBook" element={<ReadingBook/>}/>
          <Route path="/readingBook/:id" element={<ClassDetail/>}/>
          <Route path="/readingBook/:id/order" element={<Order/>}/>
          <Route path="/readingBook/:id/order/complete" element={<OrderComplete/>} />
          <Route path="/class/:id" element={<ClassDetail />} />
          <Route path="/class/:id/order" element={<Order />} />
          <Route path="/class/:id/order/complete" element={<OrderComplete />} />
          <Route path="/class/:id/hidden" element={<SecretClassDetail />} />

          {/* 투게더 클래스 추가 `23.01.13 */}
          <Route path="/togetherClass" element={<TogetherClass/>}/>
          <Route path="/togetherClass/best/:id" element={<TogetherClass/>}/>
          <Route path="/togetherClass/:id" element={<TogetherClassDetail/>}/>
          <Route path="/togetherClass/:id/hidden" element={<TogetherSecretClassDetail/>}/>
          <Route path="/togetherClass/:id/order" element={<TogetherOrder/>}/>
          <Route path="/togetherClass/:id/order/complete" element={<TogetherOrderComplete/>} />
          {/* 투게더 클래스 end */}

          <Route path="/feedback/:id" element={<ClassFeedback/>}/>
          <Route path="/magazine" element={<Magazine/>}/>
          <Route path="/magazine/:id" element={<MagazineDetail/>}/>
          <Route path="/event" element={<Event/>}/>
          <Route path="/event/category/:id" element={<Event/>}/>
          <Route path="/event/:id" element={<EventDetail/>}/>
          <Route path="/teacher" element={<Teacher/>}/>
          <Route path="/teacher/:id" element={<TeacherDetail/>}/>
          <Route path="/auth/kakao/callback" element={<Auth/>}/>
          <Route path="/search" element={<Search/>}/>
          <Route path="/search/result" element={<SearchResult/>}/>
          <Route path="/mypage/*" element={<MyPage/>}/>
          <Route path="/calendar" element={<BigCalendarWrap/>}/>
          <Route path="/apply" element={<ApplyTeacherGate/>}/>
          <Route path="/apply/teacher" element={<ApplyTeacher/>}/>
          <Route
            path="/inquiry/cooperation"
            element={<InquiryCooperation type="cooperation"/>}
          />
          <Route path="/inquiry/group" element={<InquiryGroup type="group"/>}/>
          <Route path="/policy/terms" element={<Terms/>}/>
          <Route path="/policy/personal" element={<Personal/>}/>
          <Route path="/policy/personalOrigin1" element={<PersonalOrigin1/>}/>
          <Route path="/policy/personalOrigin2" element={<PersonalOrigin2/>}/>
          <Route path="/policy/personalOrigin3" element={<PersonalOrigin3/>}/>
          <Route path="/policy/personalOrigin4" element={<PersonalOrigin4/>}/>
          {/* 2023-06-05 공지사항 추가 시작 */}
          <Route path="/footer/notice" element={<NoticeBoard/>}/>
          <Route path="/footer/notice/:id" element={<BoardDetail/>}/>
          {/*  2023-06-05 공지사항 추가 끝 */}
          {/* 2022-07-25 개인정보 처리방침 페이지 */}
          <Route path="/policy/refund" element={<RefundPolicy/>}/>
          <Route path="/category" element={<CategoryMenu/>}/>
          <Route path="/impay/callback" element={<ImpayCallback/>}/>
          <Route path="/*" element={<NotFound/>}/>

          {/* 2024-03-06 게이트 페이지 */}
          <Route path="/gatepage" element={<GatePage/>}/>
          <Route path="/together/intro" element={<TogetherIntro/>}/>
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}

export default MainRouter;
