import { METHOD } from 'common/hooks';

export const memberApi = (requestFunc) => {
  return {
    // 멤버
    memberInfo: {
      key: 'memberInfo', // 러브올 인증
      queryFn: () => requestFunc(METHOD.GET, '/info'),
    },
  };
};
