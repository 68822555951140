import React, { useState, useCallback, useMemo } from 'react';
import { BasicPagination, DropButton } from 'components/utils';
import { getHiddenNameText, getStarText } from 'common/utils';
import useDropButton from 'components/utils/hooks/useDropButton';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
    Scrollbar,
    Navigation,
    Autoplay,
} from 'swiper';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import Star from 'components/star/Star';
import { getAge } from 'common/utils';
import {
    Desktop,
    TabletAndMobile,
} from 'components/utils/MediaQuery';
import { Typography } from '@mui/material';
import useClassReview from './hooks/useClassReview';
import moment from 'moment';
import 'moment/locale/ko';
import 'assets/css/detail/classReview.css';

import { ModalWrap, ModalType2 } from '../modal';
import useModalWrapper from 'components/modal/hooks/useModalWrapper';
import ClassReviewShow from 'components/items/ClassReviewShow'; //20221121 클래스 후기 보기 팝업


function ClassReview({ classData, teacherData, star, showClassName = false }) {
    const { reviews, photoReviews, inputs } = useClassReview(
        classData
        ? { classSeq: classData.class_seq }
        : { teacherSeq: teacherData.teacher_seq },
    );
    const { reviewType, paginationInput } = inputs;
    const { expandedItem, handleItemExpand } = useDropButton();
    const [selectedReview, setSelectedReview] = useState(undefined);
    let [ isClick, setIsClick ] = useState(false);

    SwiperCore.use([Scrollbar, Navigation, Autoplay]); // swiper slide
    const [swiper, setSwiper] = React.useState();
    const prevRef = React.useRef();
    const nextRef = React.useRef();
    React.useEffect(() => {
        if (swiper) {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
        }
    }, [swiper]);

  // 20221121 클래스보기 팝업 클릭 이벤트
    const classReviewModal = useModalWrapper();

    const onSeeingReviewClick = useCallback((review) => {
        console.log(review);
        setSelectedReview(review);
        classReviewModal.handleOpen();
    }, [classReviewModal]);

    const clickListItem = useCallback((review_seq) => {
        setIsClick(!isClick);
        handleItemExpand(review_seq);
    },[isClick, handleItemExpand]);

    return (
        <>
            <ModalWrap
                className="option-change-btn"
                modalinfo="other-button"
                visible={classReviewModal.visibleModal}
                handleOpen={classReviewModal.handleOpen}
                handleClose={classReviewModal.handleClose}
            >
                <ModalType2 title="클래스 후기" buttonCancel="확인">
                    <ClassReviewShow review={selectedReview} />
                </ModalType2>
            </ModalWrap>

            <div className="detailTabBox isOn">
                <h1 className="classReview_tit">생생한 클래스 후기 ({paginationInput?.totalCount})</h1>
                <div className="classReview_raiting">
                    <h2>클래스 만족도</h2>
                    <Desktop>
                        <h2>{star ? getStarText(star) : '0.0'} /{' '}<span className="txt_gray">5</span></h2>
                    </Desktop>
                    <TabletAndMobile>
                        <h2>{star ? getStarText(star) : '0.0'} /{' '}<span className="txt_gray">5</span></h2>
                    </TabletAndMobile>
                    <div>
                        <Star star={star} />
                    </div>
                </div>

                <p className="classReview_noti txt_gray">{`※ 클래스 후기 등록, 수정, 삭제는 [마이페이지 > 나의 활동 > 클래스 후기 관리]에서 확인하실 수 있습니다.`}</p>
            
                <Desktop>
                    <div className=" review_thum_slide_wrap">
                        <Swiper
                            slidesPerView={7}
                            spaceBetween={10}
                            navigation={{
                                prevEl: prevRef?.current,
                                nextEl: nextRef?.current,
                            }}
                            modules={[Navigation]}
                            // autoplay={{ delay: 3000 }}
                            className="slide-card-items-list-type"
                            onInit={(swiper) => {
                                swiper.params.navigation.prevEl = prevRef.current;
                                swiper.params.navigation.nextEl = nextRef.current;
                                swiper.navigation.init();
                                swiper.navigation.update();
                            }}
                            onSwiper={setSwiper}
                        >
                            {photoReviews.map((item) =>
                                item.photos.map((photo) => (
                                    <SwiperSlide onClick={() => onSeeingReviewClick(item)}>
                                        <img src={photo.file_url} alt="img01" />
                                    </SwiperSlide>
                                )),
                            )}
                        </Swiper>
                        <div ref={prevRef} className="swiper-button-prev">Prev</div>
                        <div ref={nextRef} className="swiper-button-next">Next</div>
                    </div>
                </Desktop>

                <TabletAndMobile>
                    <div className="review_thum_slide_wrap">
                        <Swiper
                            slidesPerView={2.3}
                            spaceBetween={10}
                            className="slide-card-items-list-type"
                            onSwiper={setSwiper}
                        >
                            {photoReviews.map((item) =>
                                item.photos.map((photo) => (
                                    <SwiperSlide onClick={() => onSeeingReviewClick(item)}>
                                        <img src={photo.file_url} alt="img01" />
                                    </SwiperSlide>
                                ))
                            )}
                        </Swiper>
                    </div>
                </TabletAndMobile>

                <div className="classReview_dropBoxWrap">
                    <div className="classReview_dropBox_top">
                        <span>전체 ({reviews.length})</span>
                        {/* <SelectBox
                        options={OPTIONS}
                        name="sorting"
                        value={reviewType.state}
                        handleChange={reviewType.handleChange}
                        /> */}
                    </div>

                        {reviews && reviews.length > 0 ? (
                            <>
                                <ul className="classReview_dropBox_con">
                                    {reviews.map((review, index) => (
                                        <li key={index} className="classReview_dropBox" onClick={() => clickListItem(review.review_seq)}>
                                            <div className="classReview_dropBox_tit">
                                                <p className="rating">
                                                    <Star star={review.star} />
                                                    <TabletAndMobile>
                                                        <span>{`${getHiddenNameText(review.user_name)} | ${getAge(review.birth)}세 | ${review.gender_type_name}${(review.teacher_seq === 6 || review.teacher_seq === 7 || review.teacher_seq === 8) ? '' : moment(review.created_dt).format(' | YYYY-MM-DD')}`}</span>
                                                    </TabletAndMobile>
                                                </p>
                                                {showClassName && (
                                                    <>
                                                        {/* {classData && (
                                                            <p className="txt_gray">{classData.class_name}</p>
                                                        )} */}
                                                        {teacherData && ( <p className="txt_gray">{review.classInfo.class_name}</p> )}
                                                    </>
                                                )}
                                                {(review.teacher_seq === 6 || review.teacher_seq === 7 || review.teacher_seq === 8) ? (<></>) : (
                                                    <p className="txt_gray">{`수업일 : ${moment(review.schedule_date + ' ' + review.schedule_time).format('M월 D일 (dd) A hh:mm')}`}</p>
                                                )}
                                                <div className="title">
                                                    <h2>{review.review}</h2>
                                                    <Desktop>
                                                        <span>{`${getHiddenNameText(review.user_name)} | ${getAge(review.birth)}세 | ${review.gender_type_name}${(review.teacher_seq === 6 || review.teacher_seq === 7 || review.teacher_seq === 8) ? '' : moment(review.created_dt).format(' | M월 D일 (dd)')}`}</span>
                                                    </Desktop>
                                                    <DropButton
                                                        expand={expandedItem === review.review_seq}
                                                        handleItemExpand={handleItemExpand}
                                                        index={review.review_seq}
                                                    />
                                                </div>
                                            </div>
                                            <div className={(expandedItem === review.review_seq) ? 'classReview_dropBox_info isOn' : 'classReview_dropBox_info'}>
                                                {review.review}
                                                {review.photos?.length > 0 && (
                                                    <div className="review_thum_wrap" onClick={() => onSeeingReviewClick(review)}>
                                                        <img src={review.photos[0].file_url} alt="이미지 리뷰 썸네일" />
                                                        <span>{review.photos.length}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <BasicPagination
                                    page={paginationInput.page}
                                    count={paginationInput.pageCount}
                                    onChange={paginationInput.onPageChange}
                                />
                            </>
                        ) : (
                            <ul className="classInquiry_list_wrap">
                                <div className="table-empty pt30 pb50">
                                    <div className="table-empty-caution">
                                        <div className="title-section">
                                            <Typography>아직 등록된 클래스 후기가 없어요.</Typography>
                                        </div>
                                    </div>
                                </div>
                            </ul>
                        )}
                    </div>
            </div>
        </>
    );
}

export default React.memo(ClassReview);
