import React, { useCallback, useMemo } from 'react';

import Paper from '@mui/material/Paper';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { CardActionArea, Typography } from '@mui/material';

import {
    TagList,
    Heart,
    TeacherInfoItem,
    PriceInfoItem
  } from 'components/items';

import { RECEPTION_STATUS } from 'common/constants';

import useCardInfo from './hook/useCardInfo';

import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'assets/css/slide/slide.css';

import 'assets/css/cardComponent.scss';
import { useNavigate } from 'react-router';

function classCard({ data, classStar, index }) {
    // const navigate = useNavigate();
    const { 
        onClassClick, 
        onTeacherClick, 
        onTogetherClassClick 
    } = useCardInfo({
        classData: data,
        teacher: data.teacher_info,
    });

    const receptionClassName = useMemo(() => {
        if (data.reception_status === RECEPTION_STATUS.awaited.value) {
            return 'sold-out';
        } else if (data.reception_status === RECEPTION_STATUS.closed.value) {
            return 'sold-out-closed';
        } else {
            return '';
        }
            
    }, [data?.reception_status]);

    if (!data) {
        return null;
    }

    return (
        <Paper className={`card-container ${data.class_type === 9 ? "" : receptionClassName}`} onClick={data.class_type === 9 ? onTogetherClassClick : onClassClick}>
            {/* onClick={data.class_type === 9 ? onTogetherClassClick : onClassClick} */}
            <CardActionArea>
                <CardMedia component="img" src={data.thumbnail_image_url} alt={data.class_name} />
                <div className="commingSoon-txt">오픈 예정</div>
            </CardActionArea>
            <CardContent className="card-content footer">
                <div className="card-info top">
                    <TagList data={data} />
                    <Heart data={data} />
                </div>
            
                <div className="class-name">
                    {data.class_name}
                </div>

                <div className="card-info bottom">
                    <TeacherInfoItem
                        data={data}
                        onTeacherClick={onTeacherClick}
                        classStar={classStar}
                    />
                    <PriceInfoItem data={data} />
                </div>
            </CardContent>
        </Paper> 
    );
}

export default React.memo(classCard);

