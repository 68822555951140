import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useAppContext } from 'common/context';
import { useMediaQuery } from 'react-responsive';

function useAppSettings() {
    const { headerState, gnbState, screenState, action } = useAppContext();
    const [headerVisibleFlag, setHeaderVisibleFlag] = useState(false);
    const [gnbVisibleFlag, setGnbVisibleFlag] = useState(false);

    const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });
    const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 1023px)' });
    const isMobile1 = useMediaQuery({ query: '(min-width: 540px) and (max-width: 767px)' });
    const isMobile2 = useMediaQuery({ query: '(max-width: 539px)' });
    
    const isDesktopAndTablet = useMediaQuery({ query: '(min-width: 768px)' });
    const isTabletAndMobile = useMediaQuery({ query: '(max-width: 1023px)' });
    const isAllMobile = useMediaQuery({ query: '(max-width: 767px)' });

    useEffect(() => {
        return () => {
            if (headerVisibleFlag) {
                action.setHeaderVisible(true);
            }
            if (gnbVisibleFlag) {
                action.setGnbVisible(true);
            }
        };
    }, [headerVisibleFlag, gnbVisibleFlag]);

    useEffect(() => {
        action.setScreenType({ 
            isDesktop, 
            isTablet, 
            isMobile1, 
            isMobile2, 
            isDesktopAndTablet, 
            isTabletAndMobile,
            isAllMobile
        });
    }, [
        isDesktop, 
        isTablet, 
        isMobile1, 
        isMobile2, 
        isDesktopAndTablet, 
        isTabletAndMobile,
        isAllMobile
    ]);

    const updateHeaderVisible = useCallback((visible) => {
        setHeaderVisibleFlag(true);
        action.setHeaderVisible(visible);
    }, [action.setHeaderVisible]);

    const updateGnbVisible = useCallback((visible) => {
        setGnbVisibleFlag(true);
        action.setGnbVisible(visible);
    }, [action.setGnbVisible]);

    const screenTypes = useMemo(() => {
        return {
            isDesktop, 
            isTablet, 
            isMobile1, 
            isMobile2, 
            isDesktopAndTablet,
            isTabletAndMobile,
            isAllMobile
        };
    }, [
        isDesktop, 
        isTablet, 
        isMobile1, 
        isMobile2,  
        isDesktopAndTablet, 
        isTabletAndMobile,
        isAllMobile
    ]);

    return {
        headerState,
        gnbState,
        screenState,
        action,
        screenTypes,
        updateHeaderVisible,
        updateGnbVisible,
    };
}

export default useAppSettings;
