import { METHOD } from 'common/hooks';

export const classApi = (requestFunc) => {
  return {
    // 수업
    classes: {
      key: 'classes', // 클래스 조회
      queryFn: (params) => requestFunc(METHOD.GET, `/classes`, params),
    },
    classInfo: {
      key: 'classInfo', // 클래스 상세 조회
      queryFn: (params) =>
        requestFunc(METHOD.GET, `/classes/${params.class_seq}`),
    },
    secretClassInfo: {
      key: 'secretClassInfo', // 클래스 상세 조회
      queryFn: (params) =>
          requestFunc(METHOD.GET, `/classes/${params.class_seq}/hidden`),
    },
    addFavoriteClass: {
      key: 'addFavoriteClass', // 관심 클래스 등록
      queryFn: (params) =>
        requestFunc(METHOD.POST, `/classes/${params.class_seq}/favorite`),
    },
    deleteFavoriteClass: {
      key: 'deleteFavoriteClass', // 관심 클래스 삭제
      queryFn: (params) =>
        requestFunc(METHOD.DELETE, `/classes/${params.class_seq}/favorite`),
    },
    classReviews: {
      key: 'classReviews', // 수업 후기 목록
      queryFn: (params) =>
        requestFunc(METHOD.GET, `/classes/${params.class_seq}/reviews`, params),
    },
    classInquires: {
      key: 'classInquires', // 수업 문의 목록
      queryFn: (params) =>
        requestFunc(
          METHOD.GET,
          `/classes/${params.class_seq}/inquires`,
          params,
        ),
    },
    addClassInquire: {
      key: 'addClassInquire', // 수업 문의 - 작성
      queryFn: (params) =>
        requestFunc(
          METHOD.POST,
          `/classes/${params.class_seq}/inquires`,
          params,
        ),
    },
    classMaterials: {
      key: 'classMaterials', // 수업자료 목록
      queryFn: (params) =>
        requestFunc(METHOD.GET, `/classes/${params.class_seq}/material-list`),
    },
    downloadAllClassMaterials: {
      key: 'downloadAllClassMaterials', // 수업자료 일괄 다운로드
      queryFn: (params) =>
        requestFunc(METHOD.GET, `/classes/${params.class_seq}/material`),
    },
    downloadClassMaterial: {
      key: 'downloadClassMaterial', // 수업자료 개별 다운로드
      queryFn: (params) =>
        requestFunc(
          METHOD.GET,
          `/classes/${params.class_seq}/material-download/${params.file_seq}`,
        ),
    },
    deleteFavoriteClassByFavoriteSeq: {
      key: 'deleteFavoriteClassByFavoriteSeq', // 관심 클래스 삭제
      queryFn: (params) =>
        requestFunc(METHOD.DELETE, `/favorite/${params.favorite_class_seq}`),
    },
    applyClass: {
      key: 'applyClass', // 수업 신청 - 조회
      queryFn: (params) =>
        requestFunc(METHOD.POST, `/classes/${params.class_seq}/apply`, params),
    },
    order: {
      key: 'order', // 수업 신청 - 주문
      queryFn: (params) => requestFunc(METHOD.POST, `/orders`, params),
    },
    completeOrder: {
      key: 'completeOrder', // 수업 신청 - 주문 결제 완료
      queryFn: (params) => requestFunc(METHOD.POST, `/orders/payment`, params),
    },
    failOrder: {
      key: 'failOrder', // 수업 신청 - 주문 실패(사용자 취소)
      queryFn: (params) =>
        requestFunc(METHOD.POST, `/orders/payment-fail`, params),
    },
    completeOrderInfo: {
      key: 'completeOrderInfo', // 수업 신청 - 주문 결제 결과
      queryFn: (params) =>
        requestFunc(METHOD.GET, `/orders/${params.order_seq}/payment-result`),
    },
    cancelOrder: {
      key: 'cancelOrder', // 수업 신청 - 주문 결제 취소
      queryFn: (params) =>
        requestFunc(
          METHOD.POST,
          `/orders/${params.order_seq}/payment-cancel`,
          params,
        ),
    },
    updateOrderAddress: {
      key: 'updateOrderAddress', // 수업 신청 - 배송지 주소 변경
      queryFn: (params) =>
        requestFunc(
          METHOD.PATCH,
          `/orders/${params.order_seq}/change-address`,
          params,
        ),
    },
    downloadCoupons: {
      key: 'downloadCoupons',
      queryFn: (params) =>
        requestFunc(
          METHOD.POST,
          `/classes/${params.class_seq}/download-coupons`,
        ),
    },
  };
};
