import React, { useCallback } from 'react';
import { Typography, Grid, FormControl } from '@material-ui/core';
import { Calendar, TimeSet } from './';
import { TimeSelect } from 'components/items';
import { icon1, icon2 } from 'assets/images/calender';
import useSchedules from 'components/calendar/hooks/useSchedules';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// CalenderWrap.js

function CalendarWrap({
  events,
  handleDateSelect,
  timeSelector,
  schedulesStates,
  handleTimeChange,
  handlePageRefresh,
  resetValue,
}) {
  const {
    scheduleTime,
    scheduleAmTimes,
    schedulePmTimes,
    selectedSchedulesDateText,
    selectedSchedulesTimeText,
  } = schedulesStates;
  const scheduleTimes = scheduleTime?.schedule?.timetable;

  return (
    <div className="calendar-wrap">
      <Typography variant="h6" component="h6" className="calender-title">
        <img src={icon1} alt="캘린더" /> 클래스 시작일을 선택해 주세요.
      </Typography>
      <Calendar
        events={events}
        handleDateSelect={handleDateSelect}
        scheduleTimes={scheduleTimes}
        className="pop-calender"
        resetValue={resetValue}
      />
      <Typography
        variant="h6"
        component="h6"
        className="calender-title calender-title2"
      >
        <img src={icon2} alt="시간" /> 클래스 시간을 선택해 주세요.
      </Typography>

      <div className="timeSet-wrap calendar-date-wrap">
        {scheduleAmTimes.length > 0 && (
          <TimeSelect
            times={scheduleAmTimes}
            isAm={true}
            value={timeSelector.state}
            handleChange={handleTimeChange}
          />
        )}
        {schedulePmTimes.length > 0 && (
          <TimeSelect
            times={schedulePmTimes}
            isAm={false}
            value={timeSelector.state}
            handleChange={handleTimeChange}
          />
        )}
        {scheduleTime?.schedule?.timetable?.length > 0 && (
          <Collapse in={true} className="schedule-alert mt20">
            <Alert
              icon={false}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handlePageRefresh}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <Typography>{selectedSchedulesDateText}</Typography>
              <Typography>{selectedSchedulesTimeText}</Typography>
            </Alert>
          </Collapse>
        )}
      </div>
    </div>
  );
}

export default CalendarWrap;
