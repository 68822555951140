import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import useItemsHandler from './hooks/useItemsHandler';
import { Link } from 'react-router-dom';

// MainCategories.js

function MainCategories({ data }) {
  return (
    <Link to={`/search/result?keyword=${data.keyword}`}>{data.keyword}</Link>
  );
}

export default React.memo(MainCategories);
