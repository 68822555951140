import React from 'react';
import { Typography } from '@material-ui/core';
import 'assets/css/detail/classInquiry.css';

import InnerHTML from 'dangerously-set-html-content'

// ClassIntro.js

function ClassIntro({ data }) {

  return (
    <div className="detailTabBox isOn">
      <h1 className="classReview_tit">클래스 소개</h1>
      {/* <Typography
        className="text-field"
        dangerouslySetInnerHTML={{ __html: data }}
      /> */}
      
       <div className="innerHtml_style" dangerouslySetInnerHTML={{ __html: data }} />
      {/* 2022-10-28 클래스 상세 클래스 소개 이미지&테이블 스타일 지정 위한 클래스명 innerHtml_style 추가 */}

      {/* <div className="classSupplies-list-wrap">
        <div className="list-title">
          <span>수업 전 꼭 확인해 주세요.</span>
        </div>
        <div className="list-content">
          <ul>
            <li>
              <span></span>교재(라이브올 배송)
            </li>
            <li>
              <span></span>필기도구(연필, 지우개)
            </li>
          </ul>
        </div>

        <div className="list-title">
          <span>패키지 수업 신청 시, 확인해 주세요.</span>
        </div>
        <div className="list-content">
          <ul>
            <li>
              <span></span>교재(라이브올 배송)
            </li>
            <li>
              <span></span>필기도구(연필, 지우개)
            </li>
          </ul>
        </div>

        <div className="list-title">
          <span>취소/환불</span>
        </div>
        <div className="list-content">
          <ul>
            <li>
              <span></span>수업 확정일 이전까지는 취소가 가능하지만, 수업 확정
              이후에는 취소가 불가능합니다.
            </li>
          </ul>
        </div>

        <div className="list-title">
          <span>반품/교환</span>
        </div>
        <div className="list-content">
          <ul>
            <li>
              <span></span>맞춤형 제작 상품으로 인해, 교재 발송 이후 반품은
              불가합니다.
            </li>
            <li>
              <span></span>상품의 문제가 있는 경우 수령 후 7일 이내 라이브올
              고객센터로 사유와 함께 문의해 주시기 바랍니다.
            </li>
          </ul>
        </div>
      </div> */}
    </div>
  );
}

export default React.memo(ClassIntro);
