import React, { useState } from 'react';
import useClassFavorite from 'components/items/hooks/useClassFavorite';
import { heart_off, heart_on } from 'assets/images/icon';

function Heart({ data }) {
  const { favoriteCount, favoriteYn, onAddFavorite } = useClassFavorite(
    data.class_seq,
    data.favorite_count,
    data.favorite_yn,
  );

  return (
    <button className="favorite-count-icon" onClick={onAddFavorite}>
      <img src={favoriteYn ? heart_on : heart_off} alt="하트 이미지" />
      <span className="num">{favoriteCount}</span>
    </button>
  );
}

export default React.memo(Heart);
