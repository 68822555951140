import React from 'react';
import { useNavigate } from 'react-router';

// GoBackButton.js

function GoBackButton({ name, path }) {
    const navigate = useNavigate();

    return (
        <button type="button" className="btnGoBack" onClick={() => navigate(path || -1)}>{name ? name : '목록'}</button>
    );
}

export default React.memo(GoBackButton);
