import React, { useState } from 'react';
import { toCurrency, getDiscountRate } from 'common/utils';

function BasePrice({ data, discountRate }) {
  return (
    <>
      {discountRate > 0 && (
        <span className="base-price">
          {toCurrency(data.price)}
          <span>원</span>
        </span>
      )}
    </>
  );
}

export default React.memo(BasePrice);
