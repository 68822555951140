import React, { useState } from 'react';
import { Typography, Grid } from '@material-ui/core';
// import 'assets/css/pages/classDDetail.scss';

// ClassNotice.js

function ClassNotice({ data }) {
  return (
    <Grid className="classNotice">
      <Typography variant="h3">클래스 정보</Typography>
      <ul className="class-info-list">
        <li className="classinfo-item">
          <span className="classinfo-icon"></span>
          <p>커리큘럼 {data.curriculum_count ? data.curriculum_count : 0}회</p>
        </li>
        <li className="classinfo-item">
          <span className="duration-icon"></span>
          <p>1회 수업 당 {data.class_time}분</p>
        </li>
        {data.worksheet_yn && (
          <li className="classinfo-item">
            <span className="package-icon"></span>
            <p>교재포함</p>
          </li>
        )}
        <li className="classinfo-item">
          <span className="usercount-icon"></span>
          {data.all_age_yn ? (
              data.all_age_yn == 2 ?
                <p>학부모 추천</p> :
                <p>전연령 추천</p>
          ) : (
            <p>
              {data.min_target_age}~{data.max_target_age}세 추천
            </p>
          )}
        </li>
        <li className="classinfo-item">
          <span className="targetage-icon"></span>
          <p>
            {data.min_user_count}~{data.max_user_count}명 정원
            {/* 2022-08-01 고정 텍스트 수정 */}
          </p>
        </li>
      </ul>
      {/* <Typography variant="h3">
        <span className="notice-icon"></span>알립니다
      </Typography>
      {data.isOneDay && (
        <Typography className="notice-text">
          <Typography>
            - 스케줄 확정 전에는 언제든지 변경 및 환불이 가능하며, 확정 이후에는
            변경 및 환불이 불가합니다.
          </Typography>
          <Typography>
            - 스케줄 확정 전이라도 수업 자료를 다운로드한 경우 변경 및 환불이
            불가합니다.
          </Typography>
          <Typography>
            - 준비물 배송이 없는 수업의 경우, 수업 2일 전까지 변경 및 환불이
            가능합니다.
          </Typography>
          <Typography>
            - 준비물 배송이 있는 수업의 경우, 수업 7일 전까지 변경 및 환불이
            가능합니다.
          </Typography>
          <Typography>
            - 스케줄 확정 전까지 최소 인원이 모집되지 않은 스케줄은 폐강되며,
            결제하신 수단으로 환불됩니다.
          </Typography>
        </Typography>
      )}
      {data.isPackage && (
        <Typography className="notice-text">
          <Typography>
            - 패키지(다회차)수업의 첫 스케줄 확정 전에는 언제든지 변경 및 환불이
            가능합니다.
          </Typography>
          <Typography>
            - 준비물 배송이 없는 수업의 경우, 수업 2일 전까지 변경 및 환불이
            가능합니다.
          </Typography>
          <Typography>
            - 준비물 배송이 있는 수업의 경우, 수업 7일 전까지 변경 및 환불이
            가능합니다.
          </Typography>
          <Typography>- 자세한 내용은 환불 규정을 참고해 주세요.</Typography>
          <Typography>
            - 스케줄 확정 전까지 최소 인원이 모집되지 않은 스케줄은 폐강되며,
            결제하신 수단으로 환불됩니다.
          </Typography>
        </Typography>
      )} */}
    </Grid>
  );
}

export default React.memo(ClassNotice);
