import React, { useCallback } from 'react';
// import 'assets/css/index/sub-teacher-card.css';
import 'assets/css/components/teacherCard.scss';

// TeacherCard.js

function TeacherCard({ dataList }) {
    const onClick = useCallback((data) => {
        // console.log(data);
    }, []);

    return (
        <ul className="sub-teacher-card-wrap">
            {dataList.map((data) => (
                <li key={data.teacher_seq} onClick={() => onClick(data)}>
                    <h1>{data.teacher_nickname}선생님</h1>
                </li>
            ))}
        </ul>
    );
}

export default React.memo(TeacherCard);
