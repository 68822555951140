import 'assets/css/components/modal.scss';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React from 'react';
import Typography from '@mui/material/Typography';

function ModalType1(props) {
  const {
    children,
    title,
    buttonCancel,
    buttonApply,
    handleClose,
    handleApply,
  } = props;
  return (
    <>
      <div>
        <Typography variant="h4" component="h4" className="popTitle">
          {title || ''}
        </Typography>
        <div className="popContents">
          {React.cloneElement(children, { ...props })}
            <div className="popBottom">
              {buttonCancel && (
                <Button
                  className="pop-button pop-button-round pop-button-type1"
                  onClick={handleClose}
                >
                  {buttonCancel}
                </Button>
              )}
              {buttonApply && (
                <Button
                  className="pop-button pop-button-round pop-button-type2"
                  onClick={handleApply}
                >
                  {buttonApply}
                </Button>
              )}
            </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(ModalType1);
