import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useAppSettings } from 'common/hooks';

// 2023-05-04 메인화면 팝업용 컴포넌트 추가
function ModalMainPopup(props) {
  const {
    children,
    className,
    buttonName,
    modalinfo,
    visible,
    handleOpen,
    handleClose,
  } = props;

  const { screenTypes } = useAppSettings();

  // const buttons = useMemo(() => {
  //   if (modalinfo === 'inqiory-create') {
  //     return (
  //       <Button onClick={handleOpen} className="qa-btn">
  //         클래스 문의하기
  //       </Button>
  //     );
  //   }
  //   if (modalinfo === 'other-button') {
  //     return null;
  //   }
  //   return (
  //     <Button onClick={handleOpen} className={className} variant="contained">
  //       {buttonName}
  //     </Button>
  //   );
  // }, [buttonName, className, handleOpen, modalinfo]);

    return (
        <>
            <Modal
                open={visible}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={'modal-pop-wrap pop-wrap' + className}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                    maxWidth: 500,
                    width: screenTypes.isDesktopAndTablet ? 500 : 300,
                    p: 4,
                }} className="mainModal-pop-contents">
                    {React.cloneElement(children, { props, handleClose })}
                </Box>
            </Modal>
        </>
    );
}

export default React.memo(ModalMainPopup);
