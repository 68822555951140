import React, { useCallback, useState } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { Typography } from '@material-ui/core';
import { DOMAIN } from 'common/constants';
import { useNavigate } from 'react-router';
import 'assets/css/pages/main.scss'; 
// MainMagazineSlide.js

function MainMagazineSlide({ data }) {
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);
    const [selected, setSelected] = useState(false);

    const onClick = useCallback(() => {
        navigate(`/magazine/${data.magazine_seq}`);
    }, [data, navigate]);

    return (
        <Card sx={{ maxWidth: 276 }} className="main-magazine-item" onClick={onClick}>
            <div className="txt-position">
                <Typography variant="h3">{data.magazine_title}</Typography>
                <Typography>{data.magazine_sub_title}</Typography>
            </div>
            <CardMedia component="img" height="194" src={data.main_image_url} alt={data.magazine_title} />
        </Card>
    );
}

export default React.memo(MainMagazineSlide);
