import { METHOD } from 'common/hooks';

export const codeApi = (requestFunc) => {
  return {
    // 코드 관리
    codes: {
      key: 'codes', // 코드 조회
      queryFn: (params) => requestFunc(METHOD.GET, '/codes', params),
    },
    codesAll: {
      key: 'codesAll', // 코드 조회
      queryFn: () => requestFunc(METHOD.GET, '/codes/all'),
    },
  };
};
