import { useEffect, useMemo, useState } from 'react';
import { useApiContext } from 'common/context/ApiContext';
import { useLoginContext, useKakaoContext } from 'common/context/MemberContext';
import useCommonQuery from 'common/hooks/useCommonQuery';
import useCommonMutation from 'common/hooks/useCommonMutation';
import { useNavigate } from 'react-router';

function AutoLogin() {
  const navigate = useNavigate();
  const { queries } = useApiContext();
  const { isLogin, action, loginState } = useLoginContext();
  const { setAccessToken, setLoginData } = action;
  const { action: kakaoAction } = useKakaoContext();
  const [logined, setLogined] = useState(false);

  // console.log(loginState);

  const accessToken = localStorage.getItem('accessToken');
  const kakaoAccessToken = sessionStorage.getItem('kakaoAccessToken');
  // if (accessToken && accessToken.length > 0) {
  //   setAccessToken(accessToken);
  // }

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('login_callback');
    };
  }, []);

  useEffect(() => {
    if (
      accessToken &&
      accessToken.length > 0 &&
      loginState.accessToken.length === 0
    ) {
      setAccessToken(accessToken);
    }
  }, [accessToken, loginState.accessToken]);

  useEffect(() => {
    if (kakaoAccessToken && kakaoAccessToken.length > 0) {
      kakaoAction.setKakaoAccessToken(kakaoAccessToken);
    }
  }, [kakaoAccessToken]);

  useEffect(() => {
    if (
      accessToken &&
      accessToken.length > 0 &&
      loginState.accessToken &&
      loginState.accessToken.length > 0 &&
      !logined
    ) {
      // console.log(loginState.accessToken);
      requestAuthorize();
    }
  }, [accessToken, loginState.accessToken, logined]);

  const { request: requestMemberInfo } = useCommonQuery({
    query: queries.myProfile,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          // console.log(data.result_data);
          // memberAction.setMemberData(data.result_data);
        } else {
          console.log(data.result_message);
          localStorage.removeItem('accessToken');
          sessionStorage.removeItem('kakaoAccessToken');
        }
      },
      onError: (error) => {
        // console.log(error);
        localStorage.removeItem('accessToken');
        sessionStorage.removeItem('kakaoAccessToken');
      },
    },
    initEnabled: false,
  });

  const { requestAsync: requestAuthorize } = useCommonMutation({
    query: queries.authorize,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          // console.log(data.result_data);
          setLogined(true);
          setLoginData(data.result_data);

          // let pathname = sessionStorage.getItem('login_callback');
          // pathname = pathname?.length > 0 ? pathname : '/';
          // navigate(pathname, { replace: true });
          sessionStorage.removeItem('login_callback');

          // window.ChannelIO('shutdown');
          // console.log(
          //   `memberId=[${data.result_data.member_seq}], name=[${data.result_data.member_name}], mobileNumber=[${data.result_data.phone}]`,
          // );
          window.ChannelIO(
            'boot',
            {
              pluginKey: 'b1b2fb02-3d12-4b71-b160-fdf971314b1b',
              customLauncherSelector: '.chat-icon-wrap',
              hideChannelButtonOnBoot: true,
              language: 'ko',
              memberId: `${data.result_data.member_seq}`, //fill with user id
              profile: {
                name: `${
                  data.result_data.member_name
                    ? data.result_data.member_name
                    : ''
                }`, //fill with user name
                mobileNumber: `${
                  data.result_data.profile && data.result_data.profile.phone
                    ? data.result_data.profile.phone.replaceAll('+82 ', '0')
                    : ''
                }`,
                email: `${
                  data.result_data.profile && data.result_data.profile.email
                    ? data.result_data.profile.email
                    : ''
                }`,
              },
            },
            function onBoot(error, user) {
              if (error) {
                console.error(error);
              } else {
                // console.log('AutoLogin : boot success=>', user);
              }
            },
          );
        } else {
          console.log(data.result_message);
          localStorage.removeItem('accessToken');
          sessionStorage.removeItem('kakaoAccessToken');
          sessionStorage.removeItem('login_callback');
        }
      },
      onError: (error) => {
        // console.log(error);
        localStorage.removeItem('accessToken');
        sessionStorage.removeItem('kakaoAccessToken');
        sessionStorage.removeItem('login_callback');
      },
    },
  });

  return null;
}

export default AutoLogin;
