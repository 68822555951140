import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useCommonMutation, useCommonQuery } from 'common/hooks';
import { useApiContext } from 'common/context';
import usePopAlert from 'components/popAlert/hooks/usePopAlert';
import useBasicPagination from 'components/utils/hooks/useBasicPagination';

function useComments({ classInfo, classroomSeq, classmateSeq, user }) {
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const [comments, setComments] = useState([]);
  const paginationInput = useBasicPagination(comments.length, 10);

  const { request: requestFeedbacks } = useCommonQuery({
    query: queries.classroomFeedbacks,
    params: {
      classroom_seq: classroomSeq,
      classmate_seq: classmateSeq,
      page_per_count: 10,
      current_page: paginationInput.page,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          // console.log(data.result_data);
          paginationInput.updateTotalCount(data.result_data.total_count);
          setComments(data.result_data.data);
        } else {
          console.log(data.result_message);
          //   showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        // console.log(error);
        //   showAlert('알림', '스케줄 조회에 실패했습니다.');
      },
    },
  });

  const { request: requestRegistFeedback } = useCommonMutation({
    query: queries.addClassroomFeedback,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          // console.log(data.result_data);
          // const cleanComments = getCleanComments();
          // setComments(cleanComments.concat(data.result_data));
          requestFeedbacks();
        } else {
          console.log(data.result_message);
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        // console.log(error);
        showAlert('알림', '피드백 등록에 실패했습니다.');
      },
    },
  });

  const { request: requestReplyFeedback } = useCommonMutation({
    query: queries.addReplyFeedback,
    callbacks: {
      onSuccess: (data, variables) => {
        if (data.result_code === '0000') {
          // console.log(data.result_data, variables);
          // setCommentStates(
          //   emptyComment.feedback_seq,
          //   variables.feedback_seq,
          //   { ...data.result_data, isTemp: false, isReply: false },
          //   true,
          // );
          requestFeedbacks();
        } else {
          console.log(data.result_message);
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        // console.log(error);
        showAlert('알림', '피드백 등록에 실패했습니다.');
      },
    },
  });

  const { request: requestDeleteFeedback } = useCommonMutation({
    query: queries.deleteFeedback,
    callbacks: {
      onSuccess: (data, variables) => {
        if (data.result_code === '0000') {
          // console.log(data.result_data, variables);
          // setCommentStates(variables.feedback_seq, variables.parent_seq, {
          //   delete_yn: 1,
          // });
          requestFeedbacks();
        } else {
          console.log(data.result_message);
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        // console.log(error);
        showAlert('알림', '피드백 삭제에 실패했습니다.');
      },
    },
  });

  const { request: requestUpdateFeedback } = useCommonMutation({
    query: queries.updateFeedback,
    callbacks: {
      onSuccess: (data, variables) => {
        if (data.result_code === '0000') {
          // console.log(data.result_data, variables);
          setCommentStates(variables.feedback_seq, variables.parent_seq, {
            ...data.result_data,
            isReply: false,
          });
        } else {
          console.log(data.result_message);
          showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        // console.log(error);
        showAlert('알림', '피드백 수정에 실패했습니다.');
      },
    },
  });

  const emptyComment = useMemo(() => {
    return {
      birth: user ? user.birth : '',
      childs: [],
      classmate_seq: 0,
      classroom_seq: 0,
      constructor_name: user ? user.user_name : '',
      constructor_seq: 0,
      contents: '',
      created_dt: '',
      deleted_dt: '',
      deleted_yn: 0,
      feedback_seq: 0,
      file_key: '',
      file_original_name: '',
      file_url: '',
      modified_dt: '',
      modifier_seq: 0,
      parent_seq: 0,
      profile_image_url: '',
      subject: '',
      isReply: true,
      isTemp: true,
    };
  }, [user]);

  const getCleanComments = useCallback(
    (includeTemp = false) => {
      const result = [];
      for (let i = 0; i < comments.length; i++) {
        if (!includeTemp && comments[i].isTemp) {
          continue;
        }

        const childs = [];
        for (let j = 0; j < comments[i].childs.length; j++) {
          if (!includeTemp && comments[i].childs[j].isTemp) {
            continue;
          }

          childs.push({ ...comments[i].childs[j], isReply: false });
        }

        result.push({
          ...comments[i],
          isReply: false,
          childs,
        });
      }
      return result;
    },
    [comments],
  );

  const setCommentStates = useCallback(
    (id, parentId, props, includeTemp = false) => {
      // console.log(id, parentId, props);
      const cleanComments = getCleanComments(includeTemp);
      if (parentId === undefined) {
        setComments(
          cleanComments.map((comment) =>
            comment.feedback_seq === id ? { ...comment, ...props } : comment,
          ),
        );
      } else {
        setComments(
          cleanComments.map((comment) => {
            // console.log(comment);
            return comment.feedback_seq === parentId
              ? {
                  ...comment,
                  childs: comment.childs.map((childComment) =>
                    childComment.feedback_seq === id
                      ? { ...childComment, ...props }
                      : childComment,
                  ),
                }
              : comment;
          }),
        );
      }
    },
    [getCleanComments],
  );

  const handleRegist = useCallback(
    (e, data, id, parentId, isUpdate) => {
      if (!data.text || data.text.trim().length === 0) {
        showAlert('알림', '내용을 입력해 주세요.');
        return;
      }
      // console.log(data, isUpdate);

      if (isUpdate) {
        const formData = new FormData();
        formData.append('contents', data.text);
        if (data.file) {
          formData.append('attachment', data.file.info);
        }
        requestUpdateFeedback({
          feedback_seq: id,
          parent_seq: parentId,
          data: formData,
        });
        return;
      }

      if (parentId) {
        const formData = new FormData();
        formData.append('classmate_seq', classmateSeq);
        if (data.file) {
          formData.append('attachment', data.file.info);
        }
        formData.append('contents', data.text);
        requestReplyFeedback({ feedback_seq: parentId, data: formData });
      } else {
        const formData = new FormData();
        formData.append('classroom_seq', classroomSeq);
        formData.append('classmate_seq', classmateSeq);
        if (data.file) {
          formData.append('attachment', data.file.info);
        }
        formData.append('contents', data.text);
        requestRegistFeedback({ classroom_seq: classroomSeq, data: formData });
      }
    },
    [
      classmateSeq,
      classroomSeq,
      requestUpdateFeedback,
      requestReplyFeedback,
      requestRegistFeedback,
      showAlert,
    ],
  );

  const handleReply = useCallback(
    (e, parentId) => {
      const cleanComments = getCleanComments();
      setComments(
        cleanComments.map((comment) => {
          return comment.feedback_seq === parentId
            ? {
                ...comment,
                childs: comment.childs.concat({
                  ...emptyComment,
                }),
              }
            : comment;
        }),
      );
    },
    [emptyComment, getCleanComments],
  );

  const handleUpdate = useCallback(
    (e, id, parentId) => {
      // console.log(id, parentId);
      setCommentStates(id, parentId, { isReply: true });
    },
    [setCommentStates],
  );

  const handleDelete = useCallback(
    (e, id, parentId) => {
      // console.log('handle delete...', id);
      showConfirm('알림', '정말 삭제하시겠습니까?', {
        confirmHandler: () => {
          requestDeleteFeedback({
            feedback_seq: id,
            parent_seq: parentId,
          });
        },
      });
    },
    [requestDeleteFeedback, showConfirm],
  );

  useEffect(() => {
    requestFeedbacks();
  }, [paginationInput.page]);

  return {
    comments,
    paginationInput,
    handleRegist,
    handleReply,
    handleUpdate,
    handleDelete,
  };
}

export default useComments;
