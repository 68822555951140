import React from "react";
import { useToastContext } from "../context/ToastContext";

const useToast = () => {
  const { state, action } = useToastContext(); //전역 상태
  const { showToast, hideToast, clickToast, nextToast } = action;

  return {
    state,
    showToast,
    hideToast,
    clickToast,
    nextToast,
  };
};

export default useToast;


// *** HOW TO USE ***

{/* <Button
  onClick={() =>
    showToast(
      "TEXT.",
      {
        clickHandler: () => alert("clicked"),
        nextHandler: () => alert("next"),
      },
      { duration: 3000 }
    )
  }
>
  test toast
</Button>; */}

// *******************
