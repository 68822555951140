import { useInput, useFileInput } from 'common/hooks';
import React, { useCallback, useEffect } from 'react';
import { Desktop, TabletAndMobile } from 'components/utils/MediaQuery';
import { getAge } from 'common/utils';
import { BlueCloseIcon } from 'assets/images/icon';
import 'assets/css/feedback/classFeedback.css';

// Comment.js

function Comment({
  user,
  handleRegist,
  comment = undefined,
  parentId = undefined,
  isNew = false,
}) {
  const commentInput = useInput(comment ? comment.contents : '', 3000);
  const fileInput = useFileInput(10000, {
    name: comment?.file_original_name ? comment.file_original_name : '',
    file: undefined,
    info: {},
  });
  const { feedback_seq } = comment ? comment : {};

  const onRegistClick = useCallback(
    (e) => {
      const data = {
        text: commentInput.state,
        file: fileInput.state.file ? fileInput.state : null,
      };
      // console.log(data, feedback_seq, parentId, comment);
      handleRegist(
        e,
        data,
        feedback_seq,
        parentId,
        comment !== undefined && !comment.isTemp,
      );
      if (isNew) {
        commentInput.reset();
        fileInput.reset();
      }
    },
    [
      commentInput,
      fileInput,
      handleRegist,
      feedback_seq,
      parentId,
      comment,
      isNew,
    ],
  );

  const onRemoveFileClick = useCallback(() => {
    fileInput.reset(true);
  }, []);

  return (
    <div className="comment">
      <Desktop>
        <div className="comment_textWrap">
          <textarea
            maxLength={3000}
            value={commentInput.state}
            placeholder={
              isNew
                ? `※ 수업 관련 과제와 피드백 등을 선생님과 1:1로 주고받을 수 있습니다. \n※ 수업과 관련 없는 내용은 통보 없이 삭제 처리될 수 있습니다. \n※ 첨부파일은 1개 등록 가능하며 용량은 최대 10MB로 제한됩니다.`
                : ''
            }
            onChange={commentInput.handleChange}
           
            
          />
          {/* 2022-08-04 피드백 파일 삭제 버튼 추가 시작 */}
          <div className="comment-file-wrap">
            <span className="file">{fileInput.state.name}</span>
            {fileInput.state.name.length > 0 && (
              <button className="delete-btn-wrap" onClick={onRemoveFileClick}>
                <img src={BlueCloseIcon} alt="삭제 버튼 이미지" />
              </button>
            )}
          </div>
          {/* 2022-08-04 끝 */}
          <span className="len">({commentInput.state.length}/3000)</span>
        </div>

        <div className="comment_bottom">
          <input
            onChange={fileInput.handleChange}
            type="file"
            id={`task_file ${feedback_seq}`}
          />
          <label htmlFor={`task_file ${feedback_seq}`}></label>
          <button onClick={onRegistClick}>등록</button>
        </div>
      </Desktop>

      <TabletAndMobile>
        <div className="comment_textWrap">
          <div className="comment-top">
            {isNew ? (
              <>
                {user?.profile_image_url ? (
                  <img src={user.profile_image_url} className="avatar" />
                ) : (
                  <div className="avatar"></div>
                )}
              </>
            ) : (
              <>
                {comment?.profile_image_url ? (
                  <img src={comment.profile_image_url} className="avatar" />
                ) : (
                  <div className="avatar"></div>
                )}
              </>
            )}
            <h1 className="name">
              {user.user_name}({getAge(user.birth)}세)
            </h1>
          </div>

          <textarea
            value={commentInput.state}
            placeholder={
              isNew
                ? `※ 수업 관련 과제와 피드백 등을 선생님과 1:1로 주고받을 수 있습니다. \n※ 수업과 관련 없는 내용은 통보 없이 삭제 처리될 수 있습니다. \n※ 첨부파일은 1개 등록 가능하며 용량은 최대 10MB로 제한됩니다.`
                : ''
            }
            onChange={commentInput.handleChange}
          />
          {/* 2022-08-04 피드백 파일 삭제 버튼 추가 시작 */}
          <div className="comment-file-wrap">
            <span className="file">{fileInput.state.name}</span>
            {fileInput.state.name.length > 0 && (
              <button className="delete-btn-wrap" onClick={onRemoveFileClick}>
                <img src={BlueCloseIcon} alt="삭제 버튼 이미지" />
              </button>
            )}
          </div>
          {/* 2022-08-04 끝 */}
          <span className="len">({commentInput.state.length}/3000)</span>
        </div>
        <div className="comment_bottom">
          <input
            onChange={fileInput.handleChange}
            type="file"
            id={`task_file ${feedback_seq}`}
          />

          <label htmlFor={`task_file ${feedback_seq}`}></label>
          <button onClick={onRegistClick}>등록</button>
        </div>
      </TabletAndMobile>
    </div>
  );
}

export default React.memo(Comment);
