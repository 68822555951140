import { createContext, useState, useCallback, useMemo } from 'react';
import { getUseContext } from 'common/utils';

const initialData = {
  login: {
    state: {
      member_name: '',
      member_type_code: '',
      member_type_name: '',
      grade_type_code: '',
      grade_Type_name: '',
      point: {
        earned_point: 0,
        charged_point: 0,
      },
      coupon_count: 0,
      accessToken: '',
      contract: {
        bookclub: '',
        customer_number: '',
        selected_contract: '',
      },
      created_dt: ''
    },
  },
  member: {
    state: {
      member_type_code: 0,
      member_type_value: '',
      grade_type_code: 0,
      grade_type_value: '',
      point: {
        earned_point: 0,
        charged_point: 0,
      },
      coupon_count: 0,
    },
  },
  kakao: {
    state: {
      access_token: '',
      id_token: '',
      refresh_token: '',
    },
  },
};

const LoginContext = createContext();
const MemberContext = createContext();
const KakaoContext = createContext();

function MemberProvider({ children }) {
  const [loginState, setLoginState] = useState(initialData.login.state);
  const [memberState, setMemberState] = useState(initialData.member.state);
  const [kakaoState, setKakaoState] = useState(initialData.kakao.state);

  const setLoginData = useCallback((data) => {
    setLoginState((prevState) => ({ ...prevState, ...data }));
  }, []);

  const setAccessToken = useCallback(
    (data) => {
      if (loginState.accessToken === data) {
        return;
      }
      setLoginState((prevState) => ({ ...prevState, accessToken: data }));
    },
    [loginState.accessToken],
  );

  const updateLoginProfile = useCallback((data) => {
    setLoginState((prev) => {
      return {
        ...prev,
        profile: {
          ...prev.profile,
          ...data,
        },
      };
    }, []);
  }, []);

  const updateLoginUsers = useCallback((users) => {
    setLoginState((prev) => {
      return {
        ...prev,
        users,
      };
    }, []);
  }, []);

  const resetLoginData = useCallback(() => {
    setLoginState(() => ({ ...initialData.login.state }));
  }, []);

  const isLogin = useMemo(
    () =>
      loginState.accessToken &&
      loginState.accessToken.length > 0 &&
      loginState.member_seq &&
      loginState.member_seq > 0,
    [loginState.accessToken, loginState.member_seq],
  );

  const setMemberData = useCallback((data) => {
    setMemberState(() => ({ ...data }));
  }, []);

  const resetMemberData = useCallback(() => {
    setMemberState(() => ({ ...initialData.member.state }));
  }, []);

  const setKakaoData = useCallback((data) => {
    setKakaoState(() => ({ ...data }));
  }, []);

  const setKakaoAccessToken = useCallback(
    (data) => {
      if (kakaoState.access_token === data) {
        return;
      }
      setKakaoState((prevState) => ({ ...prevState, access_token: data }));
    },
    [kakaoState.access_token],
  );

  const resetKakaoData = useCallback(() => {
    setKakaoState(() => ({ ...initialData.kakao.state }));
  }, []);

  const loginValue = useMemo(() => {
    return {
      isLogin,
      loginState,
      action: {
        setLoginData,
        resetLoginData,
        updateLoginProfile,
        updateLoginUsers,
        setAccessToken,
      },
    };
  }, [
    isLogin,
    loginState,
    resetLoginData,
    setAccessToken,
    updateLoginProfile,
    updateLoginUsers,
    setLoginData,
  ]);

  const memberValue = useMemo(() => {
    return {
      memberState,
      action: { setMemberData, resetMemberData },
    };
  }, [memberState, resetMemberData, setMemberData]);

  const kakaoValue = useMemo(() => {
    return {
      kakaoState,
      action: { setKakaoData, resetKakaoData, setKakaoAccessToken },
    };
  }, [kakaoState, resetKakaoData, setKakaoData, setKakaoAccessToken]);

  return (
    <LoginContext.Provider value={loginValue}>
      <MemberContext.Provider value={memberValue}>
        <KakaoContext.Provider value={kakaoValue}>
          {children}
        </KakaoContext.Provider>
      </MemberContext.Provider>
    </LoginContext.Provider>
  );
}

export const useLoginContext = () => getUseContext(LoginContext);
export const useMemberContext = () => getUseContext(MemberContext);
export const useKakaoContext = () => getUseContext(KakaoContext);
export default MemberProvider;
