export const CATEGORY = new Map([
  [10, "/class/"],
  [20, "/togetherClass/"],
  [30, "/event/"],
]);

export const SUB_CATEGORY = new Map([
  [10, "/class/best/"],
  [20, "/togetherClass/best/"],
  [30, "/event/category/"],
]);
