import { METHOD } from 'common/hooks';

export const categoryApi = (requestFunc) => {
  return {
    // 공통
    categories: {
      key: 'categories', // 전체 카테고리 조회
      queryFn: () => requestFunc(METHOD.GET, `/categories`),
    },
    subCategories: {
      key: 'subCategories', // 하위 카테고리 조회
      queryFn: (params) =>
        requestFunc(METHOD.GET, `/categories/${params.category_seq}`),
    },
    classesByCategory: {
      key: 'classesByCategory', // 카테고리 클래스 조회
      queryFn: (params) =>
        requestFunc(METHOD.GET, `/categories/${params.category_seq}/classes`),
    },
  };
};
