import { METHOD } from 'common/hooks';

export const calendarApi = (requestFunc) => {
  return {
    // 캘린더
    monthlyCalendar: {
      key: 'monthlyCalendar', // 캘린더
      queryFn: (params) => requestFunc(METHOD.GET, `/calendar-monthly`, params),
    },
    weeklyCalendar: {
      key: 'weeklyCalendar', // 캘린더
      queryFn: (params) => requestFunc(METHOD.GET, `/calendar-weekly`, params),
    },
    refreshMonthlyCalendar: {
      key: 'refreshMonthlyCalendar', // 캘린더
      queryFn: (params) => requestFunc(METHOD.GET, `/calendar-monthly`, params),
    },
    refreshWeeklyCalendar: {
      key: 'refreshWeeklyCalendar', // 캘린더
      queryFn: (params) => requestFunc(METHOD.GET, `/calendar-weekly`, params),
    },
  };
};
