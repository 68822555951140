import { METHOD } from 'common/hooks';

export const authApi = (requestFunc) => {
    return {
        // 인증
        authorize: {
            key: 'authorize', // 멤버 실제 로그인
            queryFn: () => requestFunc(METHOD.POST, '/auth/authorize'),
        },
        kakaoLogin: {
            key: 'kakaoLogin',
            queryFn: (params) => requestFunc(METHOD.POST, `/auth/kakao`, params),
        },
        join: {
            key: 'join', // 회원가입
            queryFn: (params) => requestFunc(METHOD.POST, '/auth/join', params),
        },
        avatars: {
            key: 'avatars', //
            queryFn: () => requestFunc(METHOD.GET, '/avatars'),
        },
        checkEmail: {
            key: 'checkEmail', // 이메일 유효성 체크
            queryFn: (params) => requestFunc(METHOD.GET, '/auth/checkEmail', params),
        },
    };
};
