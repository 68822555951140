import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import ClassReview from './ClassReview';
import ClassInquiry from './ClassInquiry';
import ClassIntro from './ClassIntro';
import ClassSupplies from './ClassSupplies';
import 'assets/css/detail/detailTabBox.css';

// DetailTabBox.js

const tabList = ['클래스 후기', '클래스 문의'];

function DetailTabBox({
  teacherData,
  requestUpdateInquiry,
  requestDeleteInquiry,
}) {
  const [currentTab, setCurrentTab] = useState(0);
  const [inquiryTotalCount, setInquiryTotalCount] = useState(0);
  const updateInquiryCount = useCallback((count) => {
    setInquiryTotalCount(count);
  }, []);

  const onTabClick = useCallback((e) => {
    const targetId = e.target.getAttribute('dataid');
    setCurrentTab(parseInt(targetId));
  }, []);

  const tabArea = useMemo(() => {
    if (!teacherData) {
      return null;
    }

    if (currentTab === 0) {
      return (
        <ClassReview
          teacherData={teacherData}
          star={teacherData?.star}
          showClassName
        />
      );
    } else if (currentTab === 1) {
      return (
        <ClassInquiry
          teacherData={teacherData}
          updateInquiryCount={updateInquiryCount}
          enableRegist={false}
        />
      );
    }
  }, [currentTab, teacherData, updateInquiryCount]);

  return (
    <div className="detailTabBox_Container">
      <ul className="detailTabList">
        {tabList.map((tab, index) => (
          <li
            key={index}
            dataid={index}
            className={currentTab === index ? 'isOn' : ''}
            onClick={onTabClick}
          >
            {tab}
          </li>
        ))}
      </ul>
      <div className="detailTabBox_wrap">{tabArea}</div>
    </div>
  );
}

export default React.memo(DetailTabBox);
