import React, { useMemo, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SearchIcon from 'assets/images/common/search_icon@2x.png';
import useSearchBar from './hooks/useSearchBar';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Button from '@mui/material/Button';
import { Link, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router';

import 'assets/css/components/searchBar.scss'

// search-bar.js

function SearchBar({
  keyword = '',
  hideOption = false,
  seachbarHandles = undefined,
}) {
  const {
    searchRef,
    searchKeyword,
    popularKeywords,
    optionVisible,
    handleSearchClick,
    handleSearchKeyDown,
    handlePopularClick,
    handleSearchOptionShow,
    handleSearchOptionHide,
  } = useSearchBar(keyword);
  const { pathname } = useLocation();
  const searchedKeyword = sessionStorage.getItem('searchKeyword');

  useEffect(() => {
    if (pathname.indexOf('/search') < 0) {
      searchKeyword.reset();
    }
  }, [pathname]);

  useEffect(() => {
    if (searchedKeyword?.length > 0) {
      searchKeyword.update(searchedKeyword);
    } else {
      searchKeyword.reset();
    }
  }, [searchedKeyword]);

  const test = (e) => {
    // console.log(e.target, e.currentTarget, e.clientX);
    // e.preventDefault();
    // e.stopPropagation();
    handleSearchOptionHide();
  };

  const maxScreen = useMediaQuery({ query: '(max-width: 1260px)' });

    return (
        <Box
            component="form"
            // sx={
            //   !maxScreen
            //     ? {
            //         '& .MuiTextField-root': { m: 3, width: '500px' },
            //         '.css-c63i49-MuiInputBase-input-MuiInput-input': { width: '80%' },
            //       }
            //     : {
            //         '& .MuiTextField-root': { m: 3, width: '100%', margin: 0 }, //2022-07-21 width 퍼센트 수정
            //         '.css-c63i49-MuiInputBase-input-MuiInput-input': { width: '90%' },
            //       }
            // }
            noValidate
            autoComplete="off"
        >
            <div className="nav-search-box">
                <TextField
                    ref={searchRef}
                    id="standard-search"
                    type="search"
                    variant="standard"
                    className="search-input"
                    value={searchKeyword.state}
                    onChange={searchKeyword.handleChange}
                    onFocus={handleSearchOptionShow}
                    onBlur={test}
                    onKeyDown={handleSearchKeyDown}
                    placeholder="원하는 클래스를 검색하세요."
                />
                <button className="search-btn" onClick={handleSearchClick}>
                    <img src={SearchIcon} alt="검색" />
                </button>

                {!hideOption && optionVisible && popularKeywords.length > 0 && (
                    <div className="search-result-wrap">
                        <div className="tit">추천 검색어</div>
                        <ul className="rank">
                            {popularKeywords && popularKeywords.map((keyword, index) => (
                                <li key={keyword.keyword}>
                                    <Link to={`/search/result?keyword=${keyword.keyword}`} onMouseDown={(e) => { handlePopularClick(e, keyword.keyword); }}>
                                        {keyword.keyword}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                        <Button className="close" onClick={handleSearchOptionHide}>닫기 <CloseRoundedIcon sx={{ fontSize: '25px' }} /></Button>
                    </div>
                )}
            </div>
        </Box>
    );
}
export default React.memo(SearchBar);
