import { METHOD } from 'common/hooks';

export const magazineApi = (requestFunc) => {
  return {
    // 매거진
    magazineInfo: {
      key: 'magazineInfo', // 매거진 상세
      queryFn: (params) =>
        requestFunc(METHOD.GET, `/magazines/${params.magazine_seq}`),
    },
  };
};
