import { MobileClassItem } from '.'; //한줄에 카드 3개 노출되는 공통 기본 상품카드 컴포넌트

function MobileClassItemList({ dataList }) {
  return (
    <div className="itemContainer moContainer">
      {dataList.map((item, index) => (
        <MobileClassItem key={item.class_seq} data={item} />
      ))}
    </div>
  );
}

export default MobileClassItemList;
