import { METHOD } from 'common/hooks';

export const feedbackApi = (requestFunc) => {
  return {
    // 수업 문의
    feedbackInfo: {
      key: 'feedbackInfo', // 수업 피드백 상세 조회
      queryFn: (params) =>
        requestFunc(METHOD.GET, `/feedbacks/${params.feedback_seq}`),
    },
    updateFeedback: {
      key: 'updateFeedback', // 수업 피드백 수정
      queryFn: (params) =>
        requestFunc(
          METHOD.PATCH,
          `/feedbacks/${params.feedback_seq}`,
          params.data,
        ),
    },
    deleteFeedback: {
      key: 'deleteFeedback', // 수업 피드백 삭제
      queryFn: (params) =>
        requestFunc(METHOD.DELETE, `/feedbacks/${params.feedback_seq}`),
    },
    downloadFeedbackFiles: {
      key: 'downloadFeedbackFiles', // 수업 피드백 - 첨부파일 다운로드
      queryFn: (params) =>
        requestFunc(
          METHOD.GET,
          `/feedbacks/${params.feedback_seq}/download-attachment`,
        ),
    },
    addReplyFeedback: {
      key: 'addReplyFeedback', // 수업 피드백 작성
      queryFn: (params) =>
        requestFunc(
          METHOD.POST,
          `/feedbacks/${params.feedback_seq}/reply`,
          params.data,
        ),
    },
  };
};
