import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useAppSettings } from 'common/hooks';

function useScroll(tabClassName) {
    const [activeBtn, setActiveBtn] = useState(0);
    const [tempActiveBtn, setTempActiveBtn] = useState(0);
    const [ScrollActive, setScrollActive] = useState(false);
    const [clickAction, setClickAction] = useState({ 
        isClicked: false,
        target: -1,
    });
    const { screenTypes } = useAppSettings();

    const ScrollContent = useRef(null);

    const tabClickHandler = useCallback((e, i) => {
        const targetId = e.target.getAttribute('dataid');
        const element = ScrollContent.current.children[targetId];
        let HeaderHeight = document.getElementById('header').clientHeight;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - (HeaderHeight + document.getElementsByClassName(tabClassName ? tabClassName : 'detailTabList')[0].clientHeight);
        setClickAction({ isClicked: true, target: targetId });
        setActiveBtn(targetId);
        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
        });
    }, []);

    const handleScroll = useCallback(() => {
        if (ScrollContent.current === null) {
            return;
        }
        const HeaderBottom = document.getElementById('header').getBoundingClientRect().bottom;
        const upperBottom = document.getElementsByClassName('top')[0].getBoundingClientRect().bottom;
        const diff = upperBottom - HeaderBottom;
        if (diff <= 0 && screenTypes.isDesktop) {
            setScrollActive(true);
        } else if (upperBottom <= 0 && screenTypes.isTabletAndMobile) {
            setScrollActive(true);
        } else {
            setScrollActive(false);
        }
        const tabListBottom = document.getElementsByClassName(tabClassName ? tabClassName : 'detailTabList')[0].getBoundingClientRect().bottom;
        const currentElementBottom = ScrollContent.current.children[tempActiveBtn].getBoundingClientRect().bottom;

        const elementDiff = currentElementBottom - tabListBottom;
        if (elementDiff <= 0 && tempActiveBtn < 3) {
            if (!clickAction.isClicked || clickAction.target === tempActiveBtn + 1) {
                setActiveBtn(tempActiveBtn + 1);
                setClickAction({ isClicked: false, target: -1 });
            }
            setTempActiveBtn(tempActiveBtn + 1);
        } else if (tempActiveBtn > 0) {
            const prevElementBottom = ScrollContent.current.children[tempActiveBtn - 1].getBoundingClientRect().bottom;
            const prevDiff = prevElementBottom - tabListBottom;
            if (prevDiff > 0) {
                if (!clickAction.isClicked || clickAction.target === tempActiveBtn - 1) {
                    setActiveBtn(tempActiveBtn - 1);
                    setClickAction({ isClicked: false, target: -1 });
                }
                setTempActiveBtn(tempActiveBtn - 1);
            }
        }
    }, [clickAction.isClicked, clickAction.target, tempActiveBtn]);
    // console.log(prevElementBottom);

    useEffect(() => {
        function scrollListener() {
            window.addEventListener('scroll', handleScroll); //  window 에서 스크롤을 감시 시작
        }
        scrollListener(); // window 에서 스크롤을 감시
        return () => {
            window.removeEventListener('scroll', handleScroll); //  window 에서 스크롤을 감시를 종료
        };
    }, [handleScroll]);

    return {
        activeBtn,
        ScrollActive,
        handleScroll,
        tabClickHandler,
        ScrollContent,
    };
}

export default useScroll;
