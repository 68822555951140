import { METHOD } from 'common/hooks';

export const footerApi = (requestFunc) => {
  return {
    footerNotices: {
      key: 'footerNotices', // 푸터 게시판 - 목록 조회
      queryFn: (params) => requestFunc(METHOD.GET, `/notices`, params),
    },
    footerNoticeInfo: {
      key: 'footerNoticeInfo', // 푸터 게시판 - 목록 조회
      queryFn: (params) =>
        requestFunc(METHOD.GET, `/notices/${params.noticeSeq}`, params),
    },
  };
};
