import React, { useState, useCallback, useEffect } from 'react';
import { useCommonQuery } from 'common/hooks';
import { useApiContext } from 'common/context';

function useReadingBook() {
  const [keywordyName, setKeywordName] = useState('');
  const [subKeywords, setSubKeywords] = useState([]);
  const [classesByKeyword, setClassesByKeyword] = useState([]);
  const [selectedSubKeyword, setSelectedSubKeyword] = useState(undefined);
  const { queries } = useApiContext();

  // const { request: requestClassesByCategory } = useCommonQuery({
  //   query: queries.mainClassesByCategory,
  //   params: { category_seq: selectedSubCategory ? selectedSubCategory : '1' },
  //   callbacks: {
  //     onSuccess: (data) => {
  //       if (data.result_code === '0000') {
  //         console.log(data.result_data);
  //         const { category_name, childs, classes } = data.result_data;
  //         if (categoryName.length === 0) setCategoryName(category_name);
  //         if (subCategories.length === 0) setSubCategories(childs);
  //         setClassesByCategory(classes.slice(0, 6));
  //       } else {
  //         console.log(data.result_message);
  //       }
  //     },
  //     onError: (error) => {
  //       console.log(error);
  //     },
  //   },
  // });

  const { status: keywordsStatus, request: requestMainKeywords } =
    useCommonQuery({
      query: queries.mainKeywords,
      callbacks: {
        onSuccess: (data) => {
          if (data.result_code === '0000') {
            // console.log(data.result_data);
            const { main_keyword } = data.result_data;
            if (keywordyName.length === 0) setKeywordName(main_keyword.keyword);
            if (subKeywords.length === 0) setSubKeywords(main_keyword.childs);
            setClassesByKeyword(main_keyword.classes);
          } else {
            console.log(data.result_message);
          }
        },
        onError: (error) => {
          // console.log(error);
        },
      },
    });

  const { status: classesStatus, request: requestKeywordClasses } =
    useCommonQuery({
      query: queries.mainKeywords,
      params: { main_keyword: keywordyName, sub_keyword: selectedSubKeyword },
      callbacks: {
        onSuccess: (data) => {
          if (data.result_code === '0000') {
            console.log(data.result_data);
            setClassesByKeyword(data.result_data);
          } else {
            console.log(data.result_message);
          }
        },
        onError: (error) => {
          // console.log(error);
        },
      },
      initEnabled: false,
    });

  const handleSubCategorySelect = useCallback((keyword) => {
    setSelectedSubKeyword(keyword ? keyword.keyword : undefined);
  }, []);


  useEffect(() => {
    if (selectedSubKeyword) {
      requestKeywordClasses();
    } else {
      requestMainKeywords();
    }
  }, [selectedSubKeyword]);

  return {
    keywordyName,
    subKeywords,
    classesByKeyword,
    keywordsStatus,
    classesStatus,
    selectedSubKeyword,
    handleSubCategorySelect,
  };
}

export default useReadingBook;
