import React from 'react';
import ClassItem from './ClassItem'; //한줄에 카드 3개 노출되는 공통 기본 상품카드 컴포넌트

// ClassItemList.js

function ClassItemList({ dataList, showRank = false, scroll = false }) {
  return (
    <>
        {scroll ? (
            <>
                {dataList && dataList.map((data, index) => (
                    <ClassItem
                        key={data.class_seq}
                        data={data}
                        index={index}
                        showRank={showRank}
                    />
                ))}
            </>
        ) : (
            <div className="itemContainer">
                {dataList && dataList.map((data, index) => (
                    <ClassItem
                        key={data.class_seq}
                        data={data}
                        index={index}
                        showRank={showRank}
                    />
                ))}
            </div>
        )}
    </>
  );
}

export default React.memo(ClassItemList);
