import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import 'assets/css/components/pagination.scss';

// Pagination.js

function BasicPagination({ page, count = 0, onChange }) {
    return (
        <Stack spacing={2} className="pagination-type">
            <Pagination
                count={count}
                page={page}
                className="pagination-list"
                onChange={onChange}
            />
        </Stack>
    );
}

export default React.memo(BasicPagination);
