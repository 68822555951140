import React, { createContext, useState, useCallback } from "react";
import { getUseContext } from "common/utils";

const initialData = {
  state: {
    text: "",
    duration: 2000,
    position: { vertical: "top", horizontal: "center" },
    visible: false,
  },
  action: {
    showToast: () => {}, // Only for using
    hideToast: () => {},
    clickToast: () => {},
    nextToast: () => {},
  },
  callback: {
    clickHandler: () => {},
    nextHandler: () => {},
  },
};

const ToastContext = createContext();

const ToastProvider = ({ children }) => {
  const [state, setState] = useState(initialData.state);
  const [callback, setCallback] = useState(initialData.callback);
  const { clickHandler, nextHandler } = callback;

  const showToast = useCallback((text, handlers = {}, props = {}) => {
    console.log(props)
    setState(() => ({
      ...initialData.state,
      text: text,
      ...props,
      visible: true,
    }));
  
    setCallback(() => ({ ...initialData.callback, ...handlers }));
  }, []);

  const clickToast = useCallback(() => {
    setState(() => initialData.state);
    if (clickHandler && typeof clickHandler === "function") {
      clickHandler();
      setCallback(() => initialData.callback);
    }
  }, [clickHandler]);

  const hideToast = useCallback(() => { 
    setState(initialData.state);
    if (nextHandler && typeof nextHandler === "function") {
      nextHandler();
      setCallback(() => initialData.callback);
    }
  }, [nextHandler]);

  const nextToast = useCallback(() => {
    setState(() => initialData.state);
  }, []);

  const value = {
    state,
    action: { showToast, hideToast, clickToast, nextToast },
  };

  return (
    <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
  );
};

export const useToastContext = () => getUseContext(ToastContext);
export default ToastProvider;
