import React from 'react';
import { rating_star, rating_star_empty } from 'assets/images/icon';

function Star({ star }) {
    return (
        <>
            <img src={star >= 1 ? rating_star : rating_star_empty} alt="별" />
            <img src={star >= 2 ? rating_star : rating_star_empty} alt="별" />
            <img src={star >= 3 ? rating_star : rating_star_empty} alt="별" />
            <img src={star >= 4 ? rating_star : rating_star_empty} alt="별" />
            <img src={star >= 5 ? rating_star : rating_star_empty} alt="별" />
        </>
    );
}

export default Star;
