import React, { useRef, useState } from 'react';
import {
  Desktop,
  TabletAndMobile
} from 'components/utils/MediaQuery';

import 'assets/css/components/tab.scss';

// Tab.js

function Tab({ list, selectedValue, handleSelect }) {
    const tabRef = useRef(null);
    const [swiper, setSwiper] = useState();
    const [slideIndex, setSlideIndex] = useState(0);

    if (!list || list.length === 0) {
        return null;
    }

    return (
        <>
            <Desktop>
                <div className="tabBox">
                    <ul className="tabMenu">
                        <li className={!selectedValue ? 'on' : ''} onClick={() => handleSelect(undefined)}>ALL</li>
                        {list.map((item, index) => (
                            <li className={item.category_seq == selectedValue ? 'on' : ''} key={item.category_seq} onClick={() => handleSelect(item)} id={'classId_'+ item.category_seq}>{item.category_name}</li>
                        ))}
                    </ul>
                </div>
            </Desktop>

            <TabletAndMobile>
                <div className="tabBox">
                    <ul className="tabMenu">
                        <li className={!selectedValue ? 'on' : ''} onClick={() => handleSelect(undefined)}>ALL</li>
                        {list.map((item, index) => (
                            <li className={item.category_seq == selectedValue ? 'on' : ''} key={item.category_seq} onClick={() => handleSelect(item)} id={'classId_'+ item.category_seq}>{item.category_name}</li>
                        ))}
                    </ul>
                </div>
                {/* <Swiper
                slidesPerView={'auto'}
                className="tabMenu"
                ref={tabRef}
                preventClicks={false}
                preventClicksPropagation={false}
                initialSlide={slideIndex}
                // onClick={(e) => {
                //   const swipderWrap = e;
                //   swipderWrap?.slides.map((li, idx) => {
                //     if (swipderWrap.clickedIndex == idx) {
                //       li.classList.add('on');
                //     } else {
                //       li.classList.remove('on');
                //     }
                //   });
                //   setSlideIndex(swipderWrap.clickedIndex);
                //   swipderWrap.slideTo(swipderWrap.clickedIndex);
                //   swipderWrap.clickedSlide.classList.add('on');
                // }}
                >
                <SwiperSlide
                    className={!selectedValue ? 'on' : ''}
                    onClick={() => handleSelect(undefined)}
                >
                    ALL
                </SwiperSlide>
                {list.map((item, index) => (
                    <SwiperSlide
                    key={item.category_seq}
                    className={item.category_seq === selectedValue ? 'on' : ''}
                    onClick={() => handleSelect(item)}
                    >
                    {item.category_name}
                    </SwiperSlide>
                ))}
                </Swiper> */}
            </TabletAndMobile>
        </>
    );
}

export default React.memo(Tab);
