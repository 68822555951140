import { createContext, useMemo } from 'react';
import { getUseContext } from 'common/utils';
import {
    applicationApi,
    authApi,
    calendarApi,
    categoryApi,
    classApi,
    codeApi,
    mainApi,
    memberApi,
    mypageApi,
    magazineApi,
    eventApi,
    teacherApi,
    inquiryApi,
    mallApi,
    feedbackApi,
    classroomApi,
    couponApi,
    couponPolicyApi,
    footerApi,
    togetherApi
} from 'common/api';

import { useAxios } from 'common/hooks';

const ApiContext = createContext();

function ApiProvider({ children }) {
    const { requestFunc } = useAxios();

    const queries = useMemo(() => {
        return {
            ...applicationApi(requestFunc),
            ...authApi(requestFunc),
            ...calendarApi(requestFunc),
            ...categoryApi(requestFunc),
            ...classApi(requestFunc),
            ...codeApi(requestFunc),
            ...mainApi(requestFunc),
            ...memberApi(requestFunc),
            ...mypageApi(requestFunc),
            ...magazineApi(requestFunc),
            ...eventApi(requestFunc),
            ...teacherApi(requestFunc),
            ...inquiryApi(requestFunc),
            ...mallApi(requestFunc),
            ...feedbackApi(requestFunc),
            ...classroomApi(requestFunc),
            ...couponApi(requestFunc),
            ...couponPolicyApi(requestFunc),
            ...footerApi(requestFunc),
            ...togetherApi(requestFunc),
        };
    }, [requestFunc]);

    const value = useMemo(() => {
        return { queries };
    }, [queries]);

    return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
}

export const useApiContext = () => getUseContext(ApiContext);
export default ApiProvider;
