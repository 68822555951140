import { METHOD } from 'common/hooks';

export const mallApi = (requestFunc) => {
  return {
    // 몰 관리
    terms: {
      key: 'terms', // 약관 조회
      queryFn: (params) => requestFunc(METHOD.GET, `/terms`, params),
    },
  };
};
