import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

function useCardInfo({ classData, teacher, keyword }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const onKeywordClick = useCallback(() => {
        // console.log(keyword);
    }, [keyword]);

    const onClassClick = useCallback((e, classSeq) => {
        const parentRoute = pathname.indexOf('readingBook') >= 0 ? 'readingBook' : 'class';
        const linkTo = classData.test_mode_yn ? `${classData.class_seq}/hidden` : `${classData.class_seq}`;
        navigate(`/${parentRoute}/${linkTo}`);
    }, [classData, navigate, pathname]);

    const onTeacherClick = useCallback((e, teacherSeq) => {
        navigate(`/teacher/${teacherSeq ? teacherSeq : teacher.teacher_seq}`);
        e.stopPropagation();
    }, [teacher, navigate]);

    const onTogetherClassClick = useCallback(() => {
        navigate(classData.test_mode_yn ? `/togetherClass/${classData.class_seq}/hidden` : `/togetherClass/${classData.class_seq}`);
    }, [classData, navigate]);

    return {
        onClassClick,
        onTeacherClick,
        onKeywordClick,
        onTogetherClassClick,
    };
}

export default useCardInfo;
