import { useAppSettings } from 'common/hooks';

export const Desktop = ({ children }) => {
    const { screenTypes } = useAppSettings();
    return screenTypes.isDesktop && children;
};

export const Tablet = ({ children }) => {
    const { screenTypes } = useAppSettings();
    return screenTypes.isTablet && children;
};

export const Mobile1 = ({ children }) => {
    const { screenTypes } = useAppSettings();
    return screenTypes.isMobile1 && children;
};

export const Mobile2 = ({ children }) => {
    const { screenTypes } = useAppSettings();
    return screenTypes.isMobile2 && children;
};

export const DesktopAndTablet = ({ children }) => {
    const { screenTypes } = useAppSettings();
    return screenTypes.isDesktopAndTablet && children;
};

export const TabletAndMobile = ({ children }) => {
    const { screenTypes } = useAppSettings();
    return screenTypes.isTabletAndMobile && children;
};

export const AllMobile = ({ children }) => {
    const { screenTypes } = useAppSettings();
    return screenTypes.isAllMobile && children;
};
