import React, { useState, useCallback, useEffect } from 'react';
import { useCommonQuery } from 'common/hooks';
import { useApiContext } from 'common/context';
import { ConstructionOutlined } from '@mui/icons-material';

function useBest(id, classType, recommendAge, timeType) {
  const { queries } = useApiContext();
  const [classes, setClasses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(id);
  const [selectedClassType, setSelectedClassType] = useState(classType);
  const [selectedRecommendAge, setSelectedRecommendAge] = useState(recommendAge);
  const [selectedTimeType, setSelectedTimeType] = useState(timeType);

  const { request: requestBest2 } = useCommonQuery({
    query: queries.subCategories,
    params: {
      category_seq: 10,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          setCategories(data.result_data.childs);
        } else {
          console.log(data.result_message);
        }
      },
      onError: (error) => {
        // console.log(error);
      },
    },
  });

  const { status: categoriesStatus, request: requestBest } = useCommonQuery({
    query: queries.mainBest,
    params: {
      class_type: selectedClassType > 0 ? selectedClassType:null,
      time_type: selectedTimeType,
      recommend_age: selectedRecommendAge,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === '0000') {
          setClasses(data.result_data.classes);
          // if (categories.length === 0) {
          //   setCategories(data.result_data.categories);
          // }
        } else {
          console.log(data.result_message);
        }
      },
      onError: (error) => {
        // console.log(error);
      },
    },
    initEnabled: false,
  });

  const { status: classesStatus, request: requestBestClasses } = useCommonQuery(
    {
      query: queries.mainBestClasses,
      params: {
        category_seq: selectedCategory,
        class_type: selectedClassType > 0 ? selectedClassType:null,
        time_type: selectedTimeType,
        recommend_age: selectedRecommendAge,
      },
      callbacks: {
        onSuccess: (data) => {
          if (data.result_code === '0000') {
            setClasses(data.result_data);
          } else {
            console.log(data.result_message);
          }
        },
        onError: (error) => {
          // console.log(error);
        },
      },
      initEnabled: false,
    },
  );

  const handleCategorySelect = useCallback((category) => {
    setSelectedCategory(category ? category.category_seq : undefined);
    window.history.pushState("", null, `/class/${category?.category_seq>0?'best/'+category?.category_seq:''}`)
  }, []);


  const handleClassTypeSelect = useCallback((classType) => {
     setSelectedClassType(classType ? classType : undefined);
  }, []);


  const handleRecommendAgeSelect = useCallback((recommendAge) => {
     setSelectedRecommendAge(recommendAge ? recommendAge : undefined);
  }, []);

  const handleTimeSelect = useCallback((timeType) => {
    setSelectedTimeType(timeType ? timeType : undefined);
  }, []);

  useEffect(() => {
    // requestClassesByCategory();
    if (selectedCategory && selectedCategory !== "ALL") {
      requestBestClasses();
    } else {
      requestBest();
    }
  }, [selectedCategory, selectedClassType, selectedRecommendAge, selectedTimeType]);

  return {
    classes,
    categories,
    categoriesStatus,
    classesStatus,
    selectedCategory,
    selectedClassType,
    selectedRecommendAge,
    handleCategorySelect,
    handleClassTypeSelect,
    handleRecommendAgeSelect,
    handleTimeSelect
  };
}

export default useBest;
