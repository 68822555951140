import { METHOD } from 'common/hooks';

export const eventApi = (requestFunc) => {
    return {
        // 이벤트
        eventInfo: {
            key: 'eventInfo', // 이벤트 상세
            queryFn: (params) => requestFunc(METHOD.GET, `/events/${params.event_seq}`),
        },
        attendance: {
            key: 'attendance', // 출석체크
            queryFn: (params) => requestFunc(METHOD.POST, `/events/${params.event_seq}/attendance`),
        },
        getAttendance: {
            key: 'getAttendance', // 출석체크
            queryFn: (params) => requestFunc(METHOD.GET, `/events/${params.event_seq}/attendance`),
        },
        Festival: {
            festivalInfo: {
                key: 'festivalInfo',
                queryFn: (params) => requestFunc(METHOD.GET, `/events/${params.event_seq}/step-event`),
            },
            favoriteClass: {
                key: 'favoriteClass',
                queryFn: (params) => requestFunc(METHOD.POST, `/events/${params.event_seq}/stamp-favorite-class`),
            },
            festivalClass: {
                key: 'festivalClass',
                queryFn: (params) => requestFunc(METHOD.POST, `/events/${params.event_seq}/stamp-class-order`),
            },
            festivalJoin: {
                key: 'festivalJoin',
                queryFn: (params) => requestFunc(METHOD.POST, `/events/${params.event_seq}/apply-step-event`),
            }

        },
        renewal: {
            entryEvent: {
                key: 'entryEvent', // 응모하기
                queryFn: (params) => requestFunc(METHOD.POST, `/events/${params.event_seq}/apply-event`),
            },
            mayEvent: {
                key: 'mayEvent', // 5월 랜덤 이벤트
                queryFn: (params) => requestFunc(METHOD.POST, `/events/${params.event_seq}/may-random-event`),
            }
        },
    };
};
