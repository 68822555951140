import React, { useRef, useState } from 'react';

import 'assets/css/components/tab.scss';

// Tab.js

function KeywordTab({ list, selectedValue, handleSelect }) {

    if (!list || list.length === 0) {
        return null;
    }

    return (
        <div className="tabBox">
            <ul className="tabMenu">
                <li className={!selectedValue ? 'on' : ''} onClick={() => handleSelect(undefined)}>ALL</li>
                {list.map((item, index) => (
                    <li className={item.keyword === selectedValue ? 'on' : ''} key={index} onClick={() => handleSelect(item)}>{item.keyword}</li>
                ))}
            </ul>
        </div>
    );
}

export default React.memo(KeywordTab);
