import { useContext } from 'react';

export function getUseContext(context) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const result = useContext(context);
  if (!result) {
    throw new Error('Cannot find context');
  }
  return result;
}
