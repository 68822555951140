import { METHOD } from 'common/hooks';

export const inquiryApi = (requestFunc) => {
  return {
    // 수업 문의
    inquires: {
      key: 'inquires', // 수업 문의 - 상세
      queryFn: (params) => requestFunc(METHOD.GET, `/inquires`, params),
    },
    inquiryInfo: {
      key: 'inquiryInfo', // 수업 문의 - 상세
      queryFn: (params) =>
        requestFunc(METHOD.GET, `/inquires/${params.inquiry_seq}`),
    },
    updateInquiry: {
      key: 'updateInquiry', // 수업 문의 - 수정
      queryFn: (params) =>
        requestFunc(METHOD.PATCH, `/inquires/${params.inquiry_seq}`, params),
    },
    deleteInquiry: {
      key: 'deleteInquiry', // 수업 문의 - 삭제
      queryFn: (params) =>
        requestFunc(METHOD.DELETE, `/inquires/${params.inquiry_seq}`),
    },
  };
};
