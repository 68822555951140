import React from 'react';
import { Typography } from '@mui/material';

function SnsUrlRegist (modalContent) {
  return (
    <>
      <Typography className="txt">소문내기 하신 URL을 적어주세요.</Typography>
        <textarea
          className="txtarea"
          placeholder="SNS URL 입력"
          value={modalContent.contentInput.state}
          onChange={modalContent.contentInput.handleChange}
        />
    </>
  );
}

export default React.memo(SnsUrlRegist);
