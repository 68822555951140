import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

// reset.js

const Reset = createGlobalStyle`
    ${reset};
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    html, body{
        height: 100%;
    }
    body,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    th,
    td,
    input,
    textarea,
    select,
    option,
    a,
    p {
        color: #666666;
        font-family: "NotoSansRegular", "Lato", sans-serif;
        font-size: 16px;
        font-weight: 400;
    }
    b {
        font-weight: bold;
    }
    i {
        font-style: italic;
    }
    sup {
        vertical-align: super;
        font-size: smaller;
    }
    sub {
        vertical-align: sub;
        font-size: smaller;
    }
    a{
        text-decoration:none;
        color:inherit;
        cursor: pointer;
    }
    a:hover {
        color:inherit;
        text-decoration: none;
    }
    img,
    fieldset,
    iframe {
        border: 0 none;
    }
    ul,
    ol,
    li {
        list-style: none;
    }
    em,
    address {
        font-style: normal;
    }
    img,
    input,
    select,
    textarea {
        vertical-align: middle;
    }
    button {
        border: none;
        background: transparent;
    }
    button,
    label {
        cursor: pointer;
        _cursor/**/: hand;
    }
    button * {
        position: relative;
    }
    button img {
        *left: auto;
    }
    button:focus {
        outline: none;
        outline: 0;
    }
    textarea {
        overflow: auto;
    }
    option {
        padding-right: 6px;
    }
    .blind,
    legend,
    caption {
        text-indent: -9999px;
        font-size: 0;
        height: 0 !important;
        line-height: 0;
        overflow: hidden;
        width: 0 !important;
    }
    hr {
        display: none;
    }
    legend {
        *width: 0;
    }
    :root legend {
        margin-top: -1px;
        font-size: 0;
        line-height: 0;
    }
    caption {
        *line-height: 0;
    }
 `;

export default Reset;
