import React, { useEffect, useMemo } from 'react';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import { star, heart_off, heart_on } from 'assets/images/icon';
import { DOMAIN } from 'common/constants';
import { toCurrency, getDiscountRate } from 'common/utils';
import useClassFavorite from 'components/items/hooks/useClassFavorite';
import useItemsHandler from 'components/items/hooks/useItemsHandler';
import { RECEPTION_STATUS } from 'common/constants';
import {
  ClassCardThumnail,
  TagList,
  TeacherInfoItem,
  PriceInfoItem,
  ClassTitle,
  Heart,
} from 'components/items';
import 'assets/css/class-item.css';

// ClassItem.js

function ClassItem({ data, index, classStar, showRank = false }) {
  const { onClassClick, onTeacherClick } = useItemsHandler({
    classData: data,
    teacher: data.teacher_info,
  });

  const receptionClassName = useMemo(() => {
    if (
      data?.reception_status === undefined ||
      data?.reception_status === null
    ) {
      return '';
    }

    if (data.reception_status === RECEPTION_STATUS.awaited.value) {
      return 'sold-out';
    } else if (data.reception_status === RECEPTION_STATUS.closed.value) {
      return 'sold-out-closed';
    }
    return '';
  }, [data?.reception_status]);

  if (!data) {
    return null;
  }

  return (
    <Card
      sx={{ width: 376 }}
      className={`card-item-root ${receptionClassName}`}
      onClick={onClassClick}
    >
      {/* 
      
          2022-08-01
          접수 대기 상태 일때 card-item-root 위치에 sold-out 추가
          접수 마감 상태 일때 card-item-root 위치에 sold-out-closed 추가

      */}
      <CardActionArea>
        <ClassCardThumnail data={data} index={index} showRank={showRank} />
        {/* 2022-08-05 접수 대기 상태 일때 피시화면에서만 보이는 가상콘텐츠 추가 됨*/}
        <div className="commingSoon-txt">오픈 예정</div>
      </CardActionArea>
      <CardContent className="card-content-wrap">
        <div className="content-top">
          <TagList data={data} />
          <Heart data={data} />
        </div>
        <ClassTitle data={data} />
        <div className="content-bottom">
          <TeacherInfoItem
            data={data}
            onTeacherClick={onTeacherClick}
            classStar={classStar}
          />
          <PriceInfoItem data={data} />
        </div>
      </CardContent>
    </Card>
  );
}

export default React.memo(ClassItem);
