import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import HomeBtn from "assets/images/common/home_btn.png";
import backBtn from "assets/images/common/back_btn.png";

import "assets/css/layouts/SubHeader.scss";

const SubHeader = ({ isSearch = false, children }) => {
  const navigate = useNavigate();
  const pageState = window.history.state;

  const NON_HEIGHT_LIST = [
    "나의 올머니",
    "나의 클래스",
    "쿠폰",
    "클래스 문의",
    "클래스 후기",
    "클래스 피드백",
    "개인정보 관리",
    "탈퇴하기",
    "마이페이지",
  ];

  useEffect(() => {
    console.log("children", children);
  }, []);

  return (
    <div
      className={`sub-header ${NON_HEIGHT_LIST.includes(children) ? "sub-header-non-height" : ""}`}
      id="header"
    >
      <div className="sub-header-fixed">
        <div className="search-btn-wrap">
          <button
            className="back-btn"
            onClick={() => (pageState.idx === 0 ? navigate("/") : navigate(-1))}
          >
            <img src={backBtn} alt="뒤로가기 버튼 이미지" />
          </button>
          <div className="sub-header-title">{children}</div>
          <Link to="/" className="home-btn">
            <img src={HomeBtn} alt="홈 아이콘" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SubHeader;
