export function checkValidEmail(value) {
  const re =
    /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return re.test(value);
}

export function checkValidPassword(value) {
  const num = value.search(/[0-9]/g);
  const eng = value.search(/[a-z]/gi);
  return value.length >= 8 && value.search(/\s/) === -1 && num >= 0 && eng >= 0;
}

export function checkValidName(value) {
  const re = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|]+$/;
  return re.test(value);
}

function checkEmptyValue(value) {
  return value.length === 0;
}

export function isValidId(value) {
  return isValidEmail(value, '아이디를');
}

export function isValidEmail(value, targetText = '이메일을') {
  let valid = true;
  let message = '';
  if (checkEmptyValue(value)) {
    valid = false;
    message = `${targetText} 입력해주세요.`;
  } else if (!checkValidEmail(value)) {
    valid = false;
    message = `올바른 형식의 ${targetText} 입력해주세요.`;
  }

  return { valid: valid, message: message };
}

export function isValidPassword(value) {
  let valid = true;
  let message = '';
  if (checkEmptyValue(value)) {
    valid = false;
    message = '비밀번호를 입력해주세요.';
  } else if (!checkValidPassword(value)) {
    valid = false;
    message = '영소문자, 숫자 포함\n8자리 이상 입력해주세요.';
  }

  return { valid: valid, message: message };
}

export function isValidPasswordConfirm(pwd, pwdConfirm) {
  let valid = true;
  let message = '';
  if (checkEmptyValue(pwd)) {
    valid = false;
    message = '비밀번호를 입력해주세요.';
  } else if (checkEmptyValue(pwdConfirm)) {
    valid = false;
    message = '비밀번호를 한번 더 입력해주세요.';
  } else if (pwd !== pwdConfirm) {
    valid = false;
    message = '비밀번호가 일치하지 않습니다.';
  }

  return { valid: valid, message: message };
}
