import React, { useCallback } from 'react';
import { useLoginContext, useKakaoContext } from 'common/context';
import usePopAlert from 'components/popAlert/hooks/usePopAlert';
import { useNavigate } from 'react-router';
import { useKakao } from 'common/hooks';

import ReactGA from 'react-ga4'
import { GoogleAnalysticsProperties } from 'common/constants';

function useLogout() {
  const { action } = useLoginContext();
  const { kakaoState } = useKakaoContext();
  const { showAlert } = usePopAlert();
  const { requestKakaoLogout, requestKakaoUnlink } = useKakao();
  const navigate = useNavigate();

  const logout = useCallback(
    (show = true) => {
      // console.log(kakaoState);
      action.resetLoginData();
      localStorage.removeItem('accessToken');
      sessionStorage.removeItem('kakaoAccessToken');

      requestKakaoLogout(kakaoState.access_token);
      // requestKakaoUnlink(kakaoState.access_token);

      // 2022-09-21 : Scott ->Google Analytics : logout_complete
      ReactGA.event(GoogleAnalysticsProperties.LogoutComplete)
      
      // window.ChannelIO('shutdown');
      window.ChannelIO(
        'boot',
        {
          pluginKey: 'b1b2fb02-3d12-4b71-b160-fdf971314b1b',
          customLauncherSelector: '.chat-icon-wrap',
          hideChannelButtonOnBoot: true,
          language: 'ko',
          // "memberId": "", //fill with user id
          // "profile": {
          //     "name": "", //fill with user name
          //     "mobileNumber": "", //fill with user phone number
          //     "CUSTOM_VALUE_1": "VALUE_1", //any other custom meta data
          //     "CUSTOM_VALUE_2": "VALUE_2"
          // },
        },
        function onBoot(error, user) {
          if (error) {
            console.error(error);
          } else {
            // console.log('useLogout : boot success=>', user);
          }
        },
      );

      if (show) {
        showAlert('알림', '로그아웃 되었습니다.', {
          confirmHandler: () => navigate('/'),
          cancelHandler: () => navigate('/'),
        });
      }
    },
    [action, kakaoState, navigate, showAlert],
  );

  return { logout };
}

export default useLogout;
