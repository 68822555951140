import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
// SelectBox.js

function SelectBox({ options, value, handleChange, disabled = false, disableScrollLock = false }) {
  return (
    // 2022-07-13 디자인 수정 -> 기본 select -> mui combobox
    <>
      {/* <select className="select-type-s" value={value} onChange={handleChange}>
                {options &&
                    options.map((option, index) => (
                        <option key={option.value} value={option.value}>`
                            {option.name}
                        </option>
                    ))}
            </select> */}

      <Select
        labelId="select-box-type1"
        id="select-box-type1"
        className={'comboBox-type1 '}
        value={value}
        label="select"
        disabled={disabled}
        onChange={handleChange}
        MenuProps={{
          style: { zIndex: 2000 },
          disableScrollLock: true, // disable scroll lock
          marginThreshold: null // allow the menu to go outside the window
        }}
      >
        {options &&
          options.map((option, index) => {
            return (
              <MenuItem key={option.key} value={option.value} className="CRAZY">
                {option.name}
              </MenuItem>
            );
          })}
      </Select>
    </>
    // 2022-07-13 끝
  );
}

export default React.memo(SelectBox);

