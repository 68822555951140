import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { DetailRecommandClass, ScrollTabBox } from 'components/items';
import ClassThumnailSlider from 'components/slider/ClassThumnailSlider';
import ClassNotice from 'components/items/ClassNotice';
import ClassInfo from 'components/items/ClassInfo';
import { Desktop, TabletAndMobile } from 'components/utils/MediaQuery';
import SubHeader from 'components/layout/SubHeader';
import { useAppSettings } from 'common/hooks';
// import 'assets/css/pages/classDDetail.scss';
import 'assets/css/auth/registerprofileCard.css';
import { DOMAIN } from 'common/constants';
import { NAVER_AD_ID } from 'common/constants';
import ClassCardMap from 'components/classCardItem/classCardMap';
import useSecretClassDetail from "./hooks/useSecretClassDetail";

function SecretClassDetail() {
    let { id } = useParams();
    const { screenTypes, updateHeaderVisible } = useAppSettings();
    const {
        classInfo,
        setSelectedSchedule,
        handleApplyClass,
    } = useSecretClassDetail(id);


    useEffect(() => {
        updateHeaderVisible(screenTypes.isDesktop);

        document.title='클래스|라이브올';
        const el = document.querySelector("meta[name='description']");
        if(el)
        el.setAttribute('content','라이브올의 다양한 수업을 통해 배움의 즐거움을 경험해보세요.');

        if(classInfo) {
            document.querySelector('meta[property="og:title"').setAttribute("content", `라이브올|${classInfo.class_name}`);
            document.querySelector('meta[property="og:description"').setAttribute("content", `${classInfo.class_name}`);
            document.querySelector('meta[property="og:type"').setAttribute("content", `class`);
            document.querySelector('meta[property="og:url"').setAttribute("content", `${DOMAIN}/class/${classInfo.class_seq}/hidden`);
            document.querySelector('meta[property="og:image"').setAttribute("content", `${classInfo.thumbnail_image_url}`);
        }

        // For Naver AD
        let scriptElement = document.createElement('script');
        scriptElement.type = `text/javascript`;
        scriptElement.innerHTML = `if(!wcs_add){var wcs_add={};}wcs_add["wa"]="${NAVER_AD_ID}";if(!_nasa){var _nasa={};}if(window.wcs){wcs.inflow();wcs_do(_nasa);}`;
        document.body.appendChild(scriptElement);

        return () => {
            document.body.removeChild(scriptElement);
            document.querySelector('meta[property="og:title"').setAttribute("content", `라이브올 - 키즈 라이브 클래스`);
            document.querySelector('meta[property="og:description"').setAttribute("content", `실시간 소통하며 풍부한 배경 지식을 키워 줄 온라인 키즈 라이브 클래스`);
            document.querySelector('meta[property="og:type"').setAttribute("content", `website`);
            document.querySelector('meta[property="og:url"').setAttribute("content", `https://www.liveall.co.kr`);
            document.querySelector('meta[property="og:image"').setAttribute("content", `https://www.liveall.co.kr/m_i/og_1.jpg`);
        };

    }, [classInfo, screenTypes.isDesktop]);

    if (!classInfo) {
        return <div className="minHeightDiv"></div>;
    }

    return (
        <>
            <TabletAndMobile>
                <SubHeader children={``} />
            </TabletAndMobile>
            <Grid container className="class-detail-container" justifyContent="center">
                <Grid container justifyContent="center" className="top">
                    <Grid item xs={5} className="columnLeft">
                        <ClassThumnailSlider images={classInfo.thumbnail_images} />
                        <Desktop><ClassNotice data={classInfo} /></Desktop>
                    </Grid>
                    <Grid item xs={5} className="columnRight">
                        <ClassInfo
                            data={classInfo}
                            handleApplyClass={handleApplyClass}
                            setSelectedSchedule={setSelectedSchedule}
                        />
                        <TabletAndMobile><ClassNotice data={classInfo} /></TabletAndMobile>
                    </Grid>
                </Grid>

                <Grid container justifyContent="center" className="middle">
                    <Grid item className="tapWrap">
                        <ScrollTabBox data={classInfo} />
                    </Grid>
                </Grid>

                <div className="recommendClass_wrap">
                    {classInfo.teacher_info.others.length > 0 && (
                        <>
                            <h1>
                                <span className="purple">{classInfo.teacher_nickname}</span>{' '}
                                선생님의 다른 수업 추천
                            </h1>
                            <ClassCardMap classList={classInfo.teacher_info.others} type={"class"}/>
                        </>
                    )}
                   
                    {classInfo.category_others.length > 0 && (
                        <>
                            <h1>
                                <span className="grapefruit">{classInfo.category_name}</span>{' '}
                                카테고리에서 다른 수업 추천
                            </h1>
                            <ClassCardMap classList={classInfo.category_others} type={"class"}/>
                        </>
                    )}
                </div>
            </Grid>
        </>
    );
}

export default React.memo(SecretClassDetail);
