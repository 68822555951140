import { useCallback, useEffect } from 'react';
import { usePopAlertContext } from 'components/popAlert/context/PopAlertContext';
import { useLocation } from 'react-router-dom';

function usePopAlert() {
  const { state, action } = usePopAlertContext();
  const { showAlert, showConfirm, confirm, cancel, hideAlert } = action;
  const { pathname } = useLocation();

  const onConfirm = useCallback(
    (e) => {
      if (e.target === e.currentTarget) {
        e.stopPropagation();
        confirm();
      }
    },
    [confirm],
  );

  const onCancel = useCallback(
    (e) => {
      if (e.target === e.currentTarget) {
        e.stopPropagation();
        cancel();
      }
    },
    [cancel],
  );

  // useEffect(() => {
  //   hideAlert();
  // }, [pathname, hideAlert]);

  return {
    state,
    showAlert,
    showConfirm,
    onConfirm,
    onCancel,
  };
}

export default usePopAlert;
