import React, { useMemo } from 'react';
import ShareIcon from '@mui/icons-material/Share';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Scrollbar, Navigation, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'assets/css/slide/slide.css'; // 카드형 슬라이드 css
import 'assets/css/detail/detailClassInfo.css';
import ModalShare from '../modal/ModalShare';
import useModalWrapper from 'components/modal/hooks/useModalWrapper';
import { Desktop, TabletAndMobile } from 'components/utils/MediaQuery';

import InnerHTML from 'dangerously-set-html-content'

import {
    airplane01,
    airplane02,
    airplane03,
    airplane04,
    airplane05,
    airplane06,
    airplane07,
    airplane08,
    airplane09,
    airplane10,
} from 'assets/images/magazine/airplane';

import {
    yoonaMo01,
    yoonaMo02,
    yoonaPc01,
    yoonaPc02,
} from 'assets/images/magazine/yoona';

import {
    yt_physical_01,
    yt_physical_02,
    yt_physical_03,
    yt_physical_04,
    yt_physical_05,
    yt_physical_06,
    yt_physical_07,
    yt_physical_08,
    yt_physical_09,
    yt_physical_10,
} from 'assets/images/magazine/physical';

import {
    worldcup_1_01,
    worldcup_1_02,
    worldcup_1_03,
    worldcup_1_04,
    worldcup_1_05,
    worldcup_1_06,
    worldcup_1_07,
    worldcup_1_08,
    worldcup_1_09,
    worldcup_1_10,
    worldcup_1_11,
    worldcup_1_12,
    worldcup_2_01,
    worldcup_2_02,
    worldcup_2_03,
    worldcup_2_04,
    worldcup_2_05,
    worldcup_2_06,
    worldcup_2_07,
    worldcup_2_08,
    worldcup_2_09
} from 'assets/images/magazine/worldcup';

import {
    genalpha_1,
    genalpha_2,
    genalpha_3,
    genalpha_4,
    genalpha_5,
    genalpha_6,
    genalpha_7,
    genalpha_8,
    genalpha_9,
    genalpha_10
} from 'assets/images/magazine/alpha';

import {
    newYear_1,
    newYear_2,
    newYear_3,
    newYear_4,
    newYear_5,
    newYear_6,
    newYear_7,
} from 'assets/images/magazine/newyear';

import {
    octopants_01,
    octopants_02,
    octopants_03,
    octopants_04,
    octopants_05,
    octopants_06,
    octopants_07,
    octopants_08,
    octopants_09,
    octopants_10,
} from 'assets/images/magazine/octopants';

import {
    avocado_01,
    avocado_02,
    avocado_03,
    avocado_04,
    avocado_05,
    avocado_06,
    avocado_07,
    avocado_08,
} from 'assets/images/magazine/avocado';

import {
    voca_file_date_01,
    voca_file_date_02,
    voca_file_date_03,
    voca_file_date_04,
    voca_file_date_05,
    voca_file_date_06,
    voca_file_date_07,
    voca_file_date_08,
} from 'assets/images/magazine/voca_file_date';

import {
    voca_file_idiom_01,
    voca_file_idiom_02,
    voca_file_idiom_03,
    voca_file_idiom_04,
    voca_file_idiom_05,
    voca_file_idiom_06,
} from 'assets/images/magazine/voca_file_idiom';

import {
    con1,
    con3,
    img01,
    img02,
    img03,
    img04,
    img05,
    img06,
    img07,
    img08,
    img09,
    con1Mo,
    con3Mo,
    con4,
    con4Mo,
    con5,
    con5Mo,
    con12,
    con12Mo,
    con13,
    con13Mo,
} from 'assets/images/icon';
import moment from 'moment';

import {
    curriculum2022_01,
    curriculum2022_02,
    curriculum2022_03,
    curriculum2022_04,
    curriculum2022_05,
    curriculum2022_06,
} from "assets/images/magazine/2022curriculum";

import Banner from "../banner/Banner";

// MagazineDetailContent.js

function MagazineDetailContent({ data }) {

    if (!data) {
        return null;
    }

    const { magazine_title, magazine_seq } = data;
    const shareModalHandler = useModalWrapper();

    SwiperCore.use([Scrollbar, Navigation, Autoplay]); // swiper slide
    const [swiper, setSwiper] = React.useState();
    const prevRef = React.useRef();
    const nextRef = React.useRef();
    React.useEffect(() => {
        if (swiper) {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
        }
    }, [swiper]);

    const shareData = useMemo(() => {
        return {
            title: data?.magazine_title || '',
            description: data?.magazine_contents || '',
            descriptionMo: data?.magazine_contents_mo || '',
            imageUrl: data?.main_image_url || '',
        };
    }, [data]);

    return (
        <div className="classInfo">
            {data.banner_info?.has_banner && <Banner banner={data.banner_info} />}
            <div className="classInfo_title">
                <div>
                    <h2>{data.magazine_title}</h2>
                    <h3>작성일 {moment(data.exposure_dt).format('YYYY-MM-DD HH:mm')}</h3>
                </div>
                <div className="classInfo_share_icon">
                    <ShareIcon onClick={shareModalHandler.handleOpen} />
                </div>
                {shareModalHandler.visibleModal && (
                    <ModalShare
                        data={shareData}
                        handleClose={shareModalHandler.handleClose}
                    />
                )}
            </div>
            
            {data && data.content_type == 1 ? (
                <>
                    {magazine_seq === 1 && (
                        <>
                            <Desktop>
                                <img src={con3} alt="con3" />
                            </Desktop>
                            <TabletAndMobile>
                                <img src={con3Mo} alt="con3Mo" />
                            </TabletAndMobile>
                        </>
                    )}
                    {magazine_seq === 2 && (
                        <>
                            <Desktop>
                                <img src={con1} alt="con1" />
                            </Desktop>
                            <TabletAndMobile>
                                <img src={con1Mo} alt="con1Mo" />
                            </TabletAndMobile>
                        </>
                    )}
                    {magazine_seq === 3 && (
                        <>
                            <div className="magazine_slide_wrap">
                                <Swiper
                                    slidesPerView={1}
                                    navigation={{
                                        prevEl: prevRef?.current,
                                        nextEl: nextRef?.current,
                                    }}
                                    modules={[Navigation]}
                                    autoplay={{ delay: 3000 }}
                                    className="slide-card-items-list-type"
                                    onInit={(swiper) => {
                                        swiper.params.navigation.prevEl = prevRef.current;
                                        swiper.params.navigation.nextEl = nextRef.current;
                                        swiper.navigation.init();
                                        swiper.navigation.update();
                                    }}
                                    onSwiper={setSwiper}
                                >
                                    <SwiperSlide>
                                        <img src={img01} alt="img01" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={img02} alt="img02" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={img03} alt="img03" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={img04} alt="img04" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={img05} alt="img05" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={img06} alt="img06" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={img07} alt="img07" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={img08} alt="img08" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={img09} alt="img09" />
                                    </SwiperSlide>
                                </Swiper>
                                <div ref={prevRef} className="swiper-button-prev">Prev</div>
                                <div ref={nextRef} className="swiper-button-next">Next</div>
                            </div>
                        </>
                    )}
                    {magazine_seq === 6 && (
                        <>
                            <Desktop>
                                <img src={con4} alt="con4" />
                            </Desktop>
                            <TabletAndMobile>
                                <img src={con4Mo} alt="con4Mo" />
                            </TabletAndMobile>
                        </>
                    )}
                    {magazine_seq === 7 && (
                        <>
                            <Desktop>
                                <img src={con5} alt="con5" />
                            </Desktop>
                            <TabletAndMobile>
                                <img src={con5Mo} alt="con5Mo" />
                            </TabletAndMobile>
                        </>
                    )}
                    {magazine_seq === 8 && (
                        <>
                            <div className="magazine_slide_wrap">
                                <Swiper
                                    slidesPerView={1}
                                    navigation={{
                                        prevEl: prevRef?.current,
                                        nextEl: nextRef?.current,
                                    }}
                                    modules={[Navigation]}
                                    autoplay={{ delay: 3000 }}
                                    className="slide-card-items-list-type"
                                    onInit={(swiper) => {
                                        swiper.params.navigation.prevEl = prevRef.current;
                                        swiper.params.navigation.nextEl = nextRef.current;
                                        swiper.navigation.init();
                                        swiper.navigation.update();
                                    }}
                                    onSwiper={setSwiper}
                                >
                                    <SwiperSlide>
                                        <img src={airplane01} alt="img01" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={airplane02} alt="img02" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={airplane03} alt="img03" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={airplane04} alt="img04" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={airplane05} alt="img05" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={airplane06} alt="img06" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={airplane07} alt="img07" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={airplane08} alt="img08" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={airplane09} alt="img09" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={airplane10} alt="img10" />
                                    </SwiperSlide>
                                </Swiper>
                                <div ref={prevRef} className="swiper-button-prev">Prev</div>
                                <div ref={nextRef} className="swiper-button-next">Next</div>
                            </div>
                        </>
                    )}
                    {magazine_seq === 10 && (
                        <>
                            <Desktop>
                                <img src={yoonaPc01} alt="yoonaPc01" />
                                <iframe
                                    width="100%"
                                    height="704px"
                                    src="https://www.youtube.com/embed/egyrngtW7LQ"
                                    title="라이브올 영상"
                                    allowfullscreen
                                />
                                <img src={yoonaPc02} alt="yoonaPc02" />
                            </Desktop>
                            <TabletAndMobile>
                                <img src={yoonaMo01} alt="yoonaMo01" />
                                <iframe
                                    src="https://www.youtube.com/embed/egyrngtW7LQ"
                                    title="라이브올 영상"
                                    allowfullscreen
                                />
                                <img src={yoonaMo02} alt="yoonaMo02" />
                            </TabletAndMobile>
                        </>
                    )}
                    {magazine_seq === 11 && (
                        <>
                            <div className="magazine_slide_wrap">
                                <Swiper
                                    slidesPerView={1}
                                    navigation={{
                                        prevEl: prevRef?.current,
                                        nextEl: nextRef?.current,
                                    }}
                                    modules={[Navigation]}
                                    autoplay={{ delay: 3000 }}
                                    className="slide-card-items-list-type"
                                    onInit={(swiper) => {
                                        swiper.params.navigation.prevEl = prevRef.current;
                                        swiper.params.navigation.nextEl = nextRef.current;
                                        swiper.navigation.init();
                                        swiper.navigation.update();
                                    }}
                                    onSwiper={setSwiper}
                                >
                                    <SwiperSlide>
                                        <img src={yt_physical_01} alt="img01" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={yt_physical_02} alt="img02" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={yt_physical_03} alt="img03" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={yt_physical_04} alt="img04" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={yt_physical_05} alt="img05" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={yt_physical_06} alt="img06" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={yt_physical_07} alt="img07" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={yt_physical_08} alt="img08" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={yt_physical_09} alt="img09" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={yt_physical_10} alt="img10" />
                                    </SwiperSlide>
                                </Swiper>
                                <div ref={prevRef} className="swiper-button-prev">Prev</div>
                                <div ref={nextRef} className="swiper-button-next">Next</div>
                            </div>
                        </>
                    )}
                    {magazine_seq === 12 && (
                        <>
                            <Desktop>
                                <img src={con12} alt="Magazine 12" />
                                <iframe
                                    width="100%"
                                    height="704px"
                                    src="https://www.youtube.com/embed/i2qZhzbIpHQ"
                                    title="라이브올 영상"
                                    allowfullscreen
                                />
                                <iframe
                                    width="100%"
                                    height="704px"
                                    src="https://www.youtube.com/embed/AB3wnW7ygOU"
                                    title="라이브올 영상"
                                    allowfullscreen
                                />
                            </Desktop>
                            <TabletAndMobile>
                                <img src={con12Mo} alt="Magazine 12" />
                                <iframe
                                    src="https://www.youtube.com/embed/i2qZhzbIpHQ"
                                    title="라이브올 영상"
                                    allowfullscreen
                                />
                                <iframe
                                    src="https://www.youtube.com/embed/AB3wnW7ygOU"
                                    title="라이브올 영상"
                                    allowfullscreen
                                />
                            </TabletAndMobile>
                        </>
                    )}
                    {magazine_seq === 13 && (
                        <>
                            <Desktop>
                                <img src={con13} alt="con13" />
                            </Desktop>
                            <TabletAndMobile>
                                <img src={con13Mo} alt="con13Mo" />
                            </TabletAndMobile>
                        </>
                    )}
                    {magazine_seq === 28 && (
                        <>
                            <div className="magazine_slide_wrap">
                                <Swiper
                                    slidesPerView={1}
                                    navigation={{
                                        prevEl: prevRef?.current,
                                        nextEl: nextRef?.current,
                                    }}
                                    modules={[Navigation]}
                                    autoplay={{ delay: 3000 }}
                                    className="slide-card-items-list-type"
                                    onInit={(swiper) => {
                                        swiper.params.navigation.prevEl = prevRef.current;
                                        swiper.params.navigation.nextEl = nextRef.current;
                                        swiper.navigation.init();
                                        swiper.navigation.update();
                                    }}
                                    onSwiper={setSwiper}
                                >
                                    <SwiperSlide>
                                        <img src={worldcup_1_01} alt="img01" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={worldcup_1_02} alt="img02" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={worldcup_1_03} alt="img03" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={worldcup_1_04} alt="img04" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={worldcup_1_05} alt="img05" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={worldcup_1_06} alt="img06" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={worldcup_1_07} alt="img07" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={worldcup_1_08} alt="img08" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={worldcup_1_09} alt="img09" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={worldcup_1_10} alt="img09" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={worldcup_1_11} alt="img11" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={worldcup_1_12} alt="img12" />
                                    </SwiperSlide>
                                </Swiper>
                                <div ref={prevRef} className="swiper-button-prev">Prev</div>
                                <div ref={nextRef} className="swiper-button-next">Next</div>
                            </div>
                        </>
                    )}
                    {magazine_seq === 29 && (
                        <>
                            <div className="magazine_slide_wrap">
                                <Swiper
                                    slidesPerView={1}
                                    navigation={{
                                        prevEl: prevRef?.current,
                                        nextEl: nextRef?.current,
                                    }}
                                    modules={[Navigation]}
                                    autoplay={{ delay: 3000 }}
                                    className="slide-card-items-list-type"
                                    onInit={(swiper) => {
                                        swiper.params.navigation.prevEl = prevRef.current;
                                        swiper.params.navigation.nextEl = nextRef.current;
                                        swiper.navigation.init();
                                        swiper.navigation.update();
                                    }}
                                    onSwiper={setSwiper}
                                >
                                    <SwiperSlide>
                                        <img src={worldcup_2_01} alt="img01" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={worldcup_2_02} alt="img02" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={worldcup_2_03} alt="img03" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={worldcup_2_04} alt="img04" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={worldcup_2_05} alt="img05" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={worldcup_2_06} alt="img06" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={worldcup_2_07} alt="img07" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={worldcup_2_08} alt="img08" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src={worldcup_2_09} alt="img09" />
                                    </SwiperSlide>
                                </Swiper>
                                <div ref={prevRef} className="swiper-button-prev">Prev</div>
                                <div ref={nextRef} className="swiper-button-next">Next</div>
                            </div>
                        </>
                    )}
                    {magazine_seq === 34 && (
                        <>
                            <div className="magazine_slide_wrap">
                            <Swiper
                                slidesPerView={1}
                                navigation={{
                                prevEl: prevRef?.current,
                                nextEl: nextRef?.current,
                                }}
                                modules={[Navigation]}
                                autoplay={{ delay: 3000 }}
                                className="slide-card-items-list-type"
                                onInit={(swiper) => {
                                swiper.params.navigation.prevEl = prevRef.current;
                                swiper.params.navigation.nextEl = nextRef.current;
                                swiper.navigation.init();
                                swiper.navigation.update();
                                }}
                                onSwiper={setSwiper}
                            >
                                <SwiperSlide>
                                <img src={genalpha_1} alt="img01" />
                                </SwiperSlide>
                                <SwiperSlide>
                                <img src={genalpha_2} alt="img02" />
                                </SwiperSlide>
                                <SwiperSlide>
                                <img src={genalpha_3} alt="img03" />
                                </SwiperSlide>
                                <SwiperSlide>
                                <img src={genalpha_4} alt="img04" />
                                </SwiperSlide>
                                <SwiperSlide>
                                <img src={genalpha_5} alt="img05" />
                                </SwiperSlide>
                                <SwiperSlide>
                                <img src={genalpha_6} alt="img06" />
                                </SwiperSlide>
                                <SwiperSlide>
                                <img src={genalpha_7} alt="img07" />
                                </SwiperSlide>
                                <SwiperSlide>
                                <img src={genalpha_8} alt="img08" />
                                </SwiperSlide>
                                <SwiperSlide>
                                <img src={genalpha_9} alt="img09" />
                                </SwiperSlide>
                                <SwiperSlide>
                                <img src={genalpha_10} alt="img10" />
                                </SwiperSlide>
                            </Swiper>
                            <div ref={prevRef} className="swiper-button-prev">
                                Prev
                            </div>
                            <div ref={nextRef} className="swiper-button-next">
                                Next
                            </div>
                            </div>
                        </>
                    )}

                    {/* 2022-12-27 매거진 상세 사자성어 시작 */}
                    {magazine_seq === 41 && (
                        <>
                            <div className="magazine_slide_wrap">
                            <Swiper
                                slidesPerView={1}
                                navigation={{
                                prevEl: prevRef?.current,
                                nextEl: nextRef?.current,
                                }}
                                modules={[Navigation]}
                                autoplay={{ delay: 3000 }}
                                className="slide-card-items-list-type"
                                onInit={(swiper) => {
                                swiper.params.navigation.prevEl = prevRef.current;
                                swiper.params.navigation.nextEl = nextRef.current;
                                swiper.navigation.init();
                                swiper.navigation.update();
                                }}
                                onSwiper={setSwiper}
                            >
                                <SwiperSlide>
                                <img src={newYear_1} alt="img01" />
                                </SwiperSlide>
                                <SwiperSlide>
                                <img src={newYear_2} alt="img02" />
                                </SwiperSlide>
                                <SwiperSlide>
                                <img src={newYear_3} alt="img03" />
                                </SwiperSlide>
                                <SwiperSlide>
                                <img src={newYear_4} alt="img04" />
                                </SwiperSlide>
                                <SwiperSlide>
                                <img src={newYear_5} alt="img05" />
                                </SwiperSlide>
                                <SwiperSlide>
                                <img src={newYear_6} alt="img06" />
                                </SwiperSlide>
                                <SwiperSlide>
                                <img src={newYear_7} alt="img07" />
                                </SwiperSlide>
                            </Swiper>
                            <div ref={prevRef} className="swiper-button-prev">
                                Prev
                            </div>
                            <div ref={nextRef} className="swiper-button-next">
                                Next
                            </div>
                            </div>
                        </>
                    )}

                    {/* 2023-01-31 매거진 상세 octopants 시작 */}
                    {magazine_seq === 48 && (
                        <>
                        <div className="magazine_slide_wrap">
                            <Swiper
                                slidesPerView={1}
                                navigation={{
                                prevEl: prevRef?.current,
                                nextEl: nextRef?.current,
                                }}
                                modules={[Navigation]}
                                autoplay={{ delay: 3000 }}
                                className="slide-card-items-list-type"
                                onInit={(swiper) => {
                                swiper.params.navigation.prevEl = prevRef.current;
                                swiper.params.navigation.nextEl = nextRef.current;
                                swiper.navigation.init();
                                swiper.navigation.update();
                                }}
                                onSwiper={setSwiper}
                            >
                            <SwiperSlide>
                                <img src={octopants_01} alt="img01" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={octopants_02} alt="img02" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={octopants_03} alt="img03" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={octopants_04} alt="img04" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={octopants_05} alt="img05" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={octopants_06} alt="img06" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={octopants_07} alt="img07" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={octopants_08} alt="img08" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={octopants_09} alt="img09" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={octopants_10} alt="img10" />
                            </SwiperSlide>
                            </Swiper>
                            <div ref={prevRef} className="swiper-button-prev">
                            Prev
                            </div>
                            <div ref={nextRef} className="swiper-button-next">
                            Next
                            </div>
                        </div>
                        </>
                    )}

                    {/* 2023-01-31 매거진 상세 아보카도의 두 얼굴 시작 */}
                    {magazine_seq === 51 && (
                        <>
                        <div className="magazine_slide_wrap">
                            <Swiper
                                slidesPerView={1}
                                navigation={{
                                prevEl: prevRef?.current,
                                nextEl: nextRef?.current,
                                }}
                                modules={[Navigation]}
                                autoplay={{ delay: 3000 }}
                                className="slide-card-items-list-type"
                                onInit={(swiper) => {
                                swiper.params.navigation.prevEl = prevRef.current;
                                swiper.params.navigation.nextEl = nextRef.current;
                                swiper.navigation.init();
                                swiper.navigation.update();
                                }}
                                onSwiper={setSwiper}
                            >
                            <SwiperSlide>
                                <img src={avocado_01} alt="img01" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={avocado_02} alt="img02" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={avocado_03} alt="img03" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={avocado_04} alt="img04" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={avocado_05} alt="img05" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={avocado_06} alt="img06" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={avocado_07} alt="img07" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={avocado_08} alt="img08" />
                            </SwiperSlide>
                            </Swiper>
                            <div ref={prevRef} className="swiper-button-prev">
                            Prev
                            </div>
                            <div ref={nextRef} className="swiper-button-next">
                            Next
                            </div>
                        </div>
                        </>
                    )}

                    {/* 2023-01-31 매거진 상세 슬기로운 어휘 생활 - 날짜 어휘 시작 */}
                    {magazine_seq === 52 && (
                        <>
                        <div className="magazine_slide_wrap">
                            <Swiper
                                slidesPerView={1}
                                navigation={{
                                prevEl: prevRef?.current,
                                nextEl: nextRef?.current,
                                }}
                                modules={[Navigation]}
                                autoplay={{ delay: 3000 }}
                                className="slide-card-items-list-type"
                                onInit={(swiper) => {
                                swiper.params.navigation.prevEl = prevRef.current;
                                swiper.params.navigation.nextEl = nextRef.current;
                                swiper.navigation.init();
                                swiper.navigation.update();
                                }}
                                onSwiper={setSwiper}
                            >
                            <SwiperSlide>
                                <img src={voca_file_date_01} alt="img01" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={voca_file_date_02} alt="img02" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={voca_file_date_03} alt="img03" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={voca_file_date_04} alt="img04" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={voca_file_date_05} alt="img05" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={voca_file_date_06} alt="img06" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={voca_file_date_07} alt="img07" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={voca_file_date_08} alt="img08" />
                            </SwiperSlide>
                            </Swiper>
                            <div ref={prevRef} className="swiper-button-prev">
                            Prev
                            </div>
                            <div ref={nextRef} className="swiper-button-next">
                            Next
                            </div>
                        </div>
                        </>
                    )}

                    {/* 2023-03-13 조상님들도 줄임말을 썼다고요? */}
                    {magazine_seq === 55 && (
                        <>
                        <div className="magazine_slide_wrap">
                            <Swiper
                                slidesPerView={1}
                                navigation={{
                                prevEl: prevRef?.current,
                                nextEl: nextRef?.current,
                                }}
                                modules={[Navigation]}
                                autoplay={{ delay: 3000 }}
                                className="slide-card-items-list-type"
                                onInit={(swiper) => {
                                swiper.params.navigation.prevEl = prevRef.current;
                                swiper.params.navigation.nextEl = nextRef.current;
                                swiper.navigation.init();
                                swiper.navigation.update();
                                }}
                                onSwiper={setSwiper}
                            >
                            <SwiperSlide>
                                <img src={voca_file_idiom_01} alt="img01" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={voca_file_idiom_02} alt="img02" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={voca_file_idiom_03} alt="img03" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={voca_file_idiom_04} alt="img04" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={voca_file_idiom_05} alt="img05" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={voca_file_idiom_06} alt="img08" />
                            </SwiperSlide>
                            </Swiper>
                            <div ref={prevRef} className="swiper-button-prev">
                            Prev
                            </div>
                            <div ref={nextRef} className="swiper-button-next">
                            Next
                            </div>
                        </div>
                        </>
                    )}

                    {/* 2023-01-31 2022 개정 교육 과정 알아보기 시작 */}
                    {magazine_seq === 58 && (
                        <>
                        <div className="magazine_slide_wrap">
                            <Swiper
                                slidesPerView={1}
                                navigation={{
                                prevEl: prevRef?.current,
                                nextEl: nextRef?.current,
                                }}
                                modules={[Navigation]}
                                autoplay={{ delay: 3000 }}
                                className="slide-card-items-list-type"
                                onInit={(swiper) => {
                                swiper.params.navigation.prevEl = prevRef.current;
                                swiper.params.navigation.nextEl = nextRef.current;
                                swiper.navigation.init();
                                swiper.navigation.update();
                                }}
                                onSwiper={setSwiper}
                            >
                            <SwiperSlide>
                                <img src={curriculum2022_01} alt="img01" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={curriculum2022_02} alt="img02" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={curriculum2022_03} alt="img03" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={curriculum2022_04} alt="img04" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={curriculum2022_05} alt="img05" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={curriculum2022_06} alt="img06" />
                            </SwiperSlide>
                            </Swiper>
                            <div ref={prevRef} className="swiper-button-prev">
                            Prev
                            </div>
                            <div ref={nextRef} className="swiper-button-next">
                            Next
                            </div>
                        </div>
                        </>
                    )}
                </>
            ) : (
                <>
                    <Desktop>
                        <InnerHTML html={data?data.magazine_contents:''} />
                    </Desktop>
                    <TabletAndMobile>
                        <InnerHTML html={data?data.magazine_contents_mo:''} />
                    </TabletAndMobile>
                </>
            )}
        </div>
    );
}

export default React.memo(MagazineDetailContent);
