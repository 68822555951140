import { METHOD } from 'common/hooks';

export const mypageApi = (requestFunc) => {
  return {
    // 마이페이지 - 메인
    myMain: {
      key: 'myMain', // 수강 신청/결제 내역
      queryFn: () => requestFunc(METHOD.GET, `/mypage`),
    },
    refreshMyMain: {
      key: 'refreshMyMain', // 수강 신청/결제 내역 폴링용
      queryFn: () => requestFunc(METHOD.GET, `/mypage`),
    },
    // 마이페이지 - 클래스 관리 - 신청/결제 내역
    myApplications: {
      key: 'myApplications', // 수강 신청/결제 내역
      queryFn: () => requestFunc(METHOD.GET, `/mypage/applications`),
    },
    myApplicationInfo: {
      key: 'myApplicationInfo', // 수강 신청/결제 상세 내역
      queryFn: (params) =>
        requestFunc(
          METHOD.GET,
          `/mypage/applications/${params.application_seq}`,
        ),
    },
    updateMyApplication: {
      key: 'updateMyApplication', // 수업 신청 변경
      queryFn: (params) =>
        requestFunc(
          METHOD.PATCH,
          `/mypage/applications/${params.application_seq}`,
          params,
        ),
    },
    changeMyApplicationAddress: {
      key: 'changeMyApplicationAddress', // 배송지 주소 변경
      queryFn: (params) =>
        requestFunc(
          METHOD.PATCH,
          `/mypage/applications/${params.application_seq}/address`,
          params,
        ),
    },
    cancelMyApplicationPay: {
      key: 'cancelMyApplicationPay', // 수업 신청 취소/결제 취소
      queryFn: (params) =>
        requestFunc(
          METHOD.PATCH,
          `/mypage/applications/${params.application_seq}/cancel`,
        ),
    },
    myApplicationReceipt: {
      key: 'myApplicationReceipt', // 영수증 조회
      queryFn: (params) =>
        requestFunc(
          METHOD.GET,
          `/mypage/applications/${params.application_seq}/receipt`,
        ),
    },

    // 마이페이지 - 클래스 관리 - 나의수업
    myOrders: {
      key: 'myOrders', // 나의 수업 주문 목록
      queryFn: (params) => requestFunc(METHOD.GET, '/mypage/orders', params),
    },
    refreshMyOrders: {
      key: 'refreshMyOrders', // 나의 수업 주문 목록 폴링용
      queryFn: (params) => requestFunc(METHOD.GET, '/mypage/orders', params),
    },
    myOrderInfo: {
      key: 'myOrderInfo', // 주문 상세
      queryFn: (params) =>
        requestFunc(METHOD.GET, `/mypage/orders/${params.order_seq}`),
    },
    classroomDownloadMaterials: {
      key: 'classroomDownloadMaterials', // 수업 자료 다운로드
      queryFn: (params) =>
        requestFunc(
          METHOD.GET,
          `/classes/${params.class_seq}/material`,
          null,
          true,
        ),
    },
    classroomFeedbacks: {
      key: 'classroomFeedbacks', // 수업 피드백 목록 조회
      queryFn: (params) =>
        requestFunc(
          METHOD.GET,
          `/mypage/classrooms/${params.classroom_seq}/feedbacks`,
          params,
        ),
    },
    addClassroomFeedback: {
      key: 'addClassroomFeedback', // 수업 피드백 작성
      queryFn: (params) =>
        requestFunc(
          METHOD.POST,
          `/mypage/classrooms/${params.classroom_seq}/feedbacks`,
          params.data,
        ),
    },
    enterClassroom: {
      key: 'enterClassroom', // 수업 입장
      queryFn: (params) =>
        requestFunc(
          METHOD.POST,
          `/mypage/classrooms/${params.classroom_seq}/entry`,
          params,
        ),
    },
    leaveClassroom: {
      key: 'leaveClassroom', // 수업 퇴장
      queryFn: (params) =>
        requestFunc(
          METHOD.POST,
          `/mypage/classrooms/${params.classroom_seq}/leave`,
          params,
        ),
    },

    // 마이페이지 - 클래스 관리
    mySchedule: {
      key: 'mySchedule', // 나의 스케쥴
      queryFn: () => requestFunc(METHOD.GET, '/mypage/schedule'),
    },
    myFavorites: {
      key: 'myFavorites', // 찜한 수업 조회
      queryFn: (params) => requestFunc(METHOD.GET, '/mypage/favorites', params),
    },

    // 마이페이지 - 나의활동 - 수업 문의
    myInquires: {
      key: 'myInquires', // 수업 문의 조회
      queryFn: (params) => requestFunc(METHOD.GET, '/mypage/inquires', params),
    },
    deleteMyInquire: {
      key: 'deleteMyInquire', // 수업 문의 삭제
      queryFn: (params) =>
        requestFunc(METHOD.DELETE, `/mypage/inquires/${params.inquiry_seq}`),
    },
    updateMyInquire: {
      key: 'updateMyInquire', // 수업 문의 수정
      queryFn: (params) =>
        requestFunc(METHOD.PATCH, `/mypage/inquires/${params.inquiry_seq}`),
    },

    // 마이페이지 - 나의활동 - 수업 후기
    canReviews: {
      key: 'canReviews', // 작성 가능한 수업 후기
      queryFn: (params) =>
        requestFunc(METHOD.GET, '/mypage/reviews/writeable', params),
    },
    myReviews: {
      key: 'myReviews', // 작성한 수업 후기
      queryFn: (params) =>
        requestFunc(METHOD.GET, '/mypage/reviews/written', params),
    },
    addReview: {
      key: 'addReview', // 수업 후기 등록
      queryFn: (params) => requestFunc(METHOD.POST, '/mypage/reviews', params),
    },
    updateReview: {
      key: 'updateReview', // 수업 후기 수정
      queryFn: (params) =>
        requestFunc(
          METHOD.PATCH,
          `/mypage/reviews/${params.review_seq}`,
          params.data,
        ),
    },
    deleteReview: {
      key: 'deleteReview', // 수업 후기 삭제
      queryFn: (params) =>
        requestFunc(METHOD.DELETE, `/mypage/reviews/${params.review_seq}`),
    },
    reviewInfo: {
      key: 'reviewInfo', // 수업 후기 - 상세
      queryFn: (params) =>
        requestFunc(METHOD.GET, `/reviews/${params.review_seq}`),
    },

    // 마이페이지 - 프로필
    myBenefits: {
      key: 'myBenefits', // 나의 혜택
      queryFn: () => requestFunc(METHOD.GET, '/mypage/benefits'),
    },
    myProfile: {
      key: 'myProfile', // 회원 프로필 조회
      queryFn: () => requestFunc(METHOD.GET, '/mypage/profile'),
    },
    updateMyProfile: {
      key: 'updateMyProfile', // 회원 프로필 수정
      queryFn: (params) => requestFunc(METHOD.PATCH, '/mypage/profile', params),
    },
    updateMarketing: {
      key: 'updateMarketing', // 마케팅 정보 수신 동의
      queryFn: (params) =>
        requestFunc(METHOD.PATCH, '/mypage/profile/marketing', params),
    },
    resign: {
      key: 'resign', // 회원 탈퇴
      queryFn: (params) =>
        requestFunc(METHOD.PATCH, '/mypage/profile/resign', params),
    },
    myUsers: {
      key: 'myUsers', // 사용자 목록 조회
      queryFn: () => requestFunc(METHOD.GET, '/mypage/profile/users'),
    },
    addMyUser: {
      key: 'addMyUser', // 사용자 등록
      queryFn: (params) =>
        requestFunc(METHOD.POST, '/mypage/profile/users', params),
    },
    updateMyProfileUsers: {
      key: 'updateMyProfileUsers', // 사용자 등록/수정
      queryFn: (params) =>
        requestFunc(METHOD.PATCH, '/mypage/profile/users', params),
    },
    myUserInfo: {
      key: 'myUserInfo', // 사용자 상세 조회
      queryFn: (params) =>
        requestFunc(METHOD.GET, `/mypage/profile/users/${params.user_seq}`),
    },
    updateMyUser: {
      key: 'updateMyUser', // 사용자 수정
      queryFn: (params) =>
        requestFunc(
          METHOD.PATCH,
          `/mypage/profile/users/${params.user_seq}`,
          params,
        ),
    },
    deleteMyUser: {
      key: 'deleteMyUser', // 사용자 삭제
      queryFn: (params) =>
        requestFunc(METHOD.DELETE, `/mypage/profile/users/${params.user_seq}`),
    },

    // 마이페이지 - 포인트
    myPoint: {
      key: 'myPoint', // 나의 포인트
      queryFn: () => requestFunc(METHOD.GET, '/mypage/point'),
    },
    myPointHistory: {
      key: 'myPointHistory', // 나의 포인트 내역 조회
      queryFn: (params) =>
        requestFunc(METHOD.GET, '/mypage/point/history', params),
    },
    clubPoint: {
      key: 'clubPoint', // 클럽 포인트 조회
      queryFn: (params) => requestFunc(METHOD.POST, '/external/lep/SelectEbtUserCntrtInfo', params)
    },

    clubPointMileage: {
        key: 'clubPointMileage', // 클럽 포인트 조회
        queryFn: () => requestFunc(METHOD.POST, '/external/lep/userCntrtInfos')
    },

    clubPointSave: {
      key: 'clubPointSave', // 클럽 포인트 대표결제 수정
      queryFn: (params) => requestFunc(METHOD.POST, '/mypage/point/selectBookclubContract', params)
    },

    // 마이페이지 - 상세조회 - 배송조회
    waybillsInfo: {
      key: 'waybillsInfo',
      queryFn: (params) =>
        requestFunc(
          METHOD.GET,
          `/orders/${params.order_seq}/tracking_info/${params.waybill_seq}`,
        ),
    },

    inputReferralCode: {
      key: "inputReferralCode",
      queryFn: (params) => requestFunc(METHOD.POST, "/mypage/referral-code", params),
    },
  };
};
